import { GridColDef } from '@mui/x-data-grid'
import { ColumnDate } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'
import { ColumnPrint } from './ui/ColumnPrint'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата создания',
    field: 'createdAt',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => <ColumnDate type='date' value={value} />,
  },
  {
    headerName: 'Номер',
    field: 'number',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Гос. номер',
    field: 'plateNumber',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Ответственный менеджер',
    field: 'responsibleName',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Действия',
    field: 'action',
    sortable: false,
    renderCell: ({ row }) => <ColumnPrint id={row.id} />,
    maxWidth: 110,
  },
]
