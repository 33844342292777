import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'

import { workRuleModel } from 'src/entities/WorkRule'
import { WorkRule } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.work-rule.edit')
export const Gate = createGate<{ id: UniqueId }>()

export const $id = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { id }) => id)
export const $workRule = combine(
  $id,
  workRuleModel.$workRulesCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $id,
  filter: isString,
  target: workRuleModel.requestFx,
})

export const archiveFx = attach({
  effect: workRuleModel.archiveFx,
  source: $id,
  mapParams: (params, id) => {
    return new WorkRule({}, id as string)
  },
})
sample({
  clock: archiveFx.doneData,
  fn() {
    return {
      message: 'Условие работы отправлено в архив',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: archiveFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const archiveClicked = domain.createEvent()
sample({
  clock: archiveClicked,
  target: archiveFx,
})
