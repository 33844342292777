import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { carAtWorkSubStatusEnumOptions } from '~/shared/config/constants'
import { CarAtWorkSubStatusEnum } from '~/shared/config/enums'
import { uuidOptionSchema } from '~/shared/lib/schemas'
import { Button } from '~/shared/ui/Buttons'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { ClockSnoozeWhiteIcon } from '~/shared/ui/Icons'

const formSchema = z.object({
  reason: uuidOptionSchema,
})

export type FormValues = {
  reason: CarAtWorkSubStatusEnum
}

type FormProps = {
  onSuccess: (formValues: FormValues) => void
  onCancel: () => void
  isSubmitting: boolean
}

export function Form({ onSuccess, onCancel, isSubmitting }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })
  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <AutocompleteInput
        label='Причина'
        placeholder='Выберите причину приостановления договора'
        name='reason'
        options={carAtWorkSubStatusEnumOptions}
      />
      <br />
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          onClick={onCancel}
          disabled={isSubmitting}
          fullWidth
        >
          Отмена
        </Button>
        <Button
          variant='yellow'
          type='submit'
          loading={isSubmitting}
          fullWidth
          startDecorator={<ClockSnoozeWhiteIcon />}
        >
          Приостановить
        </Button>
      </Stack>
    </FormProvider>
  )
}
