import DoDisturbOnSharpIcon from '@mui/icons-material/DoDisturbOnSharp'
import LoopSharpIcon from '@mui/icons-material/LoopSharp'
import NotInterestedSharpIcon from '@mui/icons-material/NotInterestedSharp'
import { ReactNode } from 'react'
import { CheckStatusEnum } from '~/shared/config/enums'
import { myPalette } from '~/shared/lib/theme'
import { ChipStatus } from '~/shared/ui/Chips'
import {
  CheckCircleIcon,
  InfoCircleIcon,
  PauseCircleIcon,
  SlashCircleIcon,
} from '~/shared/ui/Icons'

type CheckStatusProps = {
  value: CheckStatusEnum | 'NO_RESULTS'
}

export function CheckStatus({ value }: CheckStatusProps) {
  const { Icon, label, color } = statuses[value]
  return <ChipStatus label={label} variant={color} startDecorator={Icon} />
}

const statuses: Record<
  CheckStatusProps['value'],
  {
    Icon: ReactNode
    label: string
    color: string
  }
> = {
  [CheckStatusEnum.NOT_FOUND]: {
    Icon: <NotInterestedSharpIcon />,
    label: 'Результаты не найдены',
    color: 'yellow',
  },
  [CheckStatusEnum.IN_PROGRESS]: {
    Icon: <LoopSharpIcon />,
    label: 'В процессе',
    color: 'gray',
  },
  [CheckStatusEnum.SUCCESS]: {
    Icon: <CheckCircleIcon />,
    label: 'Результаты получены',
    color: 'green',
  },
  [CheckStatusEnum.TIMEOUT_REACHED]: {
    Icon: <PauseCircleIcon color={myPalette.yellow['600']} />,
    label: 'Завершена по таймауту',
    color: 'yellow',
  },
  [CheckStatusEnum.CANCELLATION_IN_PROGRESS]: {
    Icon: <DoDisturbOnSharpIcon />,
    label: 'Отменяется',
    color: 'outlinedRed',
  },
  [CheckStatusEnum.CANCELLED]: {
    Icon: <DoDisturbOnSharpIcon />,
    label: 'Отменена',
    color: 'red',
  },
  [CheckStatusEnum.FAILED]: {
    Icon: <InfoCircleIcon color={myPalette.red['700']} />,
    label: 'Ошибка',
    color: 'red',
  },
  NO_RESULTS: {
    Icon: <SlashCircleIcon color={myPalette.red['700']} />,
    label: 'Нет результатов',
    color: 'red',
  },
}
