import { Grid } from '@mui/joy'
import {
  AggregatorAccount,
  CarRental,
  TelematicAccount,
  VehicleCategory,
} from '~/shared/api'
import { AsyncAutocompleteInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type AdditionalInformationProps = {
  showSkeleton?: boolean
  disabled?: boolean
}

export function AdditionalInformation({
  showSkeleton,
  disabled,
}: AdditionalInformationProps) {
  return (
    <Container>
      <ContainerTitle>Дополнительная информация</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Аккаунт Агрегатора'
            name='aggregatorAccountOption'
            placeholder='Выберете аккаунт агрегатора'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={AggregatorAccount.fetchOptions}
          />
        </Grid>
        <Grid xs={6}>
          <AsyncAutocompleteInput
            label='Арендодатель'
            name='carRentalId'
            placeholder='Выберете арендодателя'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={CarRental.fetchOptions}
          />
        </Grid>
        <Grid xs={6}>
          <AsyncAutocompleteInput
            label='Категория ТС'
            name='vehicleCategoryId'
            placeholder='Выберете категорию ТС'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={VehicleCategory.fetchOptions}
          />
        </Grid>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Аккаунт Телематики'
            name='telematicAccountOption'
            placeholder='Выберете аккаунт телематики'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            fetchOptions={TelematicAccount.fetchOptions}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='ID телематики'
            name='telematicAccountId'
            placeholder='ID телематики'
            skeletonShow={showSkeleton}
            readOnly
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Позывной'
            name='callsign'
            placeholder='Позывной'
            skeletonShow={showSkeleton}
            readOnly
          />
        </Grid>
      </Grid>
    </Container>
  )
}
