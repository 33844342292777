import { Stack, Typography } from '@mui/joy'
import { useStore } from 'effector-react'
import { useMemo } from 'react'
import { driverModel } from '~/entities/Driver'
import { getDeclension } from '~/shared/lib/utils'
import { ChipStatus } from '~/shared/ui/Chips'

export function ExperienceDays() {
  const isLoading = useStore(driverModel.experienceDaysFx.pending)
  const experienceDays = useStore(driverModel.$experienceDays)

  const label = useMemo(
    () =>
      `${experienceDays} ${getDeclension(experienceDays, [
        'день',
        'дня',
        'дней',
      ])}`,
    [experienceDays],
  )

  if (isLoading) return null

  return (
    <Stack
      data-testid='experience-days'
      direction='row'
      alignItems='center'
      spacing={1}
    >
      <Typography fontSize={12}>Стаж работы</Typography>

      <ChipStatus variant={experienceDays ? 'purple' : 'gray'} label={label} />
    </Stack>
  )
}
