import { Stack } from '@mui/material'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { carEditModel } from 'src/features/Сars/EditCar'
import { updateStatusFx } from '~/features/Сars/ui/Status/model'
import {
  carAtWorkSubStatusEnumOptions,
  carUnderRepairSubStatusEnumOptions,
} from '~/shared/config/constants'
import {
  CarStatusEnum,
  CarUnderRepairSubStatusEnum,
} from '~/shared/config/enums'
import { useModal } from '~/shared/ui/Modal'
import { AtWorkSubStatus, atWorkSubStatusModel } from '../AtWorkSubStatus'
import { ChangeStatusModal, FormValues } from '../ChangeStatusModal'
import { MainStatus, mainStatusModel } from '../MainStatus'
import {
  UnderRepairSubStatus,
  underRepairSubStatusModel,
} from '../UnderRepairSubStatus'

type StatusProps = {
  carId: UniqueId
}

export function Status({ carId }: StatusProps) {
  const { isModalShow, closeModal, openModal } = useModal()
  const car = useStore(carEditModel.$car)
  const carStatus = car?.getStatus()
  const carStatusLabel = car?.getStatusLabel()
  const carSubStatus = car?.getSubStatus()

  const isStatusUnderRepair = carStatus === CarStatusEnum.UNDER_REPAIR
  const isStatusAtWork = carStatus === CarStatusEnum.AT_WORK

  const mainStatusForm = useForm<mainStatusModel.FormValues>()
  const underRepairSubStatusForm =
    useForm<underRepairSubStatusModel.FormValues>()
  const atWorkSubStatusForm = useForm<atWorkSubStatusModel.FormValues>()

  const formMainStatus = mainStatusForm.watch('mainStatus')
  const formUnderRepairSubStatus = underRepairSubStatusForm.watch('subStatus')

  const handleDefaultMainStatusSet = () => {
    mainStatusForm.setValue(
      'mainStatus',
      carStatus && carStatusLabel
        ? { id: carStatus, label: carStatusLabel }
        : null,
    )
  }

  const handleDefaultSubStatusSet = () => {
    underRepairSubStatusForm.setValue(
      'subStatus',
      carUnderRepairSubStatusEnumOptions.find(
        ({ id }) => id === carSubStatus,
      ) || null,
    )
    atWorkSubStatusForm.setValue(
      'subStatus',
      carAtWorkSubStatusEnumOptions.find(({ id }) => id === carSubStatus) ||
        null,
    )
  }

  const handleMainStatusFormChange = () => {
    openModal()
  }

  const handleSubStatusFormChange = () => {
    openModal()
  }

  const handleModalClose = () => {
    handleDefaultMainStatusSet()
    handleDefaultSubStatusSet()
    closeModal()
  }

  const handleStatusChange = async ({
    comment,
    plannedDateEndRepair,
    subStatus,
  }: FormValues) => {
    await updateStatusFx({
      formValues: {
        status: formMainStatus?.id as CarStatusEnum,
        ...(comment ? { comment } : {}),
        ...(plannedDateEndRepair ? { plannedDateEndRepair } : {}),
        ...(subStatus
          ? { subStatus: subStatus as CarUnderRepairSubStatusEnum }
          : {}),
      },
      carId,
      fn: closeModal,
    })
  }

  useEffect(() => {
    handleDefaultMainStatusSet()
    // eslint-disable-next-line
  }, [carStatus])

  useEffect(() => {
    handleDefaultSubStatusSet()
    // eslint-disable-next-line
  }, [carSubStatus])

  return (
    <>
      <Stack spacing={2} direction='row'>
        <MainStatus
          onStatusChange={handleMainStatusFormChange}
          form={mainStatusForm}
        />
        {isStatusUnderRepair && (
          <UnderRepairSubStatus
            onStatusChange={handleSubStatusFormChange}
            form={underRepairSubStatusForm}
          />
        )}
        {isStatusAtWork && carSubStatus && (
          <AtWorkSubStatus form={atWorkSubStatusForm} />
        )}
      </Stack>

      <ChangeStatusModal
        isShow={isModalShow}
        mainStatus={formMainStatus?.id}
        subStatus={formUnderRepairSubStatus}
        onCancel={handleModalClose}
        onOk={handleStatusChange}
      />
    </>
  )
}
