import { z } from 'zod'
import { PtsDocument } from '~/shared/api'

export const formSchema = PtsDocument.schema.pick({
  series: true,
  number: true,
  issuedDate: true,
  location: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = TypeFormSchema
