import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { appealFormModel } from '~/entities/Balance/Appeal'
import { Appeal, AxiosErrorType } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const Gate = createGate()
export const domain = createDomain('features.balance-history.appeal.add')

export const setFiles = domain.createEvent<File[]>()
export const $files = domain
  .createStore<File[] | null>(null)
  .on(setFiles, (_, files) => files)
  .on(Gate.close, () => null)

export const saveFiles = async (appealId: UniqueId, files: File[] | null) => {
  if (!files?.length) return

  for (const file of files) {
    await Appeal.saveFiles(file, appealId)
  }
}

export const formSubmitted = domain.createEvent<appealFormModel.FormValues>()
export const createFx = domain.createEffect<
  appealFormModel.FormValues & {
    files: File[] | null
  },
  Appeal,
  AxiosErrorType
>({
  async handler(values) {
    const {
      files,
      amount,
      creatorComment,
      appealTypeOption,
      carOption,
      driverOption,
      subdivisionOption,
    } = values

    const appeal = new Appeal({ amount, creatorComment })

    if (isString(appealTypeOption)) appeal.setAppealType(appealTypeOption)
    if (isString(carOption)) appeal.setCar(carOption)
    if (isString(driverOption)) appeal.setDriver(driverOption)
    if (isString(subdivisionOption)) appeal.setSubdivision(subdivisionOption)

    const result = await appeal.save()
    const model = result.getModel() as Appeal

    await saveFiles(model?.getApiId() as string, files)
    return model
  },
})

sample({
  clock: formSubmitted,
  source: $files,
  fn(files, formValues) {
    return { ...formValues, files }
  },
  target: createFx,
})

sample({
  clock: [createFx.doneData],
  fn() {
    return {
      message: 'Обращение создано',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})

sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
