import {
  femaleDays,
  maleDays,
  weekDaysEnumOptions,
} from '~/shared/config/constants'
import { WeekDaysEnum } from '~/shared/config/enums'

export const getDayOffText = (rentalContractDayOff: string) => {
  const dayOffIndex = Object.keys(WeekDaysEnum).findIndex(
    (key: string) => key === rentalContractDayOff.toUpperCase(),
  )
  const textPrefix = maleDays.includes(dayOffIndex)
    ? 'каждый'
    : femaleDays.includes(dayOffIndex)
    ? 'каждая'
    : 'каждое'
  const dayOff = weekDaysEnumOptions[dayOffIndex].label
  return `(Выходной - ${textPrefix} ${dayOff})`
}
