import { Box, styled, List as JoyList, ListItem as JoyListItem } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const StickyBlock = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 100;
  backdrop-filter: blur(15px);
`

export const Wrapper = styled(Box)`
  padding: 24px 0;
  backdrop-filter: blur(15px);
  width: 80%;
  min-width: 1440px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`

export const List = styled(JoyList)`
  --List-padding: 0px;
`

export const ListItem = styled(JoyListItem)`
  margin-right: 8px;
  a {
    font-size: 14px;
    font-weight: 600;
    padding: 10px 14px;
    border-radius: 30px;
    background: transparent;
    transition: 0.3s ease;

    &:hover,
    &.Mui-selected {
      background: ${myPalette.white.main} !important;
    }
  }
`
