import { Box } from '@mui/joy'
import { useCallback, useState } from 'react'
import { logger } from '~/shared/lib/logger'
import { Modal } from '~/shared/ui/Modal'
import { Form } from './Form'

type AppealRejectModalProps = {
  isShow: boolean
  onOk: (comment: string) => Promise<void> | void
  onCancel: () => void
}

export function AppealRejectModal({
  isShow,
  onOk,
  onCancel,
}: AppealRejectModalProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleOnOk = useCallback(
    async ({ comment }: { comment: string }) => {
      try {
        setIsSubmitting(true)
        await onOk(comment)
      } catch (e) {
        logger.error('Appeal send form error', e)
      } finally {
        setIsSubmitting(false)
      }
    },
    [onOk],
  )

  return (
    <Modal
      isShow={isShow}
      onCancel={isSubmitting ? undefined : onCancel}
      title='Отказ'
      dialogProps={{
        sx: {
          minWidth: '600px',
          maxWidth: '600px',
        },
      }}
    >
      <Box sx={{ padding: '4px' }}>
        <Form
          onSuccess={handleOnOk}
          onCancel={onCancel}
          isSubmitting={isSubmitting}
        />
      </Box>
    </Modal>
  )
}
