import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import * as z from 'zod'
import { AxiosErrorType, Region } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const formSchema = Region.schema.pick({
  name: true,
  timezoneId: true,
})

export type SchemaType = z.infer<typeof formSchema>
export type FormValues = Omit<SchemaType, 'timezoneId'> & {
  timezoneId: UniqueId | Option | null
}

export const domain = createDomain('entities.taxipark.region')

export const requestFx = domain.createEffect<UniqueId, Region>({
  handler: fetchRegion,
})

export const saveFx = domain.createEffect<Region, Region, AxiosErrorType>({
  async handler(region) {
    await region.save()
    return fetchRegion(region.getApiId() as UniqueId)
  },
})

const {
  $cache: $regionsCache,
  useCache: useRegionCache,
  updateCache,
} = createCache<Region>({
  domain,
  getEntityId: (region) => region.getApiId() as UniqueId,
})
export { $regionsCache, useRegionCache }

$regionsCache
  .on(requestFx.doneData, (cache, region) => updateCache(cache, [region]))
  .on(saveFx.doneData, (cache, region) => updateCache(cache, [region], true))

export const $regionsError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useRegionError = (id: UniqueId) =>
  useStoreMap($regionsError, (errors) => errors[id])

async function fetchRegion(id: UniqueId) {
  const response = await Region.with('timezone').find(id)
  return response.getData() as Region
}
