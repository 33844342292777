import { AxiosError } from 'axios'
import { createDomain, sample, combine } from 'effector'
import { createGate } from 'effector-react'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import { interval } from 'patronum'

import { Driver, Document, AxiosErrorType } from '~/shared/api'
import { DriverDocumentType } from '~/shared/config/enums'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

const domain = createDomain('features.driver.documents')
export const Gate = createGate<{ driverId: UniqueId }>()

const $driverId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { driverId }) => driverId)
  .on(Gate.close, () => null)

export const $documents = domain
  .createStore<Document[] | null>([])
  .on(Gate.close, () => null)
export const $documentsByType = $documents.map((documents = []) =>
  groupBy(documents, (doc) => doc.getType()),
)

export const fileDropped = domain.createEvent<{
  documentType: DriverDocumentType
  file: File
}>()
export const documentDeleted = domain.createEvent<UniqueId>()

const requestDriverDocumentsFx = domain.createEffect<UniqueId, Document[]>({
  async handler(driverId) {
    const driver = new Driver({}, driverId)
    const response = await driver.documents().get(1)
    return response.getData() ?? []
  },
})
sample({
  clock: $driverId,
  filter: isString,
  target: requestDriverDocumentsFx,
})
sample({
  clock: requestDriverDocumentsFx.doneData,
  target: $documents,
})

export const $isDocumentsLoading = requestDriverDocumentsFx.pending
export const $isInitialLoading = combine(
  $documents,
  $isDocumentsLoading,
  (documents, isLoading) => {
    return documents === null && isLoading
  },
)

const saveDocumentFx = domain.createEffect<
  { driverId: UniqueId; file: File; type: DriverDocumentType },
  void,
  AxiosError
>({
  async handler({ driverId, file, type }) {
    const driver = new Driver({}, driverId)
    await driver.saveDocument({ file, type })
  },
})
const deleteDocumentFx = domain.createEffect<
  { documentId: UniqueId; driverId: UniqueId },
  void,
  AxiosErrorType
>({
  async handler({ documentId, driverId }) {
    const driver = new Driver({}, driverId)
    await driver.deleteDocument({ documentId })
  },
})
sample({
  clock: fileDropped,
  source: $driverId,
  filter: isString,
  fn(driverId, { file, documentType }) {
    return {
      driverId,
      file,
      type: documentType,
    }
  },
  target: saveDocumentFx,
})
sample({
  clock: saveDocumentFx.done,
  source: $driverId,
  filter: isString,
  target: requestDriverDocumentsFx,
})
sample({
  clock: saveDocumentFx.failData,
  fn(err) {
    let message
    switch (err.response?.status) {
      case 413:
        message = 'Ошибка загрузки документа: превышен допустимый размер'
        break
      case 422:
        message = 'Ошибка загрузки документа: недопустимый формат'
        break
      default:
        message = 'Ошибка загрузки документа'
    }
    return {
      message,
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: documentDeleted,
  source: $driverId,
  filter: isString,
  fn(driverId, documentId) {
    return {
      driverId,
      documentId,
    }
  },
  target: deleteDocumentFx,
})

sample({
  clock: deleteDocumentFx.done,
  source: $documents,
  fn(documents, { params: { documentId } }) {
    return documents?.filter((doc) => doc.getApiId() !== documentId) ?? null
  },
  target: $documents,
})
sample({
  clock: deleteDocumentFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $savingDocumentsCount = domain
  .createStore<Record<DriverDocumentType, number>>({
    [DriverDocumentType.PASSPORT]: 0,
    [DriverDocumentType.LICENSE]: 0,
    [DriverDocumentType.REGISTRATION]: 0,
  })
  .on(fileDropped, (state, { documentType }) => ({
    ...state,
    [documentType]: state[documentType] + 1,
  }))
  .on(
    [saveDocumentFx.done, saveDocumentFx.fail],
    (state, { params: { type } }) => ({
      ...state,
      [type]: state[type] - 1,
    }),
  )

const $pendingDocuments = domain
  .createStore<UniqueId[]>([])
  .on(documentDeleted, (state, docId) => [...state, docId])
  .on([deleteDocumentFx.done, deleteDocumentFx.fail], (state, { params }) =>
    state.filter((docId) => docId !== params.documentId),
  )

export const $thumbsByType = combine(
  $documentsByType,
  $pendingDocuments,
  (documents, pendingDocuments) => {
    return mapValues(documents, (docs) => {
      const thumbs = docs.map((doc) => {
        const id = doc.getApiId() as string
        return {
          id,
          src: doc.getBigTemporaryUrl(),
          preview: doc.getPreviewTemporaryUrl(),
          pending: pendingDocuments.includes(id),
        }
      })
      return [...thumbs.filter(({ preview }) => Boolean(preview))]
    })
  },
)

/* Обновление документов, если какие-то еще не готовы */
const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()
const { tick } = interval({
  timeout: 2000,
  start: intervalRequestStarted,
  stop: intervalRequestStopped,
  leading: true,
  trailing: false,
})
sample({
  clock: tick,
  source: $driverId,
  filter: isString,
  target: requestDriverDocumentsFx,
})
sample({
  clock: $documents,
  filter: (documents) => documents?.some((doc) => !doc.hasUrls()) ?? false,
  target: intervalRequestStarted,
})
sample({
  clock: $documents,
  filter: (documents) => documents?.every((doc) => doc.hasUrls()) ?? false,
  target: intervalRequestStopped,
})
