import { Stack } from '@mui/joy'
import { Typography } from '@mui/material'
import { useRef, useState } from 'react'
import {
  getCheckKindLabel,
  isFsspEmptyResults,
} from '~/entities/DriverCheck/lib'
import { FsspResults as FsspResultsType } from '~/shared/api'
import { CheckKindEnum } from '~/shared/config/enums'
import { Button } from '~/shared/ui/Buttons'
import { UploadWhiteIcon } from '~/shared/ui/Icons'
import { FsspResultsTable } from './FsspResultsTable'

type FsspResultsProps = { results: FsspResultsType; onClose: () => void }

export function FsspResults({ results, onClose }: FsspResultsProps) {
  const tableRef = useRef<HTMLTableElement>(null)
  const [isSaving, setIsSaving] = useState(false)
  const handleSave = () => {
    if (!tableRef.current) return

    setIsSaving(true)
    import('~/shared/lib/saveElementImage')
      .then(({ saveElementImage }) =>
        saveElementImage(tableRef.current as HTMLTableElement, 'ФССП.png'),
      )
      .finally(() => setIsSaving(false))
  }

  if (isFsspEmptyResults(results)) {
    return <Typography variant='body1'>Данные отсутствуют</Typography>
  }

  return (
    <Stack spacing={2} width={1400}>
      <Typography fontWeight={600} fontSize={20}>
        {getCheckKindLabel(CheckKindEnum.FSSP)}
      </Typography>

      <FsspResultsTable ref={tableRef} results={results} />
      <br />
      <Stack direction='row' justifyContent='space-between'>
        <Button variant='gray' onClick={onClose}>
          Закрыть
        </Button>
        <Button
          startDecorator={<UploadWhiteIcon />}
          onClick={handleSave}
          variant='brand'
          loading={isSaving}
        >
          Скачать
        </Button>
      </Stack>
    </Stack>
  )
}
