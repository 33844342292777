import { PaymentsTemplateType } from '~/shared/api'
import { RentalContractWorkScheduleEnum } from '~/shared/config/enums'
import { GridRow } from './types'

export const mapPaymentSchedulesToRows = (
  paymentsTemplate: PaymentsTemplateType[],
  workSchedule?: RentalContractWorkScheduleEnum,
): GridRow[] =>
  paymentsTemplate.map((template) => ({
    ...template,
    workSchedule,
  }))
