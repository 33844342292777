import { createDomain } from 'effector'
import { PaymentsTemplateDataType, RentalContract } from '~/shared/api'

export const domain = createDomain('entities.rentalContract.paymentSchedule')

export const paymentScheduleRentalContractFx = domain.createEffect<
  UniqueId,
  PaymentsTemplateDataType
>({
  async handler(rentalContractId: UniqueId) {
    return await RentalContract.getPaymentSchedule(rentalContractId)
  },
})

export const $paymentsTemplate = domain
  .createStore<PaymentsTemplateDataType | null>(null)
  .on(paymentScheduleRentalContractFx.doneData, (_, data) => data)
