import { useGate, useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { appealModel } from '~/entities/Balance/Appeal'
import { balanceHistoryModel, Form } from '~/entities/Balance/BalanceHistory'
import { DriverOptionForTransaction } from '~/shared/api'
import {
  transactionBalanceTypeEnumLabels,
  transactionMoveFundsTypeEnumLabels,
  transactionPaymentTypeEnumLabels,
} from '~/shared/config/constants'
import {
  TransactionBalanceTypeEnum,
  TransactionMoveFundsTypeEnum,
  TransactionPaymentTypeEnum,
} from '~/shared/config/enums'
import { formatMoscowDateTime } from '~/shared/lib/date'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle } from '~/shared/ui/Layout'

type AppealCreateTransactionProps = {
  moveFundsType: TransactionMoveFundsTypeEnum
}

export function AppealCreateTransaction({
  moveFundsType,
}: AppealCreateTransactionProps) {
  const { appealId } = useParams<{ appealId: string }>()

  useGate(appealModel.Gate, { appealId })

  const isSubmitting = useStore(balanceHistoryModel.createFx.pending)
  const isLoading = useStore(appealModel.requestFx.pending)

  const defaultValues = useStoreMap(appealModel.$appeal, (appeal) => {
    const driver = appeal?.getDriver()
    return {
      ...appeal?.getAttributes(),
      driverOption: driver
        ? {
            ...(appeal
              ?.getDriver()
              ?.getDriverOptionForTransaction() as DriverOptionForTransaction),
            carOption: appeal?.getCar()?.getOption(),
          }
        : null,
      appealId,
      amount: appeal?.getAmount(),
      moveFundsType: {
        id: moveFundsType,
        label: transactionMoveFundsTypeEnumLabels[moveFundsType],
      },
      paymentType: {
        id: TransactionPaymentTypeEnum.NON_CASH,
        label:
          transactionPaymentTypeEnumLabels[TransactionPaymentTypeEnum.NON_CASH],
      },
      balanceType: {
        id: TransactionBalanceTypeEnum.MAIN,
        label:
          transactionBalanceTypeEnumLabels[TransactionBalanceTypeEnum.MAIN],
      },
    }
  })
  const appeal = useStore(appealModel.$appeal)

  const title = useMemo(
    () =>
      appeal
        ? `Обращение № ${appeal?.getNumber()} от ${formatMoscowDateTime(
            appeal?.getCreatedAt() as string,
          )}`
        : 'Обращение № ...',
    [appeal],
  )

  const breadcrumbs: BreadcrumbsList = [
    {
      title: 'Баланс',
      link: '/balance/balance-histories',
    },
    {
      title: 'Обращения',
      link: '/balance/appeals',
    },
    {
      title: title,
      link: `/balance/appeals/${appealId}`,
    },
    {
      title: 'Создание транзакции',
    },
  ]

  return (
    <>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание транзакции</PageTitle>
      </Container>
      <Form
        defaultValues={defaultValues}
        isReadOnlyDriver
        isReadOnlyCar
        onSuccess={balanceHistoryModel.formSubmitted}
        formWatchOption={{
          flowStatement: { autoSet: true, separator: ';' },
        }}
        isCreating
        isSubmitting={isSubmitting}
        isLoading={isLoading}
      />
    </>
  )
}
