import { AddEmployee } from '~/features/Taxipark/Employees/AddEmployee'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Сотрудники', link: '/taxipark/employees' },
  { title: 'Создание сотрудника' },
]

export const AddEmployeePage = () => {
  return (
    <PageContainer title='Создание сотрудника'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание сотрудника</PageTitle>
      </Container>
      <AddEmployee />
    </PageContainer>
  )
}
