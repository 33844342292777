import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'
import {
  FormValues,
  carLicenseeModel,
} from '~/entities/Organizations/CarLicensee'
import { CarLicensee } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ carLicenseeId: UniqueId }>()
export const domain = createDomain('features.organizations.carLicensees.edit')

export const $carLicenseeId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { carLicenseeId }) => carLicenseeId)

export const $carLicensee = combine(
  $carLicenseeId,
  carLicenseeModel.$carLicenseesCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $carLicenseeId,
  filter: isString,
  target: carLicenseeModel.requestFx,
})

export const carLicenseeUpdateFx = attach({
  effect: carLicenseeModel.saveFx,
  source: $carLicenseeId,
  mapParams: (values: FormValues, id) => {
    const { ...attr } = values
    const carLicensee = new CarLicensee(attr, id as string)

    return carLicensee
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: carLicenseeUpdateFx,
})

sample({
  clock: carLicenseeUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: carLicenseeUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
