import { useCallback, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { DriverOptionForTransaction } from '~/shared/api'
import { getObject } from '~/shared/lib/utils'
import { fetchAggregatorAccountByCarOptionFx, FormValues } from './model'

export type BalanceUseFormWatchOptions = {
  flowStatement?: {
    autoSet?: boolean
    separator?: string
  }
}
export const useFormWatch = (
  form: UseFormReturn<FormValues>,
  options?: BalanceUseFormWatchOptions,
) => {
  const { watch, setValue, getValues } = form

  const driverOption = watch('driverOption')

  const handleDriverOptionSet = useCallback(
    async (driverOption?: DriverOptionForTransaction) => {
      const carOption = driverOption?.carOption
      setValue('carOption', carOption || null)
      setValue('aggregatorAccount', undefined)

      if (driverOption?.carOption?.id) {
        const res = await fetchAggregatorAccountByCarOptionFx(
          driverOption?.carOption?.id,
        )
        setValue('aggregatorAccount', res)
      }
    },
    [setValue],
  )

  watch(async (data, { name, type }) => {
    if (type !== 'change') return
    const formData = data as FormValues

    if (name === 'carOption') {
      const carOption = getObject(formData.carOption)
      setValue('aggregatorAccount', carOption?.aggregatorAccount)
    }
    if (name === 'flowStatementOption' && options?.flowStatement?.autoSet) {
      const flowStatementOption = getObject(formData.flowStatementOption)
      setValue(
        'comment',
        `${flowStatementOption?.label}${options?.flowStatement?.separator}`,
      )
    }
  })

  useEffect(() => {
    if (driverOption)
      handleDriverOptionSet(getObject(getValues('driverOption')))
    // eslint-disable-next-line
  }, [ driverOption])
}
