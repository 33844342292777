import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'
import { User } from './user'

const schema = z.object({
  from: z.string(),
  to: z.string(),
  createdAt: dateSchema,
  comment: z.string().nullable(),
})

export type StatusTransitionAttributes = z.infer<typeof schema>

export class StatusTransition extends ApiModel<typeof schema> {
  static jsonApiType = 'status-transitions'

  static schema = schema

  responsible(): ToOneRelation<User, this> {
    return this.hasOne(User)
  }

  getResponsible(): User {
    return this.getRelation('responsible')
  }

  getFrom(): string {
    return this.getAttribute('from') as string
  }

  getTo(): string {
    return this.getAttribute('to') as string
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt') as string
  }
}
