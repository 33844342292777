import { AxiosError } from 'axios'
import { useCallback, useState } from 'react'
import { openBlobFile } from '~/shared/lib/file/openBlobFile'
import { logger } from '~/shared/lib/logger'
import { mapMessageBlobErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { myPalette } from '~/shared/lib/theme'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { FileIcon, PrintIcon } from '~/shared/ui/Icons'

type FileExportBlobProps = {
  fn: () => Promise<Blob> | void | Blob
  title: string
  target?: '_self' | '_blank' | '_parent' | '_top'
  isIcon?: boolean
}

export function FileExportBlob({
  fn,
  title,
  target = '_self',
  isIcon,
}: FileExportBlobProps) {
  const [isLoading, toggleIsLoading] = useState(false)

  const handlePrintFile = useCallback(async () => {
    try {
      toggleIsLoading(true)
      const response = await fn()

      if (!response) return

      openBlobFile(response, target)
    } catch (e) {
      logger.error(e)
      const errorMessage = await mapMessageBlobErrors(e as AxiosError<Blob>)

      snackbarEnqueued({
        message: errorMessage,
        variant: 'error' as const,
      })
    } finally {
      toggleIsLoading(false)
    }
  }, [fn, target])

  if (isIcon) {
    return (
      <IconButton
        onClick={handlePrintFile}
        loading={isLoading}
        tooltipProps={{ title }}
      >
        <PrintIcon />
      </IconButton>
    )
  }

  return (
    <Button
      startDecorator={<FileIcon color={myPalette.brand['700']} />}
      onClick={handlePrintFile}
      loading={isLoading}
      variant='gray'
    >
      {title}
    </Button>
  )
}
