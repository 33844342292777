import { useStore, useStoreMap, useGate } from 'effector-react'
import { Form, carLicenseeModel } from '~/entities/Organizations/CarLicensee'
import { usePermissions } from '~/entities/viewer'
import { formSubmitted, Gate, $carLicensee, carLicenseeUpdateFx } from './model'

type EditCarLicenseeProps = {
  carLicenseeId: string
}

export function EditCarLicensee({ carLicenseeId }: EditCarLicenseeProps) {
  useGate(Gate, { carLicenseeId })
  const { canCarOrganizationsUpdate } = usePermissions()

  const isLoading = useStore(carLicenseeModel.requestFx.pending)
  const isSaving = useStore(carLicenseeUpdateFx.pending)

  const defaultValues = useStoreMap($carLicensee, (carLicensee) => ({
    ...carLicensee?.getAttributes(),
  }))

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canCarOrganizationsUpdate}
    />
  )
}
