import {
  USER_TAXIPARK_STORAGE_KEY,
  USER_PERMISSIONS_STORAGE_KEY,
  USER_REGIONS_STORAGE_KEY,
} from '~/shared/config/constants'
import { storageGet, storageRemove, storageSet } from '~/shared/lib/storage'

import {
  Permissions,
  Regions,
  TaxiparkId,
  UserOptionsMe,
  UserOptionsStoreType,
} from './types'

export class UserOptionsStore implements UserOptionsStoreType {
  private readonly storageKeyPermissions = USER_PERMISSIONS_STORAGE_KEY
  private readonly storageKeyTaxipark = USER_TAXIPARK_STORAGE_KEY
  private readonly storageKeyRegions = USER_REGIONS_STORAGE_KEY

  private fallbackPermissions: Permissions = null
  private fallbackTaxipark: TaxiparkId = null
  private fallbackRegions: Regions = null

  get permissions(): Permissions {
    const storageByKey = storageGet<Permissions>(this.storageKeyPermissions)

    return this.fallbackPermissions || storageByKey
  }

  get taxipark(): TaxiparkId {
    const storageByKey = storageGet<TaxiparkId>(this.storageKeyTaxipark)

    return this.fallbackTaxipark || storageByKey
  }

  get regions(): Regions {
    const storageByKey = storageGet<Regions>(this.storageKeyRegions)

    return this.fallbackRegions || storageByKey
  }

  saveTaxipark(taxipark: TaxiparkId) {
    storageSet<TaxiparkId>(this.storageKeyTaxipark, taxipark)
    this.fallbackTaxipark = taxipark
  }

  save(options: UserOptionsMe) {
    const { permissions, taxipark, regions } = options

    const taxiparkId = taxipark?.id || ''

    storageSet<Permissions>(this.storageKeyPermissions, permissions)
    storageSet<TaxiparkId>(this.storageKeyTaxipark, taxiparkId)
    storageSet<Regions>(this.storageKeyRegions, regions)

    this.fallbackPermissions = permissions
    this.fallbackTaxipark = taxiparkId
    this.fallbackRegions = regions
  }

  clear() {
    storageRemove(this.storageKeyPermissions)
    storageRemove(this.storageKeyTaxipark)
    storageRemove(this.storageKeyRegions)

    this.fallbackPermissions = null
    this.fallbackTaxipark = null
    this.fallbackRegions = null
  }
}
