import { attach, combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { rentalContractModel } from '~/entities/RentalContract'
import { FormValues } from '~/entities/RentalContract/ui/Form'
import {
  AxiosErrorType,
  Car,
  CarOptionForRentalContract,
  RentalContract,
} from '~/shared/api'
import { daysEnumLabels } from '~/shared/config/constants'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ rentalContractId: UniqueId }>()
export const domain = createDomain('features.rentalContract.view')

export const $rentalContractId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { rentalContractId }) => rentalContractId)

export const update = domain.createEvent<FormValues>()
export const updateFx = attach({
  effect: rentalContractModel.saveFx,
  mapParams: ({
    formValues,
    rentalContractId,
  }: {
    formValues: FormValues
    rentalContractId: UniqueId
  }) => {
    const { workRuleOption, workSchedule, dayOff, rentalServicesOptions } =
      formValues

    const rentalContract = new RentalContract(
      {
        ...(isString(workSchedule) && { workSchedule }),
        ...(isString(dayOff) && { dayOff }),
      },
      rentalContractId,
    )

    if (isString(workRuleOption)) rentalContract.setWorkRule(workRuleOption)
    if (Array.isArray(rentalServicesOptions)) {
      rentalContract.setRentalServices(rentalServicesOptions.filter(isString))
    }

    return rentalContract
  },
})

sample({
  clock: update,
  source: $rentalContractId,
  filter: isString,
  fn(rentalContractId, formValues) {
    return {
      rentalContractId,
      formValues,
    }
  },
  target: updateFx,
})

sample({
  clock: updateFx.doneData,
  fn() {
    return {
      message: 'Договор успешно отредактирован',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: updateFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $rentalContract = combine(
  $rentalContractId,
  rentalContractModel.$rentalContractCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

export const getCarFx = domain.createEffect<UniqueId, Car, AxiosErrorType>({
  async handler(carId) {
    const res = await Car.with('vehicleCategory').with('region').find(carId)
    return res.getData() as Car
  },
})

sample({
  clock: getCarFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $car = domain
  .createStore<Car | null>(null)
  .on(getCarFx.doneData, (_, car) => car)
  .on(Gate.close, () => null)

export const $defaultValues = combine(
  $rentalContract,
  $car,
  (rentalContract, car) => {
    const carId = rentalContract?.getCarId()
    const workRuleId = rentalContract?.getWorkRuleId()
    const workSchedule = rentalContract?.getWorkSchedule()
    const dayOff = rentalContract?.getDayOff()
    return {
      ...rentalContract?.getAttributes(),
      driverFullName: rentalContract?.getDriverFullName(),
      driverId: rentalContract?.getDriver()?.getApiId(),
      responsibleManager: rentalContract?.getResponsibleManager()?.getName(),
      carOption: carId
        ? ({
            id: carId,
            label: rentalContract?.getCarPlateNumber(),
            filters: car?.getOptionForRentalContract().filters,
          } as CarOptionForRentalContract)
        : null,
      workRuleOption: workRuleId
        ? {
            id: workRuleId,
            label: rentalContract?.getWorkRuleTitle() as string,
          }
        : null,
      workSchedule: workSchedule
        ? {
            id: workSchedule,
            label: workSchedule,
          }
        : null,
      dayOff: dayOff ? { id: dayOff, label: daysEnumLabels[dayOff] } : null,
      brand: rentalContract?.getCarBrandTitle(),
      model: rentalContract?.getCarModelTitle(),
      rental: rentalContract?.getCarRentalName(),
      rentalServicesOptions: (rentalContract?.getRentalServices() || []).map(
        (rentalService) => rentalService.getOption(),
      ),
    }
  },
)

sample({
  clock: Gate.open,
  source: $rentalContract,
  filter: (rentalContract) => Boolean(rentalContract),
  fn: (rentalContract) => rentalContract?.getCarId() as UniqueId,
  target: getCarFx,
})

sample({
  clock: $rentalContract,
  filter: (rentalContract) => Boolean(rentalContract),
  fn: (rentalContract) => rentalContract?.getCarId() as UniqueId,
  target: getCarFx,
})

sample({
  clock: $rentalContractId,
  filter: isString,
  target: rentalContractModel.requestFx,
})

sample({
  clock: [
    rentalContractModel.terminateRentalContractFx.doneData,
    rentalContractModel.suspendRentalContractFx.doneData,
    rentalContractModel.continueRentalContractFx.doneData,
  ],
  source: $rentalContractId,
  filter: isString,
  target: rentalContractModel.unseenRequestFx,
})
