import DeleteIcon from '@mui/icons-material/Delete'
import { Stack } from '@mui/joy'
import { useStore } from 'effector-react'
import { AuditsModal } from 'src/widgets/AuditsModal'
import { useRole, useStatus } from '~/entities/Balance/Appeal'
import { usePermissions } from '~/entities/viewer'
import { Button } from '~/shared/ui/Buttons'
import { DownloadIcon, UploadWhiteIcon } from '~/shared/ui/Icons'
import { appealDelete, appealDeleteFx } from './model'

type ActionButtonsProps = {
  appealId: UniqueId
}

export function ActionButtons({ appealId }: ActionButtonsProps) {
  const { canAuditsView } = usePermissions()
  const isDeletePending = useStore(appealDeleteFx.pending)

  const { isInitiator, isResponsible } = useRole()
  const { isNew, isRejected } = useStatus()

  const canEdit = isNew && isInitiator
  const canTransactionCreate = !isRejected && isResponsible

  return (
    <Stack direction='row' spacing={1}>
      {canAuditsView && <AuditsModal auditableId={appealId} />}

      <Button
        show={canTransactionCreate}
        to='new-spending'
        variant='red'
        startDecorator={<UploadWhiteIcon />}
      >
        Списать
      </Button>
      <Button
        show={canTransactionCreate}
        to='new-coming'
        variant='green'
        startDecorator={<DownloadIcon />}
      >
        Пополнить
      </Button>
      <Button
        variant='red'
        startDecorator={<DeleteIcon />}
        onClick={() => appealDelete()}
        loading={isDeletePending}
        show={canEdit}
      >
        Удалить
      </Button>
    </Stack>
  )
}
