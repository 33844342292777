import { useStore, useStoreMap, useGate } from 'effector-react'
import {
  Form,
  aggregatorAccountModel,
} from '~/entities/Taxipark/AggregatorAccount'
import {
  formSubmitted,
  Gate,
  $aggregatorAccount,
  aggregatorAccountUpdateFx,
} from './model'

type EditAggregatorAccountProps = {
  aggregatorAccountId: string
  isEditAvailable?: boolean
}

export function EditAggregatorAccount({
  aggregatorAccountId,
}: EditAggregatorAccountProps) {
  useGate(Gate, { aggregatorAccountId })

  const isLoading = useStore(aggregatorAccountModel.requestFx.pending)
  const isSaving = useStore(aggregatorAccountUpdateFx.pending)

  const defaultValues = useStoreMap($aggregatorAccount, (aggregatorAccount) => {
    const defaultYandexWorkRule = aggregatorAccount?.getDefaultYandexWorkRule()
    return {
      ...aggregatorAccount?.getAttributes(),
      defaultYandexWorkRuleOption: defaultYandexWorkRule
        ? defaultYandexWorkRule.getOption()
        : null,
    }
  })

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={false}
    />
  )
}
