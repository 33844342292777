import { useGate, useStore, useStoreMap } from 'effector-react'
import { regionModel, Form } from '~/entities/Taxipark/Regions'
import { usePermissions } from '~/entities/viewer'
import { formSubmitted, Gate, $region, regionUpdateFx } from './model'

type EditRegionProps = {
  regionId: string
}

export function EditRegion({ regionId }: EditRegionProps) {
  useGate(Gate, { regionId })
  const { canRegionsUpdate } = usePermissions()

  const isLoading = useStore(regionModel.requestFx.pending)
  const isSaving = useStore(regionUpdateFx.pending)

  const defaultValues = useStoreMap($region, (region) => {
    const timezoneId = region?.getTimezone()
    return {
      name: region?.getName(),
      timezoneId: timezoneId ? region?.getTimezone()?.getOption() : null,
    }
  })

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canRegionsUpdate}
    />
  )
}
