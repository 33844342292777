import { z } from 'zod'
import { StsDocumentAttributes } from '~/shared/api/stsDocument'
import { dateSchema } from '~/shared/lib/schemas'
import { Car } from './car'
import { ApiModel } from './core'

const schema = z.object({
  number: z
    .string()
    .trim()
    .min(1, 'Обязательное поле')
    .refine((value) => /^\d*$/.test(value), 'Только цифры'),
  issuedDate: dateSchema,
  expirationDate: dateSchema,

  createdAt: dateSchema,
  updatedAt: dateSchema.optional(),

  files: z
    .object({
      id: z.string(),
      name: z.string(),
      filepath: z.string().url(),
      preview: z.string().url(),
    })
    .array()
    .optional(),
})

export type DiagnosticCardAttributes = z.infer<typeof schema>

export class DiagnosticCard extends ApiModel<typeof schema> {
  static jsonApiType = 'diagnostic-cards'

  static schema = schema

  setCar(id: UniqueId) {
    this.setRelation('car', new Car({}, id))
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }

  getFiles(): StsDocumentAttributes['files'] {
    return this.getAttribute('files')
  }
}
