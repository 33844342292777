import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import RoutesTabs from '~/shared/ui/RoutesTabs'
import { useConfigTabs } from './useConfigTabs'

function TaxiparkPage() {
  const configTabs = useConfigTabs()

  return (
    <PageContainer title='Таксопарк'>
      <RoutesTabs
        parentPath='/taxipark'
        config={configTabs}
        parentContent={
          <Container isHeader>
            <PageTitle>Настройки</PageTitle>
          </Container>
        }
      />
    </PageContainer>
  )
}

export default TaxiparkPage
