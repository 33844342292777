import { GridColDef } from '@mui/x-data-grid'
import { Storno } from '~/features/Balance/BalanceHistory/Storno'
import { priceFormat } from '~/shared/lib/utils'
import {
  ColumnAmount,
  ColumnDate,
  ColumnLink,
  ColumnValue,
} from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const useColumns = (gridQueryKey: string): GridColDef<GridRow>[] => {
  return [
    {
      headerName: 'Дата операции',
      field: 'appliedAt',
      minWidth: 160,
      renderCell: ({ row }) => (
        <ColumnLink to={row.id}>
          <ColumnDate type='date' value={row.appliedAt} />
        </ColumnLink>
      ),
    },
    {
      headerName: 'Время операции',
      field: 'appliedAtTime',
      sortable: false,
      minWidth: 150,
      renderCell: ({ row }) => <ColumnDate type='time' value={row.appliedAt} />,
    },
    {
      headerName: 'Приход',
      field: 'coming-{amount}',
      minWidth: 160,
      renderCell: ({ value }) => (
        <ColumnAmount moveFunds='COMING' amount={value} />
      ),
    },
    {
      headerName: 'Расход',
      field: 'expenditure-{amount}',
      minWidth: 160,
      renderCell: ({ value }) => (
        <ColumnAmount moveFunds='SPENDING' amount={value} />
      ),
    },
    {
      headerName: 'Остаток',
      field: 'balanceAmount',
      minWidth: 180,
      renderCell: ({ value }) =>
        value && <ColumnValue>{priceFormat(value)}</ColumnValue>,
    },
    {
      headerName: 'Статья оборотов',
      field: 'flowStatementName',
      minWidth: 260,
    },
    {
      headerName: 'Описание',
      field: 'comment',
      flex: 1,
    },
    {
      headerName: 'Пользователь / Источник',
      field: 'sourceType',
      minWidth: 220,
    },
    {
      headerName: 'Действия',
      field: 'actions',
      sortable: false,
      minWidth: 60,
      renderCell: ({ row }) =>
        !row.stornoId && (
          <Storno isIcon transactionId={row.id} gridQueryKey={gridQueryKey} />
        ),
    },
  ]
}
