import { Grid } from '@mui/joy'
import { MaskInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type ContactsProps = {
  isLoading: boolean
  disabled: boolean
}

export function Contacts({ isLoading, disabled }: ContactsProps) {
  return (
    <Container>
      <ContainerTitle>Контакты</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <MaskInput
            label='Телефон'
            name='phone_number'
            skeletonShow={isLoading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='E-mail'
            name='email'
            type='email'
            readOnly={disabled}
            skeletonShow={isLoading}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Сайт'
            name='web_site'
            skeletonShow={isLoading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Основной расчетный счет'
            name='main_checking_account'
            readOnly={disabled}
            skeletonShow={isLoading}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Юридический адрес'
            name='legal_address'
            readOnly={disabled}
            skeletonShow={isLoading}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Фактический адрес'
            name='actual_address'
            readOnly={disabled}
            skeletonShow={isLoading}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
