import { useGate, useStore, useStoreMap } from 'effector-react'
import React from 'react'
import { permissionModel } from '~/entities/Taxipark/Permission'
import { Form } from '~/entities/Taxipark/Role'
import { $createRoleModel, createFx, formSubmitted } from './model'

export const AddRole = () => {
  useGate(permissionModel.PermissionsGate)

  const isPermissionsLoading = useStore(
    permissionModel.fetchPermissionsFx.pending,
  )
  const permissions = useStore(permissionModel.$permissions)

  const defaultValues = useStoreMap(
    $createRoleModel,
    (createRoleModel) => createRoleModel,
  )

  const isCreating = useStore(createFx.pending)

  return (
    <Form
      isSubmitting={isCreating}
      onSuccess={formSubmitted}
      defaultValues={defaultValues}
      permissions={permissions || []}
      isPermissionsLoading={isPermissionsLoading}
      isCreating
    />
  )
}
