import { combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import { Appeal } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('entities.balance.appeal')

export const Gate = createGate<{ appealId: UniqueId | undefined }>()

export const $appealId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { appealId }) => appealId)
  .on(Gate.close, () => null)

export const requestFx = domain.createEffect<UniqueId, Appeal>({
  handler: fetchAppealWithRelations,
})

export const requestSilentFx = domain.createEffect<UniqueId, Appeal>({
  handler: fetchAppealWithRelations,
})

export const saveFx = domain.createEffect<Appeal, Appeal>({
  async handler(appeal) {
    await appeal.save()
    return fetchAppealWithRelations(appeal.getApiId() as UniqueId)
  },
})

sample({
  clock: $appealId,
  filter: isString,
  target: requestFx,
})

const {
  $cache: $appealsCache,
  useCache: useAppealCache,
  updateCache,
} = createCache<Appeal>({
  domain,
  getEntityId: (appeal) => appeal.getApiId() as UniqueId,
})
export { $appealsCache, useAppealCache }

$appealsCache
  .on([requestFx.doneData, requestSilentFx.doneData], (cache, appeal) =>
    updateCache(cache, [appeal]),
  )
  .on(saveFx.doneData, (cache, appeal) => updateCache(cache, [appeal], true))

export const $appealsError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(
    [requestFx.fail, requestSilentFx.fail],
    (store, { error, params: id }) => ({
      [id]: error,
      ...store,
    }),
  )

export const useAppealError = (id: UniqueId | undefined) =>
  useStoreMap($appealsError, (errors) => id && errors[id])

export async function fetchAppealWithRelations(id: UniqueId) {
  const response = await Appeal.with('driver')
    .with('car')
    .with('appealType')
    .with('subdivision')
    .with('creator')
    .with('responsible')
    .with('balanceHistories')
    .find(id)
  return response.getData() as Appeal
}

export const $appeal = combine($appealId, $appealsCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})
