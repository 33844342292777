import { z } from 'zod'

import { CounterpartiesTypeEnum } from '~/shared/config/enums'
import { ApiModel } from './core'

const schema = z.object({
  inn: z.string(),
  name: z.string(),
})

export type CounterpartiesAttributes = z.infer<typeof schema>

export class Counterparty extends ApiModel<typeof schema> {
  static jsonApiType = 'counterparties'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  static async fetchOptionsByType(
    search: string,
    type: CounterpartiesTypeEnum,
  ) {
    const response = await Counterparty.where('name', search)
      .where('counterpartyTypeCode', type)
      .get(1)
    return response.getData().map((o) => o.getOption())
  }
}
