import { z } from 'zod'
import { Car } from '~/shared/api'

export const formSchema = Car.schema.pick({
  plannedDateEndRepair: true,
  repairComment: true,
})

type SchemaType = z.infer<typeof formSchema>

export type FormValues = Omit<SchemaType, 'plannedDateEndRepair'> & {
  plannedDateEndRepair: string
}
