import * as z from 'zod'

import { numberRequiredScheme, phoneScheme } from '~/shared/lib/schemas'

import { ApiModel } from './core'

const schema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
  full_name: z.string().trim().min(1, 'Обязательное поле'),
  inn: numberRequiredScheme,
  kpp: numberRequiredScheme,
  okpo: numberRequiredScheme,
  ogrn: numberRequiredScheme,
  phone_number: phoneScheme.optional().nullable(),
  email: z.string().email('Неверный адрес электронной почты'),
  web_site: z.string().url('Не верный url'),
  legal_address: z.string().trim().min(1, 'Обязательное поле'),
  actual_address: z.string().trim().min(1, 'Обязательное поле'),
  main_checking_account: numberRequiredScheme,
})

export type TaxiparkAttributes = z.infer<typeof schema>

export class Taxipark extends ApiModel<typeof schema> {
  static jsonApiType = 'taxiparks'

  static schema = schema
}
