import { useStore, useStoreMap } from 'effector-react'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import {
  appealModel,
  AppealRejectModal,
  SolutionFormValues,
  useRole,
} from '~/entities/Balance/Appeal'
import {
  appealsStatusEnumLabels,
  AppealsStatusEnumOptions,
  STATUS_VARIANT,
} from '~/shared/config/constants'
import { AppealsStatusEnum } from '~/shared/config/enums'
import { logger } from '~/shared/lib/logger'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { useModal } from '~/shared/ui/Modal'
import { statusFormUpdateFx } from './model'
import { StatusFormValues } from './type'

export function Status() {
  const { isModalShow, openModal, closeModal } = useModal()
  const { isInitiator, isResponsible } = useRole()
  const isStatusChanging = useStore(statusFormUpdateFx.pending)
  const isAppealFetching = useStore(appealModel.requestSilentFx.pending)
  const isLoading = useStore(appealModel.requestFx.pending)

  const form = useForm()
  const { watch, reset, setValue, getValues } = form

  const statusColor = useStoreMap(
    appealModel.$appeal,
    (appeal) => appeal?.getStatusColor() || 'gray',
  )

  const defaultStatus = useStoreMap(appealModel.$appeal, (appeal) =>
    AppealsStatusEnumOptions.find(({ id }) => id === appeal?.getStatus()),
  ) as StatusFormValues['status']

  const responsibleComment = useStoreMap(appealModel.$appeal, (appeal) =>
    appeal?.getResponsibleComment(),
  )

  const defaultValues = useStoreMap(appealModel.$appeal, (appeal) => {
    const status = appeal?.getStatus()
    return {
      status: status && {
        id: status,
        label: appealsStatusEnumLabels[status],
      },
    }
  })

  const statusId = watch('status')?.id

  const filteredStatus = useMemo(() => {
    if (isInitiator && !isResponsible) {
      return AppealsStatusEnumOptions.filter(
        ({ id }) =>
          id === AppealsStatusEnum.REJECTED || id === AppealsStatusEnum.NEW,
      )
    }
    return AppealsStatusEnumOptions
  }, [isInitiator, isResponsible])

  const canStatusChange =
    (isInitiator && statusId === AppealsStatusEnum.REJECTED) || isResponsible

  const handleStatusReset = useCallback(
    () => setValue('status', defaultStatus),
    [defaultStatus, setValue],
  )

  const handleStatusChange = useCallback(
    async (status: StatusFormValues['status']) => {
      const statusId = status?.id
      try {
        setValue('status', status)
        statusId === AppealsStatusEnum.REJECTED
          ? openModal()
          : await statusFormUpdateFx({ status: statusId })
      } catch (e) {
        handleStatusReset()
      }
    },
    [handleStatusReset, openModal, setValue],
  )

  const handleReject = useCallback(
    async (comment: SolutionFormValues['responsibleComment']) => {
      const rejectMessage = `Отказ: ${comment}`
      try {
        const status = getValues('status')?.id
        await statusFormUpdateFx({
          status: status,
          responsibleComment: responsibleComment
            ? `${responsibleComment} ${rejectMessage}`
            : rejectMessage,
        })
        closeModal()
      } catch (e) {
        logger.error('Appeal reject status error', e)
      }
    },
    [closeModal, getValues, responsibleComment],
  )

  const handleModalCancel = useCallback(() => {
    handleStatusReset()
    closeModal()
  }, [closeModal, handleStatusReset])

  useEffect(() => {
    reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  return (
    <>
      <FormProvider form={form}>
        <AutocompleteInput
          name='status'
          options={filteredStatus}
          startDecorator={null}
          disableClearable
          readOnly={!canStatusChange || isStatusChanging || isAppealFetching}
          onChange={(_, value) =>
            handleStatusChange(value as StatusFormValues['status'])
          }
          loading={isStatusChanging || isAppealFetching}
          skeletonShow={isLoading}
          size='sm'
          sx={{
            width: '110px',
            marginBottom: '8px',
            border: 'none',
            '&': {
              ...STATUS_VARIANT[statusColor],
            },

            '&.Mui-focused:before': {
              boxShadow: 'none',
            },
          }}
        />
      </FormProvider>

      <AppealRejectModal
        isShow={isModalShow}
        onCancel={handleModalCancel}
        onOk={handleReject}
      />
    </>
  )
}
