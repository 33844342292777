import { useStore } from 'effector-react'
import { driverModel } from 'src/entities/Driver'
import { DDP_DOMAIN } from '~/shared/config/env'
import { Button } from '~/shared/ui/Buttons'
import { CreditCardSearchIcon } from '~/shared/ui/Icons'

export function ButtonDebtExtra() {
  const driver = useStore(driverModel.$driver)
  const driverId = driver?.getApiId()
  const driverFullName = driver?.getFullName()

  return (
    <Button
      href={`${DDP_DOMAIN}debts?driverExtId=id-${driverId}_label-${driverFullName}`}
      startDecorator={<CreditCardSearchIcon />}
    >
      Задолженности
    </Button>
  )
}
