import { useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { CreateTransaction } from '~/features/Balance/BalanceHistory/CreateTransaction'
import { driverModel } from 'src/entities/Driver'
import { TransactionMoveFundsTypeEnum } from '~/shared/config/enums'
import { BreadcrumbsList } from '~/shared/ui/Breadcrumbs'

type CreateTransactionProps = {
  moveFundsType: TransactionMoveFundsTypeEnum
  breadcrumbsList: BreadcrumbsList
}

export function DriverCreateTransaction({
  moveFundsType,
  breadcrumbsList,
}: CreateTransactionProps) {
  const defaultValues = useStoreMap(driverModel.$driver, (driver) => ({
    driverOption: driver?.getDriverOptionForTransaction(),
  }))

  const breadcrumbs = useMemo(
    () => [
      ...breadcrumbsList,
      {
        title: 'Создание транзакции',
      },
    ],
    [breadcrumbsList],
  )

  return (
    <CreateTransaction
      moveFundsType={moveFundsType}
      defaultValues={defaultValues}
      isReadOnlyDriver
      breadcrumbs={breadcrumbs}
      isCreating
    />
  )
}
