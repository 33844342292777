import { Button } from '@mui/material'
import { combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import { createElement } from 'react'
import { AxiosErrorType, Driver, DriverAttributes } from '~/shared/api'
import { DriverStatus } from '~/shared/config/enums'
import { createCache } from '~/shared/lib/mapCacheFactory'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('entities.driver')

export const Gate = createGate<{ id: UniqueId }>()

export const $id = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { id }) => id)

export const requestDriverFx = domain.createEffect<UniqueId, Driver>({
  async handler(id) {
    return await fetchDriver(id)
  },
})

export const requestDriverSilentFx = domain.createEffect<UniqueId, Driver>({
  async handler(id) {
    return await fetchDriver(id)
  },
})

const fetchDriver = async (id: UniqueId) => {
  const response = await Driver.with('statusTransitions')
    .with('statusTransitions.responsible')
    .with('elementResponses')
    .with('region')
    .with('aggregatorDrivers')
    .with('latestRentalContract')
    .with('mainBalance')
    .find(id)
  return response.getData() as Driver
}

export const saveFx = domain.createEffect<Driver, Driver, AxiosErrorType>({
  async handler(driver) {
    await driver.save()
    return fetchDriver(driver.getApiId() as UniqueId)
  },
})

export const driverStatusUpdated = domain.createEvent<{
  id: UniqueId
  status: DriverAttributes['status']
}>()

const {
  $cache: $driversCache,
  useCache: useDriverCache,
  updateCache,
} = createCache<Driver>({
  domain,
  getEntityId: (driver) => driver.getApiId() as UniqueId,
})
export { $driversCache, useDriverCache }

export const $driver = combine($id, $driversCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

$driversCache
  .on(
    [requestDriverFx.doneData, saveFx.doneData, requestDriverSilentFx.doneData],
    (cache, driver) => updateCache(cache, [driver]),
  )
  .on(driverStatusUpdated, (cache, { id, status }) => {
    if (cache.map[id] && cache.map[id]?.getStatus() !== status) {
      const driver = cache.map[id]
      driver.setStatus(status as DriverStatus)
      // Recreate class object to force update
      cache.map[id] = Object.assign(
        Object.create(Object.getPrototypeOf(driver)),
        driver,
      )
      return { map: cache.map } // Recreate object to force update
    }
    return cache
  })

export const $driversError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(
    [requestDriverFx.fail, requestDriverSilentFx.fail],
    (store, { error, params: id }) => ({
      [id]: error,
      ...store,
    }),
  )

export const useDriverError = (id: UniqueId) =>
  useStoreMap($driversError, (errors) => errors[id])

export const allChecksCancelled = domain.createEvent<UniqueId>()
export const allChecksRetried = domain.createEvent<{
  driverId: UniqueId
  force?: boolean
}>()

export const cancelAllChecksFx = domain.createEffect<
  UniqueId,
  boolean,
  AxiosErrorType
>({
  async handler(driverId) {
    const driver = new Driver(undefined, driverId)
    await driver.cancelChecks()
    return true
  },
})
sample({
  clock: allChecksCancelled,
  target: cancelAllChecksFx,
})
sample({
  clock: cancelAllChecksFx.doneData,
  fn() {
    return {
      message: 'Все проверки остановлены',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: cancelAllChecksFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const retryAllChecksFx = domain.createEffect<
  { driverId: UniqueId; force?: boolean },
  boolean
>({
  async handler({ driverId, force = false }) {
    const driver = new Driver(undefined, driverId)
    await driver.retryChecks(force)
    return true
  },
})
sample({
  clock: allChecksRetried,
  target: retryAllChecksFx,
})
sample({
  clock: retryAllChecksFx.doneData,
  fn() {
    return {
      message: 'Все проверки перезапущены',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: retryAllChecksFx.failData,
  fn(err) {
    // TODO: add err parsing
    const hasActiveChecksErr = !!err
    const variant = hasActiveChecksErr
      ? ('warning' as const)
      : ('error' as const)
    const message = hasActiveChecksErr
      ? 'Часть проверок еще не завершена'
      : 'Проверки не были перезапущены'
    const action = hasActiveChecksErr
      ? () => {
          // eslint-disable-next-line react/no-children-prop
          return createElement(Button, {
            variant: 'text',
            size: 'small',
            sx: { color: '#fff' },
            onClick: () => allChecksRetried({ driverId: '', force: true }),
            children: 'Перезапустить принудительно',
          })
        }
      : undefined
    return {
      message,
      variant,
      action,
    }
  },
  target: snackbarEnqueued,
})

export const approved = domain.createEvent<UniqueId>()
export const rejected = domain.createEvent<UniqueId>()
export const approveFx = domain.createEffect<UniqueId, void, AxiosErrorType>({
  async handler(driverId) {
    const driver = new Driver(undefined, driverId)
    await driver.approve()
  },
})
export const rejectFx = domain.createEffect<UniqueId, void, AxiosErrorType>({
  async handler(driverId) {
    const driver = new Driver(undefined, driverId)
    await driver.reject()
  },
})
sample({
  clock: approved,
  target: approveFx,
})
sample({
  clock: approveFx.doneData,
  fn() {
    return {
      message: 'Водитель одобрен',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: approveFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: rejected,
  target: rejectFx,
})
sample({
  clock: rejectFx.doneData,
  fn() {
    return {
      message: 'Водитель отклонен',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: rejectFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: [approveFx.done, rejectFx.done],
  fn({ params }) {
    return params
  },
  target: requestDriverSilentFx,
})

export const sentToManager = domain.createEvent<UniqueId>()
export const sendToComandirFx = domain.createEffect<
  UniqueId,
  void,
  AxiosErrorType
>({
  async handler(driverId) {
    const driver = new Driver()
    driver.setApiId(driverId)
    await driver.sendToComandir()
  },
})
sample({
  clock: sentToManager,
  target: sendToComandirFx,
})
sample({
  clock: sendToComandirFx.doneData,
  fn() {
    return {
      message: 'Водитель отправлен на согласование руководителю',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: sendToComandirFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Experience days
export const experienceDaysFx = domain.createEffect<
  UniqueId,
  { experienceDays: number },
  AxiosErrorType
>({
  async handler(driverId) {
    return new Driver(undefined, driverId).getExperienceDays()
  },
})

sample({
  clock: $id,
  filter: isString,
  target: experienceDaysFx,
})

export const $experienceDays = domain
  .createStore<number>(0)
  .on(experienceDaysFx.doneData, (_, res) => res?.experienceDays || 0)
  .on(Gate.close, () => 0)
