import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  EditYandexAggregator,
  editYandexAggregatorModel,
} from '~/features/Drivers/Aggregators/EditYandexAggregator'
import { driverModel } from '~/entities/Driver'
import { aggregatorDriverModel } from '~/entities/Driver/Aggregator'
import { Breadcrumbs } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditYandexAggregatorPage = () => {
  const params = useParams<{ aggregatorId: string; driverId: string }>()
  const aggregatorId = params.aggregatorId as string
  const driverId = params.driverId as string

  const driverFullName = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getFullName(),
  )
  const aggregatorDriver = useStore(editYandexAggregatorModel.$aggregatorDriver)

  const aggregatorDriverId = aggregatorDriver?.getDriverId()
  const title = aggregatorDriver?.getAggregatorAccount().getTitle() || '...'

  const isLoading = useStore(aggregatorDriverModel.requestFx.pending)

  const breadcrumbs = useMemo(
    () => [
      {
        title: 'Водители',
        link: '/drivers',
      },
      {
        title: driverFullName || 'Водитель ...',
        link: `/drivers/${driverId}/details`,
      },
      {
        title: 'Агрегаторы',
        link: `/drivers/${driverId}/aggregators`,
      },
      { title },
    ],
    [driverFullName, driverId, title],
  )

  const yandexAggregatorError =
    aggregatorDriverModel.useAggregatorDriverError(aggregatorId)

  if (yandexAggregatorError) {
    return <NotFound message='Не удалось загрузить данные Яндекс агрегатора' />
  }

  if (
    !isLoading &&
    aggregatorDriver &&
    aggregatorDriver.getApiId() === aggregatorId &&
    driverId !== aggregatorDriverId
  ) {
    return (
      <NotFound
        message={`Загруженный Яндекс агрегатор [${aggregatorId}] не соответствует текущему водителю [${driverId}]`}
      />
    )
  }

  return (
    <PageContainer title='Карточка Яндекс агрегатора'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{title}</PageTitle>
      </Container>
      <Container>
        <EditYandexAggregator aggregatorId={aggregatorId} />
      </Container>
    </PageContainer>
  )
}
