import { useGate, useStore } from 'effector-react'
import { rentalContractModel, Form } from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import {
  $rentalContract,
  Gate,
  $car,
  getCarFx,
  $defaultValues,
  update,
  updateFx,
} from './model'

type ViewRentalContractProps = {
  rentalContractId: string
  canActions?: boolean
  canEdit?: boolean
}

export function ViewRentalContract({
  rentalContractId,
  canEdit = true,
}: ViewRentalContractProps) {
  useGate(Gate, { rentalContractId: rentalContractId })
  const { canRentalContractsUpdate } = usePermissions()

  const isRentalContractLoading = useStore(
    rentalContractModel.requestFx.pending,
  )
  const isCarLoading = useStore(getCarFx.pending)
  const isSubmitting = useStore(updateFx.pending)

  const rentalContract = useStore($rentalContract)
  const status = rentalContract?.getStatus()
  const car = useStore($car)

  const isStatusActive = status === RentalContractStatusEnum.ACTIVE

  const defaultValues = useStore($defaultValues)

  return (
    <Form
      isSubmitting={isSubmitting}
      onSuccess={update}
      isLoading={isRentalContractLoading || (!car && isCarLoading)}
      defaultValues={defaultValues}
      canEdit={canEdit && isStatusActive && canRentalContractsUpdate}
    />
  )
}
