import { ListCarRentals } from '~/features/Organization/CarRentals/ListCarRentals'
import { Container } from '~/shared/ui/Layout'

export function ListCarRentalPage() {
  return (
    <Container>
      <ListCarRentals />
    </Container>
  )
}
