import { AddAppeal } from 'src/features/Balance/Appeal/AddAppeal'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Баланс', link: '/balance/balance-histories' },
  { title: 'Обращения', link: '/balance/appeals' },
  { title: 'Новое обращение' },
]

export function AddAppealPage() {
  return (
    <PageContainer title='Новое обращение'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Новое обращение</PageTitle>
      </Container>

      <AddAppeal />
    </PageContainer>
  )
}
