import { isString } from 'xstate/es/utils'
import { FormValues } from '~/entities/Driver/ui/Form'

export const mappedFormDataToFiltersData = (
  formData: Omit<Partial<FormValues>, 'data'>,
) => {
  return Object.entries(formData).reduce((obj, [attr, value]) => {
    if (!value) return obj

    return {
      ...obj,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [attr]: isString(value) ? value : value?.id,
    }
  }, {})
}
