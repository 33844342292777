import { styled, Menu as JoyMenu, MenuItem as JoyMenuItem } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const Menu = styled(JoyMenu)`
  background: ${myPalette.white.main};
  padding: 12px;
  min-width: 200px;
  border-radius: 30px;
  border: none;
`

export const MenuItem = styled(JoyMenuItem)`
  height: 45px;
  border-radius: 30px;

  &:hover {
    background-color: ${myPalette.gray['50']} !important;
  }
` as typeof JoyMenuItem
