import { useGate, useStore, useStoreMap } from 'effector-react'
import { usePermissions } from '~/entities/viewer'
import { userOptionsStore } from '~/shared/api'
import { NotFound } from '~/shared/ui/NotFound'
import {
  formSubmitted,
  Gate,
  $taxipark,
  requestFx,
  saveFx,
  useInformationError,
} from './model'
import { Form } from './ui/Form'

export const Information = () => {
  const taxiparkId = userOptionsStore.taxipark as string
  useGate(Gate, { taxiparkId })

  const { canTaxiparkUpdate } = usePermissions()

  const isLoading = useStore(requestFx.pending)
  const isSubmitting = useStore(saveFx.pending)

  const defaultValues = useStoreMap($taxipark, (taxipark) => ({
    ...taxipark?.getAttributes(),
  }))

  const error = useInformationError(taxiparkId)

  if (!isLoading && error)
    return <NotFound message='Ошибка загрузки информации' />

  return (
    <Form
      onSuccess={formSubmitted}
      defaultValues={defaultValues}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
      canEdit={canTaxiparkUpdate}
    />
  )
}
