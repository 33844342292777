import { Box, Stack } from '@mui/joy'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from 'src/widgets/AuditsModal'
import {
  editEmployeeModel,
  EditEmployee,
} from '~/features/Taxipark/Employees/EditEmployee'
import { employeeModel } from '~/entities/Taxipark/Employee'
import { usePermissions } from '~/entities/viewer'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Button } from '~/shared/ui/Buttons'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditEmployeePage = () => {
  const { employeeId } = useParams<{ employeeId: string }>()
  const { canAuditsView, canUsersUpdate } = usePermissions()

  const resetPasswordPending = useStore(
    editEmployeeModel.resetPasswordFx.pending,
  )

  const isLoading = useStore(employeeModel.requestFx.pending)
  const employeeName = useStoreMap(editEmployeeModel.$employee, (employee) =>
    employee?.getName(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Сотрудники', link: '/taxipark/employees' },
      { title: employeeName || '' },
    ],
    [employeeName],
  )

  const error = employeeModel.useEmployeeError(employeeId as string)

  if (!employeeId || error) {
    return <NotFound message='Не удалось загрузить сотрудника' />
  }

  return (
    <PageContainer title='Карточка сотрудника'>
      <Container isHeader>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <Box>
            <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
            <PageTitle loading={isLoading}>{employeeName}</PageTitle>
          </Box>
          <Stack direction='row' spacing={2}>
            {canAuditsView && <AuditsModal auditableId={employeeId} />}
            {canUsersUpdate && (
              <Button
                onClick={() => editEmployeeModel.resetPassword()}
                loading={resetPasswordPending}
                variant='gray'
              >
                Сбросить пароль
              </Button>
            )}
          </Stack>
        </Stack>
      </Container>

      <EditEmployee employeeId={employeeId} />
    </PageContainer>
  )
}
