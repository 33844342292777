import { useCallback } from 'react'
import { BalanceHistory } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export const ListBalanceHistories = () => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = BalanceHistory.limit(pageSize)

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-appliedAt'))

      filters.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((balanceHistories) => ({
        ...balanceHistories.getAttributes(),
        id: balanceHistories.getApiId() as string,
        moveFundsType: balanceHistories?.getMoveFundsType(),
        balanceType: balanceHistories?.getBalanceType(),
        paymentType: balanceHistories?.getPaymentType(),
      }))

      /*
       * Coloquent не дает метода, чтобы получить объект meta,
       * поэтому в данном случае получаем оригинальный ответ и достаем мету из него
       * TODO: Реализовать лучший способ получения метаданных
       *  ...
       * */
      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='balance-histories-list'
      smallTitle='Баланс'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
    />
  )
}
