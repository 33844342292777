import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/joy'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import {
  carUnderRepairSubStatusEnumOptions,
  OptionEnum,
} from '~/shared/config/constants'
import {
  CarStatusEnum,
  CarUnderRepairSubStatusEnum,
} from '~/shared/config/enums'
import { dateSchema, enumOptionSchema } from '~/shared/lib/schemas'
import { Button } from '~/shared/ui/Buttons'
import {
  AutocompleteInput,
  DatePickerInput,
  FormProvider,
  TextareaInput,
} from '~/shared/ui/Form'

const formRequiredSchema = z.object({
  comment: z.string().min(3),
})
const formSchema = z.object({
  comment: z.string().optional(),
})

const subStatusSchema = z.object({
  subStatus: enumOptionSchema(CarUnderRepairSubStatusEnum),
  plannedDateEndRepair: dateSchema,
})

export type FormValues = {
  comment?: string
  subStatus?:
    | CarUnderRepairSubStatusEnum
    | OptionEnum<CarUnderRepairSubStatusEnum>
    | null
  plannedDateEndRepair?: string
}

type FormProps = {
  onSuccess: (formValues: FormValues) => void
  onCancel: () => void
  mainStatus?: CarStatusEnum
  subStatus: OptionEnum<CarUnderRepairSubStatusEnum> | null
  isSubmitting: boolean
}

export function Form({
  mainStatus,
  subStatus,
  isSubmitting,
  onSuccess,
  onCancel,
}: FormProps) {
  const isStatusUnderRepair = mainStatus === CarStatusEnum.UNDER_REPAIR
  const isStatusFree = mainStatus === CarStatusEnum.FREE

  const schema = useMemo(() => {
    if (isStatusUnderRepair) return formRequiredSchema.merge(subStatusSchema)
    if (isStatusFree) return formSchema

    return formRequiredSchema
  }, [isStatusFree, isStatusUnderRepair])

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      subStatus,
    },
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Stack spacing={2}>
        {isStatusUnderRepair && (
          <>
            <AutocompleteInput
              label='Подстатус'
              name='subStatus'
              placeholder='Укажите подстатус'
              options={carUnderRepairSubStatusEnumOptions}
              readOnly={isSubmitting}
            />
            <DatePickerInput
              label='Плановая дата выхода из ТР'
              name='plannedDateEndRepair'
              placeholder='Укажите плановую дату выхода из ТР'
              readOnly={isSubmitting}
            />
          </>
        )}
        <TextareaInput
          label='Комментарий'
          name='comment'
          placeholder='Введите комментарий'
          sx={{
            height: '138px',
          }}
          readOnly={isSubmitting}
        />
      </Stack>
      <br />
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          onClick={onCancel}
          fullWidth
          disabled={isSubmitting}
        >
          Отмена
        </Button>
        <Button variant='brand' type='submit' fullWidth loading={isSubmitting}>
          Сменить статус
        </Button>
      </Stack>
    </FormProvider>
  )
}
