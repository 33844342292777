import { createDomain } from 'effector'
import { createGate } from 'effector-react'
import { z } from 'zod'
import {
  BalanceHistory,
  Car,
  CarOptionForTransaction,
  Driver,
  DriverOptionForTransaction,
  FlowStatement,
} from '~/shared/api'
import {
  FILTER_WITHOUT_EMPTY_ENTITIES,
  Option,
  OptionEnum,
} from '~/shared/config/constants'
import {
  TransactionBalanceTypeEnum,
  TransactionMoveFundsTypeEnum,
  TransactionPaymentTypeEnum,
} from '~/shared/config/enums'
import { uuidOptionSchema } from '~/shared/lib/schemas'

export const FormGate = createGate()
const domain = createDomain('entities.balance.balanceHistory.ui.form')

const schema = z
  .object({
    driverOption: uuidOptionSchema,
    carOption: uuidOptionSchema,
    flowStatementOption: uuidOptionSchema,
    appealId: z.string().optional(),
  })
  .merge(BalanceHistory.schema)

export const formSchema = schema.pick({
  moveFundsType: true,
  paymentType: true,
  balanceType: true,
  driverOption: true,
  carOption: true,
  flowStatementOption: true,
  sourceType: true,
  comment: true,
  amount: true,
  appealId: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<
  TypeFormSchema,
  | 'driverOption'
  | 'carOption'
  | 'flowStatementOption'
  | 'moveFundsType'
  | 'balanceType'
  | 'paymentType'
> & {
  id?: UniqueId
  aggregatorAccount?: string
  driverOption: UniqueId | DriverOptionForTransaction | null
  carOption: UniqueId | CarOptionForTransaction | null
  flowStatementOption: UniqueId | Option | null
  moveFundsType:
    | TransactionMoveFundsTypeEnum
    | OptionEnum<TransactionMoveFundsTypeEnum>
    | null
  balanceType:
    | TransactionBalanceTypeEnum
    | OptionEnum<TransactionBalanceTypeEnum>
    | null
  paymentType:
    | TransactionPaymentTypeEnum
    | OptionEnum<TransactionPaymentTypeEnum>
    | null
}

export const fetchCarOptions = async (search?: string) => {
  const response = await Car.where(
    'plateNumber',
    search || FILTER_WITHOUT_EMPTY_ENTITIES,
  )
    .with('aggregatorAccount')
    .get(1)

  return response.getData().map((o) => o.getOptionForTransaction())
}

export const fetchFlowStatementOptions = async (coming: boolean) => {
  const response = await FlowStatement.where(
    coming ? 'coming' : 'spending',
    'true',
  ).get(1)
  return response.getData().map((o) => o.getOption())
}

export const fetchAggregatorAccountByCarOptionFx = domain.createEffect<
  UniqueId,
  string | undefined
>({
  async handler(carId) {
    const response = await Car.with('aggregatorAccount').find(carId)
    const car = response.getData()
    return car?.getAggregatorAccount()?.getTitle()
  },
})

export const fetchDriverOptions = async (search?: string) => {
  const response = await Driver.where('fullName', search ?? '')
    .with('latestRentalContract')
    .orderBy('lastName')
    .get(1)
  return response.getData().map((o) => o.getDriverOptionForTransaction())
}
