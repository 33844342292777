import { Grid } from '@mui/joy'
import { fuelTypeEnumOptions } from '~/shared/config/constants'
import { AutocompleteInput, NumericInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type SpecificationsProps = {
  showSkeleton?: boolean
  disabled?: boolean
}

export function Specifications({
  showSkeleton,
  disabled,
}: SpecificationsProps) {
  return (
    <Container>
      <ContainerTitle>Технические характеристики</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <AutocompleteInput
            label='Вид топлива'
            name='fuelType'
            placeholder='Выберете вид топлива'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            options={fuelTypeEnumOptions}
          />
        </Grid>
        <Grid xs={12}>
          <NumericInput
            label='Оценочная стоимость'
            name='estimatedCost'
            placeholder='Введите оценочную стоимость'
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Номер двигателя'
            name='engineNumber'
            placeholder='Введите номер двигателя'
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Номер кузова'
            name='bodyNumber'
            placeholder='Введите номер кузова'
            skeletonShow={showSkeleton}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={6}>
          <NumericInput
            label='Объем двигателя'
            name='engineSize'
            placeholder='Введите объем двигателя'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            numericProps={{ thousandSeparator: '' }}
          />
        </Grid>
        <Grid xs={6}>
          <NumericInput
            label='Мощность двигателя'
            name='enginePower'
            placeholder='Введите мощность двигателя'
            skeletonShow={showSkeleton}
            readOnly={disabled}
            numericProps={{ thousandSeparator: '', suffix: ' л.с.' }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
