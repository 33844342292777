export function RefreshWhiteIcon() {
  return (
    <svg
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.16666 8.83333C2.16666 8.83333 3.83748 6.55685 5.19486 5.19854C6.55224 3.84022 8.428 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 14.6421 14.6421 18 10.5 18C7.08075 18 4.19592 15.7119 3.29314 12.5833M2.16666 8.83333V3.83333M2.16666 8.83333H7.16666'
        stroke='white'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
