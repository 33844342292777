type Props = {
  color?: string
}

export function PlusIcon({ color = '#ffffff' }: Props) {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.00008 1.16666V12.8333M1.16675 6.99999H12.8334'
        stroke={color}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
