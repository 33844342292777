import { ListDrivers } from '~/features/Drivers/ListDrivers'
import { Container, PageContainer } from '~/shared/ui/Layout'

function ListDriversPage() {
  return (
    <PageContainer title='Водители'>
      <Container>
        <ListDrivers />
      </Container>
    </PageContainer>
  )
}

export default ListDriversPage
