import {
  Tabs as JoyTabs,
  Tab as JoyTab,
  TabList as JoyTabList,
  styled,
} from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const Tabs = styled(JoyTabs)`
  background: ${myPalette.white.main};
  border-radius: 30px;
  padding: 6px;
  margin-bottom: 16px;
`

export const TabList = styled(JoyTabList)``

export const Tab = styled(JoyTab)`
  border-radius: 30px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  color: ${myPalette.gray['500']};
  height: 44px;
  margin-right: 8px;
  white-space: nowrap;
  transition: 0.3s ease;

  &:hover,
  &.Mui-selected {
    background: ${myPalette.brand['600']} !important;
    color: ${myPalette.white.main} !important;
  }
`


