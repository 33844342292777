import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { isString } from '~/shared/lib/utils'

import { ApiModel } from './core'

const schema = z.object({
  type: z.string(),
  previewTemporaryUrl: z.string(),
  bigTemporaryUrl: z.string(),
  createdAt: dateSchema,
  updatedAt: dateSchema.optional(),
})

export type DocumentAttributes = z.infer<typeof schema>

export class Document extends ApiModel<typeof schema> {
  static jsonApiType = 'documents'

  static schema = schema

  getType(): string {
    return this.getAttribute('type')
  }

  getPreviewTemporaryUrl(): string {
    return this.getAttribute('previewTemporaryUrl')
  }

  getBigTemporaryUrl(): string {
    return this.getAttribute('bigTemporaryUrl')
  }

  hasUrls() {
    return (
      isString(this.getPreviewTemporaryUrl()) &&
      isString(this.getBigTemporaryUrl())
    )
  }
}
