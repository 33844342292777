import { attach, combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { regionModel } from '~/entities/Taxipark/Regions'
import { FormValues } from '~/entities/Taxipark/Regions/model'
import { Region } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ regionId: UniqueId }>()
export const domain = createDomain('features.taxipark.regions.edit')

export const $regionId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { regionId }) => regionId)

export const $region = combine(
  $regionId,
  regionModel.$regionsCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $regionId,
  filter: isString,
  target: regionModel.requestFx,
})

export const regionUpdateFx = attach({
  effect: regionModel.saveFx,
  source: $regionId,
  mapParams: (values: FormValues, id) => {
    const { timezoneId, ...attr } = values
    const region = new Region(attr, id as string)

    if (isString(timezoneId)) region.setTimezone(timezoneId)

    return region
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: regionUpdateFx,
})

sample({
  clock: regionUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: regionUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
