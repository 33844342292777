import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { CarStatuses } from '~/shared/api'
import { Option, OptionEnum } from '~/shared/config/constants'
import { CarStatusEnum } from '~/shared/config/enums'

export const Gate = createGate()
const domain = createDomain('features.car.main-status')

export type FormValues = {
  mainStatus: OptionEnum<CarStatusEnum> | null
}

export const fetchOptionFx = domain.createEffect({
  async handler() {
    return await CarStatuses.fetchOptions()
  },
})

sample({
  clock: Gate.open,
  target: fetchOptionFx,
})

export const $options = domain
  .createStore<Option[]>([])
  .on(fetchOptionFx.doneData, (_, options) => options)
  .on(Gate.close, () => [])
