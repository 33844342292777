import { isValid } from 'date-fns'
import { FieldValues } from 'react-hook-form'
import { Option } from '~/shared/config/constants'
import { sanitizeStringNumber } from '~/shared/lib/utils'
import { Filter } from '~/shared/ui/AsyncGrid'

export const mappedFormValuesToQuery = (
  formValues: FieldValues,
  filters: Filter[],
) => {
  return filters.reduce((values, filter) => {
    if (filter.type === 'date') {
      const formValue = formValues[filter.field]
      return {
        ...values,
        [filter.field]: isValid(new Date(formValue)) ? formValue : '',
      }
    }
    if (filter.type === 'select' || filter.type === 'autocomplete') {
      const formValue: Option = formValues[filter.field]
      return {
        ...values,
        [filter.field]: formValue?.id
          ? { id: formValue.id, label: formValue.label }
          : formValue,
      }
    }
    if (
      filter.type === 'boolean' ||
      filter.type === 'text' ||
      filter.type === 'selectMultiple' ||
      filter.type === 'autocompleteMultiple'
    ) {
      return { ...values, [filter.field]: formValues[filter.field] }
    }

    if (filter.type === 'price') {
      const formValue = formValues[filter.field]
      return {
        ...values,
        [filter.field]: formValue ? sanitizeStringNumber(formValue) : '',
      }
    }

    if (filter.type === 'dateRange') {
      const startValue = formValues[filter.startField]
      const endValue = formValues[filter.endField]
      return {
        ...values,
        [filter.startField]: isValid(new Date(startValue)) ? startValue : '',
        [filter.endField]: isValid(new Date(endValue)) ? endValue : '',
      }
    }
    return values
  }, {})
}

export const getStringParams = (
  filters: Record<string, string | FieldValues | boolean | undefined>,
) => {
  return Object.entries(filters)
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return !value?.id || !value?.label
          ? null
          : `${key}=id-${value?.id}_label-${value?.label}`
      }
      if (typeof value === 'boolean') {
        return `${key}=${value ? 1 : 0}`
      }
      return value === undefined || value === '' ? null : `${key}=${value}`
    })
    .filter(Boolean)
    .join('&')
}
