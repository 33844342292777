import { GridColDef } from '@mui/x-data-grid'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Регион',
    field: 'name',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.name}</ColumnLink>,
  },
  {
    headerName: 'Часовой Пояс',
    field: 'timezone',
    sortable: false,
    flex: 1,
  },
]
