import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { Counterparty } from '~/shared/api'
import { CounterpartiesTypeEnum } from '~/shared/config/enums'
import { Button } from '~/shared/ui/Buttons'
import {
  AsyncAutocompleteInput,
  DatePickerInput,
  FormProvider,
  NumericInput,
  TextInput,
} from '~/shared/ui/Form'
import { PlusIcon } from '~/shared/ui/Icons'
import { formSchema, FormValues } from '../EditForm/model'

type FormProps = {
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
}

export function Form({ onSuccess, isSubmitting }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Страховщик'
            name='counterpartyId'
            placeholder='Выберите страховщика'
            fetchOptions={(search: string) =>
              Counterparty.fetchOptionsByType(
                search,
                CounterpartiesTypeEnum.INSURANCE,
              )
            }
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Серия'
            name='series'
            placeholder='Введите серию'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Номер'
            name='number'
            placeholder='Введите номер'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={6}>
          <DatePickerInput
            label='Дата выдачи'
            name='issuedDate'
            placeholder='Выберите дату'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={6}>
          <DatePickerInput
            label='Действителен до'
            name='expirationDate'
            placeholder='Выберите дату'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={12}>
          <NumericInput
            label='Премия'
            name='premium'
            placeholder='Введите премию'
            numericProps={{ thousandSeparator: '' }}
            readOnly={isSubmitting}
          />
        </Grid>
      </Grid>
      <br />
      <Button
        loading={isSubmitting}
        startDecorator={<PlusIcon />}
        fullWidth
        type='submit'
      >
        Добавить
      </Button>
    </FormProvider>
  )
}
