import { CircularProgress, Stack } from '@mui/joy'
import { useGate, useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from 'src/widgets/AuditsModal'
import { carEditModel } from 'src/features/Сars/EditCar'
import { Gate } from '~/features/Сars/EditCar/model'
import { mainStatusModel } from '~/features/Сars/ui/MainStatus'
import { Status } from '~/features/Сars/ui/Status'
import { carModel } from 'src/entities/Car'
import { documentsModel } from '~/entities/Car/Documents'
import { usePermissions } from '~/entities/viewer'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import RoutesTabs from '~/shared/ui/RoutesTabs'
import { ButtonInspectionExtra } from './ui/ButtonInspectionExtra'
import { PrintFreighterCard } from './ui/PrintFreighterCard'
import { StatusAlert } from './ui/StatusAlert'
import { useConfigTabs } from './useConfigTabs'

export const CarPage = () => {
  const { canAuditsView } = usePermissions()
  const params = useParams<{ carId: string }>()
  const carId = params.carId as string

  useGate(Gate, { id: carId })
  useGate(documentsModel.DocumentsGate)
  useGate(mainStatusModel.Gate)

  const isLoading = useStore(carModel.requestFx.pending)
  const configTabs = useConfigTabs()

  const carVin = useStoreMap(carEditModel.$car, (car) => car?.getVin())
  const carPlateNumber = useStoreMap(carEditModel.$car, (car) =>
    car?.getPlateNumber(),
  )
  const carTitle = `${carVin} [${carPlateNumber || '-'}]`

  const title = `Автомобиль ${isLoading ? '...' : carTitle}`

  const breadcrumbs = useMemo(
    (): BreadcrumbsList => [
      {
        title: 'Автомобили',
        link: '/cars',
      },
      { title: carTitle || '' },
    ],
    [carTitle],
  )

  const carError = carModel.useCarError(carId)
  if (carError) {
    return <NotFound message='Не удалось загрузить автомобиль' />
  }

  return (
    <PageContainer title={title}>
      <RoutesTabs
        parentPath={`/cars/${carId}`}
        config={configTabs}
        parentContent={
          <Container isHeader>
            <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />

            <Status carId={carId} />

            <Stack
              direction='row'
              alignItems='end'
              justifyContent='space-between'
            >
              <PageTitle loading={isLoading}>{carTitle}</PageTitle>
              {isLoading && <CircularProgress size='sm' />}
              {!isLoading && (
                <Stack direction='row' spacing={2}>
                  {canAuditsView && <AuditsModal auditableId={carId} />}
                  <ButtonInspectionExtra />
                  <PrintFreighterCard carId={carId} />
                </Stack>
              )}
            </Stack>
            <br />
            <StatusAlert />
          </Container>
        }
      />
    </PageContainer>
  )
}
