import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { balanceHistoryModel } from '~/entities/Balance/BalanceHistory'
import { driverModel } from '~/entities/Driver'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('pages.drivers.editDriver.ui.mainBalance')
export const Gate = createGate()

sample({
  clock: balanceHistoryModel.createFx,
  source: driverModel.$id,
  filter: isString,
  target: driverModel.requestDriverSilentFx,
})
