export function UploadWhiteIcon() {
  return (
    <svg
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 11.5V12.5C16 13.9001 16 14.6002 15.7275 15.135C15.4878 15.6054 15.1054 15.9878 14.635 16.2275C14.1002 16.5 13.4001 16.5 12 16.5H5C3.59987 16.5 2.8998 16.5 2.36502 16.2275C1.89462 15.9878 1.51217 15.6054 1.27248 15.135C1 14.6002 1 13.9001 1 12.5V11.5M12.6667 5.66667L8.5 1.5M8.5 1.5L4.33333 5.66667M8.5 1.5V11.5'
        stroke='white'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
