import { useStore, useStoreMap, useGate } from 'effector-react'
import { Form, workRuleModel } from 'src/entities/WorkRule'
import { Region } from '~/shared/api'
import {
  fuelTypeEnumLabels,
  transmissionEnumLabels,
} from '~/shared/config/constants'

import { $workRule, Gate } from './model'

type DetailsWorkRuleProps = {
  id: UniqueId
}

export function DetailsWorkRule({ id }: DetailsWorkRuleProps) {
  useGate(Gate, { id })

  const isLoading = useStore(workRuleModel.requestFx.pending)

  const defaultValues = useStoreMap($workRule, (workRule) => {
    const transmissionType = workRule?.getTransmissionType()
    const vehicleCategory = workRule?.getVehicleCategory()
    const carModel = workRule?.getCarModel()
    const manufactureYears = workRule?.getManufactureYears()

    return {
      ...workRule?.getAttributes(),
      manufactureYears: (manufactureYears || []).join(', '),
      vehicleCategoryId: vehicleCategory ? vehicleCategory?.getOption() : null,
      carModelOption: carModel ? carModel?.getOption() : null,
      transmissionType: transmissionType
        ? {
            id: transmissionType,
            label: transmissionEnumLabels[transmissionType],
          }
        : null,
      fuelTypes: workRule?.getFuelTypes().map((fuelType) => ({
        id: fuelType,
        label: fuelTypeEnumLabels[fuelType],
      })),
      regionsIds: workRule
        ?.getRegions()
        ?.map((region: Region) => region.getOption()),
    }
  })

  return (
    <Form defaultValues={defaultValues} isLoading={isLoading} canEdit={false} />
  )
}
