import { Stack, Typography } from '@mui/joy'
import { Button } from '~/shared/ui/Buttons'
import { RefreshWhiteIcon } from '~/shared/ui/Icons'
import { Modal } from '~/shared/ui/Modal'

type TransactionStornoWarningModalProps = {
  isShow: boolean
  onCancel: () => void
  onOk: () => void
  isSubmitting: boolean
}

export function TransactionStornoWarningModal({
  isShow,
  onCancel,
  onOk,
  isSubmitting,
}: TransactionStornoWarningModalProps) {
  return (
    <Modal
      isShow={isShow}
      onCancel={!isSubmitting ? onCancel : undefined}
      title='Сторнирование транзакции'
      modalProps={{
        'data-testid': 'storno-modal',
      }}
    >
      <Typography
        sx={{
          marginBottom: '24px',
        }}
      >
        Вы уверены, что хотите сторнировать транзакцию ?
      </Typography>

      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          fullWidth
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Отмена
        </Button>
        <Button
          variant='brand'
          fullWidth
          startDecorator={<RefreshWhiteIcon />}
          onClick={onOk}
          loading={isSubmitting}
        >
          Сторнировать
        </Button>
      </Stack>
    </Modal>
  )
}
