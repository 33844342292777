import * as z from 'zod'
import { Option, OptionEnum } from '~/shared/config/constants'
import { TransactionPaymentTypeEnum } from '~/shared/config/enums'
import { enumOptionSchema, uuidOptionSchema } from '~/shared/lib/schemas'

export const formSchema = z.object({
  flowStatementId: uuidOptionSchema,
  paymentType: enumOptionSchema(TransactionPaymentTypeEnum),
})
export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<
  TypeFormSchema,
  'flowStatementId' | 'paymentType'
> & {
  flowStatementId: UniqueId | Option
  paymentType:
    | OptionEnum<TransactionPaymentTypeEnum>
    | TransactionPaymentTypeEnum
}
