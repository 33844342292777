import React from 'react'
import { ListAggregatorAccount } from '~/features/Taxipark/AggregatorAccount/ListAggregatorAccount'
import { Container } from '~/shared/ui/Layout'

export const ListAggregatorAccountPage = () => {
  return (
    <Container>
      <ListAggregatorAccount />
    </Container>
  )
}
