import { useCallback } from 'react'
import { usePermissions } from '~/entities/viewer'
import { WorkRule } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export function ListWorkRules() {
  const { canWorkRulesCreate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = []) => {
      let builder = WorkRule.limit(pageSize)
        .with('vehicleCategory')
        .orderBy('-createdAt')

      filters.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((workRule) => ({
        ...workRule.getAttributes(),
        id: workRule.getApiId() as string,
        vehicleCategory: workRule.getVehicleCategory()?.getTitle(),
        status: {
          label: workRule.getStatusLabel(),
          color: workRule.getStatusColor(),
        },
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      title='Условия работы'
      gridKey='workRules'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      headerExtra={
        <Button
          startDecorator={<PlusIcon />}
          to='new'
          show={canWorkRulesCreate}
        >
          Добавить
        </Button>
      }
    />
  )
}
