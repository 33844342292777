import { createDomain, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { balanceFormModel } from '~/entities/Balance/BalanceHistory'
import { AxiosErrorType, BalanceHistory } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const Gate = createGate<{ transactionId: UniqueId }>()
export const domain = createDomain('entities.balance.balanceHistory')

export const $transactionId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { transactionId }) => transactionId)
  .on(Gate.close, () => null)

const fetchTransaction = async (id: UniqueId) => {
  const response = await BalanceHistory.find(id)

  return response.getData() as BalanceHistory
}

export const fetchTransactionFx = domain.createEffect<UniqueId, BalanceHistory>(
  {
    handler: fetchTransaction,
  },
)

export const $loading = domain
  .createStore(false)
  .on(Gate.open, () => true)
  .on(fetchTransactionFx.finally, () => false)
  .on(Gate.close, () => false)

export const $transaction = createStore<BalanceHistory | null>(null)
  .on(fetchTransactionFx.doneData, (_, transaction) => transaction)
  .on(Gate.close, () => null)

sample({
  clock: $transactionId,
  filter: isString,
  target: fetchTransactionFx,
})

export const formSubmitted = domain.createEvent<balanceFormModel.FormValues>()
export const createFx = domain.createEffect<
  balanceFormModel.FormValues,
  void,
  AxiosErrorType
>({
  async handler(formValue) {
    const {
      driverOption,
      flowStatementOption,
      carOption,
      moveFundsType,
      balanceType,
      paymentType,
      ...attr
    } = formValue

    await BalanceHistory.create({
      ...attr,
      ...(isString(moveFundsType) && { moveFundsType }),
      ...(isString(balanceType) && { balanceType }),
      ...(isString(paymentType) && { paymentType }),
      ...(isString(driverOption) && { driverId: driverOption }),
      ...(isString(carOption) && { carId: carOption }),
      ...(isString(flowStatementOption) && {
        flowStatementId: flowStatementOption,
      }),
    })
  },
})

sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Транзакция создана',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})

sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
