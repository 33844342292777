import { useEffect, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { isString } from 'xstate/es/utils'
import { FormValues } from '~/entities/WorkRule/ui/Form'
import {
  fuelTypeEnumLabels,
  transmissionEnumLabels,
} from '~/shared/config/constants'

export const useGeneratedTitle = (
  form: UseFormReturn<FormValues>,
  onTitleChange?: (title: string) => void,
) => {
  const [
    vehicleCategoryId,
    transmissionType,
    regionsIds,
    manufactureYears,
    pricePerDay,
    fuelTypes,
    carModelOption,
    isPromotion,
  ] = form.watch([
    'vehicleCategoryId',
    'transmissionType',
    'regionsIds',
    'manufactureYears',
    'pricePerDay',
    'fuelTypes',
    'carModelOption',
    'isPromotion',
  ])

  const title = useMemo(
    () =>
      // Категория ТС, ТИП КПП, Регион, Год Выпуска, Стоимость в сутки, Модель авто, Вид топлива
      [
        isString(vehicleCategoryId)
          ? vehicleCategoryId
          : vehicleCategoryId?.label,
        isString(transmissionType)
          ? transmissionEnumLabels[transmissionType]
          : transmissionType?.label,
        regionsIds
          ?.map((region) => (isString(region) ? region : region?.label))
          .join(', '),
        manufactureYears,
        pricePerDay,
        isString(carModelOption) ? carModelOption : carModelOption?.label,
        fuelTypes
          ?.map((fuelType) =>
            isString(fuelType) ? fuelTypeEnumLabels[fuelType] : fuelType?.label,
          )
          .join(', '),
        isPromotion ? 'Акция' : '',
      ]
        .filter(Boolean)
        .join(', '),
    [
      carModelOption,
      fuelTypes,
      manufactureYears,
      pricePerDay,
      isPromotion,
      regionsIds,
      transmissionType,
      vehicleCategoryId,
    ],
  )

  useEffect(() => {
    form.setValue('title', title)
    onTitleChange?.(title)
    // eslint-disable-next-line
  }, [title])
}
