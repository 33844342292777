import { z } from 'zod'
import { StsDocumentAttributes } from '~/shared/api/stsDocument'
import { dateSchema } from '~/shared/lib/schemas'
import { Car } from './car'
import { ApiModel } from './core'

const schema = z.object({
  series: z.string().optional().nullable(),
  number: z.string().trim().min(1, 'Обязательное поле'),
  issuedDate: dateSchema,
  location: z.string().optional().nullable(),

  createdAt: dateSchema,
  updatedAt: dateSchema.optional(),

  files: z
    .object({
      id: z.string(),
      name: z.string(),
      filepath: z.string().url(),
      preview: z.string().url(),
    })
    .array()
    .optional(),
})

export type PtsDocumentAttributes = z.infer<typeof schema>

export class PtsDocument extends ApiModel<typeof schema> {
  static jsonApiType = 'pts-documents'

  static schema = schema

  setCar(id: UniqueId) {
    this.setRelation('car', new Car({}, id))
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }

  getFiles(): StsDocumentAttributes['files'] {
    return this.getAttribute('files')
  }
}
