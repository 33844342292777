import { AuthStore } from './types'

type onChangeFunc = (token: string) => void
/*
 * Base AuthStore class that is intented to be extended by all other
 * AuthStore implementations.
 * */
export abstract class BaseAuthStore implements AuthStore {
  protected baseToken = ''
  private _onChangeCallbacks: Array<onChangeFunc> = []

  /*
   * Retrieves the stored token (if any).
   * */
  get token(): string {
    return this.baseToken
  }

  /*
   * Checks if the store has valid (existing and unexpired) token.
   * */
  get isTokenValid(): boolean {
    return this.token.length > 0
  }

  /*
   * Saves the provided new token and model data in the auth store.
   * */

  saveToken(token: string): void {
    this.baseToken = token

    this.triggerTokenChange()
  }

  /*
   * Removes the stored token and model data form the auth store.
   * */
  clearToken(): void {
    this.baseToken = ''

    this.triggerTokenChange()
  }

  /*
   * Register a callback function that will be called on store change.
   *
   * Returns a removal function that you could call to "unsubscribe" from the changes.
   * */
  onChange(callback: () => void): () => void {
    this._onChangeCallbacks.push(callback)

    return () => {
      for (let i = this._onChangeCallbacks.length - 1; i >= 0; i--) {
        if (this._onChangeCallbacks[i] == callback) {
          delete this._onChangeCallbacks[i] // removes the function reference
          this._onChangeCallbacks.splice(i, 1) // reindex the array
          return
        }
      }
    }
  }

  protected triggerTokenChange(): void {
    for (const callback of this._onChangeCallbacks) {
      callback?.(this.token)
    }
  }
}
