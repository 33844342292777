import { uniq } from 'lodash'
import { useMemo } from 'react'
import { usePermissions } from '~/entities/viewer'

export const usePermissionsPage = () => {
  const {
    canCarsView,
    canCarsUpdate,
    canCarDocumentsView,
    canDriversUpdate,
    canDriversView,
    canAggregatorAccountsView,
    canRentalContractsView,
    canRentalContractsCreate,
    canTravelSheetsView,
    canBalancesUpdate,
    canBalancesView,
    canWorkRulesCreate,
    canWorkRulesView,
    canUsersView,
    canUsersUpdate,
    canTaxiparkView,
    canRegionsView,
    canRegionsUpdate,
    canSubdivisionsView,
    canSubdivisionsUpdate,
    canRolesView,
    canRolesUpdate,
    canCarOrganizationsView,
    canCarOrganizationsUpdate,
    canTaxiparkSectionView,
    canAppealsView,
    canBalanceSectionView,
    canAppealsCreator,
    canAppealsResponsible,
    canMassImportTransactions,
  } = usePermissions()

  const notPermissions = useMemo(() => {
    let data: string[] = []

    if (!canCarsView) data = [...data, '/cars', '/cars/:carId/*']
    if (!canCarsUpdate) data = [...data, '/cars/new']
    if (!canCarDocumentsView) data = [...data, '/cars/:carId/documents']
    if (!canRentalContractsView)
      data = [
        ...data,
        '/cars/:carId/rental-contracts',
        '/cars/:carId/rental-contracts/*',
        '/drivers/:driverId/rental-contracts',
        '/drivers/:driverId/rental-contracts/*',
      ]

    if (!canDriversView) data = [...data, '/drivers', '/drivers/:driverId/*']
    if (!canDriversUpdate) data = [...data, '/drivers/new']

    if (!canRentalContractsCreate)
      data = [...data, '/drivers/:driverId/rental-contracts/new']

    if (!canTravelSheetsView)
      data = [...data, '/drivers/:driverId/travel-sheets']

    if (!canBalancesView)
      data = [
        ...data,
        '/drivers/:driverId/balance',
        '/drivers/:driverId/balance/*',
        '/balance/balance-histories',
        '/balance/balance-histories/*',
      ]
    if (!canBalancesUpdate)
      data = [
        ...data,
        '/drivers/:driverId/balance/current/new-coming',
        '/drivers/:driverId/balance/current/new-spending',
        '/balance/balance-histories/new-coming',
        '/balance/balance-histories/new-spending',
      ]

    if (!canAppealsView)
      data = [...data, '/balance/appeals', '/balance/appeals/*']

    if (!canAppealsCreator) data = [...data, '/balance/appeals/new']
    if (!canAppealsResponsible)
      data = [
        ...data,
        '/balance/appeals/:appealId/new-coming',
        '/balance/appeals/:appealId/new-spending',
      ]

    if (!canBalanceSectionView) data = [...data, '/balance', '/balance/*']

    if (!canWorkRulesView)
      data = [
        ...data,
        '/work-rules',
        '/work-rules/:workRuleId',
        '/work-rules/new',
      ]
    if (!canWorkRulesCreate) data = [...data, '/work-rules/new']

    if (!canTaxiparkSectionView) data = [...data, '/taxipark/*']

    if (!canTaxiparkView) data = [...data, '/taxipark/information']

    if (!canUsersView)
      data = [...data, '/taxipark/employees', '/taxipark/employees/*']
    if (!canUsersUpdate) data = [...data, '/taxipark/employees/new']

    if (!canRegionsView)
      data = [...data, '/taxipark/regions', '/taxipark/regions/*']
    if (!canRegionsUpdate) data = [...data, '/taxipark/regions/new']

    if (!canSubdivisionsView)
      data = [...data, '/taxipark/subdivisions', '/taxipark/subdivisions/*']
    if (!canSubdivisionsUpdate) data = [...data, '/taxipark/subdivisions/new']

    if (!canRolesView) data = [...data, '/taxipark/roles', '/taxipark/roles/*']
    if (!canRolesUpdate) data = [...data, '/taxipark/roles/new']

    if (!canAggregatorAccountsView)
      data = [
        ...data,
        '/taxipark/aggregator-accounts',
        '/taxipark/aggregator-accounts/*',
      ]

    if (!canCarOrganizationsView) data = [...data, '/organizations/*']

    if (!canCarOrganizationsUpdate)
      data = [
        ...data,
        '/organizations/car-rentals/new',
        '/organizations/car-licensees/new',
      ]

    if (!canMassImportTransactions)
      data = [...data, '/balance/balance-histories/bulk']

    return uniq<string>(data)
  }, [
    canCarsView,
    canCarsUpdate,
    canCarDocumentsView,
    canRentalContractsView,
    canDriversView,
    canDriversUpdate,
    canRentalContractsCreate,
    canTravelSheetsView,
    canBalancesView,
    canBalancesUpdate,
    canAppealsView,
    canAppealsCreator,
    canAppealsResponsible,
    canBalanceSectionView,
    canWorkRulesView,
    canWorkRulesCreate,
    canTaxiparkSectionView,
    canTaxiparkView,
    canUsersView,
    canUsersUpdate,
    canRegionsView,
    canRegionsUpdate,
    canSubdivisionsView,
    canSubdivisionsUpdate,
    canRolesView,
    canRolesUpdate,
    canAggregatorAccountsView,
    canCarOrganizationsView,
    canCarOrganizationsUpdate,
    canMassImportTransactions,
  ])

  return { notPermissions }
}
