import { Grid, Stack } from '@mui/joy'
import { useSelector } from '@xstate/react'
import { useStore } from 'effector-react'
import { useContext, useState } from 'react'
import { Element } from 'react-scroll'
import { driverModel, DriverMachineContext } from 'src/entities/Driver'
import { usePermissions } from '~/entities/viewer'
import { AUTO_CHECKS, Driver } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { myPalette } from '~/shared/lib/theme'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { DownloadIcon, PauseCircleIcon, RefreshIcon } from '~/shared/ui/Icons'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { AutoCheck } from './AutoCheck'
import { SbCheck } from './SbCheck'

type DriverChecksProps = {
  driverId: string
}

export function DriverChecks({ driverId }: DriverChecksProps) {
  const {
    canDriverSendApproval,
    canDriverStatusDecide,
    canDriverChecksUpdate,
    canDriverChecksView,
  } = usePermissions()

  const isLoading = useStore(driverModel.requestDriverFx.pending)
  const [isDownloadReportSBLoading, setIsDownloadReportSBLoading] =
    useState<boolean>()

  const cachedDriver = driverModel.useDriverCache(driverId)
  const machineCtx = useContext(DriverMachineContext)
  const can = useSelector(machineCtx.interpreter, (state) => ({
    cancel_checks: state.can('cancel_checks'),
    retry_checks: state.can('retry_checks'),
    approve: state.can('approve'),
    reject: state.can('reject'),
    request_additional_approval: state.can('request_additional_approval'),
  }))

  const isSendToManagerPending = useStore(driverModel.sendToComandirFx.pending)
  const isRetryAllChecksPending = useStore(driverModel.retryAllChecksFx.pending)
  const isCancelAllChecksPending = useStore(
    driverModel.cancelAllChecksFx.pending,
  )
  const isApprovePending = useStore(driverModel.approveFx.pending)
  const isRejectPending = useStore(driverModel.rejectFx.pending)

  const handleDownloadReportSB = async () => {
    try {
      setIsDownloadReportSBLoading(true)
      const driver = cachedDriver || new Driver(undefined, driverId)
      await driver.downloadSecurityCheckExport()
    } catch (e) {
      logger.error(e)
      snackbarEnqueued({
        message: 'Ошибка получения файла',
        variant: 'error' as const,
      })
    } finally {
      setIsDownloadReportSBLoading(false)
    }
  }

  return (
    <Element name='checks'>
      <Container data-testid='driver-checks-container'>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <ContainerTitle>Проверки</ContainerTitle>

          {!isLoading && (
            <Stack direction='row' spacing={1}>
              {canDriverStatusDecide && (
                <>
                  <Button
                    show={can.approve}
                    variant='green'
                    onClick={() => driverModel.approved(driverId)}
                    loading={isApprovePending}
                    disabled={isRejectPending}
                  >
                    Принять
                  </Button>
                  <Button
                    show={can.reject}
                    variant='red'
                    onClick={() => driverModel.rejected(driverId)}
                    loading={isRejectPending}
                    disabled={isApprovePending}
                  >
                    Отклонить
                  </Button>
                </>
              )}

              {canDriverChecksView && (
                <>
                  <IconButton
                    show={can.cancel_checks && canDriverChecksUpdate}
                    variant='yellow'
                    tooltipProps={{ title: 'Остановить проверки' }}
                    onClick={() => driverModel.allChecksCancelled(driverId)}
                    loading={isCancelAllChecksPending}
                  >
                    <PauseCircleIcon />
                  </IconButton>
                  <IconButton
                    show={can.retry_checks && canDriverChecksUpdate}
                    variant='brand'
                    tooltipProps={{ title: 'Перезапустить проверки' }}
                    onClick={() => driverModel.allChecksRetried({ driverId })}
                    loading={isRetryAllChecksPending}
                  >
                    <RefreshIcon color={myPalette.white.main} />
                  </IconButton>

                  <IconButton
                    variant='gray'
                    tooltipProps={{ title: 'Скачать отчет для СБ' }}
                    onClick={handleDownloadReportSB}
                    loading={isDownloadReportSBLoading}
                  >
                    <DownloadIcon color={myPalette.brand['700']} />
                  </IconButton>
                </>
              )}
              <Button
                show={can.request_additional_approval && canDriverSendApproval}
                variant='gray'
                onClick={() => driverModel.sentToManager(driverId)}
                loading={isSendToManagerPending}
              >
                На согласование
              </Button>
            </Stack>
          )}
        </Stack>

        <Grid container spacing={3} alignItems='flex-start'>
          <Grid xs={6} container spacing={2}>
            {AUTO_CHECKS.map((checkKind) => (
              <Grid xs={6} key={checkKind}>
                <AutoCheck checkKind={checkKind} />
              </Grid>
            ))}
          </Grid>
          <Grid xs={6}>
            <SbCheck />
          </Grid>
        </Grid>
      </Container>
    </Element>
  )
}
