import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { Car } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const domain = createDomain('entities.car')

export const requestFx = domain.createEffect<UniqueId, Car>({
  handler: fetchCarWithRelations,
})

export const requestSilentFx = domain.createEffect<UniqueId, Car>({
  handler: fetchCarWithRelations,
})

export const saveFx = domain.createEffect<Car, Car>({
  async handler(car) {
    await car.save()
    return fetchCarWithRelations(car.getApiId() as UniqueId)
  },
})

const {
  $cache: $carsCache,
  useCache: useCarCache,
  updateCache: updateCache,
} = createCache<Car>({
  domain,
  getEntityId: (car) => car.getApiId() as UniqueId,
})
export { $carsCache, useCarCache, updateCache }

$carsCache
  .on([requestFx.doneData, requestSilentFx.doneData], (cache, car) =>
    updateCache(cache, [car]),
  )
  .on(saveFx.doneData, (cache, car) => updateCache(cache, [car], true))

export const $carsError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(
    [requestFx.fail, requestSilentFx.fail],
    (store, { error, params: id }) => ({
      [id]: error,
      ...store,
    }),
  )
export const useCarError = (id: UniqueId) =>
  useStoreMap($carsError, (errors) => errors[id])

export async function fetchCarWithRelations(id: UniqueId) {
  const carRes = await Car.with('carModel')
    .with('subdivision')
    .with('carModel')
    .with('carModel.carBrand')
    .with('color')
    .with('vehicleCategory')
    .with('carRental')
    .with('region')
    .with('aggregatorAccount')
    .with('statusTransitions')
    .with('statusTransitions.responsible')
    .with('telematicAccount')
    .with('equipments')
    .find(id)

  return carRes.getData() as Car
}
