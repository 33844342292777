import { useStore, useStoreMap } from 'effector-react'
import { Form, driverModel } from 'src/entities/Driver'
import { usePermissions } from '~/entities/viewer'
import { isValidDateString } from '~/shared/lib/date'
import { formSubmitted, sendFormFx } from './model'

export function EditDriver() {
  const { canDriversUpdate } = usePermissions()

  const isSaving = useStore(sendFormFx.pending)
  const isLoading = useStore(driverModel.requestDriverFx.pending)

  const defaultValues = useStoreMap(driverModel.$driver, (driver) => {
    const region = driver?.getRegion()
    const citizenship = driver?.getCitizenship()
    const drivingLicenseIssuingCountry =
      driver?.getDrivingLicenseIssuingCountry()

    const data = driver?.getData()

    const passportExpirationDate = data?.passportExpirationDate
    const registrationDate = data?.registrationDate
    const registrationExpirationDate = data?.registrationExpirationDate
    const comment = data?.comment || null
    const callsign = data?.callsign || null
    const lastJob = data?.lastJob || null
    const birthPlace = data?.birthPlace || null

    return {
      ...driver?.getAttributes(),
      regionId: region ? driver?.getRegion()?.getOption() : null,
      citizenship: citizenship ? { id: citizenship, label: citizenship } : null,
      drivingLicenseIssuingCountry: drivingLicenseIssuingCountry
        ? {
            id: drivingLicenseIssuingCountry,
            label: drivingLicenseIssuingCountry,
          }
        : null,
      data: {
        comment,
        callsign,
        lastJob,
        birthPlace,
        registrationExpirationDate:
          registrationExpirationDate &&
          isValidDateString(registrationExpirationDate)
            ? registrationExpirationDate
            : '',
        passportExpirationDate:
          passportExpirationDate && isValidDateString(passportExpirationDate)
            ? passportExpirationDate
            : '',
        registrationDate:
          registrationDate && isValidDateString(registrationDate)
            ? registrationDate
            : '',
      },
    }
  })

  return (
    <Form
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canDriversUpdate}
      isLoading={isLoading}
    />
  )
}
