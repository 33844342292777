import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  EditSubdivision,
  editSubdivisionModel,
} from '~/features/Taxipark/Subdivisions/EditSubdivision'
import { subdivisionModel } from '~/entities/Taxipark/Subdivisions'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditSubdivisionPage = () => {
  const { subdivisionId } = useParams<{ subdivisionId: string }>()

  const isLoading = useStore(subdivisionModel.requestFx.pending)
  const title = useStoreMap(editSubdivisionModel.$subdivision, (subdivision) =>
    subdivision?.getTitle(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Подразделения', link: '/taxipark/subdivisions' },
      { title: title || '' },
    ],
    [title],
  )

  const error = subdivisionModel.useSubdivisionError(subdivisionId as string)

  if (!subdivisionId || error) {
    return <NotFound message='Не удалось загрузить подразделение' />
  }

  return (
    <PageContainer title='Карточка подразделения'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{title}</PageTitle>
      </Container>

      <EditSubdivision subdivisionId={subdivisionId} />
    </PageContainer>
  )
}
