import { Typography } from '@mui/joy'
import { useCallback, useState } from 'react'
import { AddWorkRule } from '~/features/WorkRule/AddWorkRule'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Условия работы', link: '/work-rules' },
  { title: 'Новое условие работы' },
]

function AddWorkRulePage() {
  const [title, setTitle] = useState<string>()

  const handleTitleChange = useCallback((title: string) => setTitle(title), [])

  return (
    <PageContainer title='Новое условие работы'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Новое условие работы</PageTitle>
        <Typography sx={{ minHeight: '24px' }}>{title}</Typography>
      </Container>
      <AddWorkRule onTitleChange={handleTitleChange} />
    </PageContainer>
  )
}

export default AddWorkRulePage
