import { saveAs } from 'file-saver'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import * as z from 'zod'
import {
  Car,
  CarOptionForAppeal,
  CarOptionForTransaction,
} from '~/shared/api/car'
import { WorkRule } from '~/shared/api/workRule'
import {
  AggregatorCodeEnum,
  DriverDocumentType,
  DriverStatus,
} from '~/shared/config/enums'
import {
  formatIntervalToNowAsDuration,
  formatDate,
  formatDateTimeForUI,
} from '~/shared/lib/date'
import {
  dateOptionalSchema,
  dateSchema,
  phoneScheme,
  stringRequiredScheme,
  uuidOptionSchema,
} from '~/shared/lib/schemas'
import { isEmptyObject } from '~/shared/lib/utils'
import { AggregatorDriver } from './aggregator/aggregatorDriver'
import { Balance } from './balance'
import { Check } from './check'
import { ApiModel, ToManyRelation, ToOneRelation } from './core'
import { Document } from './document'
import { Region } from './region'
import { RentalContract } from './rentalContract'
import { StatusTransition } from './statusTransition'
import { User } from './user'

const dataSchema = z
  .object({
    passportExpirationDate: dateOptionalSchema,
    registrationDate: dateOptionalSchema,
    registrationExpirationDate: dateOptionalSchema,
    comment: z.string().optional().nullable(),
    callsign: z.string().optional().nullable(),
    lastJob: z.string().optional().nullable(),
    birthPlace: z.string().optional().nullable(),
  })
  .nullable()

const relationAggregatorDrivers = z.object({
  accountType: z.nativeEnum(AggregatorCodeEnum),
  driverAccountResourceName: z.string(),
  taxiDispatchName: z.string(),
  aggregatorDriverId: z.string(),
  aggregatorDriverExtId: z.string(),
})

const relationsSchema = z.object({
  regionId: uuidOptionSchema,
  aggregatorDrivers: z.array(relationAggregatorDrivers),
  mainBalance: uuidOptionSchema,
  debtBalance: uuidOptionSchema,
})

const attributeSchema = z.object({
  status: z.nativeEnum(DriverStatus).optional(),
  lastName: stringRequiredScheme,
  firstName: stringRequiredScheme,
  middleName: z.string().optional().nullable(),
  birthDate: dateSchema,
  phone: phoneScheme,
  inn: z
    .string()
    .length(12, 'Только цифры, ИНН должен содержать 12 символов')
    .or(z.literal(''))
    .optional()
    .nullable(),
  snils: z
    .string()
    .length(11, 'Только цифры, СНИЛС должен содержать 11 символов')
    .or(z.literal(''))
    .optional()
    .nullable(),
  email: z
    .string()
    .email('Неверный адрес электронной почты')
    .or(z.literal(''))
    .optional()
    .nullable(),
  passportIssueDate: dateSchema,
  citizenship: uuidOptionSchema,
  registrationAddress: z.string().trim().min(1, 'Обязательное поле').min(5),
  passportNumber: z.string().trim().min(1, 'Обязательное поле').min(5),
  drivingExperienceStartDate: dateSchema,
  drivingLicenseIssuingCountry: uuidOptionSchema,
  drivingLicenseNumber: z.string().min(1, 'Обязательное поле').min(5),
  drivingLicenseIssueDate: dateSchema,
  drivingLicenseExpirationDate: dateSchema,
  divisionCode: stringRequiredScheme,
  passportIssued: stringRequiredScheme,
  placeOfResidence: stringRequiredScheme,
  data: z
    .preprocess((arg) => {
      if (Array.isArray(arg) || !arg) return null
      return omitBy(arg, isNil)
    }, dataSchema)
    .transform((data) => {
      if (isEmptyObject(data)) return null
      return data
    }),
  comment: z.string().optional().nullable(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type DriverAttributes = z.infer<typeof attributeSchema>

export type DriverOptionForTransaction = {
  id: UniqueId
  label: string
  carOption?: CarOptionForTransaction | null
}

export type DriverOptionForAppeal = {
  id: UniqueId
  label: string
  phone: string
  carOption?: CarOptionForAppeal
}

export class Driver extends ApiModel<typeof schema> {
  static jsonApiType = 'drivers'

  static schema = schema

  readOnlyAttributes = ['createdAt', 'updatedAt']

  getPhone(): string {
    return this.getAttribute('phone')
  }
  getCitizenship(): string {
    return this.getAttribute('citizenship')
  }
  getDrivingLicenseIssuingCountry(): string {
    return this.getAttribute('drivingLicenseIssuingCountry')
  }

  getData() {
    return this.getAttribute('data')
  }
  statusTransitions(): ToManyRelation<StatusTransition, this> {
    return this.hasMany(StatusTransition)
  }

  getStatusTransitions(): StatusTransition[] {
    return this.getRelation('statusTransitions') ?? []
  }

  checks(): ToManyRelation<Check, this> {
    return this.hasMany(Check)
  }

  getChecks(): Check[] {
    return this.getRelation('checks')
  }

  latestChecks(): ToManyRelation<Check, this> {
    return this.hasMany(Check, 'latest-checks')
  }

  getLatestChecks(): Check[] {
    return this.getRelation('latest-checks')
  }

  documents(): ToManyRelation<Document, this> {
    return this.hasMany(Document, 'documents')
  }

  getDocuments(): Document[] {
    return this.getRelation('documents') ?? []
  }

  getLastApprovedOrRejectedTransition(): StatusTransition | null {
    const transitions = this.getStatusTransitions().filter((st) =>
      ['APPROVED', 'REJECTED'].includes(st.getTo()),
    )
    const last = transitions.pop()
    return last ?? null
  }

  getWhoApprovedOrRejected(): User | null {
    const transition = this.getLastApprovedOrRejectedTransition()
    const user = transition?.getResponsible()
    return user ?? null
  }

  getApprovedOrRejectedDate(): string | undefined {
    const transition = this.getLastApprovedOrRejectedTransition()
    return transition?.getCreatedAt()
  }

  getApprovedOrRejectedFormattedDate(): string | undefined {
    const date = this.getApprovedOrRejectedDate()
    if (date) return formatDateTimeForUI(date)
  }

  getSentToComandirDate(): string | undefined {
    const transitions = this.getStatusTransitions()
    const transition = transitions.find(
      (st) => st.getTo() === 'SENT_TO_COMANDIR',
    )
    return transition?.getCreatedAt()
  }

  getSentToComandirFormattedDate(): string | undefined {
    const date = this.getSentToComandirDate()
    if (date) return formatDateTimeForUI(date)
  }

  securityCheckExport(): string {
    return `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/security-check-export`
  }

  async downloadSecurityCheckExport() {
    const url = this.securityCheckExport()
    const client = Driver.httpClient.getImplementingClient()
    try {
      const response = await client.get(url, {
        responseType: 'blob',
      })
      const filename = [
        this.getFullName(),
        this.getDrivingLicenseNumber(),
        formatDate(new Date(), 'dd-MM-yyyy'),
      ]
        .filter(Boolean)
        .join(' ')
      saveAs(response.data, filename)
    } catch (e) {
      return e
    }
  }

  getFullName() {
    return [
      this.getAttribute('lastName'),
      this.getAttribute('firstName'),
      this.getAttribute('middleName'),
    ].join(' ')
  }

  getDrivingLicenseNumber(): string {
    return this.getAttribute('drivingLicenseNumber')
  }

  getStatus(): DriverStatus {
    return this.getAttribute('status')
  }

  setStatus(value: DriverStatus) {
    this.setAttribute('status', value)
  }

  getDrivingExperienceStartDate(): string {
    return this.getAttribute('drivingExperienceStartDate')
  }

  getFormattedDrivingExperienceDuration() {
    const drivingExperienceStartDate = this.getDrivingExperienceStartDate()
    return drivingExperienceStartDate
      ? formatIntervalToNowAsDuration(new Date(drivingExperienceStartDate), {
          format: ['years', 'months'],
        })
      : '-'
  }

  async retryChecks(force = false) {
    const url = `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/retry-checks`
    return Driver.effectiveHttpClient.post(url, { force })
  }

  async cancelChecks() {
    const url = `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/cancel-checks`
    return Driver.effectiveHttpClient.post(url)
  }

  async approve() {
    const url = `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/approve`
    return Driver.effectiveHttpClient.post(url)
  }

  async reject() {
    const url = `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/reject`
    return Driver.effectiveHttpClient.post(url)
  }

  async sendToComandir() {
    const url = `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/send-to-comandir`
    return Driver.effectiveHttpClient.post(url)
  }

  async saveDocument({ file, type }: { file: File; type: DriverDocumentType }) {
    const url = `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/save-document`
    const data = new FormData()
    data.append('file', file)
    data.append('type', type)
    const client = Driver.httpClient.getImplementingClient()
    return await client.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async deleteDocument({ documentId }: { documentId: UniqueId }) {
    const url = `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/delete-document`
    const client = Driver.httpClient.getImplementingClient()
    return await client.delete(url, { params: { documentId } })
  }

  async getExperienceDays(): Promise<{ experienceDays: number }> {
    const url = `${Driver.getJsonApiUrl()}/${this.getApiId()}/actions/experience-days`

    const client = Driver.httpClient.getImplementingClient()
    const res = await client.get(url)
    return res.data
  }

  static async exportExperience(): Promise<Blob> {
    const url = `${Driver.getJsonApiUrl()}/actions/export-experience`
    const client = Driver.httpClient.getImplementingClient()

    const response = await client.get(url, {
      responseType: 'blob',
    })

    return response?.data
  }

  region(): ToOneRelation<Region, this> {
    return this.hasOne(Region)
  }
  getRegion(): Region {
    return this.getRelation('region')
  }
  setRegion(id: UniqueId) {
    const region = new Region()
    region.setApiId(id)
    this.setRelation('region', region)
  }

  aggregatorDrivers(): ToManyRelation<AggregatorDriver, this> {
    return this.hasMany(AggregatorDriver, AggregatorDriver.jsonApiType)
  }
  getAggregatorDrivers(): AggregatorDriver[] {
    return this.getRelation('aggregatorDrivers')
  }

  rentalContracts(): ToManyRelation<RentalContract, this> {
    return this.hasMany(RentalContract, RentalContract.jsonApiType)
  }

  latestRentalContract(): ToOneRelation<RentalContract, this> {
    return this.hasOne(RentalContract)
  }
  getLatestRentalContract(): RentalContract {
    return this.getRelation('latestRentalContract')
  }

  mainBalance(): ToOneRelation<Balance, this> {
    return this.hasOne(Balance)
  }
  getMainBalance(): Balance {
    return this.getRelation('mainBalance')
  }

  debtBalance(): ToOneRelation<Balance, this> {
    return this.hasOne(Balance)
  }
  getDebtBalance(): Balance {
    return this.getRelation('debtBalance')
  }

  workRule(): ToOneRelation<WorkRule, this> {
    return this.hasOne(WorkRule)
  }
  getWorkRule(): WorkRule {
    return this.getRelation('workRule')
  }

  latestCar(): ToOneRelation<Car, this> {
    return this.hasOne(Car)
  }
  getLatestCar(): Car {
    return this.getRelation('latestCar')
  }

  getDriverOptionForTransaction(): DriverOptionForTransaction {
    const rentalContract = this.getLatestRentalContract()
    const carOption: DriverOptionForTransaction['carOption'] = rentalContract
      ? {
          id: rentalContract?.getCarId(),
          label: rentalContract?.getCarPlateNumber(),
        }
      : undefined

    return {
      id: this.getApiId() as UniqueId,
      label: this.getFullName(),
      carOption,
    }
  }

  getDriverOptionForAppeal(): DriverOptionForAppeal {
    const rentalContract = this.getLatestRentalContract()
    const carOption: DriverOptionForAppeal['carOption'] = rentalContract
      ? {
          id: rentalContract?.getCarId(),
          label: rentalContract?.getCarPlateNumber(),
        }
      : undefined

    return {
      id: this.getApiId() as UniqueId,
      label: this.getFullName(),
      phone: this.getPhone(),
      carOption,
    }
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getFullName() as string,
    }
  }

  static async fetchOptions(search: string) {
    const response = await Driver.where('fullName', search ?? '').get(1)
    return response.getData().map((o) => o.getOption())
  }
}
