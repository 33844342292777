import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormValues } from './model'
import { $isHasCarWithVin, checkHasCarByVin, requestByVinFx } from './model'

export const useValidateForm = (form: UseFormReturn<FormValues>) => {
  const { watch, setError, clearErrors, getValues } = form

  const isHasCarWithVin = useStore($isHasCarWithVin)
  const isPendingVin = useStore(requestByVinFx.pending)

  watch((data, { name, type }) => {
    if (type !== 'change') return
    if (name === 'vin') checkHasCarByVin(data.vin ?? '')
  })

  useEffect(() => {
    if (isHasCarWithVin) {
      const vin = getValues('vin')
      setError('vin', {
        message: `Автомобиль с vin ${vin} существует`,
      })
    } else {
      clearErrors('vin')
    }
    // eslint-disable-next-line
  }, [isHasCarWithVin])

  return {
    isValidatePending: isPendingVin,
    isValidateError: isHasCarWithVin,
  }
}
