import { useStoreMap } from 'effector-react'
import { appealModel } from '~/entities/Balance/Appeal'
import { DDP_DOMAIN } from '~/shared/config/env'
import { Button } from '~/shared/ui/Buttons'
import { ChevronRightBrandIcon } from '~/shared/ui/Icons'

export function ButtonDebtExtra() {
  const driver = useStoreMap(appealModel.$appeal, (appeal) =>
    appeal?.getDriver(),
  )

  if (!driver) return null

  return (
    <Button
      variant='gray'
      href={`${DDP_DOMAIN}debts?driverExtId=id-${driver?.getApiId()}_label-${driver?.getFullName()}`}
      endDecorator={<ChevronRightBrandIcon />}
    >
      Список задолженностей
    </Button>
  )
}
