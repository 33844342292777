import { Skeleton, Stack, Box, CircularProgress } from '@mui/joy'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from 'src/widgets/AuditsModal'
import {
  DetailsWorkRule,
  detailsWorkRuleModel,
} from 'src/features/WorkRule/DetailsWorkRule'
import { workRuleModel } from 'src/entities/WorkRule'
import { usePermissions } from '~/entities/viewer'
import { WorkRuleStatusEnum } from '~/shared/config/enums'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Button } from '~/shared/ui/Buttons'
import { ChipStatus } from '~/shared/ui/Chips'
import { ArchiveIcon } from '~/shared/ui/Icons'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

function DetailsWorkRulePage() {
  const params = useParams<{ workRuleId: string }>()
  const workRuleId = params.workRuleId as string

  const { canWorkRulesArchive, canAuditsView } = usePermissions()
  const isLoading = useStore(workRuleModel.requestFx.pending)

  const workRuleTitle = useStoreMap(
    detailsWorkRuleModel.$workRule,
    (workRule) => workRule?.getTitle(),
  )
  const workRuleStatus = useStoreMap(
    detailsWorkRuleModel.$workRule,
    (workRule) => workRule?.getStatus(),
  )
  const workRuleStatusLabel = useStoreMap(
    detailsWorkRuleModel.$workRule,
    (workRule) => workRule?.getStatusLabel(),
  )
  const workRuleStatusColor = useStoreMap(
    detailsWorkRuleModel.$workRule,
    (workRule) => workRule?.getStatusColor(),
  )

  const isArchivePending = useStore(detailsWorkRuleModel.archiveFx.pending)
  const isArchived = workRuleStatus === WorkRuleStatusEnum.ARCHIVED

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Условия работы', link: '/work-rules' },
      { title: workRuleTitle || '' },
    ],
    [workRuleTitle],
  )

  const workRuleError = workRuleModel.useWorkRuleError(workRuleId)
  if (workRuleError) {
    return <NotFound message='Не удалось загрузить условие работы' />
  }

  return (
    <PageContainer title='Карточка сотрудника'>
      <Container isHeader>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <Box>
            <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
            <ChipStatus
              label={
                isLoading ? (
                  <Skeleton sx={{ top: 0, left: 0 }} />
                ) : (
                  workRuleStatusLabel
                )
              }
              data-testid='work-rule-status'
              variant={workRuleStatusColor}
              sx={{ minWidth: '70px', marginBottom: '6px' }}
            />
            <PageTitle loading={isLoading}>{workRuleTitle}</PageTitle>
          </Box>
          <Stack direction='row' spacing={2}>
            {isLoading && <CircularProgress />}

            {!isLoading && canAuditsView && (
              <AuditsModal auditableId={workRuleId} />
            )}
            <Button
              show={!isLoading && !isArchived && canWorkRulesArchive}
              loading={isArchivePending}
              startDecorator={<ArchiveIcon />}
              onClick={() => detailsWorkRuleModel.archiveClicked()}
              sx={{ width: '180px' }}
            >
              В архив
            </Button>
          </Stack>
        </Stack>
      </Container>

      <DetailsWorkRule id={workRuleId} />
    </PageContainer>
  )
}

export default DetailsWorkRulePage
