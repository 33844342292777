import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { z } from 'zod'
import { AggregatorDriver, AxiosErrorType } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const aggregatorDriverSchema = AggregatorDriver.schema.pick({
  balanceLimit: true,
  blockOrdersOnBalanceBelowLimit: true,
  partner: true,
  platform: true,
  driverId: true,
  accountId: true,
  yandexWorkRuleOption: true,
})

export type SchemaType = z.infer<typeof aggregatorDriverSchema>

export type FormValues = Omit<SchemaType, 'yandexWorkRuleOption'> & {
  yandexWorkRuleOption: UniqueId | Option | null
}

export const domain = createDomain(
  'entities.driver.aggregator.aggregator-driver',
)

export const requestFx = domain.createEffect<UniqueId, AggregatorDriver>({
  handler: fetchAggregatorDriver,
})

export const saveFx = domain.createEffect<
  AggregatorDriver,
  AggregatorDriver,
  AxiosErrorType
>({
  async handler(aggregator) {
    await aggregator.save()
    return fetchAggregatorDriver(aggregator.getApiId() as UniqueId)
  },
})

const {
  $cache: $aggregatorDriverCache,
  useCache: useAggregatorDriverCache,
  updateCache,
} = createCache<AggregatorDriver>({
  domain,
  getEntityId: (aggregator) => aggregator.getApiId() as UniqueId,
})
export { $aggregatorDriverCache, useAggregatorDriverCache }

$aggregatorDriverCache
  .on(requestFx.doneData, (cache, aggregator) =>
    updateCache(cache, [aggregator]),
  )
  .on(saveFx.doneData, (cache, aggregator) =>
    updateCache(cache, [aggregator], true),
  )

export const $aggregatorDriverError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useAggregatorDriverError = (id: UniqueId) =>
  useStoreMap($aggregatorDriverError, (errors) => errors[id])

async function fetchAggregatorDriver(id: UniqueId) {
  const response = await AggregatorDriver.with('aggregatorAccount')
    .with('yandexWorkRule')
    .find(id)
  return response.getData() as AggregatorDriver
}
