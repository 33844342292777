import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { numberRequiredScheme } from '~/shared/lib/schemas'
import { Button } from '~/shared/ui/Buttons'
import { FormProvider, NumericInput } from '~/shared/ui/Form'

const formSchema = z.object({
  quantity: numberRequiredScheme.max(
    30,
    'Превышено максимальное количество 30шт',
  ),
})

type SchemaType = z.infer<typeof formSchema>
export type FormValues = SchemaType

type FormProps = {
  onCancel: () => void
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
}

export function Form({ onCancel, onSuccess, isSubmitting }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <NumericInput
        label='Количество путевых листов'
        placeholder='Введите количество путевых листов'
        name='quantity'
        numericProps={{ thousandSeparator: '' }}
      />
      <br />
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          onClick={onCancel}
          disabled={isSubmitting}
          fullWidth
        >
          Отмена
        </Button>
        <Button variant='brand' type='submit' loading={isSubmitting} fullWidth>
          Сформировать
        </Button>
      </Stack>
    </FormProvider>
  )
}
