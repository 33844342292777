import { Stack } from '@mui/joy'
import { Alert } from '~/shared/ui/Alerts'
import { Button } from '~/shared/ui/Buttons'
import { Modal } from '~/shared/ui/Modal'

type RentalContractContinueModalProps = {
  isShow: boolean
  onOk: () => Promise<void>
  onCancel: () => void
  isSubmitting: boolean
}

export const RentalContractContinueModal = ({
  isShow,
  onOk,
  onCancel,
  isSubmitting,
}: RentalContractContinueModalProps) => {
  return (
    <Modal
      isShow={isShow}
      onCancel={isSubmitting ? undefined : onCancel}
      dialogProps={{
        sx: {
          minWidth: '500px',
          maxWidth: '500px',
        },
      }}
    >
      <Alert variant='info' sx={{ margin: '44px 0 24px 0' }}>
        Возобновить договор ?
      </Alert>

      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Button
          variant='gray'
          fullWidth
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Отмена
        </Button>
        <Button variant='brand' fullWidth onClick={onOk} loading={isSubmitting}>
          Возобновить
        </Button>
      </Stack>
    </Modal>
  )
}
