import { Stack } from '@mui/joy'
import { BulkCreateTransactions } from '~/features/Balance/BalanceHistory/BulkCreateTransactions'
import { Balance } from '~/shared/api'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { FileExportLink } from '~/shared/ui/FileExport/FileExportLink'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

const title = 'Массовое создание транзакций'

const breadcrumbs: BreadcrumbsList = [
  {
    title: 'Баланс',
    link: '/balance/balance-histories',
  },
  {
    title,
  },
]

function BulkCreateTransactionsPage() {
  const handleMassImportTemplateGet = async () => {
    const { templateUrl } = await Balance.getMassImportTemplate()
    return templateUrl
  }

  return (
    <PageContainer title={title}>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <Stack direction='row' justifyContent='space-between'>
          <PageTitle>{title}</PageTitle>
          <FileExportLink
            title='Скачать шаблон'
            fnGetLink={handleMassImportTemplateGet}
          />
        </Stack>
      </Container>

      <BulkCreateTransactions />
    </PageContainer>
  )
}

export default BulkCreateTransactionsPage
