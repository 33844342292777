import { useStore } from 'effector-react'
import { useMemo } from 'react'
import { CheckSbForm } from '~/entities/DriverCheck'
import { usePermissions } from '~/entities/viewer'
import { SbResults } from '~/shared/api'
import { CheckBase } from './CheckBase'
import {
  $isInitialLoading,
  $sbCheck,
  saveCheckFx,
  sbFormSubmitted,
} from './model'
import { Wrapper } from './styled'

export function SbCheck() {
  const { canDriverSecurityCheck } = usePermissions()

  const check = useStore($sbCheck)
  const results = check.getAttributes()?.results

  const defaultValues = useMemo(() => results as SbResults, [results])

  const isLoading = useStore($isInitialLoading)
  const isSubmitting = useStore(saveCheckFx.pending)

  return (
    <Wrapper>
      <CheckBase check={check} loading={isLoading}>
        <CheckSbForm
          check={check}
          defaultValues={defaultValues}
          onSuccess={sbFormSubmitted}
          canEdit={canDriverSecurityCheck}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
        />
      </CheckBase>
    </Wrapper>
  )
}
