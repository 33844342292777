import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { documentsModel } from '~/entities/Car/Documents'
import { AxiosErrorType, Car, OtherDocument, ThumbType } from '~/shared/api'
import { CarDocumentType } from '~/shared/config/enums'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString, mapDocumentFileToThumbs } from '~/shared/lib/utils'

const domain = createDomain('feature.other-documents')

export const Gate = createGate<{ carId: UniqueId }>()
const $carId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { carId }) => carId)
  .on(Gate.close, () => null)

export const fetchDocumentFx = domain.createEffect<UniqueId, OtherDocument>({
  async handler(carId) {
    const response = await OtherDocument.where('carId', carId).first()
    return response.getData() as OtherDocument
  },
})

export const $loading = domain
  .createStore(false)
  .on(Gate.open, () => true)
  .on(fetchDocumentFx.finally, () => false)

export const $document = domain
  .createStore<OtherDocument | null>(null)
  .on(fetchDocumentFx.doneData, (state, document) => document)
  .on(documentsModel.DocumentsGate.close, () => null)

sample({
  clock: $carId,
  filter: isString,
  target: fetchDocumentFx,
})

// Files
export const saveFiles = domain.createEvent<File[]>()
export const saveFilesFx = domain.createEffect<
  { documentId: UniqueId; files: File[]; carId: UniqueId },
  void,
  AxiosErrorType
>({
  async handler({ documentId, files, carId }) {
    for (const file of files) {
      await Car.saveDocumentFile(
        file,
        documentId,
        CarDocumentType.OTHER_DOCUMENT,
        carId,
      )
    }
  },
})

sample({
  clock: saveFiles,
  source: { document: $document, carId: $carId },
  fn: ({ document, carId }, files) => ({
    documentId: document?.getApiId() as UniqueId,
    files,
    carId: String(carId),
  }),
  target: saveFilesFx,
})

sample({
  clock: saveFilesFx.doneData,
  source: $carId,
  filter: isString,
  target: fetchDocumentFx,
})

sample({
  clock: saveFilesFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const deleteFile = domain.createEvent<UniqueId>()
const deleteFileFx = domain.createEffect<
  { fileId: UniqueId; carId: UniqueId },
  UniqueId,
  AxiosErrorType
>({
  async handler({ fileId, carId }) {
    await Car.deleteDocumentFile(fileId, carId)
    return fileId
  },
})

export const $pendingFileList = domain
  .createStore<UniqueId[]>([])
  .on(deleteFile, (state, docId) => [...state, docId])
  .on([deleteFileFx.done, deleteFileFx.fail], (state, { params }) =>
    state.filter((stateFileId) => stateFileId !== params.fileId),
  )

sample({
  clock: deleteFile,
  source: $carId,
  filter: isString,
  fn: (carId, fileId) => ({ carId, fileId }),
  target: deleteFileFx,
})

sample({
  clock: deleteFileFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $fileList = domain
  .createStore<ThumbType[]>([])
  .on($document, (store, document) =>
    mapDocumentFileToThumbs(document?.getFiles()),
  )
  .on($pendingFileList, (files, pendingFileList) =>
    files?.map((file) =>
      pendingFileList.includes(file.id)
        ? { ...file, pending: true }
        : { ...file, pending: false },
    ),
  )
  .on(deleteFileFx.doneData, (files, deleteId) =>
    files.filter((file) => file.id !== deleteId),
  )
