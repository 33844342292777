import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { isProdEnv, SENTRY_DSN } from '~/shared/config/env'

export const initSentry = () => {
  if (!isProdEnv || typeof SENTRY_DSN !== 'string' || !SENTRY_DSN?.length)
    return

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}
