import { TransactionBalanceTypeEnum } from '~/shared/config/enums'
import { Tabs } from '~/shared/ui/RoutesTabs/type'
import { BalanceByTypePage } from './BalanceByTypePage'

export const useConfigTabs = (): Tabs => {
  return [
    {
      label: 'Текущий баланс',
      path: '/current',
      content: (
        <BalanceByTypePage balanceType={TransactionBalanceTypeEnum.MAIN} />
      ),
    },
    {
      label: 'Кредитный баланс',
      path: '/by-dept',
      content: (
        <BalanceByTypePage
          balanceType={TransactionBalanceTypeEnum.BY_DEPT}
          canComing={false}
          canSpending={false}
        />
      ),
    },
  ]
}
