import { usePermissions } from '~/entities/viewer'
import { canShowObject } from '~/shared/lib/utils'
import { Tabs } from '~/shared/ui/RoutesTabs/type'
import { CarDetailsPage } from './Details'
import { DocumentsPage } from './Documents'
// import { EquipmentsPage } from './Equipments'
import {
  ListRentalContractPage,
  ViewRentalContractPage,
} from './RentalContracts'

export const useConfigTabs = (): Tabs => {
  const { canRentalContractsView, canCarDocumentsView, canCarsView } =
    usePermissions()

  return [
    ...canShowObject(canCarsView, {
      label: 'Информация',
      path: '/details',
      content: <CarDetailsPage />,
    }),
    ...canShowObject(canCarDocumentsView, {
      label: 'Документы',
      path: '/documents',
      content: <DocumentsPage />,
    }),
    ...canShowObject(canRentalContractsView, {
      label: 'Договоры',
      path: '/rental-contracts',
      content: <ListRentalContractPage />,
      children: [
        {
          path: '/:rentalContractId',
          content: <ViewRentalContractPage />,
          full: true,
        },
      ],
    }),
    // {
    //   label: 'Комплектность',
    //   path: '/equipments',
    //   content: <EquipmentsPage />,
    // },
  ]
}
