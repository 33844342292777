import DeleteIcon from '@mui/icons-material/Delete'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Grid, Stack } from '@mui/joy'
import { ThumbType } from '~/shared/api'
import { myPalette } from '~/shared/lib/theme'
import { IconButton } from '~/shared/ui/Buttons'
import { FileWrapper, Item } from '../styled'

type ThumbsProps = {
  onDelete?: (id: UniqueId) => void
  canDelete?: boolean
  thumbs: ThumbType[]
}

export function Thumbs({ onDelete, canDelete = true, thumbs }: ThumbsProps) {
  return (
    <Grid container spacing={2.5}>
      {thumbs.map((thumb) => (
        <Grid xs={4} key={`${thumb.preview}-${thumb.id}`}>
          <Item>
            <FileWrapper>
              {thumb.preview && <img src={thumb.preview} alt='file' />}
            </FileWrapper>

            <Stack direction='row' spacing={1}>
              <IconButton
                href={thumb.src}
                tooltipProps={{ title: 'Открыть' }}
                slotProps={{ root: { target: '_blank' } }}
              >
                <OpenInNewIcon sx={{ color: myPalette.brand['700'] }} />
              </IconButton>
              {canDelete && (
                <IconButton
                  onClick={() => onDelete?.(thumb.id)}
                  tooltipProps={{ title: 'Удалить' }}
                  loading={thumb.pending}
                >
                  <DeleteIcon sx={{ color: myPalette.red['700'] }} />
                </IconButton>
              )}
            </Stack>
          </Item>
        </Grid>
      ))}
    </Grid>
  )
}
