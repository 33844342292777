import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { usePermissions } from '~/entities/viewer'
import { backFx } from '~/shared/lib/history'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { RefreshOrangeIcon, RefreshWhiteIcon } from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'
import { applyStornoFx } from './model'
import { TransactionStornoWarningModal } from './TransactionStornoWarningModal'

type StornoProps = {
  transactionId: UniqueId
  isIcon?: boolean
  isGoBack?: boolean
  gridQueryKey?: string
}

export function Storno({
  transactionId,
  isIcon,
  isGoBack,
  gridQueryKey,
}: StornoProps) {
  const { isModalShow, openModal, closeModal } = useModal()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const { canBalancesStorno } = usePermissions()
  const queryClient = useQueryClient()

  const handleCanceled = useCallback(async () => {
    try {
      setIsSubmitting(true)
      await applyStornoFx(transactionId)

      isGoBack && backFx()

      gridQueryKey &&
        (await queryClient.invalidateQueries({
          queryKey: [gridQueryKey],
        }))

      closeModal()
    } finally {
      setIsSubmitting(false)
    }
  }, [closeModal, isGoBack, queryClient, gridQueryKey, transactionId])

  if (!canBalancesStorno) return null

  return (
    <>
      {isIcon ? (
        <IconButton
          onClick={openModal}
          tooltipProps={{ title: 'Сторнирование транзакции' }}
        >
          <RefreshOrangeIcon />
        </IconButton>
      ) : (
        <Button
          variant='brand'
          startDecorator={<RefreshWhiteIcon />}
          onClick={openModal}
        >
          Сторно
        </Button>
      )}

      <TransactionStornoWarningModal
        onCancel={closeModal}
        isShow={isModalShow}
        onOk={handleCanceled}
        isSubmitting={isSubmitting}
      />
    </>
  )
}
