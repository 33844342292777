import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { MainLayout } from '~/widgets/layout/main'
import { MinimalLayout } from '~/widgets/layout/minimal'
import { ProtectedPage } from '~/features/Auth/protected-page'
import NotFoundPage from './404'
import BalancePage from './Balance'
import AddCarPage from './Cars/AddCarPage'
import { CarPage } from './Cars/EditCar'
import { ListCarsPage } from './Cars/ListCarsPage'
import { AddDriverPage } from './Drivers/AddDriverPage'
import { DriverPage } from './Drivers/EditDriver'
import ListDriversPage from './Drivers/ListDriversPage'
import { usePermissionsPage } from './hooks/usePermissionsPage'
import { LoginPage } from './Login'
import { NoAccess } from './NoAccess'
import OrganizationPage from './Organizations'
import TaxiparkPage from './Taxipark'
import UserPersonalDataPage from './UserPersonalData'
import AddWorkRulePage from './WorkRules/AddWorkRulePage'
import DetailsWorkRulePage from './WorkRules/DetailsWorkRulePage'
import ListWorkRulesPage from './WorkRules/ListWorkRulesPage'

export const Routing: React.FC = () => {
  const location = useLocation()
  const { notPermissions } = usePermissionsPage()

  const [notPermissionsPage, setNotPermissionsPage] = useState(notPermissions)

  // Найти лучший способ для ререндера роутов
  useEffect(() => {
    if (!isEqual(notPermissions, notPermissionsPage)) {
      setNotPermissionsPage(notPermissions)
    }
    // eslint-disable-next-line
  }, [location])

  return (
    <Routes>
      <Route element={<ProtectedPage />}>
        <Route element={<MainLayout />}>
          {notPermissionsPage?.map((path) => (
            <Route key={path} path={path} element={<NoAccess />} />
          ))}

          <Route path='/' element={<Navigate to='/drivers' />} />

          <Route path='/drivers' element={<ListDriversPage />} />
          <Route path='/drivers/new' element={<AddDriverPage />} />
          <Route path='/drivers/:driverId/*' element={<DriverPage />} />

          <Route path='/cars' element={<ListCarsPage />} />
          <Route path='/cars/new' element={<AddCarPage />} />
          <Route path='/cars/:carId/*' element={<CarPage />} />

          <Route path='/work-rules' element={<ListWorkRulesPage />} />
          <Route path='/work-rules/new' element={<AddWorkRulePage />} />
          <Route
            path='/work-rules/:workRuleId'
            element={<DetailsWorkRulePage />}
          />

          <Route
            path='/user-personal-data'
            element={<UserPersonalDataPage />}
          />
          <Route path='/taxipark/*' element={<TaxiparkPage />} />
          <Route path='/organizations/*' element={<OrganizationPage />} />
          <Route path='/balance/*' element={<BalancePage />} />
        </Route>
      </Route>

      <Route element={<MinimalLayout />}>
        <Route path='/login' element={<LoginPage />} />
      </Route>

      <Route element={<MainLayout />}>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
