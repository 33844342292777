import { GridColDef } from '@mui/x-data-grid'
import { ColumnChipList, ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'ФИО',
    field: 'name',
    sortable: false,
    minWidth: 240,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.name}</ColumnLink>,
  },
  {
    headerName: 'Контактный телефон',
    field: 'phone',
    sortable: false,
    minWidth: 240,
  },
  {
    headerName: 'E-mail',
    field: 'email',
    sortable: false,
    minWidth: 240,
  },
  {
    headerName: 'Регионы',
    field: 'regions',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => <ColumnChipList items={value} />,
  },
  {
    headerName: 'Роли',
    field: 'rolesIds',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => <ColumnChipList items={value} />,
  },
]
