import { Box, CircularProgress, Stack, Typography } from '@mui/joy'
import { ReactNode } from 'react'
import { getCheckKindLabel } from '~/entities/DriverCheck'
import { Check } from '~/shared/api'
import { CheckKindEnum } from '~/shared/config/enums'
import { formatDateTimeForUI } from '~/shared/lib/date'

type CheckBaseProps = {
  check?: Check
  children?: ReactNode
  checkKind?: CheckKindEnum
  loading: boolean
  expandNode?: ReactNode
  statusNode?: ReactNode
}

export function CheckBase({
  check,
  children,
  checkKind,
  loading,
  expandNode,
  statusNode,
}: CheckBaseProps) {
  const date = check?.getDate()
  const formattedDate = date ? formatDateTimeForUI(date) : null

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            height: '44px',
            minWidth: '140px',
            display: 'inline-block',
          }}
        >
          {formattedDate}
        </Typography>
        {loading && <CircularProgress size='sm' />}
        <>{!loading && expandNode}</>
      </Stack>

      {checkKind && (
        <Stack direction='row' justifyContent='space-between'>
          <Typography fontWeight={600} fontSize={20}>
            {getCheckKindLabel(checkKind)}
          </Typography>
          {statusNode}
        </Stack>
      )}
      {children}
    </Box>
  )
}
