import { AddRegion } from '~/features/Taxipark/Regions/AddRegion'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Регионы', link: '/taxipark/regions' },
  { title: 'Создание региона' },
]

export const AddRegionPage = () => {
  return (
    <PageContainer title='Создание региона'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание региона</PageTitle>
      </Container>
      <AddRegion />
    </PageContainer>
  )
}
