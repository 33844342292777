import { BalanceHistory } from '~/shared/api'
import { TransactionMoveFundsTypeEnum } from '~/shared/config/enums'

export const getMoneyMovement = (balanceHistory: BalanceHistory) => {
  const isComing =
    balanceHistory?.getMoveFundsType() === TransactionMoveFundsTypeEnum.COMING

  const amount = balanceHistory.getAmount()
  return isComing
    ? { 'coming-{amount}': amount }
    : { 'expenditure-{amount}': amount }
}
