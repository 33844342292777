import { zodResolver } from '@hookform/resolvers/zod'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import { FormButtons, formButtonsModel, FormProvider } from '~/shared/ui/Form'
import { DrivingLicense } from './DrivingLicense'
import { Extra } from './Extra'
import { Foreigners } from './Foreigners'
import { Main } from './Main'
import { formSchema, FormValues } from './model'
import { Passport } from './Passport'

export type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
  onChange?: (formData: Partial<FormValues>) => void
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
  onChange,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const { watch, reset } = form

  const formData = watch()

  useEffect(() => {
    onChange?.(formData)
    // eslint-disable-next-line
  }, [formData])

  useEffect(() => {
    if (defaultValues) reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Element name='main'>
        <Main disabled={disabled} skeletonShow={isLoading} />
      </Element>
      <Element name='passport'>
        <Passport disabled={disabled} skeletonShow={isLoading} />
      </Element>
      <Element name='foreigners'>
        <Foreigners disabled={disabled} skeletonShow={isLoading} />
      </Element>
      <Element name='drivingLicense'>
        <DrivingLicense disabled={disabled} skeletonShow={isLoading} />
      </Element>
      <Element name='extra'>
        <Extra disabled={disabled} skeletonShow={isLoading} />
      </Element>

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
