import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import * as z from 'zod'
import { AggregatorAccount, AxiosErrorType } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const formSchema = AggregatorAccount.schema.pick({
  title: true,
  aggregatorCode: true,
  defaultBalanceLimit: true,
  defaultBlockOrdersOnBalanceBelowLimit: true,
  defaultPartner: true,
  defaultPlatform: true,
  defaultYandexWorkRuleOption: true,
})

export type SchemaType = z.infer<typeof AggregatorAccount.schema>
export type FormValues = Omit<SchemaType, 'defaultYandexWorkRuleOption'> & {
  defaultYandexWorkRuleOption: UniqueId | Option | null
}

export const domain = createDomain('entities.taxipark.aggregatorAccount')

export const requestFx = domain.createEffect<UniqueId, AggregatorAccount>({
  handler: fetchAggregatorAccount,
})

export const saveFx = domain.createEffect<
  AggregatorAccount,
  AggregatorAccount,
  AxiosErrorType
>({
  async handler(aggregatorAccount) {
    await aggregatorAccount.save()
    return fetchAggregatorAccount(aggregatorAccount.getApiId() as UniqueId)
  },
})

const {
  $cache: $aggregatorAccountCache,
  useCache: useAggregatorAccountCache,
  updateCache,
} = createCache<AggregatorAccount>({
  domain,
  getEntityId: (aggregatorAccount) => aggregatorAccount.getApiId() as UniqueId,
})
export { $aggregatorAccountCache, useAggregatorAccountCache }

$aggregatorAccountCache
  .on(requestFx.doneData, (cache, aggregatorAccount) =>
    updateCache(cache, [aggregatorAccount]),
  )
  .on(saveFx.doneData, (cache, aggregatorAccount) =>
    updateCache(cache, [aggregatorAccount], true),
  )

export const $aggregatorAccountError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useAggregatorAccountError = (id: UniqueId) =>
  useStoreMap($aggregatorAccountError, (errors) => errors[id])

async function fetchAggregatorAccount(id: UniqueId) {
  const response = await AggregatorAccount.with('defaultYandexWorkRule').find(
    id,
  )
  return response.getData() as AggregatorAccount
}
