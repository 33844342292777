import { Stack } from '@mui/joy'
import { useCallback } from 'react'
import { driverMachine } from 'src/entities/Driver'
import { usePermissions } from '~/entities/viewer'
import { Driver } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { Button } from '~/shared/ui/Buttons'
import { FileExportBlob } from '~/shared/ui/FileExport'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export function ListDrivers() {
  const { canDriversUpdate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = Driver.limit(pageSize)
        .with('mainBalance')
        .with('debtBalance')
        .with('workRule')
        .with('latestCar')

      filters.forEach(({ key, value }) => {
        if (key === 'carId') {
          builder = builder.option(
            'filter[latestActiveRentalContract][carId]',
            value,
          )
          return
        }
        if (key === 'workRuleId') {
          builder = builder.option(
            'filter[latestActiveRentalContract][workRuleId]',
            value,
          )
          return
        }
        if (key === 'subdivisionId') {
          builder = builder.option('filter[latestCar][subdivisionId]', value)
          return
        }

        builder = builder.where(key, value)
      })

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((driver) => {
        const node = driverMachine.getStateNode(driver.getStatus() as string)
        return {
          ...driver.getAttributes(),
          id: driver.getApiId() as string,
          fullName: driver.getFullName(),
          status: {
            label: node.meta.label ?? '',
            color: node.meta.color ?? 'gray',
          },
          mainBalance: driver?.getMainBalance()?.getAmount() || 0,
          debtBalance: driver?.getDebtBalance()?.getAmount() || 0,
          workRule: driver?.getWorkRule()?.getTitle(),
          car: driver?.getLatestCar()?.getPlateNumber(),
          carId: driver?.getLatestCar()?.getApiId(),
        }
      })

      /*
       * Coloquent не дает метода, чтобы получить объект meta,
       * поэтому в данном случае получаем оригинальный ответ и достаем мету из него
       * TODO: Реализовать лучший способ получения метаданных
       * */
      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='drivers'
      title='Водители'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      headerExtra={
        <Stack direction='row' spacing={1}>
          <FileExportBlob
            fn={Driver.exportExperience}
            title='Выгрузить Excel со стажем'
          />
          <Button
            startDecorator={<PlusIcon />}
            to='new'
            show={canDriversUpdate}
          >
            Добавить
          </Button>
        </Stack>
      }
    />
  )
}
