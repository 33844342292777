import { z } from 'zod'
import { ApiModel } from './core'

const schema = z.object({
  name: z.string(),
})

export type FlowStatementAttributes = z.infer<typeof schema>

export class FlowStatement extends ApiModel<typeof schema> {
  static jsonApiType = 'flow-statements'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  static async fetchOptions() {
    const response = await FlowStatement.get(1)

    return response.getData().map((o) => o.getOption())
  }
}
