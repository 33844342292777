import { Box } from '@mui/joy'
import { useCallback, useState } from 'react'
import { usePermissions } from '~/entities/viewer'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Button } from '~/shared/ui/Buttons'
import { CreditCardRefreshIcon } from '~/shared/ui/Icons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { Form, FormValues } from './Form'
import { balanceTransferFx } from './model'

export function BalanceTransferModal() {
  const { canDriverBalanceTransferTasks } = usePermissions()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { isModalShow, closeModal, openModal } = useModal()

  const handleOnOk = useCallback(
    async ({ fromAggregatorProfileId, toAggregatorProfileId }: FormValues) => {
      if (fromAggregatorProfileId === toAggregatorProfileId) {
        snackbarEnqueued({
          message: 'Для переноса баланса укажите разные аккаунты агрегатора',
          variant: 'error',
        })
        return
      }

      try {
        setIsSubmitting(true)
        await balanceTransferFx({
          fromAggregatorProfileId,
          toAggregatorProfileId,
        })
        closeModal()
      } finally {
        setIsSubmitting(false)
      }
    },
    [closeModal],
  )

  if (!canDriverBalanceTransferTasks) return null

  return (
    <>
      <Button
        onClick={openModal}
        variant='green'
        startDecorator={<CreditCardRefreshIcon />}
      >
        Перенести баланс
      </Button>

      <Modal
        isShow={isModalShow}
        onCancel={closeModal}
        title='Перенос баланса'
        dialogProps={{
          sx: {
            minWidth: '600px',
            maxWidth: '600px',
          },
        }}
      >
        <Box sx={{ padding: '4px' }}>
          <Form
            onSuccess={handleOnOk}
            isSubmitting={isSubmitting}
            onCancel={closeModal}
          />
        </Box>
      </Modal>
    </>
  )
}
