import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  EditRegion,
  editRegionModal,
} from '~/features/Taxipark/Regions/EditRegion'
import { regionModel } from '~/entities/Taxipark/Regions'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditRegionPage = () => {
  const { regionId } = useParams<{ regionId: string }>()

  const isLoading = useStore(regionModel.requestFx.pending)
  const regionName = useStoreMap(editRegionModal.$region, (employee) =>
    employee?.getName(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Регионы', link: '/taxipark/regions' },
      { title: regionName || '' },
    ],
    [regionName],
  )

  const error = regionModel.useRegionError(regionId as string)

  if (!regionId || error) {
    return <NotFound message='Не удалось загрузить регион' />
  }

  return (
    <PageContainer title='Карточка региона'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{regionName}</PageTitle>
      </Container>

      <EditRegion regionId={regionId} />
    </PageContainer>
  )
}
