import { useInterpret } from '@xstate/react'
import { createContext, useLayoutEffect } from 'react'
import { createMachine, InterpreterFrom } from 'xstate'
import { DriverAttributes } from '~/shared/api'
import { DriverStatus } from '~/shared/config/enums'

export const machine = createMachine({
  id: 'Driver',
  initial: DriverStatus.CREATED,
  states: {
    [DriverStatus.CHECKING]: {
      description: 'Проверяется',
      on: {
        finish_checks: {
          target: DriverStatus.CHECKS_FINISHED,
        },
        cancel_checks: {
          target: DriverStatus.CHECKS_CANCELLATION_REQUESTED,
        },
      },
      meta: {
        label: 'Проверяется',
        color: 'gray',
        gridFilter: true,
      },
    },
    [DriverStatus.REJECTED]: {
      description: 'Отклонен',
      on: {
        retry_checks: {
          target: DriverStatus.CHECKS_RETRY_REQUESTED,
        },
      },
      meta: {
        label: 'Отклонен',
        color: 'outlinedRed',
        gridFilter: true,
      },
    },
    [DriverStatus.CREATED]: {
      description: 'Создан',
      on: {
        start_onboarding: {
          target: DriverStatus.ONBOARDING_STARTING,
        },
      },
      meta: {
        label: 'Создан',
        color: 'gray',
      },
    },
    [DriverStatus.CHECKS_STARTING]: {
      on: {
        checks_started: {
          target: DriverStatus.CHECKING,
        },
      },
      meta: {
        label: 'Запуск проверок',
        color: 'gray',
      },
    },
    [DriverStatus.REJECT_IN_PROGRESS]: {
      on: {
        reject_finished: {
          target: DriverStatus.REJECTED,
        },
      },
      meta: {
        label: 'Отклонение в процессе',
        color: 'outlinedRed',
      },
    },
    [DriverStatus.APPROVE_IN_PROGRESS]: {
      on: {
        approve_finished: {
          target: DriverStatus.APPROVED,
        },
      },
      meta: {
        label: 'Принятие в процессе',
        color: 'outlinedGreen',
      },
    },
    [DriverStatus.APPROVED]: {
      on: {
        retry_checks: {
          target: DriverStatus.CHECKS_RETRY_REQUESTED,
        },
        create_rental_contract: {
          target: DriverStatus.WORKS,
        },
      },
      meta: {
        label: 'Одобрен',
        color: 'green',
        gridFilter: true,
      },
    },
    [DriverStatus.CHECKS_FINISHED]: {
      description: 'Проверки завершены',
      on: {
        retry_checks: {
          target: DriverStatus.CHECKS_RETRY_REQUESTED,
        },
        request_additional_approval: {
          target: DriverStatus.ADDITIONAL_APPROVAL_REQUESTED,
        },
        approve: {
          target: DriverStatus.APPROVE_REQUESTED,
        },
        reject: {
          target: DriverStatus.REJECT_REQUESTED,
        },
      },
      meta: {
        label: 'Проверки завершены',
        color: 'yellow',
        gridFilter: true,
      },
    },
    [DriverStatus.CHECKS_CANCELLED]: {
      on: {
        retry_checks: {
          target: DriverStatus.CHECKS_RETRY_REQUESTED,
        },
      },
      meta: {
        label: 'Проверки отменены',
        color: 'outlinedRed',
      },
    },
    [DriverStatus.SENDING_TO_COMANDIR]: {
      on: {
        sent_to_comandir: {
          target: DriverStatus.SENT_TO_COMANDIR,
        },
      },
      meta: {
        label: 'Отправляется руководителю',
        color: 'gray',
      },
    },
    [DriverStatus.SENT_TO_COMANDIR]: {
      on: {
        approve: {
          target: DriverStatus.APPROVE_REQUESTED,
        },
        reject: {
          target: DriverStatus.REJECT_REQUESTED,
        },
      },
      meta: {
        label: 'Отправлено руководителю',
        color: 'brand',
        gridFilter: true,
      },
    },
    [DriverStatus.CHECKS_CANCELLATION_REQUESTED]: {
      on: {
        checks_cancelled: {
          target: DriverStatus.CHECKS_CANCELLED,
        },
      },
      meta: {
        label: 'Отмена проверок запрошена',
        color: 'gray',
      },
    },
    [DriverStatus.ONBOARDING_STARTING]: {
      on: {
        onboarding_started: {
          target: DriverStatus.ONBOARDING_STARTED,
        },
      },
      meta: {
        label: 'Запуск процесса найма',
        color: 'gray',
      },
    },
    [DriverStatus.ONBOARDING_STARTED]: {
      on: {
        start_checking: {
          target: [DriverStatus.CHECKS_STARTING],
        },
      },
      meta: {
        label: 'Процесс найма запущен',
        color: 'gray',
      },
    },
    [DriverStatus.REJECT_REQUESTED]: {
      on: {
        reject_started: {
          target: DriverStatus.REJECT_IN_PROGRESS,
        },
      },
      meta: {
        label: 'Отклонение запрошено',
        color: 'gray',
      },
    },
    [DriverStatus.APPROVE_REQUESTED]: {
      on: {
        approve_started: {
          target: DriverStatus.APPROVE_IN_PROGRESS,
        },
      },
      meta: {
        label: 'Принятие запрошено',
        color: 'gray',
      },
    },
    [DriverStatus.CHECKS_RETRY_REQUESTED]: {
      on: {
        start_checking: {
          target: DriverStatus.CHECKS_STARTING,
        },
      },
      meta: {
        label: 'Повтор проверок запрошен',
        color: 'gray',
      },
    },
    [DriverStatus.ADDITIONAL_APPROVAL_REQUESTED]: {
      on: {
        send_to_comandir: {
          target: DriverStatus.SENDING_TO_COMANDIR,
        },
      },
      meta: {
        label: 'Доп. согласование запрошено',
        color: 'gray',
      },
    },
    [DriverStatus.WORKS]: {
      on: {
        create_rental_contract: {
          target: DriverStatus.WORKS,
        },
        create_travel_sheet: {
          target: DriverStatus.WORKS,
        },
      },
      meta: {
        label: 'Работает',
        color: 'green',
        gridFilter: true,
      },
    },
    [DriverStatus.FIRED]: {
      on: {
        retry_checks: {
          target: DriverStatus.CHECKS_RETRY_REQUESTED,
        },
        create_rental_contract: {
          target: DriverStatus.WORKS,
        },
      },
      meta: {
        label: 'Уволен',
        color: 'red',
        gridFilter: true,
      },
    },
  },
  schema: {
    context: {},
    events: {} as
      | { type: 'start_checking' }
      | { type: 'start_onboarding' }
      | { type: 'reject_started' }
      | { type: 'approve_started' }
      | { type: 'send_to_comandir' }
      | { type: 'reject_finished' }
      | { type: 'onboarding_started' }
      | { type: 'checks_started' }
      | { type: 'retry_checks' }
      | { type: 'sent_to_comandir' }
      | { type: 'request_additional_approval' }
      | { type: 'approve' }
      | { type: 'approve_finished' }
      | { type: 'checks_cancelled' }
      | { type: 'reject' }
      | { type: 'finish_checks' }
      | { type: 'cancel_checks' }
      | { type: 'create_rental_contract' }
      | { type: 'create_travel_sheet' },
  },
  context: {},
  predictableActionArguments: true,
  preserveActionOrder: true,
})

export const useInterpreter = (driverStatus?: DriverAttributes['status']) => {
  const interpreter = useInterpret(machine)
  useLayoutEffect(() => {
    if (driverStatus) {
      interpreter.start(driverStatus)
    }
    return () => {
      interpreter.stop()
    }
  }, [interpreter, driverStatus])

  return interpreter
}

type MachineContextValue = {
  interpreter: InterpreterFrom<typeof machine>
}
export const MachineContext = createContext<MachineContextValue>(
  {} as MachineContextValue,
)
