import { ListWorkRules } from '~/features/WorkRule/ListWorkRules'
import { PageContainer, Container } from '~/shared/ui/Layout'

function ListWorkRulesPage() {
  return (
    <PageContainer title='Условия работы'>
      <Container>
        <ListWorkRules />
      </Container>
    </PageContainer>
  )
}

export default ListWorkRulesPage
