import { ReactNode, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { getMoneyMovement } from '~/features/Drivers/Balance/List/lib'
import { BalanceHistory } from '~/shared/api'
import { transactionBalanceTypeEnumLabels } from '~/shared/config/constants'
import { TransactionBalanceTypeEnum } from '~/shared/config/enums'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'

import { filters } from './filters'
import { GridRow } from './types'
import { useColumns } from './useColumns'

type ListProps = {
  balanceType: TransactionBalanceTypeEnum
  headerExtra: ReactNode
}
export const List = ({ balanceType, headerExtra }: ListProps) => {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  const gridKey = `balance-${balanceType}-list`
  const columns = useColumns(gridKey)

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = BalanceHistory.limit(pageSize)
        .where('balanceType', balanceType)
        .where('driverId', driverId)

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-appliedAt'))

      filters.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)

      const data = response.getData()
      const rows = data.map((balanceHistory) => ({
        ...balanceHistory.getAttributes(),
        id: balanceHistory.getApiId() as string,
        ...getMoneyMovement(balanceHistory),
      }))

      /*
       * Coloquent не дает метода, чтобы получить объект meta,
       * поэтому в данном случае получаем оригинальный ответ и достаем мету из него
       * TODO: Реализовать лучший способ получения метаданных
       *  ...
       * */
      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [balanceType, driverId],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle={transactionBalanceTypeEnumLabels[balanceType]}
      gridKey={gridKey}
      nestedGridKey={driverId}
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      headerExtra={headerExtra}
    />
  )
}
