import { Alert } from '~/shared/ui/Alerts'
import { Button } from '~/shared/ui/Buttons'
import { Modal } from '~/shared/ui/Modal'

type RentalContractWarningCannotCreateModalProps = {
  isShow: boolean
  onCancel: () => void
}

export function RentalContractWarningCannotCreateModal({
  isShow,
  onCancel,
}: RentalContractWarningCannotCreateModalProps) {
  return (
    <Modal isShow={isShow} onCancel={onCancel}>
      <Alert variant='warning' sx={{ margin: '44px 0 24px 0' }}>
        Создать договор возможно только после одобрения водителя
      </Alert>

      <Button variant='gray' fullWidth onClick={onCancel}>
        Закрыть
      </Button>
    </Modal>
  )
}
