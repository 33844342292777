import { AddCarLicensee } from '~/features/Organization/CarLicensees/AddCarLicensee'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Организации', link: '/organizations/car-licensees' },
  { title: 'Лицензиаты', link: '/organizations/car-licensees' },
  { title: 'Новый лицензиат' },
]

const quickNavigationOptions = [
  { label: 'Реквизиты', anchor: 'requisites' },
  { label: 'Реестр', anchor: 'registry' },
  { label: 'Сотрудники', anchor: 'employees' },
  { label: 'Контакты', anchor: 'contacts' },
]

export function AddCarLicenseePage() {
  return (
    <PageContainer title='Новый лицензиат'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Новый лицензиат</PageTitle>
      </Container>
      <AddCarLicensee />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
