export function DotsVerticalIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 10.833C10.4603 10.833 10.8334 10.4599 10.8334 9.99967C10.8334 9.53944 10.4603 9.16634 10 9.16634C9.53978 9.16634 9.16669 9.53944 9.16669 9.99967C9.16669 10.4599 9.53978 10.833 10 10.833Z'
        stroke='#261BB8'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 4.99967C10.4603 4.99967 10.8334 4.62658 10.8334 4.16634C10.8334 3.7061 10.4603 3.33301 10 3.33301C9.53978 3.33301 9.16669 3.7061 9.16669 4.16634C9.16669 4.62658 9.53978 4.99967 10 4.99967Z'
        stroke='#261BB8'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 16.6663C10.4603 16.6663 10.8334 16.2932 10.8334 15.833C10.8334 15.3728 10.4603 14.9997 10 14.9997C9.53978 14.9997 9.16669 15.3728 9.16669 15.833C9.16669 16.2932 9.53978 16.6663 10 16.6663Z'
        stroke='#261BB8'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
