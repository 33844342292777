import { z } from 'zod'
import { CarModel } from '~/shared/api/carModel'
import { Region } from '~/shared/api/region'
import { Option } from '~/shared/config/constants'
import {
  FuelTypeEnum,
  TransmissionEnum,
  WorkRuleStatusEnum,
} from '~/shared/config/enums'
import {
  dateSchema,
  uuidOptionSchema,
  yearsSeparatedByCommasSchema,
  uuidOptionsSchema,
  enumOptionSchema,
  priceRequiredScheme,
} from '~/shared/lib/schemas'

import { isString } from '~/shared/lib/utils'
import { ApiModel, ToManyRelation, ToOneRelation } from './core'
import { VehicleCategory } from './vehicleCategory'

const attributesSchema = z.object({
  title: z.string().optional(),
  isActive: z.boolean().optional(),
  isPromotion: z.boolean().nullable().optional().default(false),
  activeFrom: dateSchema,
  transmissionType: enumOptionSchema(TransmissionEnum),
  manufactureYears: yearsSeparatedByCommasSchema(),
  pricePerDay: priceRequiredScheme,
  fuelTypes: z
    .array(enumOptionSchema(FuelTypeEnum))
    .min(1, 'Обязательное поле'),
})
const relationsSchema = z.object({
  vehicleCategoryId: uuidOptionSchema,
  regionsIds: uuidOptionsSchema.min(1, 'Обязательное поле'),
  carModelOption: uuidOptionSchema.optional().nullable(),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type WorkRuleAttributes = z.infer<typeof attributesSchema>
export type WorkRuleOptionWithFilters = {
  id: UniqueId
  label: string
  filters?: {
    vehicleCategoryId?: UniqueId
    transmission?: TransmissionEnum
    fuelTypes?: FuelTypeEnum[]
    manufactureYears?: number[]
    regionIds?: UniqueId
    carModelId?: UniqueId
    isPromotion?: boolean
  }
}

export class WorkRule extends ApiModel<typeof schema> {
  static jsonApiType = 'work-rules'

  static schema = schema

  getisPromotion(): boolean {
    return this.getAttribute('isPromotion')
  }

  vehicleCategory(): ToOneRelation<VehicleCategory, this> {
    return this.hasOne(VehicleCategory)
  }
  getVehicleCategory(): VehicleCategory {
    return this.getRelation('vehicleCategory')
  }
  setVehicleCategory(id: UniqueId) {
    const vehicleCategory = new VehicleCategory()
    vehicleCategory.setApiId(id)
    this.setRelation('vehicleCategory', vehicleCategory)
  }
  getTitle(): string {
    return this.getAttribute('title')
  }
  getFuelTypes(): FuelTypeEnum[] {
    return this.getAttribute('fuelTypes')
  }
  getTransmissionType(): TransmissionEnum {
    return this.getAttribute('transmissionType')
  }
  getManufactureYears(): number[] {
    return this.getAttribute('manufactureYears')
  }

  isActive(): boolean {
    return this.getAttribute('isActive')
  }

  getStatus(): WorkRuleStatusEnum {
    return this.getAttribute('status')
  }
  getStatusLabel(): string {
    const status = this.getStatus()
    const isActive = this.isActive()
    if (isActive) return 'Действующий'

    switch (status) {
      case WorkRuleStatusEnum.CREATED:
        return 'Создан'
      case WorkRuleStatusEnum.ARCHIVED:
        return 'В архиве'
    }
  }
  getStatusColor() {
    const status = this.getStatus()
    const isActive = this.isActive()
    if (isActive) return 'green'
    switch (status) {
      case WorkRuleStatusEnum.CREATED:
        return 'yellow'
      case WorkRuleStatusEnum.ARCHIVED:
        return 'gray'
      default:
        return 'gray'
    }
  }

  regions(): ToManyRelation<Region, this> {
    return this.hasMany(Region)
  }
  getRegions(): Region[] {
    return this.getRelation('regions') ?? []
  }
  setRegions(ids: UniqueId[] | Option[]) {
    let regions: Region[] = []
    ids.map((regionId) => {
      const region = new Region()
      if (isString(regionId)) {
        region.setApiId(regionId)
        regions = regions.concat(region)
      }
    })
    this.setRelation('regions', regions)
  }

  carModel(): ToOneRelation<CarModel, this> {
    return this.hasOne(CarModel)
  }
  getCarModel(): CarModel {
    return this.getRelation('carModel')
  }
  setCarModel(id: UniqueId) {
    const carModel = new CarModel()
    carModel.setApiId(id)
    this.setRelation('carModel', carModel)
  }

  async archive() {
    const url = `${WorkRule.getJsonApiUrl()}/${this.getApiId()}/actions/archive`
    return WorkRule.effectiveHttpClient.post(url)
  }

  getOptionForRentalContract(): WorkRuleOptionWithFilters {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
      filters: {
        vehicleCategoryId: this.getVehicleCategory()?.getApiId(),
        transmission: this.getAttribute('transmissionType'),
        fuelTypes: this.getAttribute('fuelTypes'),
        manufactureYears: this.getAttribute('manufactureYears'),
        regionIds: this.getRegions()
          ?.map((region) => region.getApiId())
          .join(','),
        carModelId: this.getCarModel()?.getApiId(),
        isPromotion: this.getisPromotion(),
      },
    }
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions(search: string) {
    const response = await WorkRule.where('title', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
