import { createDomain, sample } from 'effector'
import { z } from 'zod'
import {
  AxiosErrorType,
  Car,
  CarOptionForRentalContract,
  RentalContract,
  WorkRule,
  WorkRuleOptionWithFilters,
} from '~/shared/api'
import {
  FILTER_WITHOUT_EMPTY_ENTITIES,
  Option,
  OptionEnum,
} from '~/shared/config/constants'
import {
  CarStatusEnum,
  DayEnum,
  RentalContractWorkScheduleEnum,
  WorkRuleStatusEnum,
} from '~/shared/config/enums'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('entities.rentalContract.form')

export const formSchema = RentalContract.schema.pick({
  carOption: true,
  workRuleOption: true,
  firstDayIsFree: true,
  workSchedule: true,
  rentPerDay: true,
  contractNumber: true,
  driverId: true,
  dayOff: true,
  rentalServicesOptions: true,
})

export type FormValues = Omit<
  z.infer<typeof formSchema>,
  | 'carOption'
  | 'workRuleOption'
  | 'workSchedule'
  | 'dayOff'
  | 'rentalServicesOptions'
> & {
  carOption: UniqueId | CarOptionForRentalContract | null
  workRuleOption: UniqueId | WorkRuleOptionWithFilters | null
  brand?: string
  model?: string
  rental?: string
  terminationDate?: string
  responsibleManager?: string
  workSchedule:
    | OptionEnum<RentalContractWorkScheduleEnum>
    | RentalContractWorkScheduleEnum
    | null
  dayOff: OptionEnum<DayEnum> | DayEnum | null
  rentalServicesOptions: UniqueId[] | Option[]
}

export const fetchWorkRuleOptions = async (
  search: string,
  carFilters?: CarOptionForRentalContract['filters'],
) => {
  const {
    vehicleCategoryId,
    transmission,
    fuelType,
    manufactureYear,
    regionId,
    carModelId,
    isPromotion,
  } = carFilters || {}

  let builder = WorkRule.where('title', search)
    .with('vehicleCategory')
    .where('status', WorkRuleStatusEnum.CREATED)
    .where('active', 'true')
    .with('regions')
    .with('carModel')

  if (vehicleCategoryId) {
    builder = builder.where('vehicleCategory', vehicleCategoryId)
  }
  if (transmission) {
    builder = builder.where('transmissionType', transmission)
  }
  if (fuelType) {
    builder = builder.where('fuelTypes', fuelType)
  }
  if (manufactureYear) {
    builder = builder.where('manufactureYear', String(manufactureYear))
  }
  if (carModelId) {
    builder = builder.where('carModelWithNull', carModelId)
  }
  if (isPromotion) {
    builder = builder.where('isPromotion', String(isPromotion))
  }
  if (regionId) {
    builder = builder.option('filter[regions][id]', regionId)
  }

  const response = await builder.get(1)
  return response.getData().map((o) => o.getOptionForRentalContract())
}

export const fetchCarOptions = async (
  search?: string,
  workRuleFilters?: WorkRuleOptionWithFilters['filters'],
) => {
  const {
    vehicleCategoryId,
    transmission,
    fuelTypes,
    manufactureYears,
    regionIds,
    carModelId,
    isPromotion,
  } = workRuleFilters || {}

  let builder = Car.where(
    'plateNumber',
    search || FILTER_WITHOUT_EMPTY_ENTITIES,
  )
    .where('status', CarStatusEnum.FREE)
    .with('carModel')
    .with('carModel.carBrand')
    .with('carRental')
    .with('vehicleCategory')
    .with('region')

  if (vehicleCategoryId) {
    builder = builder.where('vehicleCategoryId', vehicleCategoryId)
  }
  if (transmission) {
    builder = builder.where('transmission', transmission)
  }
  if (fuelTypes) {
    builder = builder.where('fuelType', fuelTypes.join(','))
  }
  if (manufactureYears?.length) {
    builder = builder.where('manufactureYear', manufactureYears.join(','))
  }
  if (regionIds) {
    builder = builder.where('regionId', regionIds)
  }
  if (carModelId) {
    builder = builder.where('carModelId', carModelId)
  }
  if (isPromotion) {
    builder = builder.where('isPromotion', String(isPromotion))
  }

  const response = await builder.get(1)
  return response.getData().map((o) => o.getOptionForRentalContract())
}

export const calculateRentPerDay = domain.createEvent<{
  workRuleId?: UniqueId
  workSchedule?: RentalContractWorkScheduleEnum
  fn: (value?: string) => void
}>()

export const calculateRentPerDayFx = domain.createEffect<
  {
    workRuleId?: UniqueId
    workSchedule?: RentalContractWorkScheduleEnum
    fn: (value?: string) => void
  },
  void,
  AxiosErrorType
>({
  handler: async ({ workRuleId, workSchedule, fn }) => {
    if (!workRuleId || !workSchedule) {
      fn(undefined)
      return
    }
    const { rentPerDay } = await RentalContract.getRentPerDay(
      workRuleId,
      workSchedule,
    )
    fn(String(rentPerDay))
  },
})

sample({
  clock: calculateRentPerDay,
  target: calculateRentPerDayFx,
})

sample({
  clock: calculateRentPerDayFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
