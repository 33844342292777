import { zodResolver } from '@hookform/resolvers/zod'
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  Grid,
  Stack,
} from '@mui/joy'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '~/shared/ui/Buttons'
import { DatePickerInput, FormProvider, TextareaInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { formSchema, FormValues } from './model'

export type FormProps = {
  canEdit: boolean
  isSubmitting: boolean
  isLoading: boolean
  onSuccess: ({
    formValues,
    fn,
  }: {
    formValues: FormValues
    fn: () => void
  }) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const [isButtonsShow, setIsButtonsShow] = useState<boolean>(false)
  const disabled = !isButtonsShow || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const handleButtonsShow = () => {
    if (isSubmitting || !canEdit) return
    setIsButtonsShow(true)
  }
  const handleButtonsHidden = () => {
    setIsButtonsShow(false)
  }

  const handleCancel = () => {
    form.reset(defaultValues)
    handleButtonsHidden()
  }

  const handleSuccess = (formValues: FormValues) => {
    onSuccess({ formValues, fn: handleButtonsHidden })
  }

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  return (
    <Container
      onDoubleClick={handleButtonsShow}
      data-testid='under-repair-form'
    >
      <ContainerTitle>ТР</ContainerTitle>
      <FormProvider form={form} onSuccess={handleSuccess}>
        <Grid container spacing={2}>
          <Grid xs={4}>
            <DatePickerInput
              label='Плановая дата выхода из ТР'
              name='plannedDateEndRepair'
              placeholder='Укажите плановую дату выхода из ТР'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={8}>
            <TextareaInput
              label='Комментарий'
              name='repairComment'
              placeholder='Введите комментарий'
              skeletonShow={isLoading}
              sx={{
                height: '86px',
              }}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
        <AccordionGroup>
          <Accordion expanded={isButtonsShow} sx={{ minHeight: 0, padding: 0 }}>
            <AccordionDetails data-testid='check-sb-form-accordion-details'>
              <Stack direction='row' spacing={2} mt={2}>
                <Button variant='green' type='submit' loading={isSubmitting}>
                  Сохранить
                </Button>
                <Button
                  variant='red'
                  onClick={handleCancel}
                  disabled={isSubmitting}
                >
                  Отменить
                </Button>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      </FormProvider>
    </Container>
  )
}
