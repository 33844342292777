import { Grid } from '@mui/joy'
import { useMemo } from 'react'
import { constants } from '~/shared/config'
import { AutocompleteInput, DatePickerInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type PassportProps = {
  disabled?: boolean
  skeletonShow?: boolean
}

export function Passport({ disabled, skeletonShow }: PassportProps) {
  const countries = useMemo(
    () =>
      Object.values(constants.countries).map((value) => ({
        id: value,
        label: value,
      })),
    [],
  )

  return (
    <Container data-testid='passport-form-container'>
      <ContainerTitle>Паспорт</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <AutocompleteInput
            label='Гражданство'
            placeholder='Выберите гражданство'
            name='citizenship'
            options={countries}
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Серия и номер'
            placeholder='Введите серию и номер'
            name='passportNumber'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Дата рождения'
            placeholder='Введите дату рождения'
            name='birthDate'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Дата выдачи'
            placeholder='Введите дату выдачи'
            name='passportIssueDate'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Срок действия'
            placeholder='Введите срок действия'
            name='data.passportExpirationDate'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Адрес регистрации'
            placeholder='Введите адрес регистрации'
            name='registrationAddress'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Место рождения'
            placeholder='Введите место рождения'
            name='data.birthPlace'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Код подразделения'
            placeholder='Введите код подразделения'
            name='divisionCode'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Кем выдан'
            placeholder='Введите кем выдан'
            name='passportIssued'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
