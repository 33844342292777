import { Box, CircularProgress, LinearProgress } from '@mui/joy'
import { ReactNode, useCallback, useEffect } from 'react'
import {
  useDropzone,
  DropzoneOptions,
  DropzoneInputProps,
  DropzoneRootProps,
} from 'react-dropzone'
import { ThumbType } from '~/shared/api'
import { myPalette } from '~/shared/lib/theme'
import { UploadCloudIcon } from '../Icons'
import { DropContainer, SubTitle, Title } from './styled'
import { Thumbs } from './Thumbs'
import { UploadFiles } from './UploadFiles'

export type Props = {
  thumbs?: ThumbType[]
  files?: File[]
  isSubmitting?: boolean
  isLoading?: boolean
  onDelete?: (id: UniqueId) => void
  canDelete?: boolean
  canCreate?: boolean
  rootProps?: DropzoneRootProps
  inputProps?: DropzoneInputProps
  testId?: string
  isShowUploadFiles?: boolean
  onUploadFiles?: (files: File[]) => void
  empty?: string | ReactNode
} & DropzoneOptions

export function Dropzone({
  thumbs = [],
  files,
  isSubmitting = false,
  isLoading,
  onDelete,
  canDelete = true,
  canCreate = true,
  rootProps,
  inputProps,
  testId,
  isShowUploadFiles,
  onUploadFiles,
  empty,
  ...dropzoneProps
}: Props) {
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ ...dropzoneProps })

  const handleUploadFilesDelete = useCallback(
    (file: File) => {
      onUploadFiles?.(
        (files || []).filter((prevFile) => prevFile.name !== file.name),
      )
    },
    [files, onUploadFiles],
  )

  useEffect(() => {
    if (!isShowUploadFiles) return

    onUploadFiles?.([...(files || []), ...acceptedFiles])
    // eslint-disable-next-line
  }, [acceptedFiles])

  if (isLoading) return <LinearProgress size='lg' />

  return (
    <>
      <Box>
        {canCreate && (
          <DropContainer
            data-testid={testId}
            {...getRootProps(rootProps)}
            sx={{
              ...(isSubmitting ? { pointerEvents: 'none' } : {}),
              background: isDragAccept
                ? myPalette.green['400']
                : isDragReject
                ? myPalette.red['400']
                : 'transparent',
            }}
          >
            <input {...getInputProps(inputProps)} />

            <Box sx={{ height: '40px' }}>
              {isSubmitting ? <CircularProgress /> : <UploadCloudIcon />}
            </Box>
            <Title>
              <SubTitle component='span'>Нажмите</SubTitle> или перетащите файлы
              для загрузки
            </Title>
          </DropContainer>
        )}
        <br />
        {thumbs.length > 0 && (
          <Thumbs thumbs={thumbs} onDelete={onDelete} canDelete={canDelete} />
        )}
        {!thumbs.length && isShowUploadFiles && (
          <UploadFiles
            files={files || []}
            onDelete={handleUploadFilesDelete}
            canDelete={!isSubmitting && canDelete}
          />
        )}
        {empty}
      </Box>
    </>
  )
}
