import { GridColDef } from '@mui/x-data-grid'
import { ColumnFileList } from '~/entities/Car/Documents/ui/ColumnFileList'
import { ColumnDate } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата создания',
    field: 'createdAt',
    sortable: false,
    flex: 1,
    width: 150,
    renderCell: ({ row }) => (
      <ColumnDate type='dateTime' value={row.createdAt} />
    ),
  },
  {
    headerName: 'Серия',
    field: 'series',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Номер',
    field: 'number',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Дата выдачи',
    field: 'issuedDate',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <ColumnDate type='date' value={row.issuedDate} />,
  },
  {
    headerName: 'Местонахождение',
    field: 'location',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Документы',
    field: 'files',
    sortable: false,
    minWidth: 120,
    renderCell: ({ row }) => <ColumnFileList files={row.files} />,
  },
]
