import { useStoreMap } from 'effector-react'
import { carEditModel } from 'src/features/Сars/EditCar'
import { CSS_DOMAIN } from '~/shared/config/env'
import { myPalette } from '~/shared/lib/theme'
import { Button } from '~/shared/ui/Buttons'
import { SearchIcon } from '~/shared/ui/Icons'

export function ButtonInspectionExtra() {
  const carVin = useStoreMap(carEditModel.$car, (car) => car?.getVin())

  return (
    <Button
      href={`${CSS_DOMAIN}inspections?carVin=${carVin}`}
      startDecorator={<SearchIcon color={myPalette.white.main} />}
      variant='brand'
    >
      Осмотры
    </Button>
  )
}
