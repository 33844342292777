import { useStore } from 'effector-react'
import { Form } from 'src/entities/WorkRule'
import { formSubmitted, createFx } from './model'

type AddWorkRulePageProps = {
  onTitleChange: (title: string) => void
}

export function AddWorkRule({ onTitleChange }: AddWorkRulePageProps) {
  const isCreating = useStore(createFx.pending)

  return (
    <Form
      isSubmitting={isCreating}
      onSuccess={formSubmitted}
      isCreating
      onTitleChange={onTitleChange}
    />
  )
}
