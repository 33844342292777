import {
  FsspResults,
  GibddResults,
  KbmResults,
  YandexResults,
} from '~/shared/api'
import {
  DRIVER_KBM_ERROR_COEF,
  DRIVER_KBM_WARNING_COEF,
} from '~/shared/config/constants'
import { CheckKindEnum } from '~/shared/config/enums'
import {
  fromStringApi,
  addMonths,
  isBeforeNow,
  formatDuration,
  formatDate,
} from '~/shared/lib/date'

import { isString } from '~/shared/lib/utils'

const checkKindLabels: Record<CheckKindEnum, string> = {
  [CheckKindEnum.FSSP]: 'ФССП',
  [CheckKindEnum.KBM]: 'КБМ',
  [CheckKindEnum.GIBDD]: 'ГИБДД',
  [CheckKindEnum.YANDEX]: 'Яндекс',
  [CheckKindEnum.SB]: 'СБ',
}
export const getCheckKindLabel = (value: CheckKindEnum) =>
  checkKindLabels[value]

/* GIBDD */
export const getGibddResultsData = (results: GibddResults) => {
  const drivingCategory = results?.doc?.cat
  const message = results?.message
  const decisions = (results?.decis ?? [])
    .filter(({ comment }) => isString(comment))
    .map(({ comment, srok, date }) => {
      const isExpired = [
        typeof srok === 'number',
        typeof date === 'string',
        isBeforeNow(addMonths(fromStringApi(date as string), srok as number)),
      ].every(Boolean)
      return {
        comment,
        isExpired,
        date: date ? formatDate(new Date(date), 'dd.MM.yyyy') : '-',
        srok: srok ? formatDuration({ months: srok }) : '-',
      }
    })
  return {
    drivingCategory,
    message,
    decisions,
  }
}

/* FSSP */
export const isFsspEmptyResults = (results: FsspResults) =>
  results?.length === 0

/* KBM */
export const isKbmEmptyResults = (results: KbmResults) =>
  typeof results?.kbmValue !== 'number' && typeof results?.kbmValue !== 'string'

export const getKbmColor = (
  value: number | string,
): 'red' | 'green' | 'gray' => {
  if (Number(value) > DRIVER_KBM_ERROR_COEF) return 'red'
  if (Number(value) > DRIVER_KBM_WARNING_COEF) return 'gray'
  return 'green'
}

/* Yandex */
export const isYandexEmptyResults = (results: YandexResults) =>
  Array.isArray(results) && results.length === 0

export const getYandexResultsReports = (results: YandexResults) =>
  results.scoring?.report
    .filter((report) => isString(report.driver?.check_message))
    .map((report) => {
      return {
        park: report.park_name,
        message: report.driver.check_message as string,
      }
    }) ?? []

export const getYandexResultsDebts = (results: YandexResults) =>
  results.scoring?.report
    .filter((report) => report.debt?.has_debt)
    .map((report) => ({
      park: report.park_name,
      boundsFrom: report.debt?.bounds_from,
      boundsTo: report.debt?.bounds_to,
    })) ?? []
