import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { FormValues } from '~/entities/Organizations/CarLicensee/ui/Form'
import { AxiosErrorType, CarLicensee } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const domain = createDomain('features.organization.carLicensee.add')
export const Gate = createGate<{ id: UniqueId }>()

export const createFx = domain.createEffect<
  FormValues,
  CarLicensee,
  AxiosErrorType
>({
  async handler(values) {
    const { ...formData } = values
    const carLicensee = new CarLicensee(formData)

    const result = await carLicensee.save()
    return result.getModel() as CarLicensee
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Лицензиат создан',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
