import { z } from 'zod'
import { KaskoDocument } from '~/shared/api'
import { Option } from '~/shared/config/constants'

export const formSchema = KaskoDocument.schema.pick({
  series: true,
  number: true,
  issuedDate: true,
  expirationDate: true,
  premium: true,
  counterpartyId: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<TypeFormSchema, 'counterpartyId'> & {
  counterpartyId: UniqueId | Option | null
}
