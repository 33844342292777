import { GridColDef } from '@mui/x-data-grid'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { formingLink } from './lib'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Аккаунт',
    field: 'title',
    sortable: false,
    flex: 1,
    minWidth: 300,
    renderCell: ({ row }) => (
      <ColumnLink to={formingLink(row.aggregatorCode, row.id)}>
        {row.title}
      </ColumnLink>
    ),
  },
  {
    headerName: 'Агрегатор',
    field: 'aggregatorCode',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'ID водителя в агрегаторе',
    field: 'extId',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Основной',
    field: 'mainAccount',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => (
      <ChipStatus
        label={value ? 'Да' : 'Нет'}
        variant={value ? 'green' : 'gray'}
      />
    ),
  },
  {
    headerName: 'Самозанятый',
    field: 'selfemployed',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => (
      <ChipStatus
        label={value ? 'Да' : 'Нет'}
        variant={value ? 'yellow' : 'gray'}
      />
    ),
  },
]
