export const reasonsTerminations = [
  'Аварийный ремонт',
  'Выходные',
  'Долг',
  'ДТП',
  'Не устраивают условия работы',
  'Тех.состояние авто',
  'Уехал в другой город',
  'Ушел в другой парк',
  'Блок Агрегатора',
  'Замена авто',
  'Свое авто',
  'Лишение прав',
  'Другое',
]

export const reasonsTerminationsOptions = reasonsTerminations.map((reason) => ({
  id: reason,
  label: reason,
}))
