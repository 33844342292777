import { GridColDef } from '@mui/x-data-grid'
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows'

export const addingColumn = <T extends GridValidRowModel>(
  columns: GridColDef<T>[],
  newColumn: GridColDef<T>,
  afterField: string,
): GridColDef<T>[] => {
  return columns.reduce((allColumns: GridColDef<T>[], column) => {
    if (afterField === column.field) return [...allColumns, column, newColumn]

    return [...allColumns, column]
  }, [])
}
