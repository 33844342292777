import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  aggregatorDriverSchema,
  FormValues,
} from '~/entities/Driver/Aggregator/aggregatorDriverModel'
import { YandexWorkRule } from '~/shared/api'

import {
  AsyncAutocompleteInput,
  FormButtons,
  formButtonsModel,
  FormProvider,
  NumericInput,
  Switch,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type FormProps = {
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
  accountId?: UniqueId
}

export function YandexForm({
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
  accountId,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = !isEditing || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(aggregatorDriverSchema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <ContainerTitle>Информация</ContainerTitle>
        <Grid container spacing={2.5} alignItems='center'>
          <Grid xs={4}>
            <Switch
              label='Запретить заказы при балансе ниже лимита'
              name='blockOrdersOnBalanceBelowLimit'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={4}>
            <Switch
              label='Доступны ли заказы от партнера'
              name='partner'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={4}>
            <Switch
              label='Доступны ли заказы от платформы'
              name='platform'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={12}>
            <AsyncAutocompleteInput
              label='Условия работы'
              name='yandexWorkRuleOption'
              fetchOptions={() =>
                YandexWorkRule.fetchOptionsByAccountId(accountId)
              }
              queryKey={[accountId]}
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>

          <Grid xs={12}>
            <NumericInput
              label='Лимит по счёту'
              name='balanceLimit'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>
      <FormButtons loading={isSubmitting} />
    </FormProvider>
  )
}
