import { useGate, useStore, useStoreMap } from 'effector-react'
import { subdivisionModel } from '~/entities/Taxipark/Subdivisions'
import { Form } from '~/entities/Taxipark/Subdivisions/ui/Form'
import { usePermissions } from '~/entities/viewer'
import { formSubmitted, Gate, $subdivision, subdivisionUpdateFx } from './model'

type EditSubdivisionProps = {
  subdivisionId: string
}

export function EditSubdivision({ subdivisionId }: EditSubdivisionProps) {
  useGate(Gate, { subdivisionId })
  const { canSubdivisionsUpdate } = usePermissions()

  const isLoading = useStore(subdivisionModel.requestFx.pending)
  const isSaving = useStore(subdivisionUpdateFx.pending)

  const defaultValues = useStoreMap($subdivision, (subdivision) => ({
    title: subdivision?.getTitle(),
    regionId: subdivision?.getRegion()?.getOption(),
  }))

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canSubdivisionsUpdate}
    />
  )
}
