import { useStore } from 'effector-react'
import { carEditModel } from 'src/features/Сars/EditCar'
import { carModel } from '~/entities/Car'
import { CarStatusEnum } from '~/shared/config/enums'
import { formatMoscowDateTime } from '~/shared/lib/date'
import { Alert } from '~/shared/ui/Alerts'

export function StatusAlert() {
  const isCarLoading = useStore(carModel.requestFx.pending)

  const car = useStore(carEditModel.$car)

  const lastStatusTransition = car?.getLastStatusTransition()?.getAttributes()
  const responsibleName = car
    ?.getLastStatusTransition()
    ?.getResponsible()
    ?.getName()

  const { createdAt, comment, to, from } = lastStatusTransition || {}

  const responsibleTitle = responsibleName
    ? `Статус сменил: ${responsibleName}, `
    : ''
  const dateTitle = createdAt ? `в ${formatMoscowDateTime(createdAt)} ` : ''
  const commentTitle = comment ? `комментарий: ${comment}` : ''

  const isAutoChangeStatus =
    to === CarStatusEnum.AT_WORK ||
    (from === CarStatusEnum.AT_WORK && to === CarStatusEnum.FREE)

  const title = isAutoChangeStatus
    ? `Автоматическая смена статуса ${dateTitle}`
    : `${responsibleTitle} ${dateTitle} ${commentTitle}`

  if (!lastStatusTransition || isCarLoading) return null

  return (
    <Alert data-testid='car-alert-status-change' variant='info'>
      {title}
    </Alert>
  )
}
