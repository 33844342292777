import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'
import { FormValues, carRentalModel } from '~/entities/Organizations/CarRental'
import { CarRental } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ carRentalId: UniqueId }>()
export const domain = createDomain('features.organizations.carRentals.edit')

export const $carRentalId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { carRentalId }) => carRentalId)

export const $carRental = combine(
  $carRentalId,
  carRentalModel.$carRentalsCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $carRentalId,
  filter: isString,
  target: carRentalModel.requestFx,
})

export const carRentalUpdateFx = attach({
  effect: carRentalModel.saveFx,
  source: $carRentalId,
  mapParams: (values: FormValues, id) => {
    const carRental = new CarRental(values, id as string)
    return carRental
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: carRentalUpdateFx,
})

sample({
  clock: carRentalUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: carRentalUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
