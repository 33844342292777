import { useCallback } from 'react'
import { Car } from '~/shared/api'
import {
  carAtWorkSubStatusEnumLabels,
  carUnderRepairSubStatusEnumLabels,
} from '~/shared/config/constants'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'

import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'
import { HeadingExtra } from './ui/HeadingExtra'

export function ListCars() {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = []) => {
      let builder = Car.limit(pageSize)
        .with('carModel')
        .with('carModel.carBrand')
        .with('subdivision')
        .with('region')
        .orderBy('-createdAt')

      filters.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((car) => ({
        ...car.getAttributes(),
        id: car.getApiId() as string,
        model: car.getBrandAndModelTitle(),
        subdivision: car.getSubdivision()?.getTitle(),
        status: {
          label: car.getStatusLabel(),
          color: Car.getStatusColor(car.getStatus()),
        },
        subStatus: {
          ...carUnderRepairSubStatusEnumLabels,
          ...carAtWorkSubStatusEnumLabels,
        }[car.getSubStatus()],
        region: car.getRegion()?.getName(),
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      title='Автомобили'
      gridKey='cars'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      headerExtra={<HeadingExtra />}
    />
  )
}
