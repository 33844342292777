import { useParams } from 'react-router-dom'
import { Documents } from 'src/features/Drivers/Documents'
import { DriverChecks } from 'src/features/Drivers/DriverChecks'
import { EditDriver } from 'src/features/Drivers/EditDriver'
import { usePermissions } from '~/entities/viewer'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Проверки', anchor: 'checks' },
  { label: 'Осн. информация', anchor: 'main' },
  { label: 'Паспорт', anchor: 'passport' },
  { label: 'Ин. граждане', anchor: 'foreigners' },
  { label: 'Вод. удостоверение', anchor: 'drivingLicense' },
  { label: 'Доп. информация', anchor: 'extra' },
  { label: 'Документы', anchor: 'documents' },
]

export function DetailsPage() {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  const { canDriverChecksView } = usePermissions()

  return (
    <>
      {canDriverChecksView && <DriverChecks driverId={driverId} />}

      <EditDriver />

      <Documents driverId={driverId} />

      <QuickNavigation options={quickNavigationOptions} />
    </>
  )
}
