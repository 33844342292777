import { z } from 'zod'

import { ApiModel } from './core'

const schema = z.object({
  title: z.string(),
})

export type ColorAttributes = z.infer<typeof schema>

export class Color extends ApiModel<typeof schema> {
  static jsonApiType = 'colors'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions(search: string) {
    const response = await Color.where('title', search).orderBy('title').get(1)
    return response.getData().map((o) => o.getOption())
  }
}
