import { Box, List, ListItem, Stack, Typography } from '@mui/joy'
import { useStoreMap } from 'effector-react'
import { driverModel } from '~/entities/Driver'
import {
  getCheckKindLabel,
  getGibddResultsData,
} from '~/entities/DriverCheck/lib'
import { GibddResults as GibddResultsType } from '~/shared/api'
import { CheckKindEnum } from '~/shared/config/enums'
import { Button } from '~/shared/ui/Buttons'

type GibddResultsProps = {
  results: GibddResultsType
  onClose: () => void
}

export function GibddResults({ results, onClose }: GibddResultsProps) {
  const drivingExperience = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getFormattedDrivingExperienceDuration(),
  )
  const { drivingCategory, decisions, message } = getGibddResultsData(results)
  const showMessage = !drivingCategory && !decisions.length && message
  return (
    <Stack spacing={2}>
      <Typography fontWeight={600} fontSize={20}>
        {getCheckKindLabel(CheckKindEnum.GIBDD)}
      </Typography>
      {Boolean(drivingCategory) && (
        <Typography>
          Категория: <b>{drivingCategory}</b>
        </Typography>
      )}
      {drivingExperience && (
        <Typography>
          Опыт: <b>{drivingExperience}</b>
        </Typography>
      )}
      {decisions.length > 0 && (
        <div>
          <Typography fontWeight={500}>Решения</Typography>
          <List>
            {decisions.map(({ comment, isExpired, date, srok }) => (
              <ListItem key={comment} style={{ display: 'block' }}>
                <Box>
                  <Typography>
                    {comment}
                    {isExpired && <span> (Истекло)</span>}
                  </Typography>
                </Box>
                <Box>
                  <Typography>Дата: {date}</Typography>
                </Box>
                <Box>
                  <Typography>Срок: {srok}</Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </div>
      )}
      {showMessage && <Typography>{message}</Typography>}

      <br />
      <Button variant='gray' onClick={onClose}>
        Закрыть
      </Button>
    </Stack>
  )
}
