import { z } from 'zod'

import { Region } from '~/shared/api/region'
import { Option } from '~/shared/config/constants'
import { dateSchema, uuidOptionSchema } from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'

const relationsSchema = z.object({
  regionId: uuidOptionSchema,
})

const attributeSchema = z.object({
  title: z.string().trim().min(1, 'Обязательное поле'),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type SubdivisionAttributes = z.infer<typeof attributeSchema>
export type RegionOptionWithFilters = {
  id: UniqueId
  label: string
  filters?: {
    regionId?: UniqueId
  }
}

export class Subdivision extends ApiModel<
  typeof schema,
  SubdivisionAttributes
> {
  static jsonApiType = 'subdivisions'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  region(): ToOneRelation<Region, this> {
    return this.hasOne(Region)
  }
  getRegion(): Region {
    return this.getRelation('region')
  }
  setRegion(id: UniqueId) {
    const region = new Region()
    region.setApiId(id)
    this.setRelation('region', region)
  }

  getOption(): Option {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions(search: string) {
    const response = await Subdivision.where('title', search).get(1)
    return response.getData().map((o) => o.getOption())
  }

  static async fetchOptionsWithRelations(search: string, regionId: UniqueId) {
    let builder = Subdivision.where('title', search).with('region')

    if (regionId) builder = builder.where('regionId', regionId)

    const response = await builder.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
