import { PageContainer } from '~/shared/ui/Layout'
import RoutesTabs from '~/shared/ui/RoutesTabs'
import { Header } from './ui/Header'
import { useConfigTabs } from './useConfigTabs'

function BalancePage() {
  const configTabs = useConfigTabs()
  return (
    <PageContainer title='Баланс'>
      <RoutesTabs
        parentPath='/balance'
        config={configTabs}
        parentContent={<Header />}
      />
    </PageContainer>
  )
}

export default BalancePage
