import { useCallback } from 'react'
import { usePermissions } from '~/entities/viewer'
import { Region } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { GridRow } from './types'

export const ListRegions = () => {
  const { canRegionsUpdate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize) => {
      const builder = Region.limit(pageSize)
        .with('timezone')
        .orderBy('-createdAt')

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((region) => ({
        id: region.getApiId() as string,
        timezone: region.getTimezone()?.getTitle(),
        name: region.getName(),
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Регионы'
      gridKey='regions'
      fetchRows={fetchRows}
      columns={columns}
      headerExtra={
        <Button startDecorator={<PlusIcon />} to='new' show={canRegionsUpdate}>
          Добавить
        </Button>
      }
    />
  )
}
