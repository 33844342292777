import { z } from 'zod'
import { dateSchema } from '~/shared/lib/schemas'
import { Car } from './car'
import { ApiModel } from './core'

const schema = z.object({
  plateNumber: z
    .string({ description: 'Гос. номер' })
    .regex(
      /^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}(\d{2,3})$|^[АВЕКМНОРСТУХ]{2}(\d{5})$/iu,
      'Гос. номер имеет неправильный формат, например "А111АА777" или "ХА11111"',
    ),
  series: z
    .string()
    .trim()
    .refine(
      (value) => /^\d{4}$/.test(value),
      'Длина серии - 4 символов, только цифры',
    ),
  number: z
    .string()
    .trim()
    .refine(
      (value) => /^\d{6}$/.test(value),
      'Длина номера - 6 символов, только цифры',
    ),
  issuedDate: dateSchema,
  expirationDate: dateSchema.optional().nullable(),
  issuedBy: z.string().optional().nullable(),

  createdAt: dateSchema,
  updatedAt: dateSchema.optional(),

  files: z
    .object({
      id: z.string(),
      name: z.string(),
      filepath: z.string().url(),
      preview: z.string().url(),
    })
    .array()
    .optional(),
})

export type StsDocumentAttributes = z.infer<typeof schema>

export class StsDocument extends ApiModel<typeof schema> {
  static jsonApiType = 'sts-documents'

  static schema = schema

  setCar(id: UniqueId) {
    this.setRelation('car', new Car({}, id))
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }

  getFiles(): StsDocumentAttributes['files'] {
    return this.getAttribute('files')
  }
}
