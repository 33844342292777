import { useParams } from 'react-router-dom'
import { List } from '~/entities/RentalContract/ui/List'
import { GridRow } from '~/entities/RentalContract/ui/List/types'
import { useColumns } from '~/entities/RentalContract/ui/List/useColumns'
import { addingColumn } from '~/shared/lib/addingColumn'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { Container } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const ListRentalContractPage = () => {
  const params = useParams<{ carId: string }>()
  const carId = params.carId as string

  const columns = useColumns(false)

  const mappedColumns = addingColumn<GridRow>(
    columns,
    {
      headerName: 'Водитель',
      field: 'driverFullName',
      sortable: false,
      minWidth: 330,
      renderCell: ({ row }) => (
        <ColumnLink to={`/drivers/${row.driverId}`}>
          {row.driverFullName}
        </ColumnLink>
      ),
    },
    'terminationDate',
  )

  if (!carId) {
    return <NotFound message='Не удалось загрузить договора' />
  }

  return (
    <Container>
      <List carId={carId} columns={mappedColumns} />
    </Container>
  )
}
