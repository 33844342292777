import { ListAppeals } from '~/features/Balance/Appeal/ListAppeals'
import { Container } from '~/shared/ui/Layout'

export function ListAppealPage() {
  return (
    <Container>
      <ListAppeals />
    </Container>
  )
}
