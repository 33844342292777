import { HttpClientResponse } from 'coloquent'
import { createDomain, sample } from 'effector'
import { AxiosErrorType, BalanceHistory } from '~/shared/api'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('features.balance.balanceHistory.storno')

export const applyStornoFx = domain.createEffect<
  UniqueId,
  Promise<HttpClientResponse>,
  AxiosErrorType
>({
  async handler(id) {
    return await BalanceHistory.applyStorno(id)
  },
})

sample({
  clock: applyStornoFx.doneData,
  fn() {
    return {
      message: 'Транзакция успешно сторнирована',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: applyStornoFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
