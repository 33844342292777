import { useStore } from 'effector-react'
import { useMemo } from 'react'
import {
  balanceHistoryModel,
  BalanceUseFormWatchOptions,
} from '~/entities/Balance/BalanceHistory'
import { Form, balanceFormModel } from '~/entities/Balance/BalanceHistory'
import {
  transactionBalanceTypeEnumLabels,
  transactionMoveFundsTypeEnumLabels,
  transactionPaymentTypeEnumLabels,
} from '~/shared/config/constants'
import {
  TransactionBalanceTypeEnum,
  TransactionMoveFundsTypeEnum,
  TransactionPaymentTypeEnum,
} from '~/shared/config/enums'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle } from '~/shared/ui/Layout'

type CreateTransactionProps = {
  moveFundsType: TransactionMoveFundsTypeEnum
  isReadOnlyDriver?: boolean
  isReadOnlyCar?: boolean
  defaultValues?: Partial<balanceFormModel.FormValues>
  formWatchOption?: BalanceUseFormWatchOptions
  breadcrumbs: BreadcrumbsList
  isCreating?: boolean
}

export function CreateTransaction({
  moveFundsType,
  isReadOnlyDriver,
  isReadOnlyCar,
  defaultValues,
  formWatchOption,
  breadcrumbs,
  isCreating,
}: CreateTransactionProps) {
  const isSubmitting = useStore(balanceHistoryModel.createFx.pending)

  const mappedDefaultValues = useMemo(
    () => ({
      ...defaultValues,
      moveFundsType: {
        id: moveFundsType,
        label: transactionMoveFundsTypeEnumLabels[moveFundsType],
      },
      paymentType: {
        id: TransactionPaymentTypeEnum.NON_CASH,
        label:
          transactionPaymentTypeEnumLabels[TransactionPaymentTypeEnum.NON_CASH],
      },
      balanceType: {
        id: TransactionBalanceTypeEnum.MAIN,
        label:
          transactionBalanceTypeEnumLabels[TransactionBalanceTypeEnum.MAIN],
      },
    }),
    [defaultValues, moveFundsType],
  )

  return (
    <>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание транзакции</PageTitle>
      </Container>
      <Form
        defaultValues={mappedDefaultValues}
        isReadOnlyDriver={isReadOnlyDriver}
        isReadOnlyCar={isReadOnlyCar}
        onSuccess={balanceHistoryModel.formSubmitted}
        formWatchOption={formWatchOption}
        isCreating={isCreating}
        isSubmitting={isSubmitting}
      />
    </>
  )
}
