import { z } from 'zod'

import { ApiModel } from './core'

const schema = z.object({
  name: z.string(),
  msk_offset: z.string(),
  utc_offset: z.number(),
})

export type TimezoneAttributes = z.infer<typeof schema>

export class Timezone extends ApiModel<typeof schema> {
  static jsonApiType = 'timezones'

  static schema = schema

  getTitle(): string {
    const name = this.getAttribute('name') || ''
    const mskOffset = this.getAttribute('msk_offset') || ''
    return `${name} ${mskOffset}`
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions() {
    const response = await Timezone.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
