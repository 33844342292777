import { z } from 'zod'
import { StsDocument } from '~/shared/api'

export const formSchema = StsDocument.schema.pick({
  plateNumber: true,
  series: true,
  number: true,
  issuedDate: true,
  expirationDate: true,
  issuedBy: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = TypeFormSchema
