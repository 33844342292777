import { Stack } from '@mui/joy'
import { useGate, useStore, useStoreMap } from 'effector-react'
import { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Storno } from '~/features/Balance/BalanceHistory/Storno'
import { balanceHistoryModel, Form } from '~/entities/Balance/BalanceHistory'
import { BalanceHistory } from '~/shared/api'
import {
  transactionBalanceTypeEnumLabels,
  transactionMoveFundsTypeEnumLabels,
  transactionPaymentTypeEnumLabels,
} from '~/shared/config/constants'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

type ViewTransactionProps = {
  parentBreadcrumbs: BreadcrumbsList
  mountTransactionValidate?: (transaction: BalanceHistory) => ReactNode | null
}

export function ViewTransaction({
  parentBreadcrumbs,
  mountTransactionValidate,
}: ViewTransactionProps) {
  const params = useParams<{ transactionId: string }>()
  const transactionId = params.transactionId as string

  useGate(balanceHistoryModel.Gate, { transactionId })

  const isLoading = useStore(balanceHistoryModel.$loading)
  const transaction = useStore(balanceHistoryModel.$transaction)
  const stornoId = transaction?.getStornoId()
  const appliedAt = transaction?.getAppliedAt()
    ? formatDateTimeForUI(transaction?.getAppliedAt())
    : ''

  const defaultValues = useStoreMap(
    balanceHistoryModel.$transaction,
    (transaction) => {
      const carId = transaction?.getCarId()
      const carPlateNumber = transaction?.getCarPlateNumber()
      const moveFundsType = transaction?.getMoveFundsType()
      const balanceType = transaction?.getBalanceType()
      const paymentType = transaction?.getPaymentType()
      const flowStatementName = transaction?.getFlowStatementName()
      const driverId = transaction?.getDriverId()
      const driverFullName = transaction?.getDriverFullName()

      return {
        id: transaction?.getApiId(),
        amount: transaction?.getAmount(),
        sourceType: transaction?.getSourceType(),
        comment: transaction?.getComment(),
        aggregatorAccount: transaction?.getAggregatorAccountTitle(),
        balanceType: balanceType
          ? {
              id: balanceType,
              label: transactionBalanceTypeEnumLabels[balanceType],
            }
          : null,
        paymentType: paymentType
          ? {
              id: paymentType,
              label: transactionPaymentTypeEnumLabels[paymentType],
            }
          : null,
        moveFundsType: moveFundsType
          ? {
              id: moveFundsType,
              label: transactionMoveFundsTypeEnumLabels[moveFundsType],
            }
          : null,
        flowStatementOption: flowStatementName
          ? {
              id: transaction?.getFlowStatementId() || '-',
              label: flowStatementName,
            }
          : null,
        driverOption:
          driverId && driverFullName
            ? {
                id: driverId,
                label: driverFullName,
                carOption:
                  carId && carPlateNumber
                    ? {
                        id: carId,
                        label: carPlateNumber,
                      }
                    : null,
              }
            : null,
      }
    },
  )

  const title = `Транзакция от ${appliedAt}`

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [...parentBreadcrumbs, { title: title || '' }],
    [parentBreadcrumbs, title],
  )

  if (!isLoading) {
    if (!transaction) {
      return <NotFound message='Не удалось загрузить транзакцию' />
    }

    const content = mountTransactionValidate?.(transaction)
    if (content) return <>{content}</>
  }

  return (
    <>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <PageTitle loading={isLoading}>{title}</PageTitle>
          {!stornoId && <Storno transactionId={transactionId} isGoBack />}
        </Stack>
      </Container>
      <Form defaultValues={defaultValues} isLoading={isLoading} />
    </>
  )
}
