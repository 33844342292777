import * as z from 'zod'

import { CheckStatusEnum, CheckKindEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'

import { ApiModel, ToOneRelation } from './core'
import { Driver } from './driver'

export const resultsSchemas = {
  [CheckKindEnum.GIBDD]: z.object({
    doc: z
      .object({
        cat: z.string().optional(),
        stag: z.string().optional(),
      })
      .optional(),
    decis: z
      .array(
        z.object({
          comment: z.string(),
          srok: z.number().optional(),
          date: dateSchema.optional(),
        }),
      )
      .optional(),
    message: z.string().optional(),
  }),
  [CheckKindEnum.FSSP]: z.array(
    z.object({
      title: z.string(),
      records: z.array(
        z.object({
          debtor: z.string(),
          executionCase: z.string(),
          executionDocument: z.string(),
          executionCaseEndReason: z.string(),
          service: z.string(),
          executionSubject: z.string(),
          debtAmount: z.string(),
          court: z.string(),
        }),
      ),
    }),
  ),
  [CheckKindEnum.YANDEX]: z.object({
    scoring: z.object({
      rating: z.number(),
      report: z.array(
        z.object({
          park_name: z.string(),
          debt: z
            .object({
              has_debt: z.boolean(),
              bounds_from: z.string().optional(),
              bounds_to: z.string().optional(),
            })
            .optional(),
          driver: z.object({
            check_message: z.string().optional(),
          }),
        }),
      ),
    }),
  }),
  [CheckKindEnum.KBM]: z.object({
    kbmValue: z.number().or(z.string()).nullable(),
  }),
  [CheckKindEnum.SB]: z.object({
    accepted: z.boolean(),
    comment: z.string().optional().nullable(),
  }),
}
type InferResults<T extends CheckKindEnum> = z.infer<(typeof resultsSchemas)[T]>
export type GibddResults = InferResults<CheckKindEnum.GIBDD>
export type FsspResults = InferResults<CheckKindEnum.FSSP>
export type YandexResults = InferResults<CheckKindEnum.YANDEX>
export type KbmResults = InferResults<CheckKindEnum.KBM>
export type SbResults = InferResults<CheckKindEnum.SB>

export const AUTO_CHECKS = [
  CheckKindEnum.FSSP,
  CheckKindEnum.YANDEX,
  CheckKindEnum.KBM,
  CheckKindEnum.GIBDD,
]

const kindResultsUnion = z.discriminatedUnion('kind', [
  z.object({
    kind: z.literal(CheckKindEnum.GIBDD),
    results: resultsSchemas[CheckKindEnum.GIBDD].optional(),
  }),
  z.object({
    kind: z.literal(CheckKindEnum.FSSP),
    results: resultsSchemas[CheckKindEnum.FSSP].optional(),
  }),
  z.object({
    kind: z.literal(CheckKindEnum.YANDEX),
    results: resultsSchemas[CheckKindEnum.YANDEX].optional(),
  }),
  z.object({
    kind: z.literal(CheckKindEnum.KBM),
    results: resultsSchemas[CheckKindEnum.KBM].optional(),
  }),
  z.object({
    kind: z.literal(CheckKindEnum.SB),
    results: resultsSchemas[CheckKindEnum.SB].optional(),
  }),
])

const schema = z
  .object({
    status: z.nativeEnum(CheckStatusEnum),
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .and(kindResultsUnion)

export type CheckAttributes = z.infer<typeof schema>

export class Check extends ApiModel<typeof schema> {
  static jsonApiType = 'checks'

  readOnlyAttributes = ['createdAt', 'updatedAt', 'status', 'results', 'kind']

  static schema = schema

  driver(): ToOneRelation<Driver, this> {
    return this.hasOne(Driver)
  }
  getDriver(): Driver {
    return this.getRelation('driver')
  }
  setDriver(id: UniqueId) {
    const driver = new Driver()
    driver.setApiId(id)
    this.setRelation('driver', driver)
  }

  getStatus(): CheckStatusEnum {
    return this.getAttribute('status')
  }

  getKind(): CheckKindEnum {
    return this.getAttribute('kind')
  }

  getDate(): string {
    return this.getAttribute('updatedAt') ?? this.getAttribute('createdAt')
  }

  setResults(results: CheckAttributes['results']) {
    this.setAttribute('results', results)
  }

  setStatus(status: CheckStatusEnum) {
    this.setAttribute('status', status)
  }

  isEnded() {
    const status = this.getStatus()
    return [
      CheckStatusEnum.SUCCESS,
      CheckStatusEnum.CANCELLED,
      CheckStatusEnum.TIMEOUT_REACHED,
      CheckStatusEnum.NOT_FOUND,
      CheckStatusEnum.FAILED,
    ].includes(status)
  }

  isAuto() {
    const kind = this.getKind()
    return AUTO_CHECKS.includes(kind)
  }
}
