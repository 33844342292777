import { useStore } from 'effector-react'
import { UseFormReturn } from 'react-hook-form'
import { fetchOptionFx } from '~/features/Сars/ui/MainStatus/model'
import { carModel } from '~/entities/Car'
import {
  carAtWorkSubStatusEnumOptions,
  STATUS_VARIANT,
} from '~/shared/config/constants'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { FormValues } from './model'

type AtWorkSubStatusProps = {
  form: UseFormReturn<FormValues>
}

export function AtWorkSubStatus({ form }: AtWorkSubStatusProps) {
  const isLoading = useStore(fetchOptionFx.pending)
  const isCarLoading = useStore(carModel.requestFx.pending)
  const isCarSilentLoading = useStore(carModel.requestSilentFx.pending)

  if (isCarLoading || isLoading || isCarSilentLoading) return null

  return (
    <FormProvider form={form}>
      <AutocompleteInput
        name='subStatus'
        options={carAtWorkSubStatusEnumOptions}
        startDecorator={null}
        disableClearable
        readOnly
        size='sm'
        sx={{
          minWidth: '200px',
          marginBottom: '8px',
          border: 'none',
          '&': {
            ...STATUS_VARIANT['gray'],
          },

          '&.Mui-focused:before': {
            boxShadow: 'none',
          },
        }}
      />
    </FormProvider>
  )
}
