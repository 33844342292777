import { CircularProgress, List, ListItem, Stack } from '@mui/joy'
import { useStore, useGate } from 'effector-react'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { $drivers, Gate, requestByRestFx, requestByPhoneFx } from './model'

export function PreviewList() {
  useGate(Gate)
  const drivers = useStore($drivers)
  const isRequestByRestLoading = useStore(requestByRestFx.pending)
  const isRequestByPhoneLoading = useStore(requestByPhoneFx.pending)

  return (
    <Container
      data-testid='preview-list'
      sx={{ position: 'sticky', top: '104px' }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <ContainerTitle>Список похожих водителей</ContainerTitle>
        {(isRequestByRestLoading || isRequestByPhoneLoading) && (
          <CircularProgress size='sm' />
        )}
      </Stack>
      <List sx={{ overflowX: 'auto', height: '600px' }}>
        {!drivers?.length && <ListItem>Водители не найдены</ListItem>}

        {drivers.map((driver) => {
          const id = driver.getApiId()
          return (
            <ListItem key={id}>
              <ColumnLink to={`/drivers/${id}`}>
                {driver.getFullName()} (ВУ {driver.getDrivingLicenseNumber()})
              </ColumnLink>
            </ListItem>
          )
        })}
      </List>
    </Container>
  )
}
