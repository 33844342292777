import { z } from 'zod'

import { AggregatorAccount } from '~/shared/api/aggregator/aggregatorAccount'
import { dateSchema } from '~/shared/lib/schemas'

import { ApiModel, ToOneRelation } from './core'

const attributesSchema = z.object({
  title: z.string().optional(),
  extId: z.string().optional(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type YandexWorkRuleAttributes = z.infer<typeof attributesSchema>
export type YandexWorkRuleOption = {
  id: UniqueId
  label: string
}

export class YandexWorkRule extends ApiModel<typeof schema> {
  static jsonApiType = 'yandex-work-rules'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  account(): ToOneRelation<AggregatorAccount, this> {
    return this.hasOne(AggregatorAccount)
  }
  getAccount(): AggregatorAccount {
    return this.getRelation('account')
  }

  getOptionWithAccount(): YandexWorkRuleOption {
    return {
      id: this.getApiId() as UniqueId,
      label: `${this.getTitle()} - ${this.getAccount()?.getTitle() || ''}`,
    }
  }

  static async fetchOptionsWithAccount() {
    const response = await YandexWorkRule.with('account').get(1)
    return response.getData().map((o) => o.getOptionWithAccount())
  }

  getOption(): YandexWorkRuleOption {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptionsByAccountId(accountId?: UniqueId) {
    if (!accountId) return []

    const response = await YandexWorkRule.where('accountId', accountId).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
