import { Grid } from '@mui/joy'
import { NumericInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type MainProps = {
  isLoading: boolean
  disabled: boolean
}

export function Main({ isLoading, disabled }: MainProps) {
  return (
    <Container>
      <ContainerTitle>Основная информция</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <TextInput
            name='name'
            label='Наименование'
            skeletonShow={isLoading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            name='full_name'
            label='Полное наименование'
            skeletonShow={isLoading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <NumericInput
            name='inn'
            label='ИНН'
            skeletonShow={isLoading}
            readOnly={disabled}
            numericProps={{ thousandSeparator: '' }}
          />
        </Grid>
        <Grid xs={12}>
          <NumericInput
            name='kpp'
            label='КПП'
            skeletonShow={isLoading}
            readOnly={disabled}
            numericProps={{ thousandSeparator: '' }}
          />
        </Grid>
        <Grid xs={12}>
          <NumericInput
            name='ogrn'
            label='ОГРН'
            skeletonShow={isLoading}
            readOnly={disabled}
            numericProps={{ thousandSeparator: '' }}
          />
        </Grid>
        <Grid xs={12}>
          <NumericInput
            name='okpo'
            label='ОКПО'
            skeletonShow={isLoading}
            readOnly={disabled}
            numericProps={{ thousandSeparator: '' }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
