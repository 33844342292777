import { AddCar } from 'src/features/Сars/AddCar'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Автомобили', link: '/cars' },
  { title: 'Создание автомобиля' },
]

function AddCarPage() {
  return (
    <PageContainer title='Создание автомобиля'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание автомобиля</PageTitle>
      </Container>

      <AddCar />
    </PageContainer>
  )
}

export default AddCarPage
