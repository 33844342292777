import { GridColDef } from '@mui/x-data-grid'
import { priceFormat } from '~/shared/lib/utils'
import { ColumnDate, ColumnLink, ColumnValue } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: '№ Обращения',
    field: 'number',
    minWidth: 140,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.number}</ColumnLink>,
  },
  {
    headerName: 'Статус',
    field: 'status',
    renderCell: ({ value }) => (
      <ChipStatus label={value.label} variant={value.color} />
    ),
    minWidth: 140,
  },

  {
    headerName: 'Дата и время',
    field: 'createdAt',
    renderCell: ({ row }) => (
      <ColumnDate type='moscowDateTime' value={row.createdAt} />
    ),
    minWidth: 200,
  },
  {
    headerName: 'Водитель',
    field: 'driver.fullName',
    renderCell: ({ row }) => (
      <ColumnLink to={`/drivers/${row.driverId}`}>
        {row.driverFullName}
      </ColumnLink>
    ),
    minWidth: 240,
  },
  {
    headerName: 'Телефон водителя',
    field: 'driverPhone',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Автомобиль',
    field: 'car.plateNumber',
    renderCell: ({ row }) => (
      <ColumnLink to={`/cars/${row.carId}`}>{row.carPlateNumber}</ColumnLink>
    ),
    minWidth: 140,
  },
  {
    headerName: 'Модель',
    field: 'carModel',
    sortable: false,
    minWidth: 140,
  },
  {
    headerName: 'Подразделение',
    field: 'subdivision.title',
    renderCell: ({ row }) => <ColumnValue>{row.subdivisionName}</ColumnValue>,
    minWidth: 200,
  },
  {
    headerName: 'Тип обращения',
    field: 'appealType.title',
    renderCell: ({ row }) => <ColumnValue>{row.appealType}</ColumnValue>,
    minWidth: 240,
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    renderCell: ({ row }) => (
      <ColumnValue>{priceFormat(row.amount)}</ColumnValue>
    ),
    minWidth: 180,
  },
  {
    headerName: 'Инициатор',
    field: 'creator.name',
    renderCell: ({ row }) => <ColumnValue>{row.creatorName}</ColumnValue>,
    minWidth: 180,
  },
  {
    headerName: 'Комментарий инициатора',
    field: 'creatorComment',
    minWidth: 200,
  },
  {
    headerName: 'Ответственный',
    field: 'responsible.name',
    minWidth: 180,
    renderCell: ({ row }) => <ColumnValue>{row.responsibleName}</ColumnValue>,
  },
  {
    headerName: 'Комментарий ответственного',
    field: 'responsibleComment',
    minWidth: 240,
  },
  {
    headerName: 'Кол-во дней в статусе',
    field: 'daysInStatus',
    minWidth: 200,
  },
]
