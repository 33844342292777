import { ThumbType } from '~/shared/api'
import { CarDocumentType } from '~/shared/config/enums'
import { Dropzone } from '~/shared/ui/Dropzone'

type TypedDropzoneRow = {
  documentType: CarDocumentType
  isSubmitting: boolean
  onDropAccepted: (files: File[]) => void
  onDelete: (id: UniqueId) => void
  thumbs?: ThumbType[]
  canDelete?: boolean
  canCreate?: boolean
  isLoading?: boolean
}

export function TypedDropzoneRow({
  documentType,
  isSubmitting,
  onDropAccepted,
  onDelete,
  thumbs,
  canDelete,
  canCreate,
  isLoading,
}: TypedDropzoneRow) {
  return (
    <Dropzone
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      accept={{
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': [],
        'application/pdf': [],
      }}
      onDropAccepted={onDropAccepted}
      onDelete={onDelete}
      inputProps={{ name: documentType }}
      canDelete={canDelete}
      thumbs={thumbs}
      canCreate={canCreate}
    />
  )
}
