import { Stack, LinearProgress, Divider } from '@mui/joy'
import { useQueryClient } from '@tanstack/react-query'
import { useGate, useStore, useStoreMap } from 'effector-react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  EditForm,
  ptsDocumentFormModel,
  AddModal,
  List,
} from 'src/entities/Car/Documents/PtsDocument'
import { TypedDropzoneRow } from '~/entities/Car/Documents/ui/TypedDropzoneRow'
import { usePermissions } from '~/entities/viewer'
import { CarDocumentType } from '~/shared/config/enums'
import { Alert } from '~/shared/ui/Alerts'
import { ContainerTitle } from '~/shared/ui/Layout'
import {
  formSubmittedCreate,
  Gate,
  createFx,
  $document,
  saveFilesFx,
  saveFiles,
  $loading,
  deleteFile,
  $fileList,
  formSubmittedUpdate,
  updateFx,
} from './model'

export function PtsDocument() {
  const params = useParams<{ carId: string }>()
  const carId = params.carId as string

  useGate(Gate, { carId })

  const { canCarDocumentsUpdate } = usePermissions()
  const queryClient = useQueryClient()
  const isLoading = useStore($loading)
  const isSubmitting = useStore(createFx.pending)
  const isUpdateSubmitting = useStore(updateFx.pending)
  const isSubmittingFiles = useStore(saveFilesFx.pending)
  const document = useStore($document)

  const defaultValues = useStoreMap($document, (document) => ({
    ...document?.getAttributes(),
  }))

  const fileList = useStore($fileList)

  const handleUpdateList = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: ['pts-document-list'],
    })
  }, [queryClient])

  const handleDocumentCreate = useCallback(
    (formValues: ptsDocumentFormModel.FormValues, closeModal: () => void) => {
      formSubmittedCreate({
        formValues,
        fetchList: handleUpdateList,
        closeModal,
      })
    },
    [handleUpdateList],
  )

  return (
    <>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        mb={2}
      >
        <ContainerTitle>Паспорт транспортного средства</ContainerTitle>

        <AddModal
          onSuccess={handleDocumentCreate}
          isSubmitting={isSubmitting}
        />
      </Stack>

      {isLoading && !document && <LinearProgress size='lg' />}

      {document && (
        <>
          <EditForm
            defaultValues={defaultValues}
            canEdit={canCarDocumentsUpdate}
            isSubmitting={isUpdateSubmitting}
            onSuccess={formSubmittedUpdate}
          />
          <Divider sx={{ margin: '24px 0' }} />
          <TypedDropzoneRow
            documentType={CarDocumentType.PTS_DOCUMENT}
            thumbs={fileList}
            isSubmitting={isSubmittingFiles}
            onDropAccepted={saveFiles}
            onDelete={deleteFile}
            canDelete={canCarDocumentsUpdate}
            canCreate={canCarDocumentsUpdate}
          />
        </>
      )}

      {!isLoading && !document && <Alert variant='info'>Нет документа</Alert>}
      <br />
      <List carId={carId} />
    </>
  )
}
