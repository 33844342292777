import { DataGrid, ruRU as gridRuRU } from '@mui/x-data-grid'
import { DataGridWrapper } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { GridRow } from './types'

type ListProps = {
  rows: GridRow[]
}

export const List = ({ rows }: ListProps) => {
  return (
    <DataGridWrapper
      sx={{
        height: '508px',
        '.MuiDataGrid-row': {
          pointerEvents: 'none',
        },
      }}
    >
      <DataGrid
        density='compact'
        columns={columns}
        pageSize={30}
        rows={rows}
        hideFooter
        disableColumnMenu
        disableSelectionOnClick
        disableColumnSelector
        localeText={gridRuRU.components.MuiDataGrid.defaultProps.localeText}
        sortingMode='server'
      />
    </DataGridWrapper>
  )
}
