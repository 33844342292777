import { Typography } from '@mui/joy'
import { ReactNode } from 'react'

type FormTitleProps = {
  children: ReactNode | string
}
export function ContainerTitle({ children }: FormTitleProps) {
  return (
    <Typography
      sx={{
        fontSize: '18px',
        fontWeight: 600,
        marginBottom: '38px',
      }}
    >
      {children}
    </Typography>
  )
}
