import { Documents } from '~/features/Сars/Documents'
import { PageContainer } from '~/shared/ui/Layout'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'ПТС', anchor: 'pts' },
  { label: 'СТС', anchor: 'sts' },
  { label: 'Лицензия', anchor: 'taxiLicense' },
  { label: 'ОСАГО', anchor: 'osago' },
  { label: 'КАСКО', anchor: 'kasko' },
  { label: 'ДК', anchor: 'dc' },
  { label: 'Прочее', anchor: 'otherDocument' },
]

export const DocumentsPage = () => {
  return (
    <PageContainer title='Документы автомобиля'>
      <Documents />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
