import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { carEditModel } from 'src/features/Сars/EditCar'
import { carModel } from '~/entities/Car'
import { usePermissions } from '~/entities/viewer'
import { Car } from '~/shared/api'
import { STATUS_VARIANT } from '~/shared/config/constants'
import { CarStatusEnum } from '~/shared/config/enums'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { $options, fetchOptionFx, FormValues } from './model'

type StatusProps = {
  onStatusChange: () => void
  form: UseFormReturn<FormValues>
}

export function MainStatus({ onStatusChange, form }: StatusProps) {
  const { canCarStatusUpdate } = usePermissions()
  const { setValue, watch } = form
  const carStatus = useStoreMap(carEditModel.$car, (car) => car?.getStatus())
  const isAtWorkStatus = carStatus === CarStatusEnum.AT_WORK
  const isLoading = useStore(fetchOptionFx.pending)
  const isCarLoading = useStore(carModel.requestFx.pending)
  const options = useStore($options)
  const formStatus = watch('mainStatus')

  const filteredStatus = useMemo(() => {
    if (carStatus === CarStatusEnum.CREATED) {
      return options.filter(
        ({ id }) => id === CarStatusEnum.CREATED || id === CarStatusEnum.FREE,
      )
    }

    return options.filter(
      ({ id }) => id !== CarStatusEnum.AT_WORK && id !== CarStatusEnum.CREATED,
    )
  }, [carStatus, options])

  const statusColor = useMemo(
    () => Car.getStatusColor(formStatus?.id),
    [formStatus?.id],
  )

  const handleStatusChange = async (status: FormValues['mainStatus']) => {
    setValue('mainStatus', status)
    onStatusChange()
  }

  return (
    <>
      <FormProvider form={form}>
        <AutocompleteInput
          name='mainStatus'
          options={filteredStatus}
          startDecorator={null}
          disableClearable
          skeletonShow={isLoading || isCarLoading}
          readOnly={!canCarStatusUpdate || isAtWorkStatus}
          onChange={(_, value) =>
            handleStatusChange(value as FormValues['mainStatus'])
          }
          size='sm'
          sx={{
            width: '170px',
            marginBottom: '8px',
            border: 'none',
            '&': {
              ...STATUS_VARIANT[statusColor],
            },

            '&.Mui-focused:before': {
              boxShadow: 'none',
            },
          }}
        />
      </FormProvider>
    </>
  )
}
