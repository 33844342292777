import { Typography } from '@mui/joy'

import { Check } from '~/shared/api'
import { CheckKindEnum } from '~/shared/config/enums'

import { myPalette } from '~/shared/lib/theme'
import { FsspResults, GibddResults, YandexResults } from './Result'

type Props = {
  check?: Check
  onClose: () => void
}

export function CheckResults({ check, onClose }: Props) {
  const { results, kind } = check?.getAttributes() || { results: '', kind: '' }

  if (!results) {
    return (
      <Typography sx={{ color: myPalette.yellow['600'] }}>
        Данные отсутствуют
      </Typography>
    )
  }

  switch (kind) {
    case CheckKindEnum.GIBDD:
      return <GibddResults results={results} onClose={onClose} />
    case CheckKindEnum.YANDEX: {
      return <YandexResults results={results} onClose={onClose} />
    }
    case CheckKindEnum.FSSP: {
      return <FsspResults results={results} onClose={onClose} />
    }
  }
}
