import { z } from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const schema = z.object({
  createdAt: dateSchema,
})

export type LatestStatusTransitionAttributes = z.infer<typeof schema>

export class LatestStatusTransition extends ApiModel<typeof schema> {
  static jsonApiType = 'latest-status-transition'

  static schema = schema

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }
}
