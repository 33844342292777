import { z } from 'zod'
import {
  bankBikRequiredSchema,
  bankRsRequiredSchema,
  dateSchema,
  innRequiredSchema,
  kppRequiredSchema,
  ogrnRequiredSchema,
  okpoRequiredSchema,
} from '~/shared/lib/schemas'
import { ApiModel } from './core'

export const attributeSchema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
  fullName: z.string().trim().min(1, 'Обязательное поле'),
  inn: innRequiredSchema,
  bank: z.string().trim().min(1, 'Обязательное поле'),
  kpp: kppRequiredSchema.optional(),
  ogrn: ogrnRequiredSchema,
  okpo: okpoRequiredSchema.optional(),
  phoneNumber: z.string().trim().optional(),
  email: z
    .union([
      z.string().length(0),
      z.string().email('Неверный адрес электронной почты'),
    ])
    .optional(),
  webSite: z
    .union([z.string().trim().length(0), z.string().trim().url('Неверный url')])
    .optional(),
  legalAddress: z.string().trim().min(1, 'Обязательное поле'),
  actualAddress: z.string().trim().min(1, 'Обязательное поле'),
  rs: bankRsRequiredSchema,
  ks: bankRsRequiredSchema,
  bik: bankBikRequiredSchema,
  directorPosition: z.string().trim().min(1, 'Обязательное поле'),
  directorFio: z.string().trim().min(1, 'Обязательное поле'),
  directorPositionInParentalCase: z.string().trim().min(1, 'Обязательное поле'),
  directorFioInParentalCase: z.string().trim().min(1, 'Обязательное поле'),
  directorBasisForSigningDocuments: z
    .string()
    .trim()
    .min(1, 'Обязательное поле'),
  withNds: z.boolean().optional().default(false),
})

export const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type CarRentalAttributes = z.infer<typeof schema>

export class CarRental extends ApiModel<typeof schema> {
  static jsonApiType = 'car-rentals'

  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  getWebSite(): string {
    return this.getAttribute('web_site')
  }

  static async fetchOptions() {
    const response = await CarRental.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
