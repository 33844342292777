import { createDomain, sample } from 'effector'
import { appealModel } from '~/entities/Balance/Appeal'
import { Appeal, AxiosErrorType } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

const domain = createDomain('features.balanceHistory.appeal.view.actionButtons')

export const appealDelete = domain.createEvent()
export const appealDeleteFx = domain.createEffect<
  UniqueId,
  void,
  AxiosErrorType
>({
  async handler(appealId) {
    const appeal = new Appeal(undefined, appealId)
    await appeal.delete()
  },
})

sample({
  clock: appealDelete,
  source: appealModel.$appealId,
  filter: isString,
  target: appealDeleteFx,
})

sample({
  clock: appealDeleteFx.doneData,
  fn() {
    return {
      message: 'Обращение удалено',
      variant: 'warning' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})

sample({
  clock: appealDeleteFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
