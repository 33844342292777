import { Box, Table as JoyTable } from '@mui/joy'
import { styled } from '@mui/material/styles'
import { Fragment, forwardRef } from 'react'
import { FsspResults } from '~/shared/api/check'
import { myPalette } from '~/shared/lib/theme'

type Props = {
  results: FsspResults
}

const Table = styled(JoyTable)`
  thead {
    th {
      background: ${myPalette.gray['300']};
      border-bottom: 1px solid ${myPalette.gray['400']} !important;
      border-radius: 0 !important;
    }
  }
  tbody {
    th {
      background: ${myPalette.gray['100']};
      border-bottom: 1px solid ${myPalette.gray['400']} !important;
      border-radius: 0 !important;
    }
    td {
      border-bottom: 1px solid ${myPalette.gray['400']} !important;
      vertical-align: top;
    }
  }
`

export const FsspResultsTable = forwardRef<HTMLTableElement, Props>(
  (props, ref) => {
    const { results } = props
    return (
      <Box sx={{ overflow: 'auto', height: '600px', position: 'relative' }}>
        <Table aria-label='Таблица результатов ФССП' ref={ref}>
          <thead style={{ position: 'sticky', top: 0 }}>
            <tr>
              <th style={{ width: '200px' }}>Должник</th>
              <th style={{ width: '200px' }}>ИП</th>
              <th style={{ width: '300px' }}>
                Реквизиты исполнительного документа
              </th>
              <th style={{ width: '380px' }}>
                Дата, причина окончания или прекращения ИП
              </th>
              <th style={{ width: '460px' }}>
                Предмет исполнения, сумма непогашенной задолженности
              </th>
              <th style={{ width: '360px' }}>Отдел судебных приставов</th>
              <th style={{ width: '300px' }}>Судебный пристав - исполнитель</th>
            </tr>
          </thead>
          <tbody>
            {results.map(({ title, records }) => (
              <Fragment key={title}>
                <tr>
                  <th colSpan={7}>{title}</th>
                </tr>
                {records.map((record, index) => (
                  <tr key={index}>
                    <td>{record.debtor}</td>
                    <td>{record.executionCase}</td>
                    <td>{record.executionDocument}</td>
                    <td>{record.executionCaseEndReason}</td>
                    <td>{record.executionSubject}</td>
                    <td>{record.debtAmount}</td>
                    <td>{record.court}</td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Box>
    )
  },
)
FsspResultsTable.displayName = 'FsspResultsTable'
