import { AggregatorCodeEnum } from '~/shared/config/enums'

export const formingLink = (type: AggregatorCodeEnum, id: UniqueId) => {
  switch (type) {
    case AggregatorCodeEnum.YANDEX:
      return `yandex/${id}`
    default:
      return ''
  }
}
