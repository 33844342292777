import { useStore, useStoreMap } from 'effector-react'
import { $appeal } from '~/entities/Balance/Appeal/model'
import { usePermissions, viewerModel } from '~/entities/viewer'

export const useRole = () => {
  const { canAppealsCreator, canAppealsResponsible } = usePermissions()

  const currentUserId = useStore(viewerModel.$id)
  const creatorId = useStoreMap($appeal, (appeal) =>
    appeal?.getCreator()?.getApiId(),
  )
  const isInitiator = canAppealsCreator && currentUserId === creatorId

  const isResponsible = canAppealsResponsible

  return {
    isInitiator,
    isResponsible,
  }
}
