import { CircularProgress } from '@mui/joy'
import { ReactNode, useState } from 'react'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Button } from '~/shared/ui/Buttons'
import { DownloadIcon } from '~/shared/ui/Icons'
import { MenuItem } from './styled'

type FileExportLinkProps = {
  title: string
  fnGetLink: () => Promise<string>
  show?: boolean
  variant?: 'menuItem'
  startDecorator?: ReactNode
}

export function FileExportLink({
  title,
  show = true,
  fnGetLink,
  variant,
  startDecorator,
}: FileExportLinkProps) {
  const [isLoading, toggleIsLoading] = useState(false)

  const handleDownload = async () => {
    try {
      toggleIsLoading(true)
      const url = await fnGetLink()

      const link = document.createElement('a')
      link.download = 'document-download'
      link.href = url
      link.click()
    } catch (e) {
      logger.error(e)
      snackbarEnqueued({
        message: 'Ошибка получения файла',
        variant: 'error' as const,
      })
    } finally {
      toggleIsLoading(false)
    }
  }

  if (!show) return null

  if (variant === 'menuItem') {
    return (
      <MenuItem onClick={handleDownload}>
        {startDecorator || <DownloadIcon />}
        {title}
        {isLoading && <CircularProgress size='sm' />}
      </MenuItem>
    )
  }

  return (
    <Button
      variant='brand'
      onClick={handleDownload}
      startDecorator={startDecorator || <DownloadIcon />}
      loading={isLoading}
    >
      {title}
    </Button>
  )
}
