import { useStoreMap } from 'effector-react'
import { appealModel } from '~/entities/Balance/Appeal'
import { AppealsStatusEnum } from '~/shared/config/enums'

export const useStatus = () => {
  const status = useStoreMap(appealModel.$appeal, (appeal) =>
    appeal?.getStatus(),
  )

  const isNew = status === AppealsStatusEnum.NEW
  const isProcessing = status === AppealsStatusEnum.PROCESSING
  const isRejected = status === AppealsStatusEnum.REJECTED
  const isSolved = status === AppealsStatusEnum.SOLVED

  return {
    isNew,
    isProcessing,
    isRejected,
    isSolved,
  }
}
