import { Car, Driver, Employee, Subdivision, AppealType } from '~/shared/api'
import { AppealsStatusEnumOptions } from '~/shared/config/constants'
import { Filter } from '~/shared/ui/AsyncGrid'

export const filters: Filter[] = [
  {
    field: 'createdAtFrom',
    label: 'Начало периода',
    type: 'date',
  },
  {
    field: 'createdAtTo',
    label: 'Конец периода',
    type: 'date',
  },
  {
    field: 'status',
    label: 'Статус',
    type: 'select',
    options: AppealsStatusEnumOptions,
  },
  {
    field: 'amount',
    label: 'Сумма',
    type: 'price',
  },
  {
    field: 'driverId',
    label: 'Водитель',
    type: 'autocomplete',
    fetchOptions: Driver.fetchOptions,
  },
  {
    field: 'carId',
    label: 'Автомобиль',
    type: 'autocomplete',
    fetchOptions: Car.fetchOptions,
  },
  {
    field: 'subdivisionId',
    label: 'Подразделение',
    type: 'autocomplete',
    fetchOptions: Subdivision.fetchOptions,
  },
  {
    field: 'appealTypeId',
    label: 'Тип обращения',
    type: 'autocomplete',
    fetchOptions: AppealType.fetchOptions,
  },
  {
    field: 'creatorId',
    label: 'Инициатор',
    type: 'autocomplete',
    fetchOptions: Employee.fetchOptions,
  },
  {
    field: 'creatorComment',
    label: 'Комментарий инициатора',
    type: 'text',
  },
  {
    field: 'responsibleId',
    label: 'Ответственный',
    type: 'autocomplete',
    fetchOptions: Employee.fetchOptions,
  },
  {
    field: 'responsibleComment',
    label: 'Комментарий ответственного',
    type: 'text',
  },
]
