import {
  parse as fnsParse,
  format,
  isValid,
  isBefore,
  addMonths,
  formatDuration as fnsFormatDuration,
  intervalToDuration,
  Interval,
  parseISO,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { datetime } from '~/shared/config'

export { addMonths, format as formatDate, format }

export const fromStringApi = (input: string) =>
  fnsParse(input, datetime.apiDateFormat, new Date())

export const fromDatetimeString = (input: string) => parseISO(input)

export const isValidDateString = (input: string) =>
  isValid(fromStringApi(input))

export const formatForApi = (date: Date) => format(date, datetime.apiDateFormat)

export const formatDateTimeForApi = (date: Date) =>
  format(date, datetime.apiDateTimeFormat)

export const formatDateTimeForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiDateTimeFormat)

export const formatDateForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiDateFormat)

export const formatTimeForUI = (input: string) =>
  format(fromDatetimeString(input), datetime.uiTimeFormat)

export const isBeforeNow = (date: Date | number) => isBefore(date, new Date())

export const formatDuration = (
  duration: Duration,
  options?: {
    format?: string[]
    zero?: boolean
    delimiter?: string
  },
) => fnsFormatDuration(duration, options)

export const formatIntervalAsDuration = (
  interval: Interval,
  options?: { format?: string[]; zero?: boolean; delimiter?: string },
) => {
  const duration = intervalToDuration(interval)
  return fnsFormatDuration(duration, options)
}

export const formatIntervalToNowAsDuration = (
  date: Date,
  options?: { format?: string[]; zero?: boolean; delimiter?: string },
) => {
  const now = new Date()
  return formatIntervalAsDuration({ start: date, end: now }, options)
}

export const formatMoscowDateTime = (utcDate: string) => {
  const moscowTimezone = 'Europe/Moscow'
  const moscowDateTime = utcToZonedTime(utcDate, moscowTimezone)

  return format(moscowDateTime, datetime.uiDateTimeFormat)
}

export const formatMoscowTime = (utcTime: string) => {
  const moscowTimezone = 'Europe/Moscow'
  const utcDateTime = new Date(`${new Date().toDateString()} ${utcTime} UTC`)
  const moscowDateTime = utcToZonedTime(utcDateTime, moscowTimezone)

  return format(moscowDateTime, 'HH:mm')
}
