import { attach, createDomain, sample } from 'effector'
import { appealModel } from '~/entities/Balance/Appeal'
import { Appeal, AxiosErrorType } from '~/shared/api'
import { AppealsStatusEnum } from '~/shared/config/enums'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export type StatusValuesApi = {
  status: AppealsStatusEnum
  responsibleComment?: string
}

const domain = createDomain('features.balanceHistory.appeal.view.status')

export const statusChangeFx = domain.createEffect<
  StatusValuesApi & { appealId: UniqueId },
  void,
  AxiosErrorType
>({
  async handler(values) {
    const { status, responsibleComment, appealId } = values
    await Appeal.updateStatus(status, appealId)

    if (responsibleComment) {
      await Appeal.updateByResponsible(responsibleComment, appealId)
    }
  },
})

export const statusFormUpdateFx = attach({
  source: appealModel.$appealId,
  mapParams: (values: StatusValuesApi, appealId) => ({
    ...values,
    appealId: String(appealId),
  }),
  effect: statusChangeFx,
})

sample({
  clock: statusFormUpdateFx.doneData,
  fn() {
    return {
      message: 'Статус сменен',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: statusFormUpdateFx.doneData,
  source: appealModel.$appealId,
  filter: isString,
  target: appealModel.requestSilentFx,
})

sample({
  clock: statusFormUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
