import { z } from 'zod'

import { StsDocumentAttributes } from '~/shared/api/stsDocument'
import { dateSchema } from '~/shared/lib/schemas'

import { ApiModel } from './core'

const attributeSchema = z.object({
  files: z
    .object({
      id: z.string(),
      name: z.string(),
      filepath: z.string().url(),
      preview: z.string().url(),
    })
    .array()
    .optional(),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type OtherDocumentAttributes = z.infer<typeof schema>

export class OtherDocument extends ApiModel<typeof schema> {
  static jsonApiType = 'other-documents'

  static schema = schema

  getFiles(): StsDocumentAttributes['files'] {
    return this.getAttribute('files')
  }
}
