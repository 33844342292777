import { Box, Stack } from '@mui/joy'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from 'src/widgets/AuditsModal'
import {
  EditCarRental,
  editCarRentalModel,
} from '~/features/Organization/CarRentals/EditCarRentals'
import { carRentalModel } from '~/entities/Organizations/CarRental'
import { usePermissions } from '~/entities/viewer'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Реквизиты', anchor: 'requisites' },
  { label: 'Руководство', anchor: 'management' },
  { label: 'Контакты', anchor: 'contacts' },
  { label: 'Банк', anchor: 'bank' },
]

export function EditCarRentalPage() {
  const { carRentalId } = useParams<{ carRentalId: string }>()

  const { canAuditsView } = usePermissions()

  const isLoading = useStore(carRentalModel.requestFx.pending)
  const name = useStoreMap(editCarRentalModel.$carRental, (carRental) =>
    carRental?.getName(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Организации', link: '/organizations/car-rentals' },
      { title: 'Арендодатели', link: '/organizations/car-rentals' },
      { title: name || '' },
    ],
    [name],
  )

  const error = carRentalModel.useCarRentalError(carRentalId as string)

  if (!carRentalId || error) {
    return <NotFound message='Не удалось загрузить арендодателя' />
  }

  return (
    <PageContainer title='Карточка арендодателя'>
      <Container isHeader>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <Box>
            <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
            <PageTitle loading={isLoading}>{name}</PageTitle>
          </Box>
          {canAuditsView && <AuditsModal auditableId={carRentalId} />}
        </Stack>
      </Container>

      <EditCarRental carRentalId={carRentalId} />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
