import { useStore } from 'effector-react'
import { UseFormReturn } from 'react-hook-form'
import { carModel } from '~/entities/Car'
import { usePermissions } from '~/entities/viewer'
import {
  carUnderRepairSubStatusEnumOptions,
  STATUS_VARIANT,
} from '~/shared/config/constants'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { fetchOptionFx } from '../MainStatus/model'
import { FormValues } from './model'

type UnderRepairSubStatusProps = {
  onStatusChange: () => void
  form: UseFormReturn<FormValues>
}

export function UnderRepairSubStatus({
  onStatusChange,
  form,
}: UnderRepairSubStatusProps) {
  const { canCarStatusUpdate } = usePermissions()
  const { setValue } = form

  const handleSubStatusChange = async (status: FormValues['subStatus']) => {
    setValue('subStatus', status)
    onStatusChange()
  }

  const isLoading = useStore(fetchOptionFx.pending)
  const isCarLoading = useStore(carModel.requestFx.pending)
  const isCarSilentLoading = useStore(carModel.requestSilentFx.pending)

  if (isCarLoading || isLoading || isCarSilentLoading) return null

  return (
    <FormProvider form={form}>
      <AutocompleteInput
        name='subStatus'
        options={carUnderRepairSubStatusEnumOptions}
        startDecorator={null}
        disableClearable
        readOnly={!canCarStatusUpdate}
        onChange={(_, value) =>
          handleSubStatusChange(value as FormValues['subStatus'])
        }
        size='sm'
        sx={{
          minWidth: '200px',
          marginBottom: '8px',
          border: 'none',
          '&': {
            ...STATUS_VARIANT['gray'],
          },

          '&.Mui-focused:before': {
            boxShadow: 'none',
          },
        }}
      />
    </FormProvider>
  )
}
