import { GridColDef } from '@mui/x-data-grid'
import {
  transactionBalanceTypeEnumLabels,
  transactionMoveFundsTypeEnumLabels,
} from '~/shared/config/constants'
import {
  ColumnDate,
  ColumnLink,
  ColumnValue,
  ColumnAmount,
} from '~/shared/ui/AsyncGrid'

import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Вид движения',
    field: 'moveFundsType',
    renderCell: ({ row }) => (
      <ColumnLink to={row.id}>
        {transactionMoveFundsTypeEnumLabels[row.moveFundsType]}
      </ColumnLink>
    ),
    minWidth: 140,
  },
  {
    headerName: 'Дата операции',
    field: 'appliedAt',
    renderCell: ({ row }) => <ColumnDate type='date' value={row.appliedAt} />,
    minWidth: 140,
  },
  {
    headerName: 'Время операции',
    field: 'appliedAtTime',
    sortable: false,
    renderCell: ({ row }) => <ColumnDate type='time' value={row.appliedAt} />,
    minWidth: 150,
  },
  {
    headerName: 'Аккаунт',
    field: 'aggregatorAccountTitle',
    minWidth: 240,
  },
  {
    headerName: 'Водитель',
    field: 'driverFullName',
    renderCell: ({ row }) => (
      <ColumnLink to={`/drivers/${row.driverId}`}>
        {row.driverFullName}
      </ColumnLink>
    ),
    minWidth: 240,
  },
  {
    headerName: 'Автомобиль',
    field: 'carPlateNumber',
    renderCell: ({ row }) => (
      <ColumnLink to={`/cars/${row.carId}`}>{row.carPlateNumber}</ColumnLink>
    ),
    minWidth: 140,
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    renderCell: ({ row }) => (
      <ColumnAmount moveFunds={row.moveFundsType} amount={row.amount} />
    ),
    minWidth: 180,
  },
  {
    headerName: 'Раздел баланса',
    field: 'balanceType',
    renderCell: ({ row }) => (
      <ColumnValue>
        {transactionBalanceTypeEnumLabels[row.balanceType]}
      </ColumnValue>
    ),
    minWidth: 180,
  },
  {
    headerName: 'Статья оборотов',
    field: 'flowStatementName',
    minWidth: 240,
  },
  {
    headerName: 'Подразделение',
    field: 'subdivisionName',
    minWidth: 200,
  },
  {
    headerName: 'Пользователь / Источник',
    field: 'sourceType',
    minWidth: 220,
  },
]
