import { Box } from '@mui/joy'
import { useCallback, useState } from 'react'
import { OptionEnum } from '~/shared/config/constants'
import {
  CarStatusEnum,
  CarUnderRepairSubStatusEnum,
} from '~/shared/config/enums'
import { logger } from '~/shared/lib/logger'
import { Modal } from '~/shared/ui/Modal'
import { Form, FormValues } from './Form'

type ChangeStatusModalProps = {
  isShow: boolean
  onOk: (formValues: FormValues) => Promise<void> | void
  onCancel: () => void
  mainStatus?: CarStatusEnum
  subStatus: OptionEnum<CarUnderRepairSubStatusEnum> | null
}

export function ChangeStatusModal({
  isShow,
  onOk,
  onCancel,
  mainStatus,
  subStatus,
}: ChangeStatusModalProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const handleOnOk = useCallback(
    async (formValues: FormValues) => {
      try {
        setIsSubmitting(true)
        await onOk(formValues)
      } catch (e) {
        logger.error('Change status send form error', e)
      } finally {
        setIsSubmitting(false)
      }
    },
    [onOk],
  )

  return (
    <Modal
      isShow={isShow}
      title='Смена статуса'
      onCancel={isSubmitting ? undefined : onCancel}
      dialogProps={{
        sx: {
          minWidth: '600px',
          maxWidth: '600px',
        },
      }}
    >
      <Box sx={{ padding: '4px' }}>
        <Form
          isSubmitting={isSubmitting}
          mainStatus={mainStatus}
          subStatus={subStatus}
          onSuccess={handleOnOk}
          onCancel={onCancel}
        />
      </Box>
    </Modal>
  )
}
