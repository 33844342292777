import * as z from 'zod'

import { ApiModel } from '~/shared/api/core'

const schema = z.object({})

export class Tasks extends ApiModel<typeof schema> {
  static jsonApiType = 'tasks'

  static schema = schema

  static async balanceTransfer({
    fromAggregatorProfileId,
    toAggregatorProfileId,
  }: {
    fromAggregatorProfileId: UniqueId
    toAggregatorProfileId: UniqueId
  }) {
    const url = `${Tasks.getJsonApiUrl()}/actions/balance-transfer`
    const client = Tasks.httpClient.getImplementingClient()
    return await client.post(url, {
      fromAggregatorProfileId,
      toAggregatorProfileId,
    })
  }
}
