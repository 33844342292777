import { attach, combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import {
  FormValues,
  aggregatorAccountModel,
} from '~/entities/Taxipark/AggregatorAccount'
import { AggregatorAccount } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ aggregatorAccountId: UniqueId }>()
export const domain = createDomain('features.taxipark.aggregatorAccount.edit')

export const $aggregatorAccountId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { aggregatorAccountId }) => aggregatorAccountId)

export const $aggregatorAccount = combine(
  $aggregatorAccountId,
  aggregatorAccountModel.$aggregatorAccountCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $aggregatorAccountId,
  filter: isString,
  target: aggregatorAccountModel.requestFx,
})

export const aggregatorAccountUpdateFx = attach({
  effect: aggregatorAccountModel.saveFx,
  source: $aggregatorAccountId,
  mapParams: (values: FormValues, id) => {
    const { defaultYandexWorkRuleOption, ...attr } = values

    const aggregatorDriver = new AggregatorAccount(attr, id as string)

    if (isString(defaultYandexWorkRuleOption))
      aggregatorDriver.setDefaultYandexWorkRule(defaultYandexWorkRuleOption)

    return aggregatorDriver
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: aggregatorAccountUpdateFx,
})

sample({
  clock: aggregatorAccountUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: aggregatorAccountUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
