import { z } from 'zod'
import { CarLicensee } from '~/shared/api/carLicensee'
import { dateSchema, uuidOptionSchema } from '~/shared/lib/schemas'
import { Car } from './car'
import { ApiModel, ToOneRelation } from './core'

const relationsSchema = z.object({
  carLicenseeOption: uuidOptionSchema,
})

const attributeSchema = z.object({
  number: z
    .string()
    .trim()
    .min(1, 'Обязательное поле')
    .refine((value) => /^[\d-]*$/.test(value), 'Только цифры'),
  issuedDate: dateSchema,
  expirationDate: z
    .string()
    .max(0)
    .or(dateSchema)
    .optional()
    .nullable()
    .transform((arg) => arg || null),
  issuedBy: z.string().optional(),
  carLicenseeId: z.string().optional(),
  carLicenseeName: z.string().optional(),

  files: z
    .object({
      id: z.string(),
      name: z.string(),
      filepath: z.string().url(),
      preview: z.string().url(),
    })
    .array()
    .optional(),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type TaxiLicenseAttributes = z.infer<typeof attributeSchema>

export class TaxiLicense extends ApiModel<typeof schema> {
  static jsonApiType = 'taxi-licenses'

  static schema = schema

  setCar(id: UniqueId) {
    const car = new Car()
    car.setApiId(id)
    this.setRelation('car', car)
  }

  getCreatedAt(): string {
    return this.getAttribute('createdAt')
  }
  getCarLicenseeId(): string {
    return this.getAttribute('carLicenseeId')
  }
  getCarLicenseeName(): string {
    return this.getAttribute('carLicenseeName')
  }

  getCarLicenseeAttrOption(): { id: UniqueId; label: string } {
    return {
      id: this.getAttribute('carLicenseeId'),
      label: this.getAttribute('carLicenseeName'),
    }
  }

  carLicensee(): ToOneRelation<CarLicensee, this> {
    return this.hasOne(CarLicensee)
  }
  getCarLicensee(): CarLicensee {
    return this.getRelation('carLicensee')
  }
  setCarLicensee(id: UniqueId) {
    const carLicensee = new CarLicensee()
    carLicensee.setApiId(id)
    this.setRelation('carLicensee', carLicensee)
  }

  getFiles(): TaxiLicenseAttributes['files'] {
    return this.getAttribute('files')
  }
}
