import { Box } from '@mui/joy'
import { ReactNode, useCallback, useState } from 'react'
import { Modal } from '~/shared/ui/Modal'
import { Form, FormValues } from './Form'

type RentalContractTerminateModalProps = {
  isShow: boolean
  onOk: (reasons: string[]) => Promise<void>
  onCancel: () => void
  extraBlock?: ReactNode
  buttonSuccessTitle?: string
}

export function RentalContractTerminateModal({
  isShow,
  onOk,
  extraBlock,
  onCancel,
  buttonSuccessTitle,
}: RentalContractTerminateModalProps) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSuccess = useCallback(
    async ({ reasons }: FormValues) => {
      try {
        setIsSubmitting(true)
        await onOk(reasons.map(({ id }) => id))
      } finally {
        setIsSubmitting(false)
      }
    },
    [onOk],
  )

  return (
    <Modal
      isShow={isShow}
      title='Расторжение договора'
      dialogProps={{
        sx: {
          minWidth: '600px',
          maxWidth: '600px',
        },
      }}
    >
      <Box sx={{ padding: '4px' }}>
        {extraBlock}
        <Form
          onSuccess={handleSuccess}
          onCancel={onCancel}
          isSubmitting={isSubmitting}
          buttonSuccessTitle={buttonSuccessTitle}
        />
      </Box>
    </Modal>
  )
}
