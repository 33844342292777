import { myPalette } from '~/shared/lib/theme'
import { isNumber, priceFormat } from '~/shared/lib/utils'
import { ColumnValue } from './ColumnValue'

type AmountCellProps = {
  moveFunds: 'COMING' | 'SPENDING'
  amount: number
}

export function ColumnAmount({ moveFunds, amount }: AmountCellProps) {
  const isComing = moveFunds === 'COMING'
  const mapAmount = Number(String(amount).replace(/-/g, ''))

  if (!isNumber(amount)) return null

  return (
    <ColumnValue
      sx={{ color: isComing ? myPalette.green['600'] : myPalette.red['600'] }}
    >
      {isComing ? '+' : '-'}
      {priceFormat(mapAmount)}
    </ColumnValue>
  )
}
