import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Region, WorkRule, VehicleCategory, CarModel } from '~/shared/api'
import {
  fuelTypeEnumOptions,
  Option,
  OptionEnum,
  transmissionEnumOptions,
} from '~/shared/config/constants'
import { FuelTypeEnum, TransmissionEnum } from '~/shared/config/enums'
import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  DatePickerInput,
  FormButtons,
  formButtonsModel,
  FormProvider,
  NumericInput,
  Switch,
  TextInput,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { useGeneratedTitle } from './useGeneratedTitle'

const formSchema = WorkRule.schema.pick({
  title: true,
  activeFrom: true,
  transmissionType: true,
  pricePerDay: true,
  manufactureYears: true,
  regionsIds: true,
  vehicleCategoryId: true,
  carModelOption: true,
  fuelTypes: true,
  isPromotion: true,
})

export type SchemaType = z.infer<typeof formSchema>

export type FormValues = Omit<
  SchemaType,
  | 'regionsIds'
  | 'vehicleCategoryId'
  | 'carModelOption'
  | 'fuelTypes'
  | 'transmissionType'
  | 'manufactureYears'
> & {
  regionsIds?: UniqueId[] | Option[]
  vehicleCategoryId: UniqueId | Option | null
  carModelOption: UniqueId | Option | null
  fuelTypes: OptionEnum<FuelTypeEnum>[] | FuelTypeEnum[]
  transmissionType: OptionEnum<TransmissionEnum> | TransmissionEnum | null
  manufactureYears: string | number[]
}

export type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
  onTitleChange?: (title: string) => void
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
  onTitleChange,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  useGeneratedTitle(form, onTitleChange)

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <ContainerTitle>Основная информация</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <AsyncAutocompleteInput
              label='Категория ТС'
              name='vehicleCategoryId'
              placeholder='Выбрать'
              fetchOptions={VehicleCategory.fetchOptions}
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={6}>
            <TextInput
              label='Год выпуска'
              name='manufactureYears'
              placeholder='Введите год выпуска через " , "'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={6}>
            <AutocompleteInput
              label='Тип КПП'
              name='transmissionType'
              placeholder='Выбрать'
              options={transmissionEnumOptions}
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={6}>
            <DatePickerInput
              label='Активен с'
              name='activeFrom'
              placeholder='Выберите дату'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={12}>
            <AsyncAutocompleteInput
              label='Модель автомобиля'
              name='carModelOption'
              placeholder='Добавить модель автомобиля'
              fetchOptions={CarModel.fetchOptions}
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid container xs={12} alignItems='flex-end'>
            <Grid container xs={6} data-testid='container-fuel-types'>
              <AutocompleteInput
                label='Вид топлива'
                name='fuelTypes'
                placeholder='Добавить вид топлива'
                options={fuelTypeEnumOptions}
                skeletonShow={isLoading}
                readOnly={disabled}
                multiple
                autocompleteXs={12}
                autocompleteBoxSx={{ padding: 0 }}
                dataTestId='Вид топлива'
              />
            </Grid>
            <Grid container xs={6}>
              <Grid xs={6}>
                <NumericInput
                  label='Стоимость аренды в сутки'
                  name='pricePerDay'
                  placeholder='Введите стоимость аренды в сутки'
                  skeletonShow={isLoading}
                  readOnly={disabled}
                />
              </Grid>

              <Grid xs={6}>
                <Switch
                  label='Авто участвует в акции'
                  name='isPromotion'
                  formControlProps={{ sx: { marginTop: '40px' } }}
                  skeletonShow={isLoading}
                  readOnly={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Container data-testid='container-regions'>
        <ContainerTitle>Регионы</ContainerTitle>
        <Grid container spacing={2.5}>
          <AsyncAutocompleteInput
            name='regionsIds'
            placeholder='Добавить регион'
            fetchOptions={Region.fetchOptions}
            skeletonShow={isLoading}
            readOnly={disabled}
            multiple
            autocompleteXs={6}
            dataTestId='Регионы'
          />
        </Grid>
      </Container>

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
