import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  EditAggregatorAccount,
  editAggregatorAccountModel,
} from '~/features/Taxipark/AggregatorAccount/EditAggregatorAccount'
import { aggregatorAccountModel } from '~/entities/Taxipark/AggregatorAccount'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditAggregatorAccountPage = () => {
  const { aggregatorAccountId } = useParams<{ aggregatorAccountId: string }>()

  const isLoading = useStore(aggregatorAccountModel.requestFx.pending)
  const name = useStoreMap(
    editAggregatorAccountModel.$aggregatorAccount,
    (aggregatorAccount) => aggregatorAccount?.getTitle(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Аккаунты агрегаторов', link: '/taxipark/aggregator-accounts' },
      { title: name || '' },
    ],
    [name],
  )

  const error = aggregatorAccountModel.useAggregatorAccountError(
    aggregatorAccountId as string,
  )

  if (!aggregatorAccountId || error) {
    return <NotFound message='Не удалось загрузить данные агрегатора' />
  }

  return (
    <PageContainer title='Карточка аккаунта агрегатора'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{name}</PageTitle>
      </Container>

      <EditAggregatorAccount aggregatorAccountId={aggregatorAccountId} />
    </PageContainer>
  )
}
