import { Box } from '@mui/joy'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { usePermissions } from '~/entities/viewer'
import { logger } from '~/shared/lib/logger'
import { Alert } from '~/shared/ui/Alerts'
import { Button } from '~/shared/ui/Buttons'
import { ArrowCircleBrokenDownIcon } from '~/shared/ui/Icons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { Form, FormValues } from './Form'
import { setMainFx } from './model'

export function ChangeAggregatorDriverModal() {
  const { canDriverSetMainAggregator } = usePermissions()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { isModalShow, closeModal, openModal } = useModal()
  const queryClient = useQueryClient()

  const handleOnOk = useCallback(
    async (formValues: FormValues) => {
      try {
        setIsSubmitting(true)
        await setMainFx({ aggregatorDriverId: formValues.aggregatorDriver })
        await queryClient.invalidateQueries({
          queryKey: ['aggregators-driver'],
        })
        closeModal()
      } catch (e) {
        logger.error(e)
      } finally {
        setIsSubmitting(false)
      }
    },
    [closeModal, queryClient],
  )

  if (!canDriverSetMainAggregator) return null

  return (
    <>
      <Button
        variant='brand'
        onClick={openModal}
        startDecorator={<ArrowCircleBrokenDownIcon />}
      >
        Выбрать основной аккаунт
      </Button>

      <Modal
        isShow={isModalShow}
        onCancel={closeModal}
        title='Смена основного аккаунта'
        dialogProps={{
          sx: {
            minWidth: '600px',
            maxWidth: '600px',
          },
        }}
      >
        <Box sx={{ padding: '4px' }}>
          <Alert variant='warning'>
            Вы выбираете основной аккаунт водителя в агрегаторе, <br /> на
            который будут начисляться все транзакции по балансу!
          </Alert>
          <br />
          <Form
            onSuccess={handleOnOk}
            isSubmitting={isSubmitting}
            onCancel={closeModal}
          />
        </Box>
      </Modal>
    </>
  )
}
