import { Grid } from '@mui/joy'
import { useStoreMap } from 'effector-react'
import { carEditModel, EditCar, UnderRepair } from 'src/features/Сars/EditCar'
import { CarStatusEnum } from '~/shared/config/enums'

export const CarDetailsPage = () => {
  const carStatus = useStoreMap(carEditModel.$car, (car) => car?.getStatus())

  return (
    <Grid container spacing={3}>
      {carStatus === CarStatusEnum.UNDER_REPAIR && (
        <Grid xs={12}>
          <UnderRepair />
        </Grid>
      )}

      <Grid xs={12}>
        <EditCar />
      </Grid>
    </Grid>
  )
}
