import { useGate, useStore } from 'effector-react'
import { driverModel } from 'src/entities/Driver'
import { Form } from '~/entities/RentalContract'
import {
  CheckIsFirstRentalContractGate,
  $checkIsFirstRentalContractIsLoading,
} from '~/entities/RentalContract/model'
import { $defaultValues, createFx, formSubmitted, requestFileFx } from './model'

export const CreateRentalContract = () => {
  useGate(CheckIsFirstRentalContractGate)

  const isSubmitting = useStore(createFx.pending)
  const isRequestFilePending = useStore(requestFileFx.pending)
  const defaultValues = useStore($defaultValues)

  const checkIsFirstRentalContractIsLoading = useStore(
    $checkIsFirstRentalContractIsLoading,
  )
  const driverLoading = useStore(driverModel.requestDriverFx.pending)

  return (
    <Form
      isLoading={checkIsFirstRentalContractIsLoading || driverLoading}
      isSubmitting={isSubmitting || isRequestFilePending}
      isCreating
      onSuccess={formSubmitted}
      defaultValues={defaultValues}
    />
  )
}
