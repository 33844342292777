import { GridColDef } from '@mui/x-data-grid'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Наименование',
    field: 'name',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.name}</ColumnLink>,
  },
  {
    headerName: 'Номер телефона',
    field: 'phoneNumber',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Диспетчер',
    field: 'dispatcherFullName',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Контроллёр',
    field: 'controllerFullName',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Медик',
    field: 'doctorFullName',
    sortable: false,
    flex: 1,
  },
]
