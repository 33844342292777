import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import DoneSharpIcon from '@mui/icons-material/DoneSharp'
import { FormLabel, FormHelperText, Stack, FormControl } from '@mui/joy'
import { ReactElement } from 'react'
import { Control, Controller, Path } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types/fields'
import { Button } from '~/shared/ui/Buttons'

export type SwitchButtonsProps<T extends FieldValues> = {
  name: Path<T>
  control?: Control<T>
  label?: string
  checkedLabel?: string
  checkedIcon?: ReactElement
  uncheckedLabel?: string
  uncheckedIcon?: ReactElement
  disabled?: boolean
}

export function SwitchButtons<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  checkedLabel = 'Да',
  checkedIcon = <DoneSharpIcon />,
  uncheckedLabel = 'Нет',
  uncheckedIcon = <CloseSharpIcon />,
  disabled,
}: SwitchButtonsProps<TFieldValues>) {
  return (
    <Stack spacing={1} direction='row' alignItems='flex-start'>
      {label && (
        <FormLabel sx={{ margin: '6px 0 0 0 !important' }}>{label}</FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error, invalid } }) => {
          const isSet = typeof field.value === 'boolean'
          const checked = !!field.value
          const handleChange = (value: boolean) =>
            field.onChange({
              target: {
                value,
              },
            })
          return (
            <FormControl error={invalid}>
              <Stack spacing={1} direction='row'>
                <Button
                  disabled={disabled}
                  startDecorator={checkedIcon}
                  variant={isSet && checked ? 'green' : 'textGray'}
                  onClick={() => handleChange(true)}
                  size='sm'
                >
                  {checkedLabel}
                </Button>
                <Button
                  disabled={disabled}
                  startDecorator={uncheckedIcon}
                  variant={isSet && !checked ? 'red' : 'textGray'}
                  onClick={() => handleChange(false)}
                  size='sm'
                >
                  {uncheckedLabel}
                </Button>
              </Stack>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )
        }}
      />
    </Stack>
  )
}
