import { useGate } from 'effector-react'
import { PersonalData, personalDataModel } from '~/features/User/PersonalData'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'

function UserPersonalData() {
  useGate(personalDataModel.Gate)

  return (
    <PageContainer title='Персональные данные'>
      <Container isHeader>
        <PageTitle>Персональные данные</PageTitle>
      </Container>
      <PersonalData />
    </PageContainer>
  )
}

export default UserPersonalData
