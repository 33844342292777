import React from 'react'
import { ListSubdivisions } from '~/features/Taxipark/Subdivisions/ListSubdivisions'
import { Container } from '~/shared/ui/Layout'

export const ListSubdivisionsPage = () => {
  return (
    <Container>
      <ListSubdivisions />
    </Container>
  )
}
