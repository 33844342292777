import Logout from '@mui/icons-material/Logout'
import { CircularProgress, Dropdown } from '@mui/joy'
import { Link } from 'react-router-dom'
import { viewerModel } from '~/entities/viewer'
import { UserIcon } from '~/shared/ui/Icons'
import { MenuButton, Menu, MenuItem } from './styled'

export function AccountMenu() {
  return (
    <Dropdown>
      <MenuButton
        loadingIndicator={<CircularProgress />}
        data-testid='home-header-account-button'
      >
        <UserIcon />
      </MenuButton>
      <Menu>
        <MenuItem component={Link} to='/user-personal-data'>
          Персональные данные
        </MenuItem>
        <MenuItem onClick={() => viewerModel.signOut()}>
          <Logout />
          Выйти
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}
