import { useGate, useStore } from 'effector-react'
import { useParams } from 'react-router-dom'
import { TypedDropzoneRow } from '~/entities/Car/Documents/ui/TypedDropzoneRow'
import { usePermissions } from '~/entities/viewer'
import { CarDocumentType } from '~/shared/config/enums'
import { ContainerTitle } from '~/shared/ui/Layout'
import {
  Gate,
  $document,
  saveFilesFx,
  saveFiles,
  $loading,
  deleteFile,
  $fileList,
} from './model'

export function OtherDocument() {
  const params = useParams<{ carId: string }>()
  const carId = params.carId as string

  useGate(Gate, { carId })

  const { canCarDocumentsUpdate } = usePermissions()
  const isLoading = useStore($loading)
  const isSubmittingFiles = useStore(saveFilesFx.pending)
  const document = useStore($document)

  const fileList = useStore($fileList)

  return (
    <>
      <ContainerTitle>Прочие документы</ContainerTitle>

      <TypedDropzoneRow
        isLoading={isLoading && !document}
        documentType={CarDocumentType.OTHER_DOCUMENT}
        thumbs={fileList}
        isSubmitting={isSubmittingFiles}
        onDropAccepted={saveFiles}
        onDelete={deleteFile}
        canDelete={canCarDocumentsUpdate}
        canCreate={canCarDocumentsUpdate}
      />
    </>
  )
}
