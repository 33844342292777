import { ListEmployees } from 'src/features/Taxipark/Employees/ListEmployees'
import { Container } from '~/shared/ui/Layout'

export const ListEmployeePage = () => {
  return (
    <Container>
      <ListEmployees />
    </Container>
  )
}
