import { useParams } from 'react-router-dom'
import { List, GridRow, useColumns } from '~/entities/RentalContract'
import { addingColumn } from '~/shared/lib/addingColumn'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { Container } from '~/shared/ui/Layout'

export const ListRentalContractPage = () => {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  const columns = useColumns()

  const mappedColumns = addingColumn<GridRow>(
    columns.map((column) =>
      column.field === 'plateNumber'
        ? {
            ...column,
            renderCell: ({ row }) => (
              <ColumnLink to={`/cars/${row.carId}`}>
                {row.plateNumber}
              </ColumnLink>
            ),
          }
        : column,
    ),
    {
      headerName: 'Модель автомобиля',
      field: 'carBrandAndModelTitle',
      sortable: false,
      minWidth: 180,
    },
    'terminationDate',
  )

  return (
    <Container>
      <List driverId={driverId} columns={mappedColumns} />
    </Container>
  )
}
