import { Box } from '@mui/joy'
import { usePermissions } from '~/entities/viewer'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { FormValues } from '../EditForm/model'
import { Form } from './Form'

type AddModalProps = {
  onSuccess: (formValues: FormValues, closeModal: () => void) => void
  isSubmitting: boolean
}

export function AddModal({ onSuccess, isSubmitting }: AddModalProps) {
  const { isModalShow, closeModal, openModal } = useModal()

  const { canCarDocumentsUpdate } = usePermissions()

  const handleOnSuccess = (formValues: FormValues) => {
    onSuccess(formValues, closeModal)
  }

  return (
    <>
      <Button
        onClick={openModal}
        show={canCarDocumentsUpdate}
        startDecorator={<PlusIcon />}
      >
        Добавить
      </Button>

      <Modal
        title='Добавить лицензия такси'
        isShow={isModalShow}
        onCancel={closeModal}
        dialogProps={{
          sx: {
            minWidth: '600px',
            maxWidth: '600px',
          },
        }}
      >
        <Box sx={{ padding: '4px' }}>
          <Form onSuccess={handleOnSuccess} isSubmitting={isSubmitting} />
        </Box>
      </Modal>
    </>
  )
}
