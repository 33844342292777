import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { rentalContractModel } from '~/entities/RentalContract'
import { RentalContractSuspendModal } from '~/entities/RentalContract/ui/Modal'
import { usePermissions } from '~/entities/viewer'
import { CarAtWorkSubStatusEnum } from '~/shared/config/enums'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { ClockSnoozeWhiteIcon, ClockSnoozeYellowIcon } from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'

type RentalContractSuspendProps = {
  rentalContractId: UniqueId
  isButton?: boolean
  isIcon?: boolean
}

export const RentalContractSuspend = ({
  rentalContractId,
  isButton,
  isIcon,
}: RentalContractSuspendProps) => {
  const { canRentalContractsSuspend } = usePermissions()

  const { isModalShow, closeModal, openModal } = useModal()
  const queryClient = useQueryClient()

  const handleSuspend = useCallback(
    async (reason: CarAtWorkSubStatusEnum) => {
      await rentalContractModel.suspendRentalContractFx({
        rentalContractId,
        reason,
      })
      await queryClient.invalidateQueries({
        queryKey: ['rental-contracts-list'],
      })
    },
    [queryClient, rentalContractId],
  )

  if (!canRentalContractsSuspend) return null

  return (
    <>
      <RentalContractSuspendModal
        onOk={handleSuspend}
        onCancel={closeModal}
        isShow={isModalShow}
      />
      {isButton && (
        <Button
          variant='yellow'
          color='warning'
          startDecorator={<ClockSnoozeWhiteIcon />}
          onClick={openModal}
        >
          Приостановить договор
        </Button>
      )}
      {isIcon && (
        <IconButton
          tooltipProps={{ title: 'Приостановить договор' }}
          onClick={openModal}
        >
          <ClockSnoozeYellowIcon />
        </IconButton>
      )}
    </>
  )
}
