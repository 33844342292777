import { useParams } from 'react-router-dom'
import { List } from '~/features/Drivers/Balance/List'
import { ActionButtons } from '~/entities/Balance/BalanceHistory'
import { BalanceHistory } from '~/shared/api'
import { TransactionBalanceTypeEnum } from '~/shared/config/enums'
import { Container } from '~/shared/ui/Layout'

type BalanceByTypePageProps = {
  balanceType: TransactionBalanceTypeEnum
  canComing?: boolean
  canSpending?: boolean
}

export function BalanceByTypePage({
  balanceType,
  canComing,
  canSpending,
}: BalanceByTypePageProps) {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  const defaultFilters: Record<string, string> = driverId
    ? { driverId, balanceType }
    : {}

  return (
    <Container>
      <List
        key={balanceType}
        balanceType={balanceType}
        headerExtra={
          <ActionButtons
            defaultFilters={defaultFilters}
            exportFn={BalanceHistory.exportDriverBalance}
            canComing={canComing}
            canSpending={canSpending}
          />
        }
      />
    </Container>
  )
}
