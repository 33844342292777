import * as z from 'zod'

import { YandexWorkRule } from '~/shared/api'
import { ApiModel, ToOneRelation } from '~/shared/api/core'
import { AggregatorCodeEnum } from '~/shared/config/enums'
import { numberRequiredScheme, uuidOptionSchema } from '~/shared/lib/schemas'

const relationsSchema = z.object({
  defaultYandexWorkRuleOption: uuidOptionSchema,
})

const schema = z
  .object({
    title: z.string(),
    aggregatorCode: z.nativeEnum(AggregatorCodeEnum),
    taxiDispatchId: z.string(),
    defaultBalanceLimit: numberRequiredScheme,
    defaultBlockOrdersOnBalanceBelowLimit: z.boolean().default(false),
    defaultPartner: z.boolean().default(false),
    defaultPlatform: z.boolean().default(false),
  })
  .merge(relationsSchema)

export type AggregatorAccountAttributes = z.infer<typeof schema>

export class AggregatorAccount extends ApiModel<typeof schema> {
  static jsonApiType = 'aggregator-accounts'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getAggregatorCode(): AggregatorCodeEnum {
    return this.getAttribute('aggregatorCode')
  }

  defaultYandexWorkRule(): ToOneRelation<YandexWorkRule, this> {
    return this.hasOne(YandexWorkRule)
  }
  getDefaultYandexWorkRule(): YandexWorkRule {
    return this.getRelation('defaultYandexWorkRule')
  }
  setDefaultYandexWorkRule(id: UniqueId) {
    const yandexWorkRule = new YandexWorkRule()
    yandexWorkRule.setApiId(id)
    this.setRelation('defaultYandexWorkRule', yandexWorkRule)
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle() as string,
    }
  }

  static async fetchOptions(search: string) {
    const response = await AggregatorAccount.where('title', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
