import { zodResolver } from '@hookform/resolvers/zod'
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  Stack,
  Grid,
} from '@mui/joy'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '~/shared/ui/Buttons'
import { DatePickerInput, FormProvider, TextInput } from '~/shared/ui/Form'
import { formSchema, FormValues } from './model'

type EditFormProps = {
  defaultValues?: Partial<FormValues>
  onSuccess?: (formValues: FormValues) => void
  isSubmitting?: boolean
  canEdit?: boolean
}

export function EditForm({
  defaultValues,
  onSuccess,
  isSubmitting,
  canEdit,
}: EditFormProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const disabled = !isEditing || isSubmitting

  const form = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  })
  const { reset } = form

  const handleOnSuccess = useCallback(
    (formValues: FormValues) => {
      onSuccess?.(formValues)
    },
    [onSuccess],
  )

  const handleButtonsShow = () => {
    if (!canEdit) return null
    setIsEditing(true)
  }

  const handleButtonsHidden = () => {
    setIsEditing(false)
  }

  const handleCancel = () => {
    handleButtonsHidden()
  }

  useEffect(() => {
    if (defaultValues) reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  useEffect(() => {
    if (!isSubmitting) handleButtonsHidden()
  }, [isSubmitting])

  return (
    <FormProvider form={form} onSuccess={handleOnSuccess}>
      <Grid container spacing={2.5} onDoubleClick={handleButtonsShow}>
        <Grid xs={4}>
          <TextInput
            label='Номер'
            name='number'
            placeholder='Введите номер'
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Дата выдачи'
            name='issuedDate'
            placeholder='Выберите дату'
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Действителен до'
            name='expirationDate'
            placeholder='Выберите дату'
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={4}>
          <AccordionGroup>
            <Accordion expanded={isEditing} sx={{ minHeight: 0, padding: 0 }}>
              <AccordionDetails>
                <Stack direction='row' spacing={2}>
                  <Button
                    variant='red'
                    onClick={handleCancel}
                    disabled={isSubmitting}
                  >
                    Отменить
                  </Button>

                  <Button
                    variant='green'
                    type='submit'
                    loading={isSubmitting}
                    sx={{ marginRight: '24px' }}
                  >
                    Сохранить
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
