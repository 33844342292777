import { Grid } from '@mui/joy'
import { AddDriver } from 'src/features/Drivers/AddDriver'
import {
  PreviewList,
  driversPreviewListModel,
} from 'src/features/Drivers/PreviewList'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Водители', link: '/drivers' },
  { title: 'Создание водителя' },
]

const quickNavigationOptions = [
  { label: 'Осн. информация', anchor: 'main' },
  { label: 'Паспорт', anchor: 'passport' },
  { label: 'Ин. граждане', anchor: 'foreigners' },
  { label: 'Вод. удостоверение', anchor: 'drivingLicense' },
  { label: 'Доп. информация', anchor: 'extra' },
]

export function AddDriverPage() {
  return (
    <PageContainer title='Добавление нового водителя'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Добавление нового водителя</PageTitle>
      </Container>

      <Grid container spacing={2}>
        <Grid xs={8.5}>
          <AddDriver onChange={driversPreviewListModel.filtersChanged} />
        </Grid>
        <Grid xs={3.5}>
          <PreviewList />
        </Grid>
      </Grid>

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
