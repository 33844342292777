import { AppealCreateTransaction } from '~/features/Balance/Appeal/AppealCreateTransaction'
import { ViewTransaction } from '~/features/Balance/BalanceHistory/ViewTransaction'
import { usePermissions } from '~/entities/viewer'
import { TransactionMoveFundsTypeEnum } from '~/shared/config/enums'
import { canShowObject } from '~/shared/lib/utils'
import { BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Tabs } from '~/shared/ui/RoutesTabs/type'
import { AddAppealPage } from './Appeals/AddAppealPage'
import { ListAppealPage } from './Appeals/ListAppealPage'
import { ViewAppealPage } from './Appeals/ViewAppealPage'
import BulkCreateTransactionsPage from './BalanceHstories/BulkCreateTransactionsPage'
import CreateComingTransactionPage from './BalanceHstories/CreateComingTransactionPage'
import CreateSpendingTransactionPage from './BalanceHstories/CreateSpendingTransactionPage'
import { ListBalanceHistoriesPage } from './BalanceHstories/ListBalanceHistoriesPage'

const viewTransactionBreadcrumbs: BreadcrumbsList = [
  {
    title: 'Баланс',
    link: '/balance/balance-histories',
  },
]

export const useConfigTabs = (): Tabs => {
  const { canBalancesView, canAppealsView } = usePermissions()

  return [
    ...canShowObject(canBalancesView, {
      label: 'Баланс',
      path: '/balance-histories',
      content: <ListBalanceHistoriesPage />,
      children: [
        {
          path: '/bulk',
          content: <BulkCreateTransactionsPage />,
          full: true,
        },
        {
          path: '/new-coming',
          content: <CreateComingTransactionPage />,
          full: true,
        },
        {
          path: '/new-spending',
          content: <CreateSpendingTransactionPage />,
          full: true,
        },
        {
          path: '/:transactionId',
          content: (
            <ViewTransaction parentBreadcrumbs={viewTransactionBreadcrumbs} />
          ),
          full: true,
        },
      ],
    }),
    ...canShowObject(canAppealsView, {
      label: 'Обращения',
      path: '/appeals',
      content: <ListAppealPage />,
      children: [
        {
          path: '/new',
          content: <AddAppealPage />,
          full: true,
        },
        {
          path: '/:appealId',
          content: <ViewAppealPage />,
          full: true,
        },
        {
          path: '/:appealId/new-coming',
          content: (
            <AppealCreateTransaction
              moveFundsType={TransactionMoveFundsTypeEnum.COMING}
            />
          ),
          full: true,
        },
        {
          path: '/:appealId/new-spending',
          content: (
            <AppealCreateTransaction
              moveFundsType={TransactionMoveFundsTypeEnum.SPENDING}
            />
          ),
          full: true,
        },
      ],
    }),
  ]
}
