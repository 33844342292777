import { Box, Tooltip } from '@mui/joy'
import { useStore } from 'effector-react'
import { useMemo } from 'react'
import {
  $autoChecks,
  $isInitialLoading,
} from '~/features/Drivers/DriverChecks/model'
import { CheckResults, CheckStatus } from '~/entities/DriverCheck'
import { getKbmColor } from '~/entities/DriverCheck/lib'
import { CheckKindEnum, CheckStatusEnum } from '~/shared/config/enums'
import { myPalette } from '~/shared/lib/theme'
import { ChipStatus } from '~/shared/ui/Chips'
import { ExpandIcon } from '~/shared/ui/Icons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { CheckBase } from './CheckBase'
import { Wrapper } from './styled'

type AutoCheckProps = { checkKind: CheckKindEnum }

export function AutoCheck({ checkKind }: AutoCheckProps) {
  const autoChecks = useStore($autoChecks)
  const isLoading = useStore($isInitialLoading)
  const check = autoChecks.find((check) => check.getKind() === checkKind)
  const attr = check?.getAttributes()
  const kind = attr?.kind
  const status = check?.getStatus()

  const isSuccess = status === CheckStatusEnum.SUCCESS
  const checkKbm = kind === CheckKindEnum.KBM

  const { openModal, closeModal, isModalShow } = useModal()

  const statusNode = useMemo(() => {
    if (isLoading) return null
    if (checkKbm && isSuccess) {
      const kbmValue = attr?.results?.kbmValue as number
      return <ChipStatus label={kbmValue} variant={getKbmColor(kbmValue)} />
    }
    return <CheckStatus value={status || 'NO_RESULTS'} />
  }, [attr, checkKbm, isLoading, isSuccess, status])

  return (
    <>
      <Wrapper>
        <CheckBase
          check={check}
          checkKind={checkKind}
          loading={isLoading}
          expandNode={
            isSuccess && !checkKbm ? (
              <Tooltip title='Открыть' variant='outlined'>
                <Box sx={{ cursor: 'pointer' }} onClick={openModal}>
                  <ExpandIcon color={myPalette.gray['400']} />
                </Box>
              </Tooltip>
            ) : null
          }
          statusNode={statusNode}
        />
      </Wrapper>
      <Modal
        isShow={isModalShow}
        onCancel={closeModal}
        dialogProps={{
          sx: {
            minWidth: '600px',
          },
        }}
      >
        <Box sx={{ padding: '4px' }}>
          <CheckResults check={check} onClose={closeModal} />
        </Box>
      </Modal>
    </>
  )
}
