import { Grid } from '@mui/joy'
import { Region } from '~/shared/api'
import { AsyncAutocompleteInput, MaskInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type MainProps = {
  disabled?: boolean
  skeletonShow?: boolean
}

export function Main({ disabled, skeletonShow }: MainProps) {
  return (
    <Container data-testid='main-form-container'>
      <ContainerTitle>Основная информация</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={4}>
          <TextInput
            label='Фамилия'
            placeholder='Введите фамилию'
            name='lastName'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={4}>
          <TextInput
            label='Имя'
            placeholder='Введите имя'
            name='firstName'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={4}>
          <TextInput
            label='Отчество'
            placeholder='Введите отчество'
            name='middleName'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='E-mail'
            placeholder='Введите E-mail'
            name='email'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <MaskInput
            name='phone'
            placeholder='Введите телефон'
            label='Телефон'
            skeletonShow={skeletonShow}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={12}>
          <AsyncAutocompleteInput
            label='Регион работы'
            placeholder='Выберите регион'
            name='regionId'
            fetchOptions={Region.fetchOptions}
            skeletonShow={skeletonShow}
            readOnly={disabled}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
