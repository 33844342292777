import { createDomain } from 'effector'
import { z } from 'zod'
import {
  Appeal,
  Car,
  CarOptionForAppeal,
  Driver,
  DriverOptionForAppeal,
} from '~/shared/api'
import {
  FILTER_WITHOUT_EMPTY_ENTITIES,
  Option,
} from '~/shared/config/constants'
import { uuidOptionSchema } from '~/shared/lib/schemas'

const domain = createDomain('entities.appeal.ui.mainForm')

const schema = z
  .object({
    driverOption: uuidOptionSchema,
    carOption: uuidOptionSchema,
    subdivisionOption: uuidOptionSchema.optional(),
    appealTypeOption: uuidOptionSchema,
  })
  .merge(Appeal.schema)

export const formSchema = schema.pick({
  creatorComment: true,
  amount: true,
  driverOption: true,
  carOption: true,
  subdivisionOption: true,
  appealTypeOption: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<
  TypeFormSchema,
  'driverOption' | 'carOption' | 'subdivisionOption' | 'appealTypeOption'
> & {
  id?: UniqueId
  driverOption: UniqueId | DriverOptionForAppeal | null
  carOption: UniqueId | CarOptionForAppeal | null
  subdivisionOption: CarOptionForAppeal['subdivisionOption'] | UniqueId | null
  appealTypeOption: Option | UniqueId | null
  phone: string
}

export const fetchCarOptions = async (search?: string) => {
  const response = await Car.where(
    'plateNumber',
    search || FILTER_WITHOUT_EMPTY_ENTITIES,
  )
    .with('subdivision')
    .get(1)

  return response.getData().map((o) => o.getOptionForAppeal())
}

export const fetchSubdivisionByCarIdFx = domain.createEffect<
  UniqueId,
  Option | undefined
>({
  async handler(carId) {
    const response = await Car.with('subdivision').find(carId)
    const car = response.getData()
    return car?.getSubdivision().getOption()
  },
})

export const fetchDriverOptions = async (search?: string) => {
  const response = await Driver.where('fullName', search ?? '')
    .with('latestRentalContract')
    .orderBy('lastName')
    .get(1)
  return response.getData().map((o) => o.getDriverOptionForAppeal())
}
