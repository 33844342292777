import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { mapSearchParamsToFilters } from '~/shared/lib/utils'
import { FileExportBlob } from '~/shared/ui/FileExport'

type BalanceHistoryExportProps = {
  defaultFilters?: Record<string, string>
  exportFn: (search: string) => Promise<Blob>
}

export const BalanceHistoryExport = ({
  defaultFilters,
  exportFn,
}: BalanceHistoryExportProps) => {
  const [searchParams] = useSearchParams()

  const filters = useMemo(
    () => ({
      ...mapSearchParamsToFilters(searchParams),
      ...defaultFilters,
    }),
    [defaultFilters, searchParams],
  )

  const isFiltersValid = useMemo(
    () => filters.appliedAtTo && filters.appliedAtFrom,
    [filters.appliedAtFrom, filters.appliedAtTo],
  )

  const errorMessageFn = useCallback(() => {
    snackbarEnqueued({
      message: 'Задайте в фильтре дату начала и конца периода',
      variant: 'error' as const,
    })
  }, [])

  const handleExportFn = async () =>
    exportFn(new URLSearchParams(filters).toString())

  return (
    <FileExportBlob
      title='Выгрузить в Excel'
      fn={isFiltersValid ? handleExportFn : errorMessageFn}
    />
  )
}
