import { useCallback } from 'react'
import { Appeal } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export const ListAppeals = () => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = Appeal.limit(pageSize)
        .with('driver')
        .with('car')
        .with('appealType')
        .with('subdivision')
        .with('creator')
        .with('responsible')

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      filters.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)

      const data = response.getData()

      const rows = data.map((appeal: Appeal) => {
        const carModel = `${appeal?.getCar()?.getAttributeBrandTitle()} ${appeal
          ?.getCar()
          ?.getAttributeModelTitle()}`
        return {
          ...appeal.getAttributes(),
          id: appeal.getApiId() as string,
          appealType: appeal?.getAppealType()?.getTitle(),
          driverFullName: appeal?.getDriver()?.getFullName(),
          driverId: appeal?.getDriver()?.getApiId() as string,
          driverPhone: appeal?.getDriver()?.getPhone(),
          carPlateNumber: appeal?.getCar()?.getPlateNumber(),
          carId: appeal?.getCar()?.getApiId() as string,
          carModel,
          subdivisionName: appeal?.getSubdivision()?.getTitle(),
          creatorName: appeal?.getCreator().getName(),
          responsibleName: appeal?.getResponsible()?.getName(),
          status: {
            label: appeal.getStatusLabel(),
            color: appeal.getStatusColor(),
          },
        }
      })

      /*
       * Coloquent не дает метода, чтобы получить объект meta,
       * поэтому в данном случае получаем оригинальный ответ и достаем мету из него
       * TODO: Реализовать лучший способ получения метаданных
       *  ...
       * */
      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Обращения'
      gridKey='appeals-list'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
    />
  )
}
