import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import {
  FormProvider,
  formButtonsModel,
  TextInput,
  MaskInput,
  NumericInput,
  FormButtons,
  DatePickerInput,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { formSchema, FormValues } from './model'

export type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Element name='requisites'>
        <Container>
          <ContainerTitle>Реквизиты</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={12}>
              <TextInput
                label='Наименование'
                name='name'
                placeholder='Введите наименование'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={12}>
              <TextInput
                label='Юридический адрес'
                name='legalAddress'
                placeholder='Введите юридический адрес'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={4}>
              <NumericInput
                label='ИНН'
                name='inn'
                placeholder='Введите ИНН'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
            <Grid xs={4}>
              <NumericInput
                label='ОГРН'
                name='ogrn'
                placeholder='Введите ОГРН'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
            <Grid xs={4}>
              <NumericInput
                label='ОКПО'
                name='okpo'
                placeholder='Введите ОКПО'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='registry'>
        <Container>
          <ContainerTitle>Реестр</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={6}>
              <TextInput
                label='Номер разрешения в реестре перевозчиков'
                name='freighterPermitNumber'
                placeholder='Введите номер'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <DatePickerInput
                label='Срок действия размещения'
                name='freighterPermitExpirationDate'
                placeholder='Выберите дату'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='employees'>
        <Container>
          <ContainerTitle>Сотрудники</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={6}>
              <TextInput
                label='Диспетчер'
                name='dispatcherFullName'
                placeholder='Введите ФИО диспетчера'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                label='Контроллёр'
                name='controllerFullName'
                placeholder='Введите ФИО контроллера'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                label='Медик'
                name='doctorFullName'
                placeholder='Введите ФИО медика'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='contacts'>
        <Container>
          <ContainerTitle>Контакты</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={6}>
              <MaskInput
                name='phoneNumber'
                label='Телефон'
                placeholder='Введите телефон'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
