import { Box, BoxProps } from '@mui/joy'
import { ReactNode } from 'react'

type ColumnLinkProps = BoxProps & {
  children: string | ReactNode
}

export function ColumnValue({ children, ...props }: ColumnLinkProps) {
  return (
    <Box
      {...props}
      sx={{
        ...props?.sx,
        textOverflow: 'ellipsis !important',
        overflow: 'hidden !important',
        display: 'inline-block',
      }}
    >
      {children}
    </Box>
  )
}
