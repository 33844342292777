import { Stack } from '@mui/joy'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { AggregatorDriver } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { BalanceTransferModal } from '../BalanceTransferModal'
import { ChangeAggregatorDriverModal } from '../ChangeAggregatorDriverModal'
import { columns } from './columns'
import { GridRow } from './types'

export const ListAggregators = () => {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize) => {
      const builder = AggregatorDriver.limit(pageSize)
        .with('aggregatorAccount')
        .where('driverId', driverId)

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((aggregatorDriver) => ({
        ...aggregatorDriver.getAttributes(),
        id: aggregatorDriver.getApiId() as string,
        title: aggregatorDriver?.getAggregatorAccount().getTitle(),
        aggregatorCode: aggregatorDriver
          ?.getAggregatorAccount()
          .getAggregatorCode(),
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [driverId],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='aggregators-driver'
      nestedGridKey={driverId}
      fetchRows={fetchRows}
      columns={columns}
      smallTitle='Агрегаторы'
      headerExtra={
        <Stack direction='row' spacing={2}>
          <ChangeAggregatorDriverModal />
          <BalanceTransferModal />
        </Stack>
      }
    />
  )
}
