import { Box, Dropdown, MenuButton } from '@mui/joy'
import { Link } from 'react-router-dom'
import { usePermissions } from '~/entities/viewer'
import { Car } from '~/shared/api'
import { myPalette } from '~/shared/lib/theme'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { FileExportLink } from '~/shared/ui/FileExport/FileExportLink'
import {
  DotsVerticalIcon,
  DownloadIcon,
  FileIcon,
  PlusIcon,
  UploadIcon,
} from '~/shared/ui/Icons'
import { useModal } from '~/shared/ui/Modal'
import { ImportCarTemplate } from '../ImportCarTemplate'
import { Menu, MenuItem } from './styled'

export function HeadingExtra() {
  const { canCarsUpdate, canExportCarDocumentsStatusReport } = usePermissions()
  const { openModal, closeModal, isModalShow } = useModal()

  const handleExportCarDocumentsStatusReport = async () => {
    const { reportUrl } = await Car.exportCarDocumentsStatusReport()
    return reportUrl
  }

  const handleMassImportTemplateGet = async () => {
    const { templateUrl } = await Car.getMassImportTemplate()
    return templateUrl
  }
  const isButtonActionsShow = canCarsUpdate || canExportCarDocumentsStatusReport

  return (
    <>
      <Button
        show={canCarsUpdate}
        variant='brand'
        startDecorator={<UploadIcon />}
        onClick={openModal}
      >
        Загрузить список
      </Button>

      {isButtonActionsShow && (
        <Dropdown>
          <IconButton
            data-testid='button-menu-car'
            variant='gray'
            component={MenuButton}
          >
            <DotsVerticalIcon />
          </IconButton>
          <Menu keepMounted={true}>
            <FileExportLink
              title='Выгрузить отчет по документам'
              fnGetLink={handleExportCarDocumentsStatusReport}
              show={canExportCarDocumentsStatusReport}
              variant='menuItem'
              startDecorator={<FileIcon color={myPalette.gray['700']} />}
            />
            {canCarsUpdate && (
              <>
                <MenuItem component={Link} to='/cars/new'>
                  <Box sx={{ width: '20px' }}>
                    <PlusIcon color={myPalette.gray['700']} />
                  </Box>
                  Добавить автомобиль
                </MenuItem>

                <FileExportLink
                  title='Скачать шаблон автомобилей'
                  fnGetLink={handleMassImportTemplateGet}
                  variant='menuItem'
                  startDecorator={
                    <DownloadIcon color={myPalette.gray['700']} />
                  }
                />
              </>
            )}
          </Menu>
        </Dropdown>
      )}

      <ImportCarTemplate isShow={isModalShow} onCancel={closeModal} />
    </>
  )
}
