import { GridColDef } from '@mui/x-data-grid'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Наименование',
    field: 'name',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.name}</ColumnLink>,
  },
  {
    headerName: 'Полное название',
    field: 'fullName',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Номер телефона',
    field: 'phoneNumber',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Email',
    field: 'email',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Руководитель',
    field: 'directorFio',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Сайт',
    field: 'webSite',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) =>
      row.webSite && (
        <ColumnLink href={row.webSite} target='_blank'>
          Перейти
        </ColumnLink>
      ),
  },
]
