import { VehicleCategory } from '~/shared/api'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    field: 'title',
    label: 'Наименование',
    type: 'text',
  },
  {
    field: 'vehicleCategory',
    label: 'Категория ТС',
    type: 'autocomplete',
    fetchOptions: VehicleCategory.fetchOptions,
  },
  {
    field: 'active',
    label: 'Действующий',
    type: 'boolean',
  },
  {
    field: 'isPromotion',
    label: 'Авто участвует в акции',
    type: 'boolean',
  },
]
