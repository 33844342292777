import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { AxiosErrorType, CarRental } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const domain = createDomain('entities.carRental')

export const requestFx = domain.createEffect<UniqueId, CarRental>({
  handler: fetchCarRentalWithRelations,
})

export const saveFx = domain.createEffect<CarRental, CarRental, AxiosErrorType>(
  {
    async handler(carRental) {
      await carRental.save()
      return fetchCarRentalWithRelations(carRental.getApiId() as UniqueId)
    },
  },
)

const {
  $cache: $carRentalsCache,
  useCache: useCarRentalCache,
  updateCache,
} = createCache<CarRental>({
  domain,
  getEntityId: (carRental) => carRental.getApiId() as UniqueId,
})
export { $carRentalsCache, useCarRentalCache }

$carRentalsCache
  .on(requestFx.doneData, (cache, carRental) => updateCache(cache, [carRental]))
  .on(saveFx.doneData, (cache, carRental) =>
    updateCache(cache, [carRental], true),
  )

export const $carRentalsError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useCarRentalError = (id: UniqueId) =>
  useStoreMap($carRentalsError, (errors) => errors[id])

async function fetchCarRentalWithRelations(id: UniqueId) {
  const response = await CarRental.find(id)
  return response.getData() as CarRental
}
