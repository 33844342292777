import { zodResolver } from '@hookform/resolvers/zod'
import { Divider, Grid, Typography } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { myPalette } from '~/shared/lib/theme'
import { Button } from '~/shared/ui/Buttons'
import { DatePickerInput, FormProvider, TextInput } from '~/shared/ui/Form'
import { PlusIcon } from '~/shared/ui/Icons'
import { formSchema, FormValues } from '../EditForm/model'

type FormProps = {
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
}

export function Form({ onSuccess, isSubmitting }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Grid container spacing={2.5}>
        <Grid xs={12}>
          <DatePickerInput
            label='Дата выдачи'
            name='issuedDate'
            placeholder='Введите дату выдачи'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Номер'
            name='number'
            placeholder='Введите номер'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={12}>
          <Divider>
            <Typography
              sx={{
                fontSize: '18px',
                fontWeight: 600,
                color: myPalette.gray['400'],
              }}
            >
              Доп. информация
            </Typography>
          </Divider>
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Серия'
            name='series'
            placeholder='Введите серию'
            readOnly={isSubmitting}
          />
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Местонахождение'
            name='location'
            placeholder='Введите местонахождение'
            readOnly={isSubmitting}
          />
        </Grid>
      </Grid>
      <br />
      <Button
        loading={isSubmitting}
        startDecorator={<PlusIcon />}
        fullWidth
        type='submit'
      >
        Добавить
      </Button>
    </FormProvider>
  )
}
