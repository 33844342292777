import { Skeleton } from '@mui/joy'
import { useMemo } from 'react'
import { RentalContract } from '~/shared/api'
import { ChipStatus } from '~/shared/ui/Chips'

type StatusProps = {
  rentalContract: RentalContract | null
  isLoading: boolean
}

export function Status({ rentalContract, isLoading }: StatusProps) {
  const label = useMemo(
    () => rentalContract?.getStatusLabel(),
    [rentalContract],
  )
  const color = useMemo(
    () => rentalContract?.getStatusColor(),
    [rentalContract],
  )

  return (
    <ChipStatus
      data-testid='rental-contract-status'
      label={
        isLoading ? (
          <Skeleton sx={{ top: 0, left: 0, minWidth: '100px' }} />
        ) : (
          label
        )
      }
      variant={color}
      sx={{ minWidth: '100px', marginBottom: '6px' }}
    />
  )
}
