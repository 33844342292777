import { useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { driverModel } from 'src/entities/Driver'
import { BreadcrumbsList } from '~/shared/ui/Breadcrumbs'

export const useBreadcrumbs = (driverId: string) => {
  const fullName = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getFullName(),
  )

  const generalBalanceBreadcrumbs = useMemo(
    () => [
      {
        title: 'Водители',
        link: '/drivers',
      },
      {
        title: fullName || 'Водитель ...',
        link: `/drivers/${driverId}/details`,
      },
      {
        title: 'Ведомость',
        link: `/drivers/${driverId}/balance/current`,
      },
    ],
    [driverId, fullName],
  )

  const currentViewTransactionBreadcrumbs = useMemo(
    (): BreadcrumbsList => [
      ...generalBalanceBreadcrumbs,
      {
        title: 'Текущий баланс',
        link: `/drivers/${driverId}/balance/current`,
      },
    ],
    [driverId, generalBalanceBreadcrumbs],
  )

  const byDeptViewTransactionBreadcrumbs = useMemo(
    (): BreadcrumbsList => [
      ...generalBalanceBreadcrumbs,
      {
        title: 'Кредитный баланс',
        link: `/drivers/${driverId}/balance/by-dept`,
      },
    ],
    [driverId, generalBalanceBreadcrumbs],
  )

  return {
    currentViewTransactionBreadcrumbs,
    byDeptViewTransactionBreadcrumbs,
  }
}
