import { GridColDef } from '@mui/x-data-grid'
import { ColumnAmount, ColumnDate, ColumnLink } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'ФИО',
    field: 'fullName',
    minWidth: 360,
    renderCell: ({ row }) => (
      <ColumnLink to={`/drivers/${row.id}/details`}>{row.fullName}</ColumnLink>
    ),
  },
  {
    headerName: 'Номер ВУ',
    field: 'drivingLicenseNumber',
    sortable: false,
    minWidth: 214,
  },
  {
    headerName: 'Контактный телефон',
    field: 'phone',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'E-mail',
    field: 'email',
    sortable: false,
    minWidth: 214,
    flex: 1,
  },
  {
    headerName: 'Статус',
    field: 'status',
    sortable: false,
    minWidth: 280,
    renderCell: ({ value }) => (
      <ChipStatus label={value.label} variant={value.color} />
    ),
  },
  {
    headerName: 'Дата рождения',
    field: 'birthDate',
    sortable: false,
    minWidth: 214,
    renderCell: ({ value }) => <ColumnDate type='date' value={value} />,
  },
  {
    headerName: 'Текущий баланс',
    field: 'mainBalance',
    minWidth: 160,
    renderCell: ({ value }) => {
      if (!value) return null
      return (
        <ColumnAmount
          amount={value}
          moveFunds={value >= 0 ? 'COMING' : 'SPENDING'}
        />
      )
    },
  },
  {
    headerName: 'Кредитный баланс',
    field: 'debtBalance',
    minWidth: 160,
    renderCell: ({ value }) => {
      if (!value) return null
      return (
        <ColumnAmount
          amount={value}
          moveFunds={value >= 0 ? 'COMING' : 'SPENDING'}
        />
      )
    },
  },
  {
    headerName: 'Условие работы',
    field: 'workRule',
    sortable: false,
    minWidth: 214,
    flex: 1,
  },
  {
    headerName: 'Автомобиль',
    field: 'car',
    sortable: false,
    minWidth: 214,
    flex: 1,
    renderCell: ({ row, value }) => (
      <ColumnLink to={`/cars/${row.carId}/details`}>{value}</ColumnLink>
    ),
  },
  {
    headerName: 'Условие работы Яндекс',
    field: 'yandexWorkRule',
    sortable: false,
    minWidth: 214,
    flex: 1,
  },
]
