import { zodResolver } from '@hookform/resolvers/zod'
import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  Typography,
  Stack,
  Box,
} from '@mui/joy'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { CheckStatus, getCheckKindLabel } from '~/entities/DriverCheck'
import { Check, resultsSchemas } from '~/shared/api/check'
import { CheckKindEnum } from '~/shared/config/enums'
import { Button } from '~/shared/ui/Buttons'
import { FormProvider, SwitchButtons, TextareaInput } from '~/shared/ui/Form'

const schema = resultsSchemas[CheckKindEnum.SB]

export type FormValues = z.infer<typeof schema>
type Props = {
  check?: Check
  canEdit: boolean
  defaultValues: Partial<FormValues>
  onSuccess: (formValues: FormValues) => void
  isLoading: boolean
  isSubmitting: boolean
}

export function CheckSbForm({
  canEdit,
  defaultValues,
  onSuccess,
  isLoading,
  isSubmitting,
  check,
}: Props) {
  const [isButtonsShow, setIsButtonsShow] = useState<boolean>(false)
  const status = check?.getStatus()
  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const disabled = !isButtonsShow || isSubmitting || !canEdit

  const handleButtonsShow = () => {
    if (isSubmitting || !canEdit) return
    setIsButtonsShow(true)
  }
  const handleButtonsHidden = () => {
    setIsButtonsShow(false)
  }

  const handleCancel = () => {
    form.reset(defaultValues)
    handleButtonsHidden()
  }

  useEffect(() => {
    if (!isLoading) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [isLoading])

  useEffect(() => {
    !isSubmitting && handleButtonsHidden()
  }, [isSubmitting])

  return (
    <FormProvider form={form} onSuccess={onSuccess} data-testid='check-sb-form'>
      <Box onDoubleClick={handleButtonsShow}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          sx={{ marginBottom: '28px' }}
        >
          <Stack direction='row' spacing={3}>
            <Typography fontWeight={600} fontSize={20}>
              {getCheckKindLabel(CheckKindEnum.SB)}
            </Typography>
            {!isLoading && <CheckStatus value={status || 'NO_RESULTS'} />}
          </Stack>

          <SwitchButtons disabled={disabled} label='Одобрен' name='accepted' />
        </Stack>
        <TextareaInput
          name='comment'
          placeholder='Введите комментарий'
          sx={{
            height: '88px',
          }}
          skeletonShow={isLoading}
          readOnly={disabled}
        />
      </Box>
      <AccordionGroup>
        <Accordion expanded={isButtonsShow} sx={{ minHeight: 0, padding: 0 }}>
          <AccordionDetails data-testid='check-sb-form-accordion-details'>
            <Stack direction='row' spacing={2} mt={2}>
              <Button variant='green' type='submit' loading={isSubmitting}>
                Сохранить
              </Button>
              <Button
                variant='red'
                onClick={handleCancel}
                disabled={isSubmitting}
              >
                Отменить
              </Button>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </FormProvider>
  )
}
