import { useCallback } from 'react'
import { PtsDocument } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { GridRow } from './types'

type ListProps = {
  carId: UniqueId
}

export const List = ({ carId }: ListProps) => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize) => {
      const builder = PtsDocument.limit(pageSize)
        .where('carId', carId)
        .where('isActive', 'false')
        .orderBy('-createdAt')

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((ptsDocument) => ({
        ...ptsDocument.getAttributes(),
        id: ptsDocument.getApiId() as string,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [carId],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='pts-document-list'
      nestedGridKey={carId}
      fetchRows={fetchRows}
      columns={columns}
      memoryLocal
      smallTitle='Архивные документы'
    />
  )
}
