import { Car } from '~/shared/api'
import { transactionMoveFundsTypeEnumOptions } from '~/shared/config/constants'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    field: 'appliedAtFrom',
    label: 'Начало периода',
    type: 'date',
  },
  {
    field: 'appliedAtTo',
    label: 'Конец периода',
    type: 'date',
  },
  {
    field: 'moveFundsType',
    label: 'Вид движения',
    type: 'select',
    options: transactionMoveFundsTypeEnumOptions,
  },
  {
    field: 'flowStatementName',
    label: 'Статья оборотов',
    type: 'text',
  },
  {
    field: 'sourceType',
    label: 'Пользователь/Источник',
    type: 'text',
  },
  {
    field: 'carId',
    label: 'Автомобиль',
    type: 'autocomplete',
    fetchOptions: Car.fetchOptions,
  },
]
