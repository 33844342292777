import { useStore, useStoreMap } from 'effector-react'
import { $user, fetchUserFx } from '~/entities/viewer/model'
import { Region, Role } from '~/shared/api'
import { Form } from './ui/Form'

export function PersonalData() {
  const defaultValues = useStoreMap($user, (user) => ({
    ...user?.getAttributes(),
    rolesIds: user?.getRoles()?.map((role: Role) => role.getOption()),
    regionsIds: user?.getRegions()?.map((region: Region) => region.getOption()),
  }))
  const isFetchUserPending = useStore(fetchUserFx.pending)

  return <Form defaultValues={defaultValues} isLoading={isFetchUserPending} />
}
