import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import {
  FormButtons,
  formButtonsModel,
  FormProvider,
  MaskInput,
  NumericInput,
  Switch,
  TextInput,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { FormValues, formSchema } from './model'

type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Element name='requisites'>
        <Container>
          <ContainerTitle>Реквизиты</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={12}>
              <TextInput
                label='Наименование'
                name='name'
                placeholder='Введите наименование'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={12}>
              <TextInput
                name='fullName'
                label='Полное наименование'
                placeholder='Введите полное наименование'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={12}>
              <TextInput
                label='Юридический адрес'
                name='legalAddress'
                placeholder='Введите юридический адрес'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={12}>
              <TextInput
                label='Фактический адрес'
                name='actualAddress'
                placeholder='Введите фактический адрес'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={3}>
              <NumericInput
                label='ИНН'
                name='inn'
                placeholder='Введите ИНН'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
            <Grid xs={3}>
              <NumericInput
                label='КПП'
                name='kpp'
                placeholder='Введите КПП'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
            <Grid xs={3}>
              <NumericInput
                label='ОГРН'
                name='ogrn'
                placeholder='Введите ОГРН'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
            <Grid xs={3}>
              <NumericInput
                label='ОКПО'
                name='okpo'
                placeholder='Введите ОКПО'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
            <Grid xs={12}>
              <Switch
                label='Организация работает с НДС'
                name='withNds'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='management'>
        <Container>
          <ContainerTitle>Руководство</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={6}>
              <TextInput
                label='Должность руководителя'
                name='directorPosition'
                placeholder='Введите должность руководителя'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                label='ФИО руководителя'
                name='directorFio'
                placeholder='Введите ФИО руководителя'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                label='Должность руководителя в родительном падеже'
                name='directorPositionInParentalCase'
                placeholder='Введите должность руководителя в родительном падеже'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                label='ФИО руководителя в родительном падеже'
                name='directorFioInParentalCase'
                placeholder='Введите ФИО руководителя в родительном падеже'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                label='Основание для подписи документов (род. падеж)'
                name='directorBasisForSigningDocuments'
                placeholder='Введите основание для подписи документов (род. падеж)'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='contacts'>
        <Container>
          <ContainerTitle>Контакты</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={3}>
              <MaskInput
                name='phoneNumber'
                label='Телефон'
                placeholder='Введите телефон'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={3}>
              <TextInput
                label='E-mail'
                name='email'
                type='email'
                placeholder='Введите E-mail'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                label='Сайт'
                name='webSite'
                placeholder='Введите сайт'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='bank'>
        <Container>
          <ContainerTitle>Банк</ContainerTitle>
          <Grid container spacing={2.5}>
            <Grid xs={6}>
              <TextInput
                label='Наименование банка'
                name='bank'
                placeholder='Введите наименование банка'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <NumericInput
                label='Расчётный счёт'
                name='rs'
                placeholder='Введите расчётный счёт'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
            <Grid xs={6}>
              <NumericInput
                label='Корреспондентский счет'
                name='ks'
                placeholder='Введите корреспондентский счет'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
            <Grid xs={6}>
              <NumericInput
                label='БИК'
                name='bik'
                placeholder='Введите БИК'
                skeletonShow={isLoading}
                readOnly={disabled}
                numericProps={{ thousandSeparator: '' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>
      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
