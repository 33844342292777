import { Box, Stack } from '@mui/joy'
import { useStore, useStoreMap } from 'effector-react'
import { uniq } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from 'src/widgets/AuditsModal'
import { EditRole, roleEmployeeModal } from '~/features/Taxipark/Roles/EditRole'
import { permissionModel } from '~/entities/Taxipark/Permission'
import { roleModel } from '~/entities/Taxipark/Role'
import { usePermissions } from '~/entities/viewer'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

export const EditRolePage = () => {
  const { roleId } = useParams<{ roleId: string }>()
  const { canAuditsView } = usePermissions()

  const isLoading = useStore(roleModel.requestFx.pending)
  const roleName = useStoreMap(roleEmployeeModal.$role, (employee) =>
    employee?.getName(),
  )
  const permissions = useStore(permissionModel.$permissions)

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Роли', link: '/taxipark/roles' },
      { title: roleName as string },
    ],
    [roleName],
  )

  const quickNavigationOptions = useMemo(() => {
    return uniq(
      (permissions || []).map((permission) => permission.groupTitle),
    ).map((title) => ({
      label: title,
      anchor: title,
    }))
  }, [permissions])

  const error = roleModel.useRoleError(roleId as string)
  if (!roleId || error) {
    return <NotFound message='Не удалось загрузить роль' />
  }

  return (
    <PageContainer title='Карточка роли'>
      <Container isHeader>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <Box>
            <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
            <PageTitle loading={isLoading}>{roleName}</PageTitle>
          </Box>
          <Stack direction='row' spacing={2}>
            {canAuditsView && <AuditsModal auditableId={roleId} />}
          </Stack>
        </Stack>
      </Container>

      <EditRole roleId={roleId} />

      {Boolean(permissions?.length) && (
        <QuickNavigation options={quickNavigationOptions} />
      )}
    </PageContainer>
  )
}
