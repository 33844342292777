import { Grid, Stack } from '@mui/joy'
import { format, parseISO } from 'date-fns'
import { useStore, useStoreMap } from 'effector-react'
import { Element } from 'react-scroll'
import {
  Documents,
  MainForm,
  SolutionForm,
  TransactionList,
  useRole,
  useStatus,
  appealModel,
} from '~/entities/Balance/Appeal'
import { datetime } from '~/shared/config'
import {
  transactionBalanceTypeEnumLabels,
  transactionMoveFundsTypeEnumLabels,
} from '~/shared/config/constants'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import {
  appealSolutionFormUpdateFx,
  mainFormSubmitted,
  solutionFormSubmitted,
  $fileList,
  saveFiles,
  deleteFile,
  saveFilesFx,
  updateByCreatorFx,
} from './model'
import { ButtonDebtExtra } from './ui/ButtonDebtExtra'

export function ViewAppeal() {
  const isLoading = useStore(appealModel.requestFx.pending)
  const isMainFormSubmitting = useStore(updateByCreatorFx.pending)
  const isAppealFetching = useStore(appealModel.requestSilentFx.pending)
  const isSolutionFormSubmitting = useStore(appealSolutionFormUpdateFx.pending)
  const isSaveFilesSubmitting = useStore(saveFilesFx.pending)

  const { isResponsible, isInitiator } = useRole()
  const { isNew } = useStatus()
  const canEditDocuments = isInitiator && isNew

  const canEdit = isNew && isInitiator

  const mainFormDefaultValues = useStoreMap(appealModel.$appeal, (appeal) => {
    const driver = appeal?.getDriver()
    const carOption = appeal?.getCar()
    const subdivisionOption = appeal?.getSubdivision()
    const appealTypeOption = appeal?.getAppealType()
    return {
      ...appeal?.getAttributes(),
      driverOption: driver ? driver?.getDriverOptionForAppeal() : null,
      phone: appeal?.getDriver()?.getPhone(),
      carOption: carOption ? carOption?.getOption() : null,
      subdivisionOption: subdivisionOption
        ? subdivisionOption?.getOption()
        : null,
      appealTypeOption: appealTypeOption ? appealTypeOption?.getOption() : null,
    }
  })

  const solutionFormDefaultValues = useStoreMap(
    appealModel.$appeal,
    (appeal) => ({
      responsibleComment: appeal?.getResponsibleComment(),
      responsible: appeal?.getResponsible()?.getName(),
    }),
  )

  const transactionList = useStoreMap(appealModel.$appeal, (appeal) => {
    const transactions = appeal?.getBalanceHistories()
    return (transactions || []).map((transaction) => {
      const date = format(
        parseISO(transaction.getAppliedAt()),
        datetime.uiDateTimeFormat,
      )
      const moveFundsType =
        transactionMoveFundsTypeEnumLabels[transaction.getMoveFundsType()]
      const balanceType =
        transactionBalanceTypeEnumLabels[transaction.getBalanceType()]
      return {
        label: `Транзакция от ${date} ${moveFundsType} ${balanceType} ${transaction.getAmount()} руб.`,
        link: `/balance/balance-histories/${transaction.getApiId()}`,
      }
    })
  })

  const fileList = useStore($fileList)

  return (
    <>
      <Element name='main'>
        <MainForm
          isSubmitting={isMainFormSubmitting || isAppealFetching}
          defaultValues={mainFormDefaultValues}
          onSuccess={mainFormSubmitted}
          isLoading={isLoading}
          canEdit={canEdit}
          title={
            <Stack
              direction='row'
              alignItems='end'
              justifyContent='space-between'
            >
              <ContainerTitle>Основная информация</ContainerTitle>
              <ButtonDebtExtra />
            </Stack>
          }
        />
      </Element>

      <Element name='solution'>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <SolutionForm
              canEdit={isResponsible}
              defaultValues={solutionFormDefaultValues}
              onSuccess={solutionFormSubmitted}
              isSubmitting={isSolutionFormSubmitting}
              isLoading={isLoading}
            />
          </Grid>
          <Grid xs={6}>
            <TransactionList
              transactions={transactionList}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Element>

      <Element name='documents'>
        <Container>
          <Grid container spacing={2.5}>
            <ContainerTitle>Файлы</ContainerTitle>
            <Grid xs={12}>
              <Documents
                canEditDocuments={canEditDocuments}
                thumbs={fileList}
                onDropAccepted={saveFiles}
                onDelete={deleteFile}
                isSubmitting={isSaveFilesSubmitting}
                isLoading={isLoading}
                empty={
                  !canEditDocuments && !fileList?.length
                    ? 'Файлы не загружены'
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </Container>
      </Element>
    </>
  )
}
