import { Stack } from '@mui/joy'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { ActionButtons as AppealActionButtons } from '~/entities/Balance/Appeal'
import { ActionButtons as BalanceHistoryActionButtons } from '~/entities/Balance/BalanceHistory'
import { Appeal, BalanceHistory } from '~/shared/api'
import { Container, PageTitle } from '~/shared/ui/Layout'

export function Header() {
  const { pathname } = useLocation()

  const isBalanceHistoriesPage = useMemo(
    () => !!matchPath('/balance/balance-histories', pathname),
    [pathname],
  )
  const isAppealsPage = useMemo(
    () => !!matchPath('/balance/appeals', pathname),
    [pathname],
  )

  return (
    <Container isHeader>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <PageTitle>Баланс</PageTitle>

        {isBalanceHistoriesPage && (
          <BalanceHistoryActionButtons
            parentPath='balance-histories'
            exportFn={BalanceHistory.exportBalanceHistory}
            canMassImport
          />
        )}
        {isAppealsPage && (
          <AppealActionButtons exportFn={Appeal.exportAppeal} />
        )}
      </Stack>
    </Container>
  )
}
