import { useStore } from 'effector-react'
import { Form } from '~/entities/Taxipark/Subdivisions/ui/Form'
import { formSubmitted, createFx } from './model'

export const AddSubdivision = () => {
  const isSubmitting = useStore(createFx.pending)

  return (
    <Form isCreating onSuccess={formSubmitted} isSubmitting={isSubmitting} />
  )
}
