import { z } from 'zod'
import { TaxiLicense } from '~/shared/api'
import { Option } from '~/shared/config/constants'

export const formSchema = TaxiLicense.schema.pick({
  number: true,
  issuedDate: true,
  expirationDate: true,
  issuedBy: true,
  carLicenseeOption: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<TypeFormSchema, 'carLicenseeOption'> & {
  carLicenseeOption: UniqueId | Option | null
}
