import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const schema = z.object({
  createdAt: dateSchema,
  number: z.number(),
  plateNumber: z.string(),
  responsibleName: z.string().optional(),
})

export type TravelSheetAttributes = z.infer<typeof schema>

export class TravelSheet extends ApiModel<typeof schema> {
  static jsonApiType = 'travel-sheets'

  static schema = schema

  static async bulkCreate(driverId: UniqueId, quantity: number): Promise<Blob> {
    const url = `${TravelSheet.getJsonApiUrl()}/actions/print`
    const client = TravelSheet.httpClient.getImplementingClient()
    const response = await client.post(
      url,
      {
        driverId,
        quantity,
      },
      {
        responseType: 'blob',
      },
    )
    return response?.data
  }

  static async fetchFile(id: UniqueId): Promise<Blob> {
    const url = `${TravelSheet.getJsonApiUrl()}/${id}/actions/print`
    const client = TravelSheet.httpClient.getImplementingClient()

    const response = await client.post(
      url,
      {},
      {
        responseType: 'blob',
      },
    )

    return response?.data
  }
}
