import { Car } from '~/shared/api'
import { FileExportBlob } from '~/shared/ui/FileExport'

type PrintFreighterCardProps = {
  carId: UniqueId
}

export function PrintFreighterCard({ carId }: PrintFreighterCardProps) {
  const fn = () => Car.printFreighterCard(carId)

  return <FileExportBlob fn={fn} title='Распечатать карточку фрахтовщика' />
}
