import { Stack } from '@mui/joy'
import { usePermissions } from '~/entities/viewer'
import { Filter } from '~/shared/ui/AsyncGrid'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { AppealExport } from '../AppealExport'

type ActionButtonsProps = {
  defaultFilters?: Filter
  exportFn: (search: string) => Promise<Blob>
}

export const ActionButtons = ({
  defaultFilters,
  exportFn,
}: ActionButtonsProps) => {
  const { canAppealsCreator, canAppealsReportExport } = usePermissions()

  return (
    <Stack
      direction='row'
      alignItems='flex-start'
      justifyContent='space-between'
      spacing={1}
    >
      {canAppealsReportExport && (
        <AppealExport exportFn={exportFn} defaultFilters={defaultFilters} />
      )}
      <Button
        show={canAppealsCreator}
        to='appeals/new'
        startDecorator={<PlusIcon />}
      >
        Создать
      </Button>
    </Stack>
  )
}
