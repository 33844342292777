import * as z from 'zod'

import { Timezone } from '~/shared/api/index'
import { dateSchema, uuidOptionSchema } from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'

const relationsSchema = z.object({
  timezoneId: uuidOptionSchema,
})
const attributeSchema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type RegionAttributes = z.infer<typeof attributeSchema>

export class Region extends ApiModel<typeof schema, RegionAttributes> {
  static jsonApiType = 'regions'

  static schema = schema

  timezone(): ToOneRelation<Timezone, this> {
    return this.hasOne(Timezone)
  }

  getTimezone(): Timezone {
    return this.getRelation('timezone')
  }

  setTimezone(id: UniqueId) {
    const timezone = new Timezone()
    timezone.setApiId(id)
    this.setRelation('timezone', timezone)
  }

  getName(): string {
    return this.getAttribute('name')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }

  static async fetchOptions(search: string, regionId?: UniqueId) {
    if (regionId) {
      const response = await Region.find(regionId)
      const region = response.getData() as Region
      return [region?.getOption()]
    }

    const response = await Region.where('name', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
