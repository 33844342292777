import { CreateTransaction } from '~/features/Balance/BalanceHistory/CreateTransaction'
import { TransactionMoveFundsTypeEnum } from '~/shared/config/enums'
import { BreadcrumbsList } from '~/shared/ui/Breadcrumbs'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Баланс', link: '/balance/balance-histories' },
  { title: 'Создание транзакции' },
]

function CreateComingTransactionPage() {
  return (
    <CreateTransaction
      moveFundsType={TransactionMoveFundsTypeEnum.COMING}
      breadcrumbs={breadcrumbs}
      isCreating
    />
  )
}

export default CreateComingTransactionPage
