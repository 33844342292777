import { useCallback } from 'react'
import { usePermissions } from '~/entities/viewer'
import { Subdivision } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { GridRow } from './types'

export const ListSubdivisions = () => {
  const { canSubdivisionsUpdate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize) => {
      const builder = Subdivision.limit(pageSize)
        .with('region')
        .orderBy('-createdAt')

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((subdivision) => {
        return {
          title: subdivision.getTitle(),
          id: subdivision.getApiId() as string,
          region: subdivision.getRegion().getName(),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Подразделения'
      gridKey='subdivisions'
      fetchRows={fetchRows}
      columns={columns}
      headerExtra={
        <Button
          startDecorator={<PlusIcon />}
          to='new'
          show={canSubdivisionsUpdate}
        >
          Добавить
        </Button>
      }
    />
  )
}
