import { GridColDef } from '@mui/x-data-grid'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'VIN',
    field: 'vin',
    sortable: false,
    minWidth: 260,
    renderCell: ({ row }) => (
      <ColumnLink to={`/cars/${row.id}/details`}>{row.vin}</ColumnLink>
    ),
  },
  {
    headerName: 'Гос. номер',
    field: 'plateNumber',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Регион',
    field: 'region',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Подразделение',
    field: 'subdivision',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Модель',
    field: 'model',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Статус',
    field: 'status',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) =>
      value && <ChipStatus label={value.label} variant={value.color} />,
  },
  {
    headerName: 'Под статус',
    field: 'subStatus',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) =>
      value && <ChipStatus label={value} variant='gray' />,
  },
  {
    headerName: 'Выкуп',
    field: 'isLeasingFullyPaid',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => (
      <ChipStatus
        label={value ? 'Да' : 'Нет'}
        variant={value ? 'yellow' : 'gray'}
      />
    ),
  },
  {
    headerName: 'Участвует в акции',
    field: 'isPromotion',
    sortable: false,
    minWidth: 160,
    renderCell: ({ value }) => (
      <ChipStatus
        label={value ? 'Да' : 'Нет'}
        variant={value ? 'purple' : 'gray'}
      />
    ),
  },
  {
    headerName: 'ПредТотал',
    field: 'isPreTotaled',
    sortable: false,
    flex: 1,
    renderCell: ({ value }) => (
      <ChipStatus
        label={value ? 'Да' : 'Нет'}
        variant={value ? 'red' : 'gray'}
      />
    ),
  },
]
