import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { diagnosticCardFormModel } from 'src/entities/Car/Documents/DiagnosticCard'
import { documentsModel } from '~/entities/Car/Documents'
import { AxiosErrorType, Car, DiagnosticCard, ThumbType } from '~/shared/api'
import { CarDocumentType } from '~/shared/config/enums'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString, mapDocumentFileToThumbs } from '~/shared/lib/utils'

const domain = createDomain('feature.diagnostic-card-documents')

export const Gate = createGate<{ carId: UniqueId }>()
const $carId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { carId }) => carId)
  .on(Gate.close, () => null)

export const fetchDocumentFx = domain.createEffect<UniqueId, DiagnosticCard>({
  async handler(carId) {
    const response = await DiagnosticCard.where('carId', carId)
      .where('isActive', 'true')
      .first()
    return response.getData() as DiagnosticCard
  },
})

export const $loading = domain
  .createStore(false)
  .on(Gate.open, () => true)
  .on(fetchDocumentFx.finally, () => false)

export const formSubmittedCreate = domain.createEvent<{
  formValues: diagnosticCardFormModel.FormValues
  fetchList: () => void
  closeModal: () => void
}>()

export const createFx = domain.createEffect<
  {
    carId: UniqueId
    formValues: diagnosticCardFormModel.FormValues
    fetchList: () => void
    closeModal: () => void
  },
  DiagnosticCard,
  AxiosErrorType
>({
  async handler({ carId, formValues, fetchList, closeModal }) {
    const doc = new DiagnosticCard(formValues)

    doc.setCar(carId)

    const response = await doc.save()

    fetchList()
    closeModal()

    return response.getModel() as DiagnosticCard
  },
})

export const formSubmittedUpdate =
  domain.createEvent<diagnosticCardFormModel.FormValues>()

export const updateFx = domain.createEffect<
  { formValues: diagnosticCardFormModel.FormValues; documentId: UniqueId },
  DiagnosticCard,
  AxiosErrorType
>({
  async handler({ formValues, documentId }) {
    const doc = new DiagnosticCard(formValues, documentId)

    const response = await doc.save()
    return response.getModel() as DiagnosticCard
  },
})

export const $document = domain
  .createStore<DiagnosticCard | null>(null)
  .on([createFx.doneData, updateFx.doneData], (state, document) => document)
  .on(fetchDocumentFx.doneData, (state, document) => document)
  .on(documentsModel.DocumentsGate.close, () => null)

sample({
  clock: $carId,
  filter: isString,
  target: fetchDocumentFx,
})
sample({
  clock: formSubmittedCreate,
  source: $carId,
  filter: isString,
  fn: (carId, entity) => ({ carId, ...entity }),
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Документ добавлен',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: formSubmittedUpdate,
  source: $document,
  fn: (document, formValues) => ({
    documentId: document?.getApiId() as UniqueId,
    formValues,
  }),
  target: [updateFx],
})
sample({
  clock: updateFx.doneData,
  fn() {
    return {
      message: 'Документ отредактирован',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued],
})
sample({
  clock: updateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Files
export const saveFiles = domain.createEvent<File[]>()
export const saveFilesFx = domain.createEffect<
  { documentId: UniqueId; files: File[]; carId: UniqueId },
  void,
  AxiosErrorType
>({
  async handler({ documentId, files, carId }) {
    for (const file of files) {
      await Car.saveDocumentFile(
        file,
        documentId,
        CarDocumentType.DIAGNOSTIC_CARDS_DOCUMENT,
        carId,
      )
    }
  },
})

sample({
  clock: saveFiles,
  source: { document: $document, carId: $carId },
  fn: ({ document, carId }, files) => ({
    documentId: document?.getApiId() as UniqueId,
    files,
    carId: String(carId),
  }),
  target: saveFilesFx,
})

sample({
  clock: saveFilesFx.doneData,
  source: $carId,
  filter: isString,
  target: fetchDocumentFx,
})

sample({
  clock: saveFilesFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const deleteFile = domain.createEvent<UniqueId>()
const deleteFileFx = domain.createEffect<
  { fileId: UniqueId; carId: UniqueId },
  UniqueId,
  AxiosErrorType
>({
  async handler({ fileId, carId }) {
    await Car.deleteDocumentFile(fileId, carId)
    return fileId
  },
})

export const $pendingFileList = domain
  .createStore<UniqueId[]>([])
  .on(deleteFile, (state, docId) => [...state, docId])
  .on([deleteFileFx.done, deleteFileFx.fail], (state, { params }) =>
    state.filter((stateFileId) => stateFileId !== params.fileId),
  )

sample({
  clock: deleteFile,
  source: $carId,
  filter: isString,
  fn: (carId, fileId) => ({ carId, fileId }),
  target: deleteFileFx,
})

sample({
  clock: deleteFileFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $fileList = domain
  .createStore<ThumbType[]>([])
  .on($document, (store, document) =>
    mapDocumentFileToThumbs(document?.getFiles()),
  )
  .on($pendingFileList, (files, pendingFileList) =>
    files?.map((file) =>
      pendingFileList.includes(file.id)
        ? { ...file, pending: true }
        : { ...file, pending: false },
    ),
  )
  .on(deleteFileFx.doneData, (files, deleteId) =>
    files.filter((file) => file.id !== deleteId),
  )
