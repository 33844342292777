import { GridColDef } from '@mui/x-data-grid'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Наименование',
    field: 'title',
    sortable: false,
    flex: 1,
    minWidth: 500,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.title}</ColumnLink>,
  },
  {
    headerName: 'Статус',
    field: 'status',
    sortable: false,
    minWidth: 170,
    renderCell: ({ value }) => (
      <ChipStatus label={value.label} variant={value.color} />
    ),
  },
  {
    headerName: 'Категория ТС',
    field: 'vehicleCategory',
    sortable: false,
    minWidth: 200,
  },
  {
    headerName: 'Авто участвует в акции',
    field: 'isPromotion',
    sortable: false,
    minWidth: 200,
    renderCell: ({ value }) => (
      <ChipStatus
        label={value ? 'Да' : 'Нет'}
        variant={value ? 'purple' : 'gray'}
      />
    ),
  },
]
