import { AggregatorAccount, Car, Driver, Subdivision } from '~/shared/api'
import {
  transactionBalanceTypeEnumOptions,
  transactionMoveFundsTypeEnumOptions,
} from '~/shared/config/constants'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    field: 'appliedAtFrom',
    label: 'Начало периода',
    type: 'date',
  },
  {
    field: 'appliedAtTo',
    label: 'Конец периода',
    type: 'date',
  },
  {
    field: 'moveFundsType',
    label: 'Вид движения',
    type: 'select',
    options: transactionMoveFundsTypeEnumOptions,
  },
  {
    field: 'aggregatorAccountId',
    label: 'Аккаунт',
    type: 'autocomplete',
    fetchOptions: AggregatorAccount.fetchOptions,
  },
  {
    field: 'driverId',
    label: 'Водитель',
    type: 'autocomplete',
    fetchOptions: Driver.fetchOptions,
  },
  {
    field: 'carId',
    label: 'Автомобиль',
    type: 'autocomplete',
    fetchOptions: Car.fetchOptions,
  },
  {
    field: 'balanceType',
    label: 'Раздел баланса',
    type: 'select',
    options: transactionBalanceTypeEnumOptions,
  },
  {
    field: 'flowStatementName',
    label: 'Статья оборотов',
    type: 'text',
  },
  {
    field: 'subdivisionId',
    label: 'Подразделение',
    type: 'autocomplete',
    fetchOptions: Subdivision.fetchOptions,
  },
  {
    field: 'sourceType',
    label: 'Пользователь/Источник',
    type: 'text',
  },
]
