import { createDomain, sample } from 'effector'
import { employeeModel } from '~/entities/Taxipark/Employee'
import { AxiosErrorType, Employee } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const domain = createDomain('features.taxipark.employees.add')

export const createFx = domain.createEffect<
  employeeModel.FormValues,
  Employee,
  AxiosErrorType
>({
  async handler(values) {
    const { regionsIds, rolesIds, ...formData } = values
    const employee = new Employee(formData)

    if (Array.isArray(regionsIds)) employee.setRegions(regionsIds)
    if (Array.isArray(rolesIds)) employee.setRoles(rolesIds)

    const result = await employee.save()
    return result.getModel() as Employee
  },
})

export const formSubmitted = domain.createEvent<employeeModel.FormValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Пользователь создан',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
