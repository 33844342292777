import { Grid, Stack } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { exhaustiveMatchingGuard } from '~/shared/lib/exhaustiveMatchingGuard'
import { Filter } from '~/shared/ui/AsyncGrid'
import { Button } from '~/shared/ui/Buttons'
import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  NumericInput,
  TextInput,
  DatePickerInput,
  DateRangeInput,
  FormProvider,
  Switch,
} from '~/shared/ui/Form'
import { mappedFormValuesToQuery } from './helpers'
import { useFiltersQueryParams } from './useFiltersQueryParams'

type Props = {
  filters: Filter[]
  onFiltersChange: () => void
}

export function Filters({ filters, onFiltersChange }: Props) {
  const [filterQuery, setFilterQuery] = useFiltersQueryParams(filters)

  const form = useForm({
    defaultValues: filterQuery,
  })
  const { reset, watch } = form

  const clear = () => {
    const emptyValues = Object.fromEntries(
      filters.map((filter) =>
        filter.type === 'dateRange'
          ? [filter.startField, '', filter.endField, '']
          : [filter.field, ''],
      ),
    )
    reset(emptyValues)
  }

  watch((data) => {
    setFilterQuery(mappedFormValuesToQuery(data, filters), 'replaceIn')
    onFiltersChange()
  })

  return (
    <FormProvider form={form} data-testid='filters'>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='flex-end'
        sx={{ marginBottom: '32px' }}
      >
        <Grid container spacing={2} alignItems='center' sx={{ width: '100%' }}>
          {filters.map((filter) => (
            <Grid
              xs={3}
              key={
                filter.type === 'dateRange'
                  ? `${filter?.startField}-${filter?.endField}`
                  : filter.field
              }
              data-testid={`filter-${filter.label}`}
              alignItems='center'
            >
              {renderFilter(filter)}
            </Grid>
          ))}
        </Grid>
        <Button variant='gray' onClick={clear} sx={{ marginLeft: '12px' }}>
          Очистить
        </Button>
      </Stack>
    </FormProvider>
  )
}

function renderFilter(filter: Filter) {
  const { type, label } = filter
  switch (type) {
    case 'text': {
      return <TextInput placeholder={label} name={filter.field} />
    }
    case 'select': {
      return (
        <AutocompleteInput
          placeholder={label}
          name={filter.field}
          options={filter.options}
        />
      )
    }
    case 'selectMultiple': {
      return (
        <AutocompleteInput
          placeholder={label}
          name={filter.field}
          options={filter.options}
          multiple
          multipleType='internal'
        />
      )
    }
    case 'autocomplete': {
      return (
        <AsyncAutocompleteInput
          placeholder={label}
          name={filter.field}
          fetchOptions={filter.fetchOptions}
          canAsyncSearch={filter.canAsyncSearch}
        />
      )
    }
    case 'autocompleteMultiple': {
      return (
        <AsyncAutocompleteInput
          placeholder={label}
          name={filter.field}
          fetchOptions={filter.fetchOptions}
          multiple
          multipleType='internal'
          canAsyncSearch={filter.canAsyncSearch}
        />
      )
    }
    case 'boolean': {
      return <Switch label={label} name={filter.field} />
    }
    case 'date': {
      return <DatePickerInput name={filter.field} placeholder={label} />
    }
    case 'dateRange': {
      return (
        <DateRangeInput
          startName={filter.startField}
          endName={filter.endField}
          placeholder={label}
        />
      )
    }
    case 'price': {
      return <NumericInput placeholder={label} name={filter.field} />
    }
    default:
      return exhaustiveMatchingGuard(type)
  }
}
