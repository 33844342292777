import { useCallback } from 'react'
import { usePermissions } from '~/entities/viewer'
import { Permission, Role } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { GridRow } from './types'

export const ListRole = () => {
  const { canRolesUpdate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize) => {
      const builder = Role.limit(pageSize)
        .with('permissions')
        .orderBy('-createdAt')

      const response = await builder.get(page)

      const data = response.getData()
      const rows = data.map((role: Role) => ({
        ...role.getAttributes(),
        name: role.getName(),
        permissions:
          role.getPermissions()?.map((item: Permission) => item.getTitle()) ||
          [],
        id: role.getApiId() as string,
      }))

      /*
       * Coloquent не дает метода, чтобы получить объект meta,
       * поэтому в данном случае получаем оригинальный ответ и достаем мету из него
       * TODO: Реализовать лучший способ получения метаданных
       * */
      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Роли'
      gridKey='roles'
      fetchRows={fetchRows}
      columns={columns}
      headerExtra={
        <Button startDecorator={<PlusIcon />} to='new' show={canRolesUpdate}>
          Добавить
        </Button>
      }
    />
  )
}
