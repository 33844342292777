import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { ReactNode, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { AppealType } from '~/shared/api/appealType'
import { getObject } from '~/shared/lib/utils'
import { LinkDecorator } from '~/shared/ui/Buttons'
import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  FormButtons,
  formButtonsModel,
  FormProvider,
  MaskInput,
  NumericInput,
  TextareaInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import {
  fetchSubdivisionByCarIdFx,
  fetchCarOptions,
  fetchDriverOptions,
  formSchema,
  FormValues,
} from './model'
import { useFormWatch } from './useFormWatch'

type MainFormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
  children?: ReactNode
  title: ReactNode
}

export function MainForm({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  onSuccess,
  defaultValues,
  children,
  title,
}: MainFormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    defaultValues: { phone: '', ...defaultValues },
    resolver: zodResolver(formSchema),
  })

  const isSelectSubdivisionLoading = useStore(fetchSubdivisionByCarIdFx.pending)

  useFormWatch(form, isEditing || isCreating)

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        {title}
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <AsyncAutocompleteInput
              label='Водитель'
              placeholder='Выбрать'
              name='driverOption'
              fetchOptions={fetchDriverOptions}
              skeletonShow={isLoading}
              readOnly={disabled}
              disableClearable
              endDecorator={
                disabled && (
                  <LinkDecorator
                    to={`/drivers/${
                      getObject(defaultValues?.driverOption)?.id
                    }`}
                    tooltipText='Перейти в карточку водителя'
                  />
                )
              }
            />
          </Grid>
          <Grid xs={6}>
            <MaskInput
              name='phone'
              label='Телефон'
              placeholder='Телефон'
              skeletonShow={isLoading}
              readOnly
            />
          </Grid>
          <Grid xs={6}>
            <AsyncAutocompleteInput
              label='Автомобиль'
              name='carOption'
              placeholder='Выбрать'
              fetchOptions={fetchCarOptions}
              loading={isSelectSubdivisionLoading}
              disableClearable
              endDecorator={
                disabled && (
                  <LinkDecorator
                    to={`/cars/${getObject(defaultValues?.carOption)?.id}`}
                    tooltipText='Перейти в карточку автомобиля'
                  />
                )
              }
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={6}>
            <AutocompleteInput
              label='Подразделение'
              placeholder='Подразделение'
              name='subdivisionOption'
              options={[]}
              loading={isSelectSubdivisionLoading}
              skeletonShow={isLoading}
              readOnly
            />
          </Grid>
          <Grid xs={6} container>
            <Grid xs={12}>
              <AsyncAutocompleteInput
                label='Тип обращения'
                disableClearable
                name='appealTypeOption'
                placeholder='Выбрать'
                fetchOptions={AppealType.fetchOptions}
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={12}>
              <NumericInput
                placeholder='Введите сумму'
                label='Сумма'
                name='amount'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
          <Grid xs={6}>
            <TextareaInput
              label='Комментарий'
              name='creatorComment'
              placeholder='Введите комментарий'
              sx={{
                height: '138px',
              }}
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>

      {children}

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
