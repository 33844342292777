type Props = {
  color?: string
}

export function ArrowCircleBrokenDownIcon({ color = '#ffffff' }: Props) {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 2.33782C18.989 4.06687 21 7.29859 21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 7.29859 3.01099 4.06687 6 2.33782M7 11L11 15M11 15L15 11M11 15V1'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
