import { ListAggregators } from 'src/features/Drivers/Aggregators/ListAggregators'
import { Container } from '~/shared/ui/Layout'

export const ListAggregatorsPage = () => {
  return (
    <Container>
      <ListAggregators />
    </Container>
  )
}
