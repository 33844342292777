import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import {
  AxiosErrorType,
  Balance,
  MassImportSaveValues,
  MassImportTransaction,
} from '~/shared/api'
import { TransactionPaymentTypeEnum } from '~/shared/config/enums'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formModel } from './ui/Form'

export const Gate = createGate()
const domain = createDomain()

export const massImportSave = domain.createEvent<formModel.FormValues>()
export const massImportSaveFx = domain.createEffect<
  MassImportSaveValues,
  void,
  AxiosErrorType
>({
  handler(values) {
    return Balance.massImportSave(values)
  },
})

// Mass import
export const massImport = domain.createEvent<File>()
export const massImportFx = domain.createEffect<
  File,
  { importId: UniqueId },
  AxiosErrorType
>({
  handler(file) {
    return Balance.massImport(file)
  },
})

export const $importId = domain
  .createStore<UniqueId | null>(null)
  .on(massImportFx.doneData, (_, { importId }) => importId)
  .on([Gate.close, massImport, massImportSaveFx.doneData], () => null)

sample({
  clock: massImport,
  target: massImportFx,
})

sample({
  clock: massImportFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Total amount
export const getTotalAmountFx = domain.createEffect<
  UniqueId,
  { totalAmount: number },
  AxiosErrorType
>({
  handler(importId) {
    return MassImportTransaction.totalAmount(importId)
  },
})

sample({
  clock: getTotalAmountFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: $importId,
  filter: isString,
  target: getTotalAmountFx,
})

export const $totalAmount = domain
  .createStore<number | null>(null)
  .on(getTotalAmountFx.doneData, (_, { totalAmount }) => totalAmount)
  .on([Gate.close, massImport, massImportSaveFx.doneData], () => null)

// Transactions error
export const getIsTransactionsErrorFx = domain.createEffect<UniqueId, boolean>({
  async handler(importId) {
    const response = await MassImportTransaction.limit(1)
      .where('importId', importId)
      .where('isValid', 'false')
      .get(1)
    const data = response.getData()
    return Boolean(data?.length)
  },
})

sample({
  clock: $importId,
  filter: isString,
  target: getIsTransactionsErrorFx,
})

export const $isTransactionsError = domain
  .createStore<boolean>(false)
  .on(getIsTransactionsErrorFx.doneData, (_, isError) => isError)
  .on([Gate.close, massImport, massImportSaveFx.doneData], () => false)

// Mass import save
sample({
  clock: massImportSave,
  source: $importId,
  filter: isString,
  fn: (importId, { flowStatementId, paymentType }) => {
    return {
      importId,
      flowStatementId: flowStatementId as UniqueId,
      paymentType: paymentType as TransactionPaymentTypeEnum,
    }
  },
  target: massImportSaveFx,
})

sample({
  clock: massImportSaveFx.doneData,
  fn() {
    return {
      message: 'Транзакции успешно созданны',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})

sample({
  clock: massImportSaveFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
