import { useCallback } from 'react'

import { MassImportTransaction } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'

import { columns } from './columns'
import { GridRow } from './types'

type ListProps = {
  importId: UniqueId | null
}

export const List = ({ importId }: ListProps) => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = []) => {
      if (!importId) return { rows: [], total: 0 }

      let builder = MassImportTransaction.limit(pageSize)

      filters.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((transaction) => ({
        ...transaction.getAttributes(),
        id: transaction.getApiId() as string,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [importId],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Транзакции'
      gridKey='mass-import-transactions'
      fetchRows={fetchRows}
      columns={columns}
      externalFilter={
        importId ? [{ key: 'importId', value: importId }] : undefined
      }
    />
  )
}
