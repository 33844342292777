import {
  AutocompleteProps,
  Typography,
  FormLabel,
  FormHelperText,
  FormControl,
  Skeleton,
  Box,
  Grid,
  CircularProgress,
  BoxProps,
} from '@mui/joy'
import { uniqBy } from 'lodash'
import { SyntheticEvent, useMemo, useState } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { Option } from '~/shared/config/constants'
import { InternalChipList } from '~/shared/ui/Form/ui/InternalChipList'
import { SearchIcon } from '~/shared/ui/Icons'
import { ExternalChipList } from '../ui/ExternalChipList'
import { Listbox } from '../ui/Listbox'
import { Autocomplete } from '../ui/styled'

type MultipleType = 'external' | 'internal'

type AutocompleteInputProps<
  T extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = {
  name: string
  label?: string
  skeletonShow?: boolean
  options: T[]
  rules?: ControllerProps['rules']
  dataTestId?: string
  autocompleteBoxSx?: BoxProps['sx']
} & Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> &
  (
    | {
        multiple: true
        autocompleteXs?: number
        multipleType?: MultipleType
      }
    | {
        multiple?: false | undefined
        autocompleteXs?: never
        multipleType?: never
      }
  )

export function AutocompleteInput<T extends Option>({
  label,
  name,
  skeletonShow,
  options,
  multiple,
  autocompleteXs,
  multipleType = 'external',
  autocompleteBoxSx,
  rules,
  dataTestId,
  ...props
}: AutocompleteInputProps<T, boolean, boolean, boolean>) {
  const [search, setSearch] = useState<string>('')
  const [isOpen, setIsOpen] = useState(false)
  const layout = useMemo(() => {
    if (multiple && multipleType === 'external') {
      return {
        formControl: {
          component: Grid,
          xs: 12,
          sx: {
            justifyContent: 'space-between',
          },
        },
        autocompleteBox: {
          component: Grid,
          sx: {
            paddingTop: 0,
            paddingLeft: 0,
            paddingBottom: 0,
            ...autocompleteBoxSx,
          },
          xs: autocompleteXs,
        },
      }
    }
  }, [autocompleteBoxSx, autocompleteXs, multiple, multipleType])

  const handleSearchSet = (
    _: SyntheticEvent,
    value: string,
    reason: 'input' | 'reset' | 'clear',
  ) => {
    if (reason === 'input') {
      setSearch(value)
    }
    if (!multiple && (reason === 'reset' || reason === 'clear')) {
      setSearch('')
    }
  }

  const handleOnBlur = () => {
    setIsOpen(false)
    setSearch('')
  }

  const handleOnFocus = () => {
    setIsOpen(true)
  }

  return (
    <Controller
      name={name}
      defaultValue={multiple ? [] : null}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <FormControl error={invalid} {...layout?.formControl}>
          {label && <FormLabel>{label}</FormLabel>}

          {multiple && multipleType === 'external' && (
            <ExternalChipList<T>
              skeletonShow={skeletonShow}
              values={value || []}
              onChange={onChange}
              disabled={props?.readOnly}
            />
          )}

          <Box {...layout?.autocompleteBox}>
            <Autocomplete
              name={name}
              multiple={multiple}
              {...(multiple
                ? {
                    open: isOpen,
                    onBlur: handleOnBlur,
                    onFocus: handleOnFocus,
                    renderTags:
                      multipleType === 'internal'
                        ? (values: T[]) => (
                            <InternalChipList
                              values={values}
                              onChange={onChange}
                              disabled={props?.readOnly}
                            />
                          )
                        : () => null,
                  }
                : {})}
              value={multiple ? value || [] : value || null}
              size='lg'
              onChange={(_, value) => {
                onChange(value)
              }}
              clearOnBlur
              onInputChange={handleSearchSet}
              {...(multiple && { inputValue: search })}
              options={options}
              noOptionsText={
                <Typography color='warning'>Нет вариантов</Typography>
              }
              startDecorator={!props.readOnly && <SearchIcon />}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              slotProps={{
                ...(skeletonShow ? { root: { component: Skeleton } } : {}),
                listbox: {
                  component: Listbox,
                  params: {
                    onClick: () => {
                      onChange(
                        uniqBy([...(value || []), ...(options || [])], 'id'),
                      )
                    },
                    isSelectAll: multiple,
                  },
                },
              }}
              {...props}
              endDecorator={
                props.loading ? (
                  <CircularProgress size='sm' />
                ) : (
                  props.endDecorator
                )
              }
            />
          </Box>

          {invalid && (
            <FormHelperText
              data-testid={`error-message-${dataTestId || label}`}
            >
              {error?.message || ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
