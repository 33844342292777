import * as z from 'zod'
import { CarRental } from '~/shared/api'

export const formSchema = CarRental.schema.pick({
  name: true,
  fullName: true,
  inn: true,
  bank: true,
  kpp: true,
  ogrn: true,
  okpo: true,
  phoneNumber: true,
  email: true,
  webSite: true,
  legalAddress: true,
  actualAddress: true,
  rs: true,
  ks: true,
  bik: true,
  directorPosition: true,
  directorFio: true,
  directorPositionInParentalCase: true,
  directorFioInParentalCase: true,
  directorBasisForSigningDocuments: true,
  withNds: true,
})

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = TypeFormSchema
