import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputProps,
  Skeleton,
} from '@mui/joy'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import type { InputMaskElement, Definitions } from 'imask'
import React, { ChangeEvent, forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask'
import { Input } from '../ui/styled'

type MaskInputProps = {
  label?: string
  name: string
  skeletonShow?: boolean
  mask?: string
  definitions?: Definitions
} & Omit<InputProps, 'name' | 'slotProps'>

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: MaskInputProps['name']
  params: {
    mask?: MaskInputProps['mask']
    definitions?: MaskInputProps['definitions']
  }
}

const MaskAdapter = forwardRef<HTMLElement, CustomProps>(function Wrapper(
  { onChange, params, ...other },
  ref: InputMaskElement,
) {
  return (
    <IMaskInput
      {...other}
      mask={params.mask || '+7 (###) ###-##-##'}
      definitions={
        params.definitions || {
          '#': /[0-9]/,
        }
      }
      onInput={(value: ChangeEvent<HTMLInputElement>) => onChange(value)}
      inputRef={ref}
    />
  )
})

export function MaskInput({
  label,
  name,
  skeletonShow,
  mask,
  definitions,
  ...inputProps
}: MaskInputProps) {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error, invalid } }) => (
        <FormControl error={invalid}>
          {label && <FormLabel>{label}</FormLabel>}

          <Input
            fullWidth
            size='lg'
            {...inputProps}
            {...field}
            error={invalid}
            slotProps={{
              ...(skeletonShow ? { root: { component: Skeleton } } : {}),
              input: {
                component: MaskAdapter,
                params: {
                  mask,
                  definitions,
                },
              },
            }}
          />

          {invalid && (
            <FormHelperText data-testid={`error-message-${label}`}>
              {error?.message || ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
