import { attach, createDomain, sample } from 'effector'
import {
  appealModel,
  appealFormModel,
  SolutionFormValues,
} from '~/entities/Balance/Appeal'

import {
  Appeal,
  AppealUpdateByCreator,
  AxiosErrorType,
  ThumbType,
} from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const domain = createDomain('features.balanceHistory.appeal.view')

export const mainFormSubmitted =
  domain.createEvent<appealFormModel.FormValues>()

export const updateByCreatorFx = domain.createEffect<
  AppealUpdateByCreator & { appealId: UniqueId },
  void,
  AxiosErrorType
>({
  async handler(values) {
    const { appealId, ...data } = values
    await Appeal.updateByCreator(data, appealId)
  },
})

export const appealMainFormUpdateFx = attach({
  effect: updateByCreatorFx,
  source: appealModel.$appealId,
  mapParams: (values: appealFormModel.FormValues, appealId) => {
    const {
      amount,
      creatorComment,
      appealTypeOption,
      carOption,
      driverOption,
      subdivisionOption,
    } = values

    return {
      amount,
      creatorComment,
      carId: carOption as string,
      driverId: driverOption as string,
      appealTypeId: appealTypeOption as string,
      subdivisionId: subdivisionOption as string,
      appealId: String(appealId),
    }
  },
})

sample({
  clock: updateByCreatorFx.doneData,
  source: appealModel.$appealId,
  filter: isString,
  target: appealModel.requestSilentFx,
})

sample({
  clock: mainFormSubmitted,
  target: appealMainFormUpdateFx,
})

sample({
  clock: updateByCreatorFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: appealModel.requestSilentFx.doneData,
  target: formButtonsModel.editingEnded,
})

sample({
  clock: updateByCreatorFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Solution form Submitted
export const updateByResponsibleFx = domain.createEffect<
  SolutionFormValues & { appealId: UniqueId },
  void,
  AxiosErrorType
>({
  async handler(values) {
    const { responsibleComment, appealId } = values

    await Appeal.updateByResponsible(responsibleComment, appealId)
  },
})

export const solutionFormSubmitted = domain.createEvent<SolutionFormValues>()
export const appealSolutionFormUpdateFx = attach({
  effect: updateByResponsibleFx,
  source: appealModel.$appealId,
  mapParams: (values: SolutionFormValues, appealId) => ({
    ...values,
    appealId: String(appealId),
  }),
})

sample({
  clock: solutionFormSubmitted,
  target: appealSolutionFormUpdateFx,
})

sample({
  clock: appealSolutionFormUpdateFx.doneData,
  fn() {
    return {
      message: 'Комментарий успешно сохранён',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: appealSolutionFormUpdateFx.doneData,
  source: appealModel.$appealId,
  filter: isString,
  target: appealModel.requestSilentFx,
})

sample({
  clock: appealSolutionFormUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Files
export const saveFiles = domain.createEvent<File[]>()
export const saveFilesFx = domain.createEffect<
  { appealId: UniqueId; files: File[] },
  void,
  AxiosErrorType
>({
  async handler({ appealId, files }) {
    for (const file of files) {
      await Appeal.saveFiles(file, appealId)
    }
  },
})

sample({
  clock: saveFiles,
  source: appealModel.$appealId,
  fn: (appealId, files) => ({
    appealId: appealId as string,
    files,
  }),
  target: saveFilesFx,
})

sample({
  clock: saveFilesFx.doneData,
  source: appealModel.$appealId,
  filter: isString,
  target: appealModel.requestSilentFx,
})

sample({
  clock: saveFilesFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const deleteFile = domain.createEvent<UniqueId>()
const deleteFileFx = domain.createEffect<
  { appealId: UniqueId; fileId: UniqueId },
  UniqueId,
  AxiosErrorType
>({
  async handler({ appealId, fileId }) {
    await Appeal.deleteFile(appealId, fileId)
    return fileId
  },
})

export const $pendingFileList = domain
  .createStore<UniqueId[]>([])
  .on(deleteFile, (state, fileId) => [...state, fileId])
  .on([deleteFileFx.done, deleteFileFx.fail], (state, { params }) =>
    state.filter((fileId) => fileId !== params.fileId),
  )

sample({
  clock: deleteFile,
  source: appealModel.$appealId,
  fn(appealId, fileId) {
    return { appealId: String(appealId), fileId }
  },
  target: deleteFileFx,
})

sample({
  clock: deleteFileFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $fileList = domain
  .createStore<ThumbType[]>([])
  .on(appealModel.$appeal, (store, appeal) =>
    (appeal?.getFiles() || []).map((file) => ({
      id: file.id,
      src: file.original_url,
      preview: file.preview_url,
    })),
  )
  .on($pendingFileList, (files, pendingFileList) =>
    files?.map((file) =>
      pendingFileList.includes(file.id)
        ? { ...file, pending: true }
        : { ...file, pending: false },
    ),
  )
  .on(deleteFileFx.doneData, (files, deleteId) =>
    files.filter((file) => file.id !== deleteId),
  )
