import { ListCarLicensees } from '~/features/Organization/CarLicensees/ListCarLicensees'
import { Container } from '~/shared/ui/Layout'

export function ListCarLicenseePage() {
  return (
    <Container>
      <ListCarLicensees />
    </Container>
  )
}
