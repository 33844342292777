import { attach, combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { subdivisionModel } from '~/entities/Taxipark/Subdivisions'
import { FormValues } from '~/entities/Taxipark/Subdivisions/model'
import { Subdivision } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ subdivisionId: UniqueId }>()
export const domain = createDomain('features.taxipark.subdivision.edit')

export const $subdivisionId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { subdivisionId }) => subdivisionId)

export const $subdivision = combine(
  $subdivisionId,
  subdivisionModel.$subdivisionCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $subdivisionId,
  filter: isString,
  target: subdivisionModel.requestFx,
})

export const subdivisionUpdateFx = attach({
  effect: subdivisionModel.saveFx,
  source: $subdivisionId,
  mapParams: (values: FormValues, id) => {
    const { regionId, ...attr } = values

    const subdivision = new Subdivision(attr, id as string)

    if (isString(regionId)) subdivision.setRegion(regionId)

    return subdivision
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: subdivisionUpdateFx,
})

sample({
  clock: subdivisionUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: subdivisionUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
