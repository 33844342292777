import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { FormValues } from '~/entities/Taxipark/Regions/model'
import { AxiosErrorType, Region } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.taxipark.regions.add')
export const Gate = createGate<{ id: UniqueId }>()

export const createFx = domain.createEffect<FormValues, Region, AxiosErrorType>(
  {
    async handler(values) {
      const { name, timezoneId } = values
      const region = new Region({ name })

      if (isString(timezoneId)) region.setTimezone(timezoneId)

      const result = await region.save()
      return result.getModel() as Region
    },
  },
)

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Регион создан',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})

sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
