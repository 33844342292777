import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { AxiosErrorType, Role } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const domain = createDomain('entities.taxipark.role')

export const requestFx = domain.createEffect<UniqueId, Role>({
  handler: fetchRoleWithRelations,
})

export const saveFx = domain.createEffect<Role, Role, AxiosErrorType>({
  async handler(role) {
    await role.save()
    return fetchRoleWithRelations(role.getApiId() as UniqueId)
  },
})

const {
  $cache: $rolesCache,
  useCache: useRoleCache,
  updateCache,
} = createCache<Role>({
  domain,
  getEntityId: (role) => role.getApiId() as UniqueId,
})
export { $rolesCache, useRoleCache }

$rolesCache
  .on(requestFx.doneData, (cache, role) => updateCache(cache, [role]))
  .on(saveFx.doneData, (cache, role) => updateCache(cache, [role], true))

export const $rolesError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useRoleError = (id: UniqueId) =>
  useStoreMap($rolesError, (errors) => errors[id])

async function fetchRoleWithRelations(id: UniqueId) {
  const response = await Role.with('permissions').find(id)
  return response.getData() as Role
}
