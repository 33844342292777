import { GridColDef } from '@mui/x-data-grid'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Наименование',
    field: 'title',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.title}</ColumnLink>,
  },
]
