import { usePermissions } from '~/entities/viewer'
import { canShowObject } from '~/shared/lib/utils'
import { Tabs } from '~/shared/ui/RoutesTabs/type'
import { EditAggregatorAccountPage } from './AggregatorAccounts/EditAggregatorAccountPage'
import { ListAggregatorAccountPage } from './AggregatorAccounts/ListAggregatorAccountPage'
import { AddEmployeePage } from './Employees/AddEmployeePage'
import { EditEmployeePage } from './Employees/EditEmployeePage'
import { ListEmployeePage } from './Employees/ListEmployeePage'
import { InformationPage } from './InformationPage/InformationPage'
import { AddRegionPage } from './Regions/AddRegionPage'
import { EditRegionPage } from './Regions/EditRegionPage'
import { ListRegionPage } from './Regions/ListRegionsPage'
import { AddRolePage } from './Roles/AddRolePage'
import { EditRolePage } from './Roles/EditRolePage'
import { ListRolePage } from './Roles/ListRolePage'
import { AddSubdivisionPage } from './Subdivisions/AddSubdivisionPage'
import { EditSubdivisionPage } from './Subdivisions/EditSubdivisionPage'
import { ListSubdivisionsPage } from './Subdivisions/ListSubdivisionsPage'

export const useConfigTabs = (): Tabs => {
  const {
    canTaxiparkView,
    canUsersView,
    canRegionsView,
    canSubdivisionsView,
    canRolesView,
    canAggregatorAccountsView,
  } = usePermissions()

  return [
    ...canShowObject(canTaxiparkView, {
      label: 'Информация',
      path: '/information',
      content: <InformationPage />,
    }),
    ...canShowObject(canUsersView, {
      label: 'Сотрудники',
      path: '/employees',
      content: <ListEmployeePage />,
      children: [
        {
          path: '/new',
          content: <AddEmployeePage />,
          full: true,
        },
        {
          path: '/:employeeId',
          content: <EditEmployeePage />,
          full: true,
        },
      ],
    }),
    ...canShowObject(canRegionsView, {
      label: 'Регионы',
      path: '/regions',
      content: <ListRegionPage />,
      children: [
        {
          path: '/new',
          content: <AddRegionPage />,
          full: true,
        },
        {
          path: '/:regionId',
          content: <EditRegionPage />,
          full: true,
        },
      ],
    }),
    ...canShowObject(canSubdivisionsView, {
      label: 'Подразделения',
      path: '/subdivisions',
      content: <ListSubdivisionsPage />,
      children: [
        {
          path: '/new',
          content: <AddSubdivisionPage />,
          full: true,
        },
        {
          path: '/:subdivisionId',
          content: <EditSubdivisionPage />,
          full: true,
        },
      ],
    }),
    ...canShowObject(canRolesView, {
      label: 'Роли',
      path: '/roles',
      content: <ListRolePage />,
      children: [
        {
          path: '/new',
          content: <AddRolePage />,
          full: true,
        },
        {
          path: '/:roleId',
          content: <EditRolePage />,
          full: true,
        },
      ],
    }),
    ...canShowObject(canAggregatorAccountsView, {
      label: 'Аккаунты агрегаторов',
      path: '/aggregator-accounts',
      content: <ListAggregatorAccountPage />,
      children: [
        {
          path: '/:aggregatorAccountId',
          content: <EditAggregatorAccountPage />,
          full: true,
        },
      ],
    }),
  ]
}
