import { Stack } from '@mui/joy'
import { usePermissions } from '~/entities/viewer'
import { Button } from '~/shared/ui/Buttons'
import { DownloadIcon, UploadWhiteIcon } from '~/shared/ui/Icons'
import { BalanceHistoryExport } from '../BalanceHistoryExport'

type ActionButtonsProps = {
  defaultFilters?: Record<string, string>
  exportFn: (search: string) => Promise<Blob>
  canComing?: boolean
  canSpending?: boolean
  canMassImport?: boolean
  parentPath?: string
}

export const ActionButtons = ({
  defaultFilters,
  exportFn,
  canComing = true,
  canSpending = true,
  canMassImport = false,
  parentPath,
}: ActionButtonsProps) => {
  const { canBalancesExport, canMassImportTransactions, canBalancesUpdate } =
    usePermissions()

  return (
    <Stack
      direction='row'
      alignItems='flex-start'
      justifyContent='space-between'
      spacing={1}
    >
      {canBalancesExport && (
        <BalanceHistoryExport
          exportFn={exportFn}
          defaultFilters={defaultFilters}
        />
      )}

      <Button
        show={canSpending && canBalancesUpdate}
        to={parentPath ? `${parentPath}/new-spending` : 'new-spending'}
        variant='red'
        startDecorator={<UploadWhiteIcon />}
      >
        Списать
      </Button>

      <Button
        show={canComing && canBalancesUpdate}
        to={parentPath ? `${parentPath}/new-coming` : 'new-coming'}
        variant='green'
        startDecorator={<DownloadIcon />}
      >
        Пополнить
      </Button>

      <Button
        show={canMassImport && canMassImportTransactions}
        to={parentPath ? `${parentPath}/bulk` : 'bulk'}
        variant='brand'
      >
        Массовые транзакции
      </Button>
    </Stack>
  )
}
