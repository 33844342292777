import { Grid } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import { Documents, MainForm } from '~/entities/Balance/Appeal'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { formSubmitted, createFx, setFiles, Gate, $files } from './model'

export function AddAppeal() {
  useGate(Gate)

  const isSubmitting = useStore(createFx.pending)
  const files = useStore($files)

  return (
    <MainForm
      onSuccess={formSubmitted}
      isSubmitting={isSubmitting}
      isCreating
      title={<ContainerTitle>Основная информация</ContainerTitle>}
    >
      <Container>
        <Grid container spacing={2.5}>
          <ContainerTitle>Файлы</ContainerTitle>
          <Grid xs={12}>
            <Documents
              isShowUploadFiles
              onUploadFiles={setFiles}
              isSubmitting={isSubmitting}
              files={files || []}
            />
          </Grid>
        </Grid>
      </Container>
    </MainForm>
  )
}
