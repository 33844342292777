import { Grid, Box, Skeleton } from '@mui/joy'
import { useStore } from 'effector-react'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Alert } from '~/shared/ui/Alerts'
import { Dropzone } from '~/shared/ui/Dropzone'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import {
  $importId,
  $isTransactionsError,
  $totalAmount,
  getTotalAmountFx,
  massImport,
  massImportFx,
  massImportSave,
  massImportSaveFx,
} from './model'
import { Form, formModel } from './ui/Form'
import { List } from './ui/List'

export function BulkCreateTransactions() {
  const masImportPending = useStore(massImportFx.pending)
  const totalAmountPending = useStore(getTotalAmountFx.pending)
  const isSubmitting = useStore(massImportSaveFx.pending)
  const isTransactionsError = useStore($isTransactionsError)

  const importId = useStore($importId)
  const totalAmount = useStore($totalAmount)

  const handleMassImport = (files: File[]) => {
    massImport(files[0])
  }

  const handleMassImportSave = (formValues: formModel.FormValues) => {
    if (!importId) {
      snackbarEnqueued({
        message: 'Загрузите файл с транзакциями',
        variant: 'error',
      })
      return
    }
    if (isTransactionsError) {
      snackbarEnqueued({
        message:
          'Обнаружены строчки, по которым нельзя создать транзакцию. Исправьте данные в исходном файле и повторите загрузку всего списка',
        variant: 'error',
      })
      return
    }
    massImportSave(formValues)
  }

  return (
    <>
      <Form onSuccess={handleMassImportSave} isSubmitting={isSubmitting}>
        <Container>
          <Grid xs={12}>
            <ContainerTitle>Файл</ContainerTitle>
            <Dropzone
              isSubmitting={masImportPending}
              accept={{
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  [],
                'application/vnd.ms-excel': [],
              }}
              onDropAccepted={handleMassImport}
              inputProps={{ name: 'massImport' }}
              maxFiles={1}
              canDelete
              canCreate
            />
          </Grid>
        </Container>

        {!masImportPending && isTransactionsError && (
          <Container>
            <Grid xs={12}>
              <Alert variant='error'>
                Обнаружены строчки, по которым нельзя создать транзакцию.
                Исправьте данные в исходном файле и повторите загрузку всего
                списка
              </Alert>
            </Grid>
          </Container>
        )}
        {!masImportPending && !importId && (
          <Container>
            <Grid xs={12}>
              <Alert variant='info'>
                Загрузите файл для просмотра транзакций
              </Alert>
            </Grid>
          </Container>
        )}

        <Container>
          <List importId={importId} />
        </Container>

        <Container>
          <Box
            sx={{
              display: 'flex',
              fontWeight: 600,
            }}
          >
            Общая сумма:{' '}
            <>
              {totalAmountPending ? (
                <Skeleton
                  sx={{
                    width: '100px',
                    marginLeft: '8px',
                    height: '24px',
                    position: 'relative',
                    borderRadius: '12px',
                  }}
                />
              ) : (
                Number(totalAmount || 0).toLocaleString()
              )}
            </>
          </Box>
        </Container>
      </Form>
    </>
  )
}
