import { zodResolver } from '@hookform/resolvers/zod'
import { Accordion, AccordionDetails, AccordionGroup, Grid } from '@mui/joy'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button } from '~/shared/ui/Buttons'
import { FormProvider, TextareaInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { SolutionFormValues } from './type'

const formSchema = z.object({
  responsibleComment: z.string().min(1, 'Обязательное поле'),
})

type SolutionFormProps = {
  canEdit: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  defaultValues?: Partial<SolutionFormValues>
  onSuccess: (formValues: SolutionFormValues) => void
}

export function SolutionForm({
  canEdit,
  isLoading,
  defaultValues,
  onSuccess,
  isSubmitting,
}: SolutionFormProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const disabled = !isEditing || isSubmitting

  const form = useForm<SolutionFormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  })
  const { reset } = form

  const handleButtonsShow = () => {
    if (!canEdit) return null
    setIsEditing(true)
  }

  const handleButtonsHidden = () => {
    setIsEditing(false)
  }

  const handleCancel = () => {
    reset()
    handleButtonsHidden()
  }

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  useEffect(() => {
    if (!isSubmitting) handleButtonsHidden()
  }, [isSubmitting])

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Container onDoubleClick={handleButtonsShow}>
        <ContainerTitle>Решение</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={12}>
            <TextInput
              label='Ответственный'
              placeholder='Ответственный'
              name='responsible'
              readOnly
              skeletonShow={isLoading}
            />
          </Grid>
          <Grid xs={12}>
            <TextareaInput
              label='Комментарий'
              name='responsibleComment'
              placeholder='Введите комментарий'
              skeletonShow={isLoading}
              readOnly={disabled}
              sx={{
                height: '138px',
              }}
            />
          </Grid>

          <AccordionGroup>
            <Accordion expanded={isEditing} sx={{ minHeight: 0, padding: 0 }}>
              <AccordionDetails>
                <Grid xs={12} direction='row'>
                  <Button
                    variant='green'
                    type='submit'
                    loading={isSubmitting}
                    sx={{ marginRight: '24px' }}
                  >
                    Сохранить
                  </Button>
                  <Button
                    variant='red'
                    onClick={handleCancel}
                    disabled={isSubmitting}
                  >
                    Отменить
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </AccordionGroup>
        </Grid>
      </Container>
    </FormProvider>
  )
}
