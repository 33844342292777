import {
  QueryParamConfigMap,
  StringParam,
  ObjectParam,
  BooleanParam,
  useQueryParams,
  JsonParam,
} from 'use-query-params'

import {
  decodeArrayParams,
  decodeObjectParam,
  encodeArrayParams,
} from '~/shared/lib/utils'
import { Filter, FilterType } from './types'

const mapQueryParamTypeToFilterType: Record<
  FilterType,
  | typeof StringParam
  | typeof ObjectParam
  | typeof BooleanParam
  | typeof JsonParam
> = {
  text: StringParam,
  price: StringParam,
  boolean: BooleanParam,
  date: StringParam,
  dateRange: StringParam,
  select: {
    ...ObjectParam,
    decode: decodeObjectParam,
  },
  selectMultiple: {
    ...JsonParam,
    encode: encodeArrayParams,
    decode: decodeArrayParams,
  },
  autocompleteMultiple: {
    ...JsonParam,
    encode: encodeArrayParams,
    decode: decodeArrayParams,
  },
  autocomplete: {
    ...ObjectParam,
    decode: decodeObjectParam,
  },
}

export function useFiltersQueryParams(filters: Filter[]) {
  const queryParamConfigMap: QueryParamConfigMap = Object.fromEntries(
    filters.flatMap((filter) =>
      filter.type === 'dateRange'
        ? [
            [filter.startField, mapQueryParamTypeToFilterType[filter.type]],
            [filter.endField, mapQueryParamTypeToFilterType[filter.type]],
          ]
        : [[filter.field, mapQueryParamTypeToFilterType[filter.type]]],
    ),
  )
  return useQueryParams(queryParamConfigMap)
}
