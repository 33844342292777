import { useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { Element } from 'react-scroll'
import { usePermissions } from '~/entities/viewer'
import { DriverDocumentType } from '~/shared/config/enums'
import { Dropzone, DropzoneProps } from '~/shared/ui/Dropzone'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import {
  documentDeleted,
  fileDropped,
  $thumbsByType,
  Gate,
  $isInitialLoading,
  $savingDocumentsCount,
} from './model'

type Props = {
  driverId: UniqueId
}

export function Documents(props: Props) {
  const { canDriversUpdate } = usePermissions()
  useGate(Gate, props)

  const thumbsByType = useStore($thumbsByType)
  const passport = useMemo(
    () => thumbsByType[DriverDocumentType.PASSPORT],
    [thumbsByType],
  )

  return (
    <Element name='documents'>
      <Container>
        <ContainerTitle>Паспорт</ContainerTitle>
        <TypedDropzone
          canDelete={canDriversUpdate}
          canCreate={canDriversUpdate}
          documentType={DriverDocumentType.PASSPORT}
          thumbs={passport}
        />
      </Container>

      <Container>
        <ContainerTitle>Водительское удостоверение</ContainerTitle>
        <TypedDropzone
          canDelete={canDriversUpdate}
          canCreate={canDriversUpdate}
          documentType={DriverDocumentType.LICENSE}
          thumbs={thumbsByType[DriverDocumentType.LICENSE]}
        />
      </Container>
      <Container>
        <ContainerTitle>Регистрация</ContainerTitle>
        <TypedDropzone
          canDelete={canDriversUpdate}
          canCreate={canDriversUpdate}
          documentType={DriverDocumentType.REGISTRATION}
          thumbs={thumbsByType[DriverDocumentType.REGISTRATION]}
        />
      </Container>
    </Element>
  )
}

function TypedDropzone({
  documentType,
  ...props
}: { documentType: DriverDocumentType } & Omit<DropzoneProps, 'onDelete'>) {
  const isInitialLoading = useStore($isInitialLoading)
  const savingDocumentsCount = useStore($savingDocumentsCount)
  return (
    <Dropzone
      isLoading={isInitialLoading}
      isSubmitting={savingDocumentsCount[documentType] > 0}
      {...props}
      accept={{
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': [],
        'application/pdf': [],
      }}
      onDropAccepted={(files) => {
        files.map((file) => fileDropped({ documentType, file }))
      }}
      onDelete={(id) => {
        documentDeleted(id)
      }}
      testId='driver-documents'
    />
  )
}
