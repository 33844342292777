import { ThumbType, FileType } from '~/shared/api'
import { Option } from '~/shared/config/constants'

export const identity = <ValueType>(value: ValueType): ValueType => value

type TimesIterateeFn<T> = (n: number) => T
export const times = <T = number>(
  n: number,
  iteratee: TimesIterateeFn<T> = (arg) => arg as T,
) => [...Array(n).keys()].map((n) => iteratee(n))

export const isString = (arg: unknown): arg is string => typeof arg === 'string'
export const isNumber = (arg: unknown): arg is number => typeof arg === 'number'

export const isObject = (arg: unknown): arg is object =>
  typeof arg === 'object' && arg !== null && !Array.isArray(arg)

export const isEmptyObject = (arg: unknown) =>
  isObject(arg) &&
  Object.entries(arg).filter(([, v]) => typeof v !== 'undefined').length === 0

export const getObject = <T>(arg: T) => (isObject(arg) ? arg : undefined)

export const sanitizePhoneNumber = (phone: string) =>
  phone.replace(/[(),\-_\s]/g, '')

export const cutStringBefore = (str: string, char: string) =>
  str.replace(new RegExp(`.*?${char}(.*)`), '$1')

export const cutStringAfter = (str: string, char: string) => str.split(char)[0]

export const sanitizeStringNumber = (price: string | number) =>
  parseFloat(String(price).replaceAll(' ', '').replace(',', '.'))

export const priceFormat = (price: number) => {
  return price.toLocaleString('ru-RU', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
}

export const encodeArrayParams = (options: Option[]) => {
  return (options || [])
    .map((option) => `id-${option.id}_label-${option.label}`)
    .join(',')
}

export const decodeArrayParams = (
  str: string | (string | null)[] | null | undefined,
) => {
  if (!isString(str)) return ''

  return (str || '')
    .split(/(?=id-)/)
    .map((item) => item.replace(/,$/, ''))
    .filter(Boolean)
    .map(decodeObjectParam)
}

export const decodeObjectParam = (
  value: string | (string | null)[] | null | undefined,
) => {
  if (!isString(value) || !value.startsWith('id-')) return undefined

  const obj = {
    id: cutStringBefore(cutStringAfter(value, '_label-'), 'id-'),
    label: cutStringBefore(value, '_label-'),
  }
  return obj.id ? obj : undefined
}

export const mapSearchParamsToFilters = (searchParams: URLSearchParams) => {
  return Object.fromEntries(
    [...searchParams]
      .map(([key, value]) => [
        key,
        Array.isArray(value)
          ? value.map(decodeObjectParam).filter(Boolean)
          : decodeObjectParam(value)?.id || value,
      ])
      .filter(([, value]) => Boolean(value)),
  )
}

export const canShowObject = <T extends object>(isShow: boolean, object: T) =>
  isShow ? [object] : []

export const mapDocumentFileToThumbs = (
  files: FileType[] | null | undefined,
): ThumbType[] =>
  (files || []).map((file) => ({
    id: file.id,
    src: file.filepath,
    preview: file.preview,
  }))

export function getDeclension(
  number: number,
  declensions: [string, string, string],
) {
  number = Math.abs(number) % 100
  const lastDigit = number % 10
  const lastTwoDigits = number % 100

  if (lastTwoDigits > 10 && lastTwoDigits < 20) {
    return declensions[2]
  }

  if (lastDigit === 1) {
    return declensions[0]
  }

  if (lastDigit > 1 && lastDigit < 5) {
    return declensions[1]
  }

  return declensions[2]
}
