import { ReactNode } from 'react'
import { ThumbType } from '~/shared/api'
import { Dropzone } from '~/shared/ui/Dropzone'

type DocumentsProps = {
  canEditDocuments?: boolean
  isShowUploadFiles?: boolean
  onDropAccepted?: (files: File[]) => void
  onUploadFiles?: (files: File[]) => void
  onDelete?: (id: UniqueId) => void
  thumbs?: ThumbType[]
  isSubmitting: boolean
  isLoading?: boolean
  files?: File[]
  empty?: ReactNode
}

export function Documents({
  canEditDocuments = true,
  isShowUploadFiles,
  onDropAccepted,
  onUploadFiles,
  onDelete,
  thumbs,
  isSubmitting,
  isLoading,
  files,
  empty,
}: DocumentsProps) {
  return (
    <Dropzone
      isSubmitting={isSubmitting}
      isLoading={isLoading}
      accept={{
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': [],
        'application/pdf': [],
      }}
      onDropAccepted={onDropAccepted}
      onUploadFiles={onUploadFiles}
      onDelete={onDelete}
      canCreate={canEditDocuments}
      canDelete={canEditDocuments}
      thumbs={thumbs}
      isShowUploadFiles={isShowUploadFiles}
      files={files}
      empty={empty}
      testId='appeal-dropzone'
    />
  )
}
