import { useParams } from 'react-router-dom'
import RoutesTabs from '~/shared/ui/RoutesTabs'
import { useConfigTabs } from './useConfigTabs'

export function BalancePage() {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  const configTabs = useConfigTabs()

  return (
    <RoutesTabs
      parentPath={`/drivers/${driverId}/balance`}
      config={configTabs}
    />
  )
}
