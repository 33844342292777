import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
  FormValues,
  aggregatorAccountModel,
} from '~/entities/Taxipark/AggregatorAccount'
import { YandexWorkRule } from '~/shared/api'
import {
  AsyncAutocompleteInput,
  FormButtons,
  formButtonsModel,
  FormProvider,
  NumericInput,
  Switch,
  TextInput,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

export type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(aggregatorAccountModel.formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <ContainerTitle>Информация</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <TextInput
              label='Наименование'
              name='title'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={6}>
            <TextInput
              label='Код'
              name='aggregatorCode'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <ContainerTitle>Настройки</ContainerTitle>
        <Grid container spacing={2.5}>
          <Grid xs={12}>
            <AsyncAutocompleteInput
              label='Условия работы'
              name='defaultYandexWorkRuleOption'
              fetchOptions={YandexWorkRule.fetchOptionsWithAccount}
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={12}>
            <NumericInput
              label='Лимит по счёту'
              name='defaultBalanceLimit'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={4}>
            <Switch
              label='Запретить заказы при балансе ниже лимита'
              name='driverBlockOrdersOnBalanceBelowLimit'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={4}>
            <Switch
              label='Доступны ли заказы от партнера'
              name='defaultPartner'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={4}>
            <Switch
              label='Доступны ли заказы от платформы'
              name='defaultPlatform'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>
      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
