import { RentalContract } from '~/shared/api'
import { FileExportBlob } from '~/shared/ui/FileExport'

type PrintProps = {
  id: UniqueId
}

export function Print({ id }: PrintProps) {
  const fn = async () => {
    return await RentalContract.fetchFile(id)
  }

  return (
    <FileExportBlob
      target='_blank'
      fn={fn}
      isIcon
      title='Распечатать договор'
    />
  )
}
