import { combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import sortBy from 'lodash/sortBy'
import { interval, delay } from 'patronum'

import { driverModel } from 'src/entities/Driver'
import { AxiosErrorType, Check, Driver } from '~/shared/api'
import { SbResults } from '~/shared/api/check'
import { CheckKindEnum, CheckStatusEnum } from '~/shared/config/enums'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.driver.checks')
export const Gate = createGate<{ driverId: UniqueId }>()

export const $driverId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { driverId }) => driverId)
  .on(Gate.close, () => null)

export const requestLatestChecksFx = domain.createEffect<UniqueId, Check[]>({
  async handler(driverId) {
    const driver = new Driver()
    driver.setApiId(driverId)
    const response = await driver.latestChecks().get(1)
    return response.getData()
  },
})

export const saveCheckFx = domain.createEffect<
  { check: Check; driverId: UniqueId },
  Check,
  AxiosErrorType
>({
  async handler({ check, driverId }) {
    const newCheck = new Check(check.getAttributes())
    newCheck.setDriver(driverId)
    const response = await newCheck.save()
    return response.getModel() as Check
  },
})

export const $checks = domain
  .createStore<Check[] | null>(null)
  .on(requestLatestChecksFx.doneData, (_, response) => response)
  .on(Gate.close, () => null)

export const $sortedChecks = $checks.map((checks) =>
  sortBy(checks ?? [], (c) => c.getKind()),
)
export const $autoChecks = $sortedChecks.map((checks) =>
  checks.filter((check) => check.isAuto()),
)
export const $sbCheck = combine(
  $sortedChecks,
  $driverId,
  (checks, driverId) => {
    const created = checks.find((check) => check.getKind() === CheckKindEnum.SB)
    if (created) return created

    const check = new Check({
      kind: CheckKindEnum.SB,
    })
    const driver = new Driver()
    driver.setApiId(driverId as string)
    check.setRelation('driver', driver)
    return check
  },
)

export const $isInitialLoading = combine(
  requestLatestChecksFx.pending,
  $checks,
  (pending, checks) => pending && !Array.isArray(checks),
)

const intervalRequestStarted = domain.createEvent()
const intervalRequestStopped = domain.createEvent()
const { tick } = interval({
  timeout: 4000,
  start: intervalRequestStarted,
  stop: intervalRequestStopped,
  leading: true,
  trailing: false,
})
sample({
  clock: tick,
  source: $driverId,
  filter: isString,
  target: requestLatestChecksFx,
})
sample({
  clock: Gate.open,
  target: intervalRequestStarted,
})
sample({
  clock: Gate.close,
  target: intervalRequestStopped,
})
sample({
  clock: requestLatestChecksFx.doneData,
  filter: (checks) => {
    const autoChecks = checks.filter((check) => check.isAuto())
    return autoChecks.length > 0 && autoChecks.every((check) => check.isEnded())
  },
  target: intervalRequestStopped,
})

// TODO: переделать без delay
delay({
  source: driverModel.cancelAllChecksFx.doneData,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  target: intervalRequestStarted,
  timeout: 5000,
})
delay({
  source: driverModel.retryAllChecksFx.doneData,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  target: intervalRequestStarted,
  timeout: 5000,
})

export const sbFormSubmitted = domain.createEvent<SbResults>()
sample({
  clock: sbFormSubmitted,
  source: { check: $sbCheck, driverId: $driverId },
  fn({ check, driverId }, results) {
    check.setResults(results)
    check.setStatus(CheckStatusEnum.SUCCESS)
    return { check, driverId: String(driverId) }
  },
  target: saveCheckFx,
})

sample({
  clock: saveCheckFx.doneData,
  source: $driverId,
  filter: isString,
  target: requestLatestChecksFx,
})
sample({
  clock: saveCheckFx.doneData,
  fn() {
    return {
      message: 'Данные сохранены',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: saveCheckFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const $checksNotEmpty = $checks.map(
  (checks) => checks && checks?.length > 0,
)
export const $everyCheckEnded = $checks.map((checks) =>
  checks?.every((check) => check.isEnded()),
)
export const $checksNotEmptyAndEnded = combine(
  $checksNotEmpty,
  $everyCheckEnded,
  (checksNotEmpty, everyCheckEnded) => checksNotEmpty && everyCheckEnded,
)
