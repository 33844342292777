import { Box, Typography } from '@mui/joy'
import { useStore } from 'effector-react'
import { LoginForm, loginFormModel } from '~/features/Auth/by-credentials'
import { viewerModel } from '~/entities/viewer'
import { Button } from '~/shared/ui/Buttons'
import { Container } from '~/shared/ui/Layout'

export function LoginPage() {
  const authenticated = viewerModel.useAuthenticated()
  const isLoginPending = useStore(loginFormModel.loginFx.pending)
  const isMePending = useStore(loginFormModel.getUserMeFx.pending)

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Container width='480px'>
        {authenticated && !isLoginPending && !isMePending ? (
          <>
            <Typography textAlign='center'>Вы уже авторизованы</Typography>
            <br />
            <Button size='lg' onClick={() => viewerModel.signOut()}>
              Выйти
            </Button>
          </>
        ) : (
          <>
            <Box>
              <Typography
                fontSize={18}
                fontWeight={600}
                textAlign='center'
                sx={{ mb: '40px' }}
              >
                Вход
              </Typography>
            </Box>
            <LoginForm />
          </>
        )}
      </Container>
    </Box>
  )
}
