import { Grid } from '@mui/joy'
import { useMemo } from 'react'
import { constants } from '~/shared/config'
import { AutocompleteInput, DatePickerInput, TextInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type DrivingLicenseProps = {
  disabled?: boolean
  skeletonShow?: boolean
}

export function DrivingLicense({
  disabled,
  skeletonShow,
}: DrivingLicenseProps) {
  const countries = useMemo(
    () =>
      Object.values(constants.countries).map((value) => ({
        id: value,
        label: value,
      })),
    [],
  )
  return (
    <Container data-testid='driving-license-form-container'>
      <ContainerTitle>Водительское удостоверение</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <AutocompleteInput
            label='Страна выдачи'
            placeholder='Выберите страну выдачи'
            name='drivingLicenseIssuingCountry'
            options={countries}
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Номер'
            placeholder='Введите номер'
            name='drivingLicenseNumber'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Дата выдачи'
            placeholder='Введите дату выдачи'
            name='drivingLicenseIssueDate'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Дата начала водительского стажа'
            placeholder='Введите дата начала водительского стажа'
            name='drivingExperienceStartDate'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Дата окончания'
            placeholder='Введите дата окончания'
            name='drivingLicenseExpirationDate'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
