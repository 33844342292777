import { createDomain, sample, combine } from 'effector'
import { createGate } from 'effector-react'
import { carFormModel, carModel } from 'src/entities/Car'
import { AxiosErrorType, Car } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'
import { underRepairModel } from './UnderRepair'

export const domain = createDomain('features.car.edit')
export const Gate = createGate<{ id: UniqueId }>()

export const $id = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { id }) => id)

export const $car = combine($id, carModel.$carsCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

sample({
  clock: $id,
  filter: isString,
  target: carModel.requestFx,
})

sample({
  clock: Gate.open,
  source: { id: $id, car: $car },
  filter: ({ id, car }) => Boolean(id && car && id === car.getApiId()),
  fn: ({ id }) => String(id),
  target: carModel.requestSilentFx,
})

export const sendFormFx = domain.createEffect<
  { formValues: carFormModel.FormValues; id: UniqueId },
  Car,
  AxiosErrorType
>({
  async handler({ formValues, id }) {
    const {
      colorId,
      modelId,
      subdivisionId,
      vehicleCategoryId,
      carRentalId,
      regionId,
      aggregatorAccountOption,
      telematicAccountOption,
      fuelType,
      transmission,
      ...attrs
    } = formValues

    const car = new Car(
      {
        ...attrs,
        ...(isString(fuelType) && { fuelType }),
        ...(isString(transmission) && { transmission }),
      },
      id as string,
    )

    if (isString(colorId)) car.setColor(colorId)
    if (isString(modelId)) car.setCarModel(modelId)
    if (isString(subdivisionId)) car.setSubdivision(subdivisionId)
    if (isString(vehicleCategoryId)) car.setVehicleCategory(vehicleCategoryId)
    if (isString(carRentalId)) car.setCarRental(carRentalId)
    if (isString(regionId)) car.setRegion(regionId)
    if (isString(aggregatorAccountOption)) {
      car.setAggregatorAccount(aggregatorAccountOption)
    }
    isString(telematicAccountOption)
      ? car.setTelematicAccount(telematicAccountOption)
      : await car.detachTelematicAccount()

    await car.save()
    const result = await carModel.fetchCarWithRelations(id)
    return result as Car
  },
})

export const sendUnderRepairFormFx = domain.createEffect<
  { formValues: underRepairModel.FormValues; id: UniqueId; fn: () => void },
  Car,
  AxiosErrorType
>({
  async handler({ formValues, id, fn }) {
    const car = new Car(formValues, id as string)

    await car.save()
    const result = await carModel.fetchCarWithRelations(id)
    fn()
    return result as Car
  },
})

carModel.$carsCache.on(
  [sendFormFx.doneData, sendUnderRepairFormFx.doneData],
  (cache, car) => carModel.updateCache(cache, [car], true),
)

sample({
  clock: [sendFormFx.doneData, sendUnderRepairFormFx.doneData],
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})
sample({
  clock: [sendFormFx.failData, sendUnderRepairFormFx.failData],
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const formSubmitted = domain.createEvent<carFormModel.FormValues>()
sample({
  clock: formSubmitted,
  source: $id,
  filter: isString,
  fn: (id, formValues) => ({ id, formValues }),
  target: sendFormFx,
})

export const formUnderRepairSubmitted = domain.createEvent<{
  formValues: underRepairModel.FormValues
  fn: () => void
}>()

sample({
  clock: formUnderRepairSubmitted,
  source: $id,
  filter: isString,
  fn: (id, { formValues, fn }) => ({ id, formValues, fn }),
  target: sendUnderRepairFormFx,
})
