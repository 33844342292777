import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { AxiosErrorType, WorkRule } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const domain = createDomain('entities.workRule')

export const fetchWorkRule = async (id: UniqueId) => {
  const response = await WorkRule.with('vehicleCategory')
    .with('regions')
    .with('carModel')
    .find(id)
  return response.getData() as WorkRule
}

export const requestFx = domain.createEffect<UniqueId, WorkRule>({
  handler: fetchWorkRule,
})

export const saveFx = domain.createEffect<WorkRule, WorkRule>({
  async handler(workRule) {
    const response = await workRule.save()
    return response.getModel() as WorkRule
  },
})

export const archiveFx = domain.createEffect<
  WorkRule,
  WorkRule,
  AxiosErrorType
>({
  async handler(workRule) {
    await workRule.archive()
    const response = await fetchWorkRule(workRule.getApiId() as UniqueId)
    if (!response) throw new Error(`Work rule ${workRule.getApiId()} not found`)
    return response
  },
})

const {
  $cache: $workRulesCache,
  useCache: useWorkRuleCache,
  updateCache,
} = createCache<WorkRule>({
  domain,
  getEntityId: (workRule) => workRule.getApiId() as UniqueId,
})
export { $workRulesCache, useWorkRuleCache }

$workRulesCache
  .on(requestFx.doneData, (cache, workRule) => updateCache(cache, [workRule]))
  .on([saveFx.doneData, archiveFx.doneData], (cache, workRule) =>
    updateCache(cache, [workRule], true),
  )

export const $workRulesError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useWorkRuleError = (id: UniqueId) =>
  useStoreMap($workRulesError, (errors) => errors[id])
