import { z, EnumLike } from 'zod'
import { formatForApi, fromStringApi } from '~/shared/lib/date'
import { sanitizePhoneNumber, sanitizeStringNumber } from '~/shared/lib/utils'

export const dateSchema = z
  .preprocess(
    (arg) => {
      if (typeof arg == 'string') {
        return fromStringApi(arg)
      }
      return arg
    },
    z.date({
      invalid_type_error: 'Обязательное поле',
    }),
  )
  .transform(formatForApi)

export const dateOptionalSchema = z
  .preprocess(
    (arg) => {
      if (typeof arg == 'string') {
        return fromStringApi(arg)
      }
      return arg
    },
    z.date({
      invalid_type_error: 'Обязательное поле',
    }),
  )
  .transform(formatForApi)
  .or(z.string().max(0))

export const phoneScheme = z
  .string()
  .superRefine((value, ctx) => {
    const sanitized = sanitizePhoneNumber(value)

    if (!sanitized.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Обязательное поле',
      })
    }

    if (sanitized.length > 0 && sanitized.length < 12) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Неверный номер телефона',
      })
    }
  })
  .transform(sanitizePhoneNumber)

export const priceRequiredScheme = z
  .string()
  .or(z.number())
  .superRefine((value, ctx) => {
    const sanitized = sanitizeStringNumber(value)
    if (!sanitized) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Обязательное поле',
      })
    }
    if (sanitized > 999999999) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Превышена допустимая стоимость',
      })
    }
  })
  .transform(sanitizeStringNumber)

export const priceRequiredWithZeroScheme = z
  .string()
  .or(z.number())
  .superRefine((value, ctx) => {
    const sanitized = sanitizeStringNumber(value)
    if (!sanitized && sanitized !== 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Обязательное поле',
      })
    }
    if (sanitized > 999999999) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Превышена допустимая стоимость',
      })
    }
  })
  .transform(sanitizeStringNumber)

export const optionSchema = z.object({
  id: z.string(),
  label: z.string(),
})
export const uuidOptionSchema = z
  .union([z.string().uuid(), optionSchema])
  .transform((arg) => {
    if (typeof arg === 'string') {
      return arg
    }
    return arg.id
  })
export const uuidOptionsSchema = z.array(uuidOptionSchema)

export const enumOptionSchema = <T extends EnumLike>(values: T) =>
  z
    .union([
      z.nativeEnum(values),
      z.object({
        id: z.nativeEnum(values),
        label: z.string(),
      }),
    ])
    .transform((arg) => {
      if (typeof arg === 'object' && 'id' in arg) {
        return arg.id
      }
      return arg
    })

export const yearSchema = z
  .union([
    z
      .string()
      .min(1, 'Обязательное поле')
      .regex(/^\d{4}$/, 'Год имеет неправильный формат, например "2022"'),
    z.number(),
  ])
  .transform((arg) => {
    if (typeof arg === 'string') return parseInt(arg)
    return arg
  })

const yearsSeparatedByCommasRegex = /^(?:\d{4}(?:,\s*|$))+$/
export const yearsSeparatedByCommasSchema = () =>
  z
    .string()
    .regex(
      yearsSeparatedByCommasRegex,
      'Года имеют неправильный формат, ожидается, например "1999, 2004, 2022"',
    )
    .transform((arg) => {
      return arg
        .split(',')
        .map((num) => Number(num.trim()))
        .filter(Number)
    })

export const numberRequiredScheme = z.coerce
  .number({
    invalid_type_error: 'Обязательное поле',
  })
  .min(0.01, 'Обязательное поле')

export const stringRequiredScheme = z
  .string()
  .trim()
  .min(1, 'Обязательное поле')

export const okpoRequiredSchema = z
  .string()
  .trim()
  .regex(
    /^\d{8}$|^\d{10}$|^\d{0}$/,
    'Только цифры, длина ОКПО - 8 или 10 символов',
  )

export const innRequiredSchema = z
  .string()
  .trim()
  .regex(/^\d{10}$|^\d{12}$/, 'Только цифры, длина ИНН - 10 или 12 символов')

export const kppRequiredSchema = z
  .string()
  .trim()
  .regex(/^\d{0}$|^\d{9}$/, 'Только цифры, длина КПП - 9 символов')

export const ogrnRequiredSchema = z
  .string()
  .trim()
  .regex(/^\d{13}$|^\d{15}$/, 'Только цифры, длина ОГРН - 13 или 15 символов')

export const bankRsRequiredSchema = z
  .string()
  .trim()
  .refine(
    (value) => /^\d{20}$/.test(value),
    'Только цифры, длина поля - 20 символов',
  )

export const bankBikRequiredSchema = z
  .string()
  .trim()
  .refine(
    (value) => /^\d{9}$/.test(value),
    'Только цифры, длина БИК - 9 символов',
  )
