import { AddSubdivision } from '~/features/Taxipark/Subdivisions/AddSubdivision'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Подразделения', link: '/taxipark/subdivisions' },
  { title: 'Создание подразделения' },
]

export const AddSubdivisionPage = () => {
  return (
    <PageContainer title='Создание подразделения'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание подразделения</PageTitle>
      </Container>
      <AddSubdivision />
    </PageContainer>
  )
}
