import * as z from 'zod'
import { Driver } from '~/shared/api'
import { Option } from '~/shared/config/constants'

export const formSchema = Driver.schema.pick({
  lastName: true,
  firstName: true,
  middleName: true,
  birthDate: true,
  phone: true,
  inn: true,
  snils: true,
  email: true,
  passportIssueDate: true,
  citizenship: true,
  registrationAddress: true,
  passportNumber: true,
  drivingExperienceStartDate: true,
  drivingLicenseIssuingCountry: true,
  drivingLicenseNumber: true,
  drivingLicenseIssueDate: true,
  drivingLicenseExpirationDate: true,
  divisionCode: true,
  passportIssued: true,
  placeOfResidence: true,
  data: true,
  comment: true,
  regionId: true,
})

export type SchemaType = z.infer<typeof formSchema>

export type FormValues = Omit<
  SchemaType,
  'regionId' | 'citizenship' | 'drivingLicenseIssuingCountry'
> & {
  regionId: UniqueId | Option | null
  citizenship: UniqueId | Option | null
  drivingLicenseIssuingCountry: UniqueId | Option | null
}
