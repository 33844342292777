import { Grid } from '@mui/joy'
import { useStoreMap } from 'effector-react'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { Modal } from '~/shared/ui/Modal'
import { FieldData } from './FieldData'
import { getDayOffText } from './helpers'
import { List, mapPaymentSchedulesToRows } from './List'
import { $paymentsTemplate } from './model'

type RentalContractPaymentScheduleModalProps = {
  isShow: boolean
  onCancel: () => void
}

export function RentalContractPaymentScheduleModal({
  isShow,
  onCancel,
}: RentalContractPaymentScheduleModalProps) {
  const { rentalContract, paymentsSchedule } = useStoreMap(
    $paymentsTemplate,
    (template) => {
      const rentalContract = template?.rentalContract
      return {
        rentalContract: {
          ...rentalContract,
          createdAt: rentalContract?.createdAt
            ? formatDateTimeForUI(rentalContract?.createdAt)
            : '',
        },
        paymentsSchedule: mapPaymentSchedulesToRows(
          template?.paymentsTemplate.data || [],
          rentalContract?.workSchedule,
        ),
      }
    },
  )

  const { contractNumber, rentPerDay, createdAt, workSchedule, dayOff } =
    rentalContract

  const scheduleText = dayOff
    ? `${workSchedule} ${getDayOffText(dayOff)}`
    : workSchedule

  return (
    <Modal
      isShow={isShow}
      onCancel={onCancel}
      title={`График списаний по договору ${contractNumber}`}
      dialogProps={{
        sx: {
          minWidth: '1000px',
          maxWidth: '1000px',
        },
      }}
    >
      <Grid container spacing={2} sx={{ marginBottom: '32px' }}>
        <FieldData
          label='Стоимость аренды в сутки'
          value={Number(rentPerDay).toLocaleString()}
        />
        <FieldData label='Дата заключения договора' value={createdAt} />
        <FieldData label='График работы' value={scheduleText} />
      </Grid>
      <List rows={paymentsSchedule} />
    </Modal>
  )
}
