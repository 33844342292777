import { ListBalanceHistories } from '~/features/Balance/BalanceHistory/ListBalanceHistories'
import { Container } from '~/shared/ui/Layout'

export const ListBalanceHistoriesPage = () => {
  return (
    <Container>
      <ListBalanceHistories />
    </Container>
  )
}
