import { z } from 'zod'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type AppealTypeAttributes = z.infer<typeof attributeSchema>

export class AppealType extends ApiModel<typeof schema, AppealTypeAttributes> {
  static jsonApiType = 'appeal-types'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }
  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions() {
    const response = await AppealType.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
