import { List, ListItem } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'
import { Button, IconButton } from '~/shared/ui/Buttons'
import { FileIcon } from '~/shared/ui/Icons'

type FileType = {
  id: UniqueId
  name: string
  filepath: string
}
type ColumnFileListProps = {
  files?: FileType[]
}

export function ColumnFileList({ files }: ColumnFileListProps) {
  if (!files?.length) return null

  return (
    <IconButton
      tooltipProps={{
        title: (
          <List>
            {files.map((file) => (
              <ListItem
                key={file.id}
                slotProps={{
                  root: {
                    component: Button,
                    href: file.filepath,
                    target: '_blank',
                    variant: 'textGray',
                    sx: {
                      fontWeight: 500,
                      border: 'none',
                      '&:hover': {
                        border: 'none',
                        color: myPalette.brand['700'],
                      },
                    },
                  },
                }}
              >
                {file.name}
              </ListItem>
            ))}
          </List>
        ),
      }}
    >
      <FileIcon color={myPalette.brand['600']} />
    </IconButton>
  )
}
