import { z } from 'zod'

import { ApiModel } from './core'

const schema = z.object({
  label: z.string(),
})

export type CarStatusesAttributes = z.infer<typeof schema>

export class CarStatuses extends ApiModel<typeof schema> {
  static jsonApiType = 'car-statuses'

  static schema = schema

  getLabel(): string {
    return this.getAttribute('label')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getLabel(),
    }
  }

  static async fetchOptions() {
    const response = await CarStatuses.get(1)
    return response.getData().map((status) => status.getOption())
  }
}
