import { driverMachine } from 'src/entities/Driver'
import {
  Car,
  CarLicensee,
  Region,
  Subdivision,
  WorkRule,
  YandexWorkRule,
} from '~/shared/api'
import { Filter } from '~/shared/ui/AsyncGrid'

export const filters: Filter[] = [
  {
    field: 'fullName',
    label: 'ФИО',
    type: 'text',
  },
  {
    field: 'drivingLicenseNumber',
    label: 'Номер ВУ',
    type: 'text',
  },
  {
    field: 'phone',
    label: 'Телефон',
    type: 'text',
  },
  {
    field: 'status',
    label: 'Статус',
    type: 'select',
    options: Object.entries(driverMachine.states)
      .filter(([, node]) => node.config.meta.gridFilter)
      .map(([key, node]) => ({
        id: key,
        label: node.config.meta.label ?? key,
      })),
  },
  {
    field: 'carId',
    label: 'Автомобиль',
    type: 'autocomplete',
    fetchOptions: Car.fetchOptions,
  },
  {
    field: 'regionId',
    label: 'Регион',
    type: 'autocompleteMultiple',
    fetchOptions: Region.fetchOptions,
  },
  {
    field: 'subdivisionId',
    label: 'Подразделение',
    type: 'autocompleteMultiple',
    fetchOptions: Subdivision.fetchOptions,
  },
  {
    field: 'licenseeId',
    label: 'Лицензиат',
    type: 'autocomplete',
    fetchOptions: CarLicensee.fetchOptions,
  },
  {
    field: 'workRuleId',
    label: 'Условие работы',
    type: 'autocompleteMultiple',
    fetchOptions: WorkRule.fetchOptions,
  },
  {
    field: 'yandexWorkRuleId',
    label: 'Условие работы Яндекс',
    type: 'autocompleteMultiple',
    fetchOptions: YandexWorkRule.fetchOptionsWithAccount,
    canAsyncSearch: false,
  },
]
