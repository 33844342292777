import { attach, combine, createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { aggregatorDriverModel } from '~/entities/Driver/Aggregator'
import { FormValues } from '~/entities/Driver/Aggregator/aggregatorDriverModel'
import { AggregatorDriver } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ aggregatorId: UniqueId }>()

export const domain = createDomain(
  'features.driver.aggregator.yandex-aggregator-driver.edit',
)

export const $aggregatorDriverId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { aggregatorId }) => aggregatorId)

export const $aggregatorDriver = combine(
  $aggregatorDriverId,
  aggregatorDriverModel.$aggregatorDriverCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $aggregatorDriverId,
  filter: isString,
  target: aggregatorDriverModel.requestFx,
})

export const aggregatorDriverUpdateFx = attach({
  effect: aggregatorDriverModel.saveFx,
  source: $aggregatorDriverId,
  mapParams: (values: FormValues, id) => {
    const { yandexWorkRuleOption, ...attr } = values

    const aggregatorDriver = new AggregatorDriver(attr, id as string)

    if (isString(yandexWorkRuleOption))
      aggregatorDriver.setYandexWorkRule(yandexWorkRuleOption)

    return aggregatorDriver
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: aggregatorDriverUpdateFx,
})

sample({
  clock: aggregatorDriverUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: aggregatorDriverUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
