import { createDomain, sample } from 'effector'
import { isString } from 'xstate/es/utils'
import { FormValues } from 'src/entities/WorkRule'
import { AxiosErrorType, WorkRule } from '~/shared/api'
import { FuelTypeEnum, TransmissionEnum } from '~/shared/config/enums'
import { pushFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const domain = createDomain('features.work-rule.create')

export const createFx = domain.createEffect<
  FormValues,
  WorkRule,
  AxiosErrorType
>({
  async handler({
    vehicleCategoryId,
    regionsIds,
    carModelOption,
    fuelTypes,
    transmissionType,
    manufactureYears,
    ...attributes
  }) {
    const workRule = new WorkRule({
      ...attributes,
      fuelTypes: fuelTypes as FuelTypeEnum[],
      transmissionType: transmissionType as TransmissionEnum,
      manufactureYears: manufactureYears as number[],
    })

    if (isString(vehicleCategoryId))
      workRule.setVehicleCategory(vehicleCategoryId)

    if (isString(carModelOption)) workRule.setCarModel(carModelOption)

    if (Array.isArray(regionsIds)) workRule.setRegions(regionsIds)

    await workRule.save()
    return workRule
  },
})
sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Условие работы создано',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn(workRule) {
    return `/work-rules/${workRule.getApiId()}`
  },
  target: pushFx,
})
