import { CircularProgress, Stack } from '@mui/joy'
import { format, parseISO } from 'date-fns'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from 'src/widgets/AuditsModal'
import {
  RentalContractContinue,
  RentalContractPaymentSchedule,
  RentalContractSuspend,
  RentalContractTerminate,
} from '~/widgets/DriverControls/RentalContractControls'
import {
  ViewRentalContract,
  viewRentalContractModel,
} from '~/features/RentalContracts/ViewRentalContract'
import { driverModel } from '~/entities/Driver'
import { rentalContractModel, Status, Print } from '~/entities/RentalContract'
import { usePermissions } from '~/entities/viewer'
import { datetime } from '~/shared/config'
import { RentalContractStatusEnum } from '~/shared/config/enums'
import { Breadcrumbs } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const ViewRentalContractPage = () => {
  const params = useParams<{ rentalContractId: string; driverId: string }>()
  const rentalContractId = params.rentalContractId as string
  const driverId = params.driverId as string

  const { canAuditsView } = usePermissions()

  const isLoading = useStore(rentalContractModel.requestFx.pending)

  const driverFullName = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getFullName(),
  )
  const rentalContract = useStore(viewRentalContractModel.$rentalContract)
  const rentalContractDriverId = rentalContract?.getDriverId()
  const status = rentalContract?.getStatus()
  const number = rentalContract?.getNumber()
  const createdAt = rentalContract?.getCreatedAt()

  const title = `${number} от ${
    createdAt ? format(parseISO(createdAt), datetime.uiDateFormat) : ''
  }`

  const breadcrumbs = useMemo(
    () => [
      {
        title: 'Водители',
        link: '/drivers',
      },
      {
        title: driverFullName || 'Водитель ...',
        link: `/drivers/${driverId}/details`,
      },
      {
        title: 'Договоры',
        link: `/drivers/${driverId}/rental-contracts`,
      },
      { title },
    ],
    [driverFullName, driverId, title],
  )

  const isStatusActive = status === RentalContractStatusEnum.ACTIVE
  const isStatusSuspended = status === RentalContractStatusEnum.SUSPENDED

  const rentalContractError =
    rentalContractModel.useRentalContractError(rentalContractId)

  if (rentalContractError) {
    return <NotFound message='Не удалось загрузить данные договора' />
  }

  if (
    !isLoading &&
    rentalContract &&
    rentalContract.getApiId() === rentalContractId &&
    driverId !== rentalContractDriverId
  ) {
    return (
      <NotFound
        message={`Загруженный договор [${rentalContractId}] не соответствует текущему водителю [${driverId}]`}
      />
    )
  }

  return (
    <PageContainer title='Карточка договора'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />

        <Status rentalContract={rentalContract} isLoading={isLoading} />

        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='flex-end'
          spacing={2}
        >
          <PageTitle loading={isLoading}>{title}</PageTitle>
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <Stack direction='row' spacing={2}>
              {canAuditsView && <AuditsModal auditableId={rentalContractId} />}
              <Print id={rentalContractId} />

              {(isStatusActive || isStatusSuspended) && (
                <RentalContractTerminate
                  rentalContractId={rentalContractId}
                  goBackOnTermination
                  isButton
                />
              )}
              {isStatusActive && (
                <RentalContractSuspend
                  isIcon
                  rentalContractId={rentalContractId}
                />
              )}
              {isStatusSuspended && (
                <RentalContractContinue
                  isIcon
                  rentalContractId={rentalContractId}
                />
              )}
              <RentalContractPaymentSchedule
                rentalContractId={rentalContractId}
                isIcon
              />
            </Stack>
          )}
        </Stack>
      </Container>
      <ViewRentalContract rentalContractId={rentalContractId} />
    </PageContainer>
  )
}
