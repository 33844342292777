import { Option } from '~/shared/config/constants'
import { ChipDelete } from '~/shared/ui/Chips'

type InternalChipListProps<T extends Option> = {
  values: T[]
  onChange: (values: Option[]) => void
  disabled?: boolean
}

export function InternalChipList<T extends Option>({
  values,
  onChange,
  disabled,
}: InternalChipListProps<T>) {
  return (
    <>
      {values.map((value) => (
        <ChipDelete
          key={value.id}
          onDelete={() => {
            const filteredValues = values.filter((v: T) => v.id !== value.id)
            onChange(filteredValues)
          }}
          disabled={disabled}
        >
          {value.label}
        </ChipDelete>
      ))}
    </>
  )
}
