import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import RoutesTabs from '~/shared/ui/RoutesTabs'
import { useConfigTabs } from './useConfigTabs'

function OrganizationPage() {
  const configTabs = useConfigTabs()
  return (
    <PageContainer title='Организации'>
      <RoutesTabs
        parentPath='/organizations'
        config={configTabs}
        parentContent={
          <Container isHeader>
            <PageTitle>Организации</PageTitle>
          </Container>
        }
      />
    </PageContainer>
  )
}

export default OrganizationPage
