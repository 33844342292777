import { useStore } from 'effector-react'
import { uniq } from 'lodash'
import { useMemo } from 'react'
import { AddRole } from '~/features/Taxipark/Roles/AddRole'
import { permissionModel } from '~/entities/Taxipark/Permission'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Роли', link: '/taxipark/roles' },
  { title: 'Создание роли' },
]

export const AddRolePage = () => {
  const permissions = useStore(permissionModel.$permissions)

  const quickNavigationOptions = useMemo(() => {
    return uniq(
      (permissions || []).map((permission) => permission.groupTitle),
    ).map((title) => ({
      label: title,
      anchor: title,
    }))
  }, [permissions])

  return (
    <PageContainer title='Создание роли'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание роли</PageTitle>
      </Container>
      <AddRole />

      {Boolean(permissions?.length) && (
        <QuickNavigation options={quickNavigationOptions} />
      )}
    </PageContainer>
  )
}
