import { CircularProgress, Stack } from '@mui/joy'
import { useGate, useStore } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  ViewAppeal,
  Status,
  ActionButtons,
} from '~/features/Balance/Appeal/ViewAppeal'
import { appealModel } from '~/entities/Balance/Appeal'
import { formatMoscowDateTime } from '~/shared/lib/date'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Осн. информация', anchor: 'main' },
  { label: 'Решение', anchor: 'solution' },
  { label: 'Файлы', anchor: 'documents' },
]

export function ViewAppealPage() {
  const { appealId } = useParams<{ appealId: string }>()

  useGate(appealModel.Gate, { appealId })

  const isLoading = useStore(appealModel.requestFx.pending)
  const appeal = useStore(appealModel.$appeal)
  const carError = appealModel.useAppealError(appealId)

  const title = useMemo(() => {
    if (!appeal) return ''

    return `№ ${appeal?.getNumber()} от ${formatMoscowDateTime(
      appeal?.getCreatedAt() as string,
    )}`
  }, [appeal])

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Баланс', link: '/balance/balance-histories' },
      { title: 'Обращения', link: '/balance/appeals' },
      { title },
    ],
    [title],
  )

  if (!appealId || carError) {
    return <NotFound message='Не удалось загрузить обращение' />
  }

  return (
    <PageContainer title={title}>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <Status />
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <PageTitle loading={isLoading}>{title}</PageTitle>
          {isLoading && <CircularProgress />}
          {!isLoading && <ActionButtons appealId={appealId} />}
        </Stack>
      </Container>

      <ViewAppeal />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
