import { ListTravelSheets } from '~/features/Drivers/TravelSheets/ListTravelSheets'
import { Container, PageContainer } from '~/shared/ui/Layout'

export const ListTravelSheetPage = () => {
  return (
    <PageContainer title='Путевые листы'>
      <Container>
        <ListTravelSheets />
      </Container>
    </PageContainer>
  )
}
