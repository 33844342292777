import { Box, Skeleton } from '@mui/joy'
import { Option } from '~/shared/config/constants'
import { ChipList } from '~/shared/ui/Chips'

type ExternalChipListProps<T extends Option> = {
  skeletonShow?: boolean
  values: T[]
  onChange: (values: Option[]) => void
  disabled?: boolean
}

export function ExternalChipList<T extends Option>({
  skeletonShow,
  values,
  onChange,
  disabled,
}: ExternalChipListProps<T>) {
  return (
    <Box
      sx={{
        marginBottom: '24px',
        minHeight: '24px',
      }}
    >
      {skeletonShow ? (
        <Skeleton
          sx={{
            position: 'relative',
            height: '24px',
            borderRadius: '12px',
          }}
        />
      ) : (
        <ChipList
          type='delete'
          onDelete={(option) => {
            const filteredValues = values.filter((v) => v.id !== option.id)
            onChange(filteredValues)
          }}
          options={values || []}
          chipProps={{
            disabled,
          }}
        />
      )}
    </Box>
  )
}
