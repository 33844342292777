import { Typography } from '@mui/joy'
import { Button } from '~/shared/ui/Buttons'
import { Modal } from '~/shared/ui/Modal'

type TravelSheetWarningCannotCreateModalProps = {
  isShow: boolean
  onClose: () => void
}

export function TravelSheetWarningCannotCreateModal({
  isShow,
  onClose,
}: TravelSheetWarningCannotCreateModalProps) {
  return (
    <Modal
      isShow={isShow}
      onCancel={onClose}
      title='Формирование путевых листов'
      dialogProps={{
        sx: {
          minWidth: '500px',
          maxWidth: '500px',
        },
      }}
    >
      <Typography sx={{ textAlign: 'center', paddingBottom: '12px' }}>
        Для формирования путевых листов необходим действующий договор
      </Typography>
      <br />
      <Button fullWidth variant='gray' onClick={onClose}>
        Закрыть
      </Button>
    </Modal>
  )
}
