import {
  Box,
  FormControl,
  FormLabel,
  List,
  ListItem,
  Stack,
  Textarea,
  Typography,
} from '@mui/joy'
import {
  getCheckKindLabel,
  getYandexResultsDebts,
  getYandexResultsReports,
  isYandexEmptyResults,
} from '~/entities/DriverCheck/lib'
import { YandexResults as YandexResultsType } from '~/shared/api'
import { CheckKindEnum } from '~/shared/config/enums'
import { myPalette } from '~/shared/lib/theme'
import { Button } from '~/shared/ui/Buttons'

type YandexResultsProps = {
  results: YandexResultsType
  onClose: () => void
}

export function YandexResults({ results, onClose }: YandexResultsProps) {
  if (isYandexEmptyResults(results)) {
    return <Typography>Данные отсутствуют</Typography>
  }

  const reports = getYandexResultsReports(results)
  const isReports = Boolean(reports?.length)

  const debts = getYandexResultsDebts(results)
  const isDebts = Boolean(debts?.length)
  return (
    <Stack direction='column' spacing={2}>
      <Typography fontWeight={600} fontSize={20}>
        {getCheckKindLabel(CheckKindEnum.YANDEX)}
      </Typography>
      {isReports ? (
        <Stack spacing={3} py={1}>
          {reports.map(({ park, message }) => (
            <FormControl key={park}>
              <FormLabel>{park}</FormLabel>
              <Textarea value={message} readOnly />
            </FormControl>
          ))}
        </Stack>
      ) : (
        <Typography>Нет комментариев из таксопарков</Typography>
      )}
      <Box>
        {isDebts ? (
          <>
            <Typography fontWeight={600}>Долги</Typography>
            <List>
              {debts.map(({ park, boundsFrom, boundsTo }) => (
                <ListItem key={park + boundsFrom}>
                  <Typography>
                    {park}
                    {' — '}
                    <b>
                      {typeof boundsFrom === 'string' ? 'менее' : 'более'}{' '}
                      {(boundsFrom ?? boundsTo ?? '').replace('-', '')}
                    </b>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <Typography sx={{ color: myPalette.green['600'] }}>
            Нет долгов
          </Typography>
        )}
      </Box>

      <br />
      <Button variant='gray' onClick={onClose}>
        Закрыть
      </Button>
    </Stack>
  )
}
