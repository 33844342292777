import { useStore } from 'effector-react'
import { Form } from '~/entities/Taxipark/Regions/ui/Form'
import { formSubmitted, createFx } from './model'

export const AddRegion = () => {
  const isSubmitting = useStore(createFx.pending)

  return (
    <Form onSuccess={formSubmitted} isSubmitting={isSubmitting} isCreating />
  )
}
