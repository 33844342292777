import { useCallback, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { DriverOptionForAppeal } from '~/shared/api'
import { getObject } from '~/shared/lib/utils'
import { fetchSubdivisionByCarIdFx, FormValues } from './model'

export const useFormWatch = (
  form: UseFormReturn<FormValues>,
  isWatching?: boolean,
) => {
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = form

  const isCarOptionError = errors['carOption']
  const isSubdivisionOptionError = errors['subdivisionOption']

  const handleDriverOptionSet = useCallback(
    async (driverOption?: DriverOptionForAppeal) => {
      if (!driverOption) {
        setValue('phone', '')
        setValue('carOption', null)
        setValue('subdivisionOption', null)
        if (isCarOptionError) trigger('carOption')
        if (isSubdivisionOptionError) trigger('subdivisionOption')
        return
      }

      setValue('phone', driverOption.phone)
      setValue('carOption', driverOption?.carOption || null)
      setValue('subdivisionOption', null)

      if (driverOption?.carOption?.id) {
        const subdivisionOption = await fetchSubdivisionByCarIdFx(
          driverOption?.carOption?.id,
        )
        setValue('subdivisionOption', subdivisionOption)
      }

      if (isCarOptionError) trigger('carOption')
      if (isSubdivisionOptionError) trigger('subdivisionOption')
    },
    [isCarOptionError, isSubdivisionOptionError, setValue, trigger],
  )

  const driverOptionForm = watch('driverOption')
  const driverOption = getObject(driverOptionForm)

  useEffect(() => {
    if (!isWatching) return

    handleDriverOptionSet(driverOption)
    // eslint-disable-next-line
  }, [driverOption?.id])

  const carOptionForm = watch('carOption')
  const carOption = getObject(carOptionForm)

  useEffect(() => {
    if (!isWatching) return

    setValue('subdivisionOption', carOption?.subdivisionOption)
    // eslint-disable-next-line
  }, [carOption?.id])
}
