import { Typography, Grid, Box } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

type FieldDataProps = {
  label?: string
  value?: string
}

export function FieldData({ label, value }: FieldDataProps) {
  return (
    <Grid xs={4}>
      <Box
        sx={{
          background: myPalette.gray['100'],
          borderRadius: '16px',
          padding: '16px',
        }}
      >
        <Typography
          sx={{
            color: myPalette.gray['400'],
            fontSize: '14px',
            marginBottom: '8px',
          }}
        >
          {label}
        </Typography>
        <Typography sx={{ fontWeight: 600, fontSize: '20px' }}>
          {value}
        </Typography>
      </Box>
    </Grid>
  )
}
