import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FormValues } from '~/features/Taxipark/Information/model'
import { Taxipark } from '~/shared/api'
import { FormButtons, FormProvider, formButtonsModel } from '~/shared/ui/Form'
import { Contacts } from './Contacts'
import { Main } from './Main'

type FormProps = {
  onSuccess: () => void
  defaultValues?: Partial<FormValues>
  isSubmitting: boolean
  isLoading: boolean
  canEdit: boolean
}

export function Form({
  defaultValues,
  onSuccess,
  isSubmitting,
  isLoading,
  canEdit,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = !isEditing || isSubmitting
  const form = useForm<FormValues>({
    resolver: zodResolver(Taxipark.schema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <Main isLoading={isLoading} disabled={disabled} />
        </Grid>
        <Grid xs={6}>
          <Contacts isLoading={isLoading} disabled={disabled} />
        </Grid>
      </Grid>

      <FormButtons loading={isSubmitting} />
    </FormProvider>
  )
}
