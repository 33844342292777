import { Box, Stack } from '@mui/joy'
import { useQueryClient } from '@tanstack/react-query'
import { useStore } from 'effector-react'
import { useState } from 'react'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Alert } from '~/shared/ui/Alerts'
import { Button } from '~/shared/ui/Buttons'
import { FileExportBlob } from '~/shared/ui/FileExport'
import { UploadIcon } from '~/shared/ui/Icons'
import { Modal, useModal } from '~/shared/ui/Modal'
import { Documents } from './Documents'
import { checkIsBlobResponse, massImportFx } from './model'

type ExportCarTemplateProps = {
  isShow: boolean
  onCancel: () => void
}

export function ImportCarTemplate({
  isShow,
  onCancel,
}: ExportCarTemplateProps) {
  const queryClient = useQueryClient()
  const isSubmitting = useStore(massImportFx.pending)
  const [file, setFile] = useState<File>()
  const [errorFile, setErrorFile] = useState<Blob>()

  const {
    openModal: errorModalOpen,
    closeModal: errorModalClose,
    isModalShow: isErrorModalShow,
  } = useModal()

  const handleFileSet = (files?: File[]) => {
    setFile(files?.[0])
  }

  const handleCancel = () => {
    setFile(undefined)
    onCancel()
  }

  const handleFileErrorSave = (blob: Blob) => {
    setErrorFile(blob)
    errorModalOpen()
  }

  const handleFileSuccessSave = async () => {
    snackbarEnqueued({
      message: 'Автомобили успешно импортированы',
      variant: 'success' as const,
    })
    await queryClient.invalidateQueries({
      queryKey: ['cars'],
    })
  }

  const handleSave = async () => {
    if (!file) {
      snackbarEnqueued({
        message: 'Загрузите файл для импорта',
        variant: 'warning' as const,
      })
      return
    }

    try {
      const response = await massImportFx(file)

      checkIsBlobResponse(response)
        ? handleFileErrorSave(response.data as Blob)
        : await handleFileSuccessSave()

      handleCancel()
    } catch (e) {
      logger.error(e)
    }
  }

  const handleErrorModalClose = () => {
    errorModalClose()
    setErrorFile(undefined)
  }

  return (
    <>
      <Modal
        isShow={isShow}
        onCancel={isSubmitting ? undefined : handleCancel}
        dialogProps={{
          sx: {
            minWidth: '800px',
            maxWidth: '800px',
          },
        }}
        title='Загрузка списка автомобилей'
      >
        <Stack spacing={2}>
          <Box
            sx={{
              '.MuiGrid-direction-xs-row': {
                width: '100%',
              },
            }}
          >
            <Documents
              isShowUploadFiles
              onUploadFiles={handleFileSet}
              isSubmitting={isSubmitting}
              files={file ? [file] : []}
            />
          </Box>

          {isSubmitting && (
            <Alert variant='info'>
              Пожалуйста, подождите, пока процесс импорта завершится. Это может
              занять некоторое время.
            </Alert>
          )}
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Button
              variant='gray'
              fullWidth
              disabled={isSubmitting}
              onClick={onCancel}
            >
              Закрыть
            </Button>
            <Button
              fullWidth
              variant='brand'
              startDecorator={<UploadIcon />}
              onClick={handleSave}
              loading={isSubmitting}
            >
              Загрузить список
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        isShow={isErrorModalShow}
        onCancel={handleErrorModalClose}
        title='Ошибка импорта'
      >
        <Stack spacing={2}>
          <Alert variant='error'>
            Обнаружена ошибка в файле импорта. Пожалуйста, исправьте ошибки и
            повторите загрузку.
          </Alert>
          <Stack direction='row' justifyContent='space-between' spacing={2}>
            <Button variant='gray' onClick={handleErrorModalClose}>
              Закрыть
            </Button>
            <FileExportBlob
              title='Выгрузить отчет ошибок'
              fn={() => errorFile}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  )
}
