import { useCallback } from 'react'
import { ViewTransaction } from '~/features/Balance/BalanceHistory/ViewTransaction'
import { DriverCreateTransaction } from '~/features/Drivers/Balance/CreateTransaction'
import { usePermissions } from '~/entities/viewer'
import { BalanceHistory } from '~/shared/api'
import { TransactionMoveFundsTypeEnum } from '~/shared/config/enums'
import { canShowObject } from '~/shared/lib/utils'
import { NotFound } from '~/shared/ui/NotFound'
import { Tabs } from '~/shared/ui/RoutesTabs/type'
import { EditYandexAggregatorPage, ListAggregatorsPage } from './Aggregators'
import { BalancePage } from './Balance'
import { DetailsPage } from './Details'
import {
  CreateRentalContractPage,
  ViewRentalContractPage,
  ListRentalContractPage,
} from './RentalContracts'
import { ListTravelSheetPage } from './TravelSheets'
import { useBreadcrumbs } from './useBreadcrumbs'

export const useConfigTabs = (driverId: UniqueId): Tabs => {
  const { canRentalContractsView, canTravelSheetsView, canBalancesView } =
    usePermissions()

  const mountValidateTransaction = useCallback(
    (transaction: BalanceHistory) => {
      if (driverId === transaction.getDriverId()) return null

      return (
        <NotFound
          message={`Загруженная транзакция [${transaction.getApiId()}] не соответствует текущему водителю [${driverId}]`}
        />
      )
    },
    [driverId],
  )

  const {
    currentViewTransactionBreadcrumbs,
    byDeptViewTransactionBreadcrumbs,
  } = useBreadcrumbs(driverId)

  return [
    {
      label: 'Профиль',
      path: '/details',
      content: <DetailsPage />,
    },
    {
      label: 'Агрегаторы',
      path: '/aggregators',
      content: <ListAggregatorsPage />,
      children: [
        {
          path: '/yandex/:aggregatorId',
          content: <EditYandexAggregatorPage />,
          full: true,
        },
      ],
    },
    ...canShowObject(canRentalContractsView, {
      label: 'Договоры',
      path: '/rental-contracts',
      content: <ListRentalContractPage />,
      children: [
        {
          path: '/new',
          content: <CreateRentalContractPage />,
          full: true,
        },
        {
          path: '/:rentalContractId',
          content: <ViewRentalContractPage />,
          full: true,
        },
      ],
    }),
    ...canShowObject(canTravelSheetsView, {
      label: 'Путевые листы',
      path: '/travel-sheets',
      content: <ListTravelSheetPage />,
    }),
    ...canShowObject(canBalancesView, {
      label: 'Ведомость',
      path: '/balance',
      content: <BalancePage />,
      children: [
        {
          path: '/current/new-coming',
          content: (
            <DriverCreateTransaction
              moveFundsType={TransactionMoveFundsTypeEnum.COMING}
              breadcrumbsList={currentViewTransactionBreadcrumbs}
            />
          ),
          full: true,
        },
        {
          path: '/current/new-spending',
          content: (
            <DriverCreateTransaction
              moveFundsType={TransactionMoveFundsTypeEnum.SPENDING}
              breadcrumbsList={currentViewTransactionBreadcrumbs}
            />
          ),
          full: true,
        },
        {
          path: '/current/:transactionId',
          content: (
            <ViewTransaction
              parentBreadcrumbs={currentViewTransactionBreadcrumbs}
              mountTransactionValidate={mountValidateTransaction}
            />
          ),
          full: true,
        },
        {
          path: '/by-dept/:transactionId',
          content: (
            <ViewTransaction
              parentBreadcrumbs={byDeptViewTransactionBreadcrumbs}
              mountTransactionValidate={mountValidateTransaction}
            />
          ),
          full: true,
        },
      ],
    }),
  ]
}
