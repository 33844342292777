import { ListRegions } from '~/features/Taxipark/Regions/ListRegions'
import { Container } from '~/shared/ui/Layout'

export const ListRegionPage = () => {
  return (
    <Container>
      <ListRegions />
    </Container>
  )
}
