import { ListCars } from '~/features/Сars/ListCars'
import { Container, PageContainer } from '~/shared/ui/Layout'

export function ListCarsPage() {
  return (
    <PageContainer title='Автомобили'>
      <Container>
        <ListCars />
      </Container>
    </PageContainer>
  )
}
