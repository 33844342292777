import { styled, MenuItem as JoyMenuItem } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const MenuItem = styled(JoyMenuItem)`
  height: 45px;
  border-radius: 30px;

  &:hover {
    background-color: ${myPalette.gray['50']} !important;
  }
` as typeof JoyMenuItem
