import { z } from 'zod'

import { ApiModel } from './core'

const schema = z.object({
  title: z.string(),
})

export type VehicleCategoryAttributes = z.infer<typeof schema>

export class VehicleCategory extends ApiModel<typeof schema> {
  static jsonApiType = 'vehicle-categories'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions(search: string) {
    const response = await VehicleCategory.where('title', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
