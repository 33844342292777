import { useStore, useStoreMap } from 'effector-react'
import { carModel } from '~/entities/Car'
import { usePermissions } from '~/entities/viewer'
import { $car, formUnderRepairSubmitted, sendUnderRepairFormFx } from '../model'
import { Form } from './Form'

export function UnderRepair() {
  const { canCarsUpdate } = usePermissions()
  const isLoading = useStore(carModel.requestFx.pending)
  const isSubmitting = useStore(sendUnderRepairFormFx.pending)

  const defaultValues = useStoreMap($car, (car) => {
    return {
      plannedDateEndRepair: car?.getPlannedDateEndRepair(),
      repairComment: car?.getRepairComment(),
    }
  })

  return (
    <Form
      defaultValues={defaultValues}
      onSuccess={formUnderRepairSubmitted}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      canEdit={canCarsUpdate}
    />
  )
}
