import { AxiosResponse } from 'axios'
import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import * as z from 'zod'

import { UserOptionsMe } from '~/shared/api/core/store'
import { Region } from '~/shared/api/region'
import { Role } from '~/shared/api/role'
import {
  dateSchema,
  phoneScheme,
  uuidOptionsSchema,
} from '~/shared/lib/schemas'

import { ApiModel, ToManyRelation } from './core'

const attributeSchema = z.object({
  email: z.string().email('Неверный адрес электронной почты'),
  password: z
    .string()
    .min(5, { message: 'Пароль должен содержать не менее 5 символов' }),
  name: z.string(),
  phone: phoneScheme,
  telegramUserId: z.number().nullable(),
})

const relationsSchema = z.object({
  regionsIds: uuidOptionsSchema,
  rolesIds: uuidOptionsSchema,
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type UserAttributes = z.infer<typeof schema>

export class User extends ApiModel<typeof schema> {
  static jsonApiType = 'users'

  static schema = schema

  static async login(credentials: { email: Email; password: string }) {
    const url = `${this.getJsonApiUrl()}/login`
    const client = this.httpClient.getImplementingClient()
    return client
      .post<typeof credentials, AxiosResponse<{ token: string }>>(
        url,
        credentials,
        {
          skipAuthRefresh: true,
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
          },
        } as AxiosAuthRefreshRequestConfig,
      )
      .then(({ data }) => data)
  }

  static async me() {
    const url = `${this.getJsonApiUrl()}/me`
    const client = User.httpClient.getImplementingClient()
    return client.get<UserOptionsMe>(url).then(({ data }) => data)
  }

  getEmail(): string {
    return this.getAttribute('email')
  }

  getName(): string | undefined {
    return this.getAttribute('name')
  }

  getPhone(): string | undefined {
    return this.getAttribute('phone')
  }

  regions(): ToManyRelation<Region, this> {
    return this.hasMany(Region)
  }
  getRegions(): Region[] {
    return this.getRelation('regions') ?? []
  }

  roles(): ToManyRelation<Role, this> {
    return this.hasMany(Role)
  }
  getRoles(): Role[] {
    return this.getRelation('roles') ?? []
  }
}
