export function CreditCardSearchIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3333 18.3337L17.0833 17.0837M18.3333 8.33366H1.66663M18.3333 10.0003V6.83366C18.3333 5.90024 18.3333 5.43353 18.1516 5.07701C17.9918 4.76341 17.7369 4.50844 17.4233 4.34865C17.0668 4.16699 16.6 4.16699 15.6666 4.16699H4.33329C3.39987 4.16699 2.93316 4.16699 2.57664 4.34865C2.26304 4.50844 2.00807 4.7634 1.84828 5.07701C1.66663 5.43353 1.66663 5.90024 1.66663 6.83366V13.167C1.66663 14.1004 1.66663 14.5671 1.84828 14.9236C2.00807 15.2372 2.26304 15.4922 2.57664 15.652C2.93316 15.8337 3.39987 15.8337 4.33329 15.8337H8.74996M17.9166 15.0003C17.9166 16.6112 16.6108 17.917 15 17.917C13.3891 17.917 12.0833 16.6112 12.0833 15.0003C12.0833 13.3895 13.3891 12.0837 15 12.0837C16.6108 12.0837 17.9166 13.3895 17.9166 15.0003Z'
        stroke='white'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
