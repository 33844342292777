import { useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CreateRentalContract } from '~/features/RentalContracts/CreateRentalContract'
import { driverModel } from '~/entities/Driver'
import { Breadcrumbs } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

export const CreateRentalContractPage = () => {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  const fullName = useStoreMap(driverModel.$driver, (driver) =>
    driver?.getFullName(),
  )

  const breadcrumbs = useMemo(
    () => [
      {
        title: 'Водители',
        link: '/drivers',
      },
      {
        title: fullName || 'Водитель ...',
        link: `/drivers/${driverId}/details`,
      },
      {
        title: 'Договоры',
        link: `/drivers/${driverId}/rental-contracts`,
      },
      { title: 'Создание договора' },
    ],
    [driverId, fullName],
  )

  return (
    <PageContainer title='Создание договора'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание договора</PageTitle>
      </Container>

      <CreateRentalContract />
    </PageContainer>
  )
}
