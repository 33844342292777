import { Stack } from '@mui/joy'
import { useStore } from 'effector-react'
import { driverModel } from 'src/entities/Driver'
import { Alert } from '~/shared/ui/Alerts'

type AlertsProps = {
  driverId: string
}

export function Alerts({ driverId }: AlertsProps) {
  const driver = useStore(driverModel.$driver)
  const driverIdFromModel = driver?.getApiId()
  const driverLoaded = driverIdFromModel === driverId
  const sentToComandirDate = driver?.getSentToComandirFormattedDate()
  const driverStatus = driver?.getStatus()
  const decisionMaker = driver?.getWhoApprovedOrRejected()
  const decisionDate = driver?.getApprovedOrRejectedFormattedDate()

  const decisionMakerName =
    driverLoaded && decisionMaker
      ? [
          decisionMaker.getName(),
          decisionMaker.getPhone(),
          decisionMaker.getEmail(),
          `в ${decisionDate}`,
        ].join(' ')
      : null

  if (!driverLoaded) return null

  return (
    <>
      <Stack spacing={1}>
        {decisionMakerName && (
          <Alert variant='info'>
            Решение по водителю принял <b>{decisionMakerName}</b>
          </Alert>
        )}

        {driverStatus === 'SENT_TO_COMANDIR' && sentToComandirDate && (
          <Alert variant='warning'>
            Отправлено руководителю в <b>{sentToComandirDate}</b>
          </Alert>
        )}
      </Stack>
    </>
  )
}
