import { Grid } from '@mui/joy'
import { DatePickerInput } from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type ForeignersProps = {
  disabled?: boolean
  skeletonShow?: boolean
}

export function Foreigners({ disabled, skeletonShow }: ForeignersProps) {
  return (
    <Container>
      <ContainerTitle>Для иностранных граждан</ContainerTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <DatePickerInput
            label='Дата регистрации'
            placeholder='Введите дату регистрации'
            name='data.registrationDate'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
        <Grid xs={6}>
          <DatePickerInput
            label='Срок регистрации'
            placeholder='Введите срок регистрации'
            name='data.registrationExpirationDate'
            readOnly={disabled}
            skeletonShow={skeletonShow}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
