import { z } from 'zod'
import { ApiModel } from './core'

const schema = z.object({
  importId: z.string(),
  driverFullName: z.string(),
  driverPhone: z.string(),
  amount: z.string(),
  driverId: z.string(),
  comment: z.string(),
  errors: z.string().array().nullable(),
})

export type MassImportTransactionAttributes = z.infer<typeof schema>

export class MassImportTransaction extends ApiModel<typeof schema> {
  static jsonApiType = 'mass-import-transactions'

  static schema = schema

  static async totalAmount(
    importId: UniqueId,
  ): Promise<{ totalAmount: number }> {
    const url = `${MassImportTransaction.getJsonApiUrl()}/actions/total-amount/?importId=${importId}`

    const client = MassImportTransaction.httpClient.getImplementingClient()

    const response = await client.get(url)

    return response?.data
  }
}
