export function RefreshOrangeIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.66666 8.33333C1.66666 8.33333 3.33747 6.05685 4.69485 4.69854C6.05223 3.34022 7.92799 2.5 9.99999 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 9.99999 17.5C6.58075 17.5 3.69592 15.2119 2.79313 12.0833M1.66666 8.33333V3.33333M1.66666 8.33333H6.66666'
        stroke='#DC6803'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
