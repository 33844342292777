import { Dropzone } from '~/shared/ui/Dropzone'

type DocumentsProps = {
  isShowUploadFiles?: boolean
  onUploadFiles?: (files?: File[]) => void
  isSubmitting: boolean
  files?: File[]
}

export function Documents({
  isShowUploadFiles,
  onUploadFiles,
  isSubmitting,
  files,
}: DocumentsProps) {
  return (
    <Dropzone
      isSubmitting={isSubmitting}
      accept={{
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
        'application/vnd.ms-excel': [],
      }}
      maxFiles={1}
      files={files}
      onUploadFiles={onUploadFiles}
      isShowUploadFiles={isShowUploadFiles}
      canDelete={!isSubmitting}
      canCreate={!files?.length}
    />
  )
}
