import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { TravelSheet } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'

import { columns } from './columns'
import { GridRow } from './types'
import { TravelSheetCreate } from './ui/TravelSheetControl'

export const ListTravelSheets = () => {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize) => {
      const builder = TravelSheet.limit(pageSize)
        .orderBy('-createdAt')
        .where('driverId', driverId)

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((travelSheet) => {
        return {
          ...travelSheet.getAttributes(),
          id: travelSheet.getApiId() as string,
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [driverId],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Путевые листы'
      gridKey='travel-sheets-list'
      nestedGridKey={driverId}
      fetchRows={fetchRows}
      columns={columns}
      headerExtra={<TravelSheetCreate />}
    />
  )
}
