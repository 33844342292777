import { useCallback } from 'react'
import { AggregatorAccount } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'

import { columns } from './columns'
import { GridRow } from './types'

export const ListAggregatorAccount = () => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize) => {
      const builder = AggregatorAccount.limit(pageSize)

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((aggregatorAccount: AggregatorAccount) => ({
        ...aggregatorAccount.getAttributes(),
        id: aggregatorAccount.getApiId() as string,
      }))

      /*
       * Coloquent не дает метода, чтобы получить объект meta,
       * поэтому в данном случае получаем оригинальный ответ и достаем мету из него
       * TODO: Реализовать лучший способ получения метаданных
       * */
      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Аккаунты агрегаторов'
      gridKey='aggregatorsAccount'
      fetchRows={fetchRows}
      columns={columns}
    />
  )
}
