import { useStore, useStoreMap, useGate } from 'effector-react'
import { Form, carRentalModel } from '~/entities/Organizations/CarRental'
import { usePermissions } from '~/entities/viewer'
import { formSubmitted, Gate, $carRental, carRentalUpdateFx } from './model'

type EditCarRentalProps = {
  carRentalId: string
}

export function EditCarRental({ carRentalId }: EditCarRentalProps) {
  useGate(Gate, { carRentalId })
  const { canCarOrganizationsUpdate } = usePermissions()

  const isLoading = useStore(carRentalModel.requestFx.pending)
  const isSaving = useStore(carRentalUpdateFx.pending)

  const defaultValues = useStoreMap($carRental, (carRental) => ({
    ...carRental?.getAttributes(),
  }))

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canCarOrganizationsUpdate}
    />
  )
}
