export function InfoIcon() {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.3'>
        <rect
          x='5.14282'
          y='5.14307'
          width='23.7143'
          height='23.7143'
          rx='11.8571'
          stroke='#382AFF'
          strokeWidth='2'
        />
      </g>
      <g opacity='0.1'>
        <rect
          x='1.57153'
          y='1.57129'
          width='30.8571'
          height='30.8571'
          rx='15.4286'
          stroke='#382AFF'
          strokeWidth='2'
        />
      </g>
      <g clipPath='url(#clip0_978_11985)'>
        <path
          d='M16.9998 20.3332V16.9998M16.9998 13.6665H17.0082M25.3332 16.9998C25.3332 21.6022 21.6022 25.3332 16.9998 25.3332C12.3975 25.3332 8.6665 21.6022 8.6665 16.9998C8.6665 12.3975 12.3975 8.6665 16.9998 8.6665C21.6022 8.6665 25.3332 12.3975 25.3332 16.9998Z'
          stroke='#382AFF'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_978_11985'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(7 7)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}
