import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import * as z from 'zod'
import { AxiosErrorType, Subdivision } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const formSchema = Subdivision.schema.pick({
  title: true,
  regionId: true,
})

export type SchemaType = z.infer<typeof formSchema>
export type FormValues = Omit<SchemaType, 'regionId'> & {
  regionId: UniqueId | Option
}

export const domain = createDomain('entities.subdivision')

export const requestFx = domain.createEffect<UniqueId, Subdivision>({
  handler: fetchSubdivision,
})

export const saveFx = domain.createEffect<
  Subdivision,
  Subdivision,
  AxiosErrorType
>({
  async handler(subdivision) {
    await subdivision.save()
    return fetchSubdivision(subdivision.getApiId() as UniqueId)
  },
})

const {
  $cache: $subdivisionCache,
  useCache: useSubdivisionCache,
  updateCache,
} = createCache<Subdivision>({
  domain,
  getEntityId: (subdivision) => subdivision.getApiId() as UniqueId,
})
export { $subdivisionCache, useSubdivisionCache }

$subdivisionCache
  .on(requestFx.doneData, (cache, subdivision) =>
    updateCache(cache, [subdivision]),
  )
  .on(saveFx.doneData, (cache, subdivision) =>
    updateCache(cache, [subdivision], true),
  )

export const $subdivisionsError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useSubdivisionError = (id: UniqueId) =>
  useStoreMap($subdivisionsError, (errors) => errors[id])

async function fetchSubdivision(id: UniqueId) {
  const response = await Subdivision.with('region').find(id)
  return response.getData() as Subdivision
}
