import { AxiosResponse } from 'axios'
import { createDomain, sample } from 'effector'
import { AxiosErrorType, Car } from '~/shared/api'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('pages.cars.list.importCarTemplate')

export const checkIsBlobResponse = (
  response: AxiosResponse<Blob | undefined>,
): boolean => {
  return Boolean(
    response?.data &&
      response?.data?.size &&
      response?.headers['content-type'].startsWith('application/'),
  )
}

export const massImportFx = domain.createEffect<
  File,
  AxiosResponse<Blob | undefined>,
  AxiosErrorType
>({
  async handler(file) {
    return await Car.massImport(file)
  },
})

sample({
  clock: massImportFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: [snackbarEnqueued],
})
