import { AddCarRental } from '~/features/Organization/CarRentals/AddCarRental'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Организации', link: '/organizations/car-rentals' },
  { title: 'Арендодатели', link: '/organizations/car-rentals' },
  { title: 'Новый арендодатель' },
]

const quickNavigationOptions = [
  { label: 'Реквизиты', anchor: 'requisites' },
  { label: 'Руководство', anchor: 'management' },
  { label: 'Контакты', anchor: 'contacts' },
  { label: 'Банк', anchor: 'bank' },
]

export function AddCarRentalPage() {
  return (
    <PageContainer title='Новый арендодатель'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Новый арендодатель</PageTitle>
      </Container>
      <AddCarRental />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
