import { createDomain, sample } from 'effector'

import { FormValues } from 'src/entities/Driver'
import { AxiosErrorType, Driver } from '~/shared/api'
import { pushFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.driver.create')

export const createFx = domain.createEffect<FormValues, Driver, AxiosErrorType>(
  {
    async handler(values) {
      const { regionId, citizenship, drivingLicenseIssuingCountry, ...attr } =
        values
      const driver = new Driver({
        ...(isString(citizenship) && { citizenship }),
        ...(isString(drivingLicenseIssuingCountry) && {
          drivingLicenseIssuingCountry,
        }),
        ...attr,
      })

      if (isString(regionId)) driver.setRegion(regionId)

      const result = await driver.save()
      return result.getModel() as Driver
    },
  },
)
sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Водитель создан',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn(driver) {
    return `/drivers/${driver.getApiId()}`
  },
  target: pushFx,
})
