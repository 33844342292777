import { attach, combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'
import * as z from 'zod'
import { AxiosErrorType, Taxipark } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { createCache } from '~/shared/lib/mapCacheFactory'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export type FormValues = z.infer<typeof Taxipark.schema>

export const domain = createDomain('features.taxipark.information')
export const Gate = createGate<{ taxiparkId: UniqueId }>()

export const $taxiparkId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { taxiparkId }) => taxiparkId)

export const requestFx = domain.createEffect<UniqueId, Taxipark>({
  handler: fetchTaxipark,
})

async function fetchTaxipark(id: UniqueId) {
  const response = await Taxipark.find(id)
  return response.getData() as Taxipark
}

sample({
  clock: $taxiparkId,
  filter: isString,
  target: requestFx,
})

const {
  $cache: $taxiparkCache,
  useCache: useTaxiparkCache,
  updateCache,
} = createCache<Taxipark>({
  domain,
  getEntityId: (taxipark) => taxipark.getApiId() as UniqueId,
})

export { useTaxiparkCache }

export const $taxipark = combine($taxiparkId, $taxiparkCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

export const saveFx = domain.createEffect<Taxipark, Taxipark, AxiosErrorType>({
  async handler(taxipark) {
    await taxipark.save()
    return fetchTaxipark(taxipark.getApiId() as UniqueId)
  },
})

export const taxiparkUpdateFx = attach({
  effect: saveFx,
  source: $taxiparkId,
  mapParams: (values: FormValues, id) => {
    return new Taxipark(values, id as string)
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: taxiparkUpdateFx,
})

sample({
  clock: taxiparkUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: taxiparkUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

$taxiparkCache
  .on(requestFx.doneData, (cache, taxipark) => updateCache(cache, [taxipark]))
  .on(saveFx.doneData, (cache, taxipark) =>
    updateCache(cache, [taxipark], true),
  )

export const $taxiparkError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useInformationError = (id: UniqueId) =>
  useStoreMap($taxiparkError, (errors) => errors[id])
