import { createDomain, sample } from 'effector'
import { AxiosErrorType, Tasks } from '~/shared/api'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('driver.aggregators.balanceTransferModal')

export const balanceTransferFx = domain.createEffect<
  {
    fromAggregatorProfileId: UniqueId
    toAggregatorProfileId: UniqueId
  },
  void,
  AxiosErrorType
>({
  handler: async ({ fromAggregatorProfileId, toAggregatorProfileId }) => {
    await Tasks.balanceTransfer({
      fromAggregatorProfileId,
      toAggregatorProfileId,
    })
  },
})

sample({
  clock: balanceTransferFx.doneData,
  fn() {
    return {
      message: 'Баланс успешно перенесен!',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: balanceTransferFx.failData,
  fn(e) {
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
