import { Box, Stack } from '@mui/joy'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from 'src/widgets/AuditsModal'
import {
  EditCarLicensee,
  editCarLicenseeModel,
} from '~/features/Organization/CarLicensees/EditCarLicensee'
import { carLicenseeModel } from '~/entities/Organizations/CarLicensee'
import { usePermissions } from '~/entities/viewer'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Реквизиты', anchor: 'requisites' },
  { label: 'Сотрудники', anchor: 'employees' },
  { label: 'Контакты', anchor: 'contacts' },
]

export function EditCarLicenseePage() {
  const { carLicenseeId } = useParams<{ carLicenseeId: string }>()

  const { canAuditsView } = usePermissions()

  const isLoading = useStore(carLicenseeModel.requestFx.pending)
  const name = useStoreMap(editCarLicenseeModel.$carLicensee, (carLicensee) =>
    carLicensee?.getName(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Организации', link: '/organizations/car-licensees' },
      { title: 'Лицензиаты', link: '/organizations/car-licensees' },
      { title: name || '' },
    ],
    [name],
  )

  const error = carLicenseeModel.useCarLicenseeError(carLicenseeId as string)

  if (!carLicenseeId || error) {
    return <NotFound message='Не удалось загрузить лицензиата' />
  }

  return (
    <PageContainer title='Карточка лицензиата'>
      <Container isHeader>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <Box>
            <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
            <PageTitle loading={isLoading}>{name}</PageTitle>
          </Box>
          {canAuditsView && <AuditsModal auditableId={carLicenseeId} />}
        </Stack>
      </Container>

      <EditCarLicensee carLicenseeId={carLicenseeId} />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
