import { Box, Tooltip, Typography } from '@mui/joy'
import { GridColDef } from '@mui/x-data-grid'
import { myPalette } from '~/shared/lib/theme'
import { ColumnAmount, ColumnValue } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Водитель',
    field: 'driverFullName',
    sortable: false,
    minWidth: 330,
  },
  {
    headerName: 'Телефон',
    field: 'driverPhone',
    sortable: false,
    minWidth: 190,
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    sortable: false,
    minWidth: 130,
    renderCell: ({ value }) => {
      const amount = Number(value)
      if (isNaN(amount)) return <ColumnValue>{value}</ColumnValue>

      return (
        <ColumnAmount
          amount={amount}
          moveFunds={amount >= 0 ? 'COMING' : 'SPENDING'}
        />
      )
    },
  },
  {
    headerName: 'Комментарий',
    field: 'comment',
    sortable: false,
    minWidth: 320,
  },
  {
    headerName: 'Ошибки',
    field: 'errors',
    sortable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip
        variant='outlined'
        title={
          row.errors ? (
            <div>
              {row.errors.map((error) => (
                <Typography
                  sx={{
                    color: myPalette.red['700'],
                  }}
                  key={error}
                >
                  {error}
                </Typography>
              ))}
            </div>
          ) : null
        }
      >
        <Box>
          <ColumnValue
            sx={{
              color: myPalette.red['700'],
            }}
          >
            {(row.errors || []).join(', ')}
          </ColumnValue>
        </Box>
      </Tooltip>
    ),
  },
]
