import { createDomain, sample } from 'effector'
import { carModel } from 'src/entities/Car'
import { AxiosErrorType, Car, CarStatusUpdateType } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'

const domain = createDomain('features.car.status')

export const updateStatusFx = domain.createEffect<
  {
    formValues: CarStatusUpdateType
    carId: UniqueId
    fn: () => void
  },
  Promise<void>,
  AxiosErrorType
>({
  async handler({ formValues, carId, fn }) {
    await Car.updateStatus(formValues, carId)
    await carModel.requestSilentFx(carId)
    fn()
  },
})

sample({
  clock: updateStatusFx.doneData,
  fn() {
    return {
      message: 'Статус сменен',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: updateStatusFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
