import { TravelSheet } from '~/shared/api'
import { FileExportBlob } from '~/shared/ui/FileExport'

type ColumnPrintProps = {
  id: UniqueId
}

export function ColumnPrint({ id }: ColumnPrintProps) {
  const fn = async () => {
    return await TravelSheet.fetchFile(id)
  }

  return <FileExportBlob fn={fn} isIcon title='Распечатать путевой лист' />
}
