import { z } from 'zod'

import { ApiModel } from './core'

const schema = z.object({
  title: z.string(),
})

export type TelematicAccountAttributes = z.infer<typeof schema>

export class TelematicAccount extends ApiModel<typeof schema> {
  static jsonApiType = 'telematic-accounts'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions() {
    const response = await TelematicAccount.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
