import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, LinearProgress } from '@mui/joy'
import { useStore } from 'effector-react'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import { OptionTitle } from '~/shared/api/permission'
import {
  FormButtons,
  formButtonsModel,
  FormProvider,
  Switch,
  TextInput,
} from '~/shared/ui/Form'
import { Container, ContainerTitle } from '~/shared/ui/Layout'
import { mapPermissionsToGroupPermissions, mapSuccessData } from './helpers'
import { formSchema, FormSuccessValues, FormValues } from './model'

export type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  isPermissionsLoading?: boolean
  defaultValues?: Partial<FormValues>
  onSuccess: (formData: FormSuccessValues) => void
  permissions: OptionTitle[]
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  isPermissionsLoading,
  defaultValues,
  permissions,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)

  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const { reset } = form

  useEffect(() => {
    if (defaultValues) reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  const groupedPermissions = useMemo(
    () => mapPermissionsToGroupPermissions(permissions),
    [permissions],
  )

  return (
    <FormProvider
      form={form}
      onSuccess={() => onSuccess(mapSuccessData(form.getValues()))}
      canEdit={canEdit}
    >
      <Container>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <TextInput
              label='Название'
              name='name'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>

      {isPermissionsLoading && (
        <Container>
          <LinearProgress size='lg' />
        </Container>
      )}
      {!isPermissionsLoading &&
        groupedPermissions.map(([title, permissions]) => (
          <Element name={title} key={title}>
            <Container>
              <ContainerTitle>{title}</ContainerTitle>
              <Grid container spacing={2.5} rowSpacing={5} columnSpacing={5}>
                {permissions.map((permission) => (
                  <Grid key={`${permission?.id}`} xs={6}>
                    <Switch
                      name={permission.id}
                      label={permission.title}
                      readOnly={disabled}
                      skeletonShow={isLoading}
                      data-testid={permission.title}
                    />
                  </Grid>
                ))}
              </Grid>
            </Container>
          </Element>
        ))}

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
