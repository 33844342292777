import { Box } from '@mui/joy'
import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TravelSheet } from '~/shared/api'
import { openBlobFile } from '~/shared/lib/file/openBlobFile'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { Modal } from '~/shared/ui/Modal'
import { Form, FormValues } from './Form'

type TravelSheetCreateModalProps = {
  isShow: boolean
  onClose: () => void
}

export function TravelSheetCreateModal({
  isShow,
  onClose,
}: TravelSheetCreateModalProps) {
  const params = useParams<{ driverId: string }>()
  const driverId = params.driverId as string
  const queryClient = useQueryClient()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = useCallback(
    async ({ quantity }: FormValues) => {
      try {
        setIsSubmitting(true)
        const response = await TravelSheet.bulkCreate(driverId, quantity)

        openBlobFile(response)
        onClose()

        snackbarEnqueued({
          message: 'Путевые листы сформированы',
          variant: 'success' as const,
        })

        await queryClient.invalidateQueries({
          queryKey: ['travel-sheets-list'],
        })
      } catch (e) {
        logger.error(e)
        snackbarEnqueued({
          message: 'Ошибка формирования путевых листов',
          variant: 'error' as const,
        })
      } finally {
        setIsSubmitting(false)
      }
    },
    [driverId, onClose, queryClient],
  )

  return (
    <Modal
      isShow={isShow}
      onCancel={isSubmitting ? undefined : onClose}
      title='Формирование путевых листов'
      dialogProps={{
        sx: {
          minWidth: '500px',
          maxWidth: '500px',
        },
      }}
    >
      <Box sx={{ padding: '4px' }}>
        <Form
          onSuccess={handleSubmit}
          onCancel={onClose}
          isSubmitting={isSubmitting}
        />
      </Box>
    </Modal>
  )
}
