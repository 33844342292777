import { List, ListItem, Typography, Skeleton } from '@mui/joy'
import { Button } from '~/shared/ui/Buttons'
import { Container, ContainerTitle } from '~/shared/ui/Layout'

type TransactionListProps = {
  transactions?: { label: string; link: string }[]
  isLoading: boolean
}

export function TransactionList({
  transactions = [],
  isLoading,
}: TransactionListProps) {
  return (
    <Container data-testid='transactions-list' sx={{ height: '375px' }}>
      <ContainerTitle>Транзакции</ContainerTitle>

      {isLoading && (
        <Skeleton sx={{ position: 'relative', borderRadius: '12px' }} />
      )}

      {!isLoading && !transactions?.length && (
        <Typography>Транзакций нет</Typography>
      )}

      {!isLoading && (
        <List
          sx={{
            overflowX: 'auto',
          }}
        >
          {transactions.map((transaction) => (
            <ListItem key={transaction.link} sx={{ padding: '6px 0' }}>
              <Button
                sx={{
                  padding: '0',
                }}
                variant='textBrand'
                to={transaction.link}
              >
                {transaction.label}
              </Button>
            </ListItem>
          ))}
        </List>
      )}
    </Container>
  )
}
